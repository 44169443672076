import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { Button } from 'reactstrap'
import { findDOMNode } from 'react-dom';
import dragdots from '../assets/images/dragdots.png'

class AnimationItem extends Component {

	render() {
		const { playAnimation, animation, isDragging, connectDragSource, connectDropTarget } = this.props;
		const opacity = isDragging ? 0 : 1;

		return connectDragSource(connectDropTarget(
      <tr style={{ opacity, cursor: 'grab' }}>
        <td>
          <img valign="bottom" src={dragdots} height="13px" alt="Drag dots" />
        </td>
        <td valign="bottom">
          {animation.label}
        </td>
        <td valign="bottom">
          <Button color="primary" onClick={() => playAnimation(animation.value)}><i className="pe-7s-play"></i></Button>
        </td>
      </tr>
		));
	}
}

const animationSource = {

	beginDrag(props) {		
		return {			
			index: props.index,
			listId: props.listId,
			animation: props.animation
		};
	},

	endDrag(props, monitor) {
		const item = monitor.getItem();
		const dropResult = monitor.getDropResult();	

		if ( dropResult && dropResult.listId !== item.listId ) {
			props.removeAnimation(item.index);
		}
	}
};

const animationTarget = {

	hover(props, monitor, component) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;
		const sourceListId = monitor.getItem().listId;	

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Determine rectangle on screen
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			return;
		}

		// Time to actually perform the action
		if ( props.listId === sourceListId ) {
			props.moveAnimation(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			monitor.getItem().index = hoverIndex;
		}		
	}
};

export default flow(
	DropTarget("ANIMATION", animationTarget, connect => ({
		connectDropTarget: connect.dropTarget()
	})),
	DragSource("ANIMATION", animationSource, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	}))
)(AnimationItem);