import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";

class Teams extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      teams: [],
      newTeam: null,
      error: null,
      message: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.fetchTeams();
  }

  fetchTeams = () => {
    const url = `/teams`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          teams: response.data.map((config) => config.configuration),
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  newTeam = () => {
    const url = `/teams`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newTeam: response.data,
          redirect: true,
        });
      } else {
        this.setState({
          error: "Network error",
        });
      }
    });
  };

  deleteTeam = (id) => {
    const { selectedRobot } = this.state;
    const url = `/teams/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          teams: response.data.map((config) => config.configuration),
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  getMembersPreview = (team) => {
    let membersPreview = [];
    if (team.en.teamMembers.length > 0) {
      team.en.teamMembers.forEach((member) =>
        membersPreview.push(member.picture)
      );
    } else if (team.de.teamMembers.length > 0) {
      team.de.teamMembers.forEach((member) =>
        membersPreview.push(member.picture)
      );
    } else if (team.fr.teamMembers.length > 0) {
      team.fr.teamMembers.forEach((member) =>
        membersPreview.push(member.picture)
      );
    } else {
      team.it.teamMembers.forEach((member) =>
        membersPreview.push(member.picture)
      );
    }
    return membersPreview;
  };

  render() {
    const { teams, redirect, newTeam } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/team",
            state: {
              team: newTeam,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Teams</h1>
                <p className="category">
                  Here you can select a team to edit or create a new one
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h4 className="title">
                  Select a team to edit or create a new one
                </h4>
              </div>
              <Button
                onClick={this.newTeam}
                className="btn-success"
                style={{ position: "absolute", top: "25px", right: "40px" }}
              >
                New team <i className="pe-7s-plus"></i>
              </Button>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Preview</th>
                      <th width="40px">Edit</th>
                      <th width="40px">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teams === null
                      ? undefined
                      : teams.map((team) => (
                          <tr key={team._id}>
                            <td className="td-name">
                              {team.en.name !== ""
                                ? team.en.name
                                : team.de.name !== ""
                                ? team.de.name
                                : team.fr.name !== ""
                                ? team.fr.name
                                : team.it.name}
                            </td>
                            <td>
                              {this.getMembersPreview(team).map((preview) => (
                                <img
                                  src={`${preview}`}
                                  height="70px"
                                  style={{ marginRight: "5px" }}
                                  alt="Preview"
                                />
                              ))}
                            </td>
                            <td className="td-name">
                              <Button
                                tag={Link}
                                to={{
                                  pathname: "/team",
                                  state: {
                                    team: team,
                                  },
                                }}
                                color="primary"
                              >
                                <i className="pe-7s-pen"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => this.deleteTeam(team._id)}
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Teams;
