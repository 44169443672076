export const types = [
    { label: 'Calendar', value: 'calendar' },
    { label: 'FAQ', value: 'faq' },
    { label: 'Presentation', value: 'presentation' },
    { label: 'Quizz', value: 'quizz' },
    { label: 'Survey', value: 'survey' },
    { label: 'Team', value: 'team' },
    { label: 'Service contact', value: 'servicecontact' },
    { label: 'Entertainment applications', value: 'entertainment' },
    { label: 'News feed', value: 'news' },
    { label: 'Data collector', value: 'datacollector' },
    { label: 'Barcode', value: 'barcode' },
  ];
  
  export const typesToEndpoint = {
    calendar: 'calendars',
    faq: 'faqs',
    presentation: 'presentations',
    quizz: 'quizzs',
    survey: 'surveys',
    team: 'teams',
    servicecontact: 'servicecontacts',
    entertainment: 'entertainments',
    news: 'news',
    datacollector: 'datacollectors',
    barcode: 'barcodes'
  };