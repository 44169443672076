import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "../../../utils/AxiosService";
import { robotTypes } from "../constants/robotTypes";

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class AddRobots extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      robotId: "",
      robotName: "",
      robotType: "pepper",
      message: null,
    };
  }

  addRobot = () => {
    const { robotId, robotName, robotType } = this.state;
    const { companyId, updateRobotList, fetchRobots } = this.props;
    const url = `/robots`;
    axios
      .post(url, { robotId, name: robotName, companyId, type: robotType })
      .then((res) => {
        if (res.status === 200) {
          toast("Robot created", { type: "success" });
          updateRobotList();
          fetchRobots();
        } else {
          toast("Server error", { type: "error" });
        }
      });
  };

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { message, robotType } = this.state;
    const { changesMade } = this.props;
    return (
      <div className="admin-container">
        <div className="admin-container">
          <div className="form-container">
            <Form>
              <FormGroup>
                <Label for="robotId">Id</Label>
                <Input
                  onChange={(event) =>
                    this.setState(byPropKey("robotId", event.target.value))
                  }
                  type="text"
                  name="robotId"
                  id="robotId"
                  placeholder="Robot id"
                />
                <Label for="robotName">Name</Label>
                <Input
                  onChange={(event) =>
                    this.setState(byPropKey("robotName", event.target.value))
                  }
                  type="text"
                  name="robotName"
                  id="robotName"
                  placeholder="Robot name"
                />
                <Label for="robotType">Robot Type</Label>
                <Dropdown
                  isSearchable
                  className="dropdown-speechaction"
                  options={robotTypes}
                  onChange={(field) =>
                    this.setState({ robotType: field.value })
                  }
                  value={{ label: robotType, value: robotType }}
                />
              </FormGroup>
              {changesMade ? (
                <Popup
                  trigger={
                    <span>
                      <button className="btn-primary">Create</button>
                    </span>
                  }
                  position="right center"
                  modal={true}
                >
                  {(close) => (
                    <div style={{ position: "relative" }}>
                      <div className="header" style={{ marginBottom: "20px" }}>
                        <h2>Confirmation</h2>
                      </div>
                      <div className="content">
                        <p>
                          All unsaved modifications to robots will be lost. Do
                          you want to proceed?
                        </p>
                      </div>
                      <br />
                      <br />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          right: "15px",
                        }}
                      >
                        <Button onClick={close}>Cancel</Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          className="btn-primary"
                          onClick={() => this.addRobot()}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  )}
                </Popup>
              ) : (
                <Button
                  className="btn-primary"
                  onClick={() => this.addRobot(null)}
                >
                  Create
                </Button>
              )}
            </Form>
            {message !== null ? (
              <Alert color={message.color}>{message.text}</Alert>
            ) : undefined}
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}
export default AddRobots;
