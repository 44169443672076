import React, { Component } from "react";
import { SketchPicker } from "react-color";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import uuidv4 from "uuid/v4";
import LanguagePicker from "../../components/LanguagePicker";
import SpeechAction from "../../components/SpeechAction";
import languages from "../../constants/languages";
import axios from "../../utils/AxiosService";
import { hexToRGBA } from "../../utils/colorConverter";
import * as constants from "./constants";

class DataCollector extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    const { dc } = props.location.state;
    this.state = {
      lang: "en",
      dc,
      changesMade: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  changeName = (name) => {
    const { dc, lang } = this.state;
    const tmpDc = dc;
    tmpDc[lang].name = name;
    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  changeFieldName = (name, index) => {
    const { dc, lang } = this.state;
    const tmpDc = dc;
    tmpDc[lang].fields[index].name = name;
    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  changeFieldType = (type, index) => {
    const { dc } = this.state;
    const tmpDc = dc;
    languages.forEach((lang) => {
      tmpDc[lang].fields[index].type = type.value;
    });

    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  changeStorage = (type) => {
    const { dc } = this.state;
    const tmpDc = dc;
    tmpDc.storage = type.value;
    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  changeReceiver = (type) => {
    const { dc } = this.state;
    const tmpDc = dc;
    tmpDc.receiver = type.value;
    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  changeFieldMandatory = (index) => {
    const { dc } = this.state;
    const tmpDc = dc;
    languages.forEach((lang) => {
      tmpDc[lang].fields[index].isMandatory =
        !tmpDc[lang].fields[index].isMandatory;
    });

    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  addField = () => {
    const { dc } = this.state;
    const tmpDc = dc;
    languages.forEach((lang) => {
      tmpDc[lang].fields.push({
        _id: uuidv4(),
        name: "",
        type: "text",
        isMandatory: false,
      });
    });
    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  saveDc = () => {
    const { selectedRobot } = this.props;
    const { dc } = this.state;
    const url = `/datacollectors/${dc._id}/robots/${selectedRobot._id}`;
    axios.put(url, { datacollector: dc }).then((response) => {
      if (response.status === 200) {
        toast("Data collector saved", { type: "success" });
        this.setState({
          dc: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  selectStoryline = (action, storyline) => {
    const { dc } = this.state;
    const tmpDc = dc;
    languages.forEach((lang) => {
      tmpDc[lang][action] = storyline;
    });
    this.setState({
      dc: tmpDc,
      changesMade: true,
    });
  };

  deleteField = (index) => {
    const { dc } = this.state;
    const tempDc = dc;
    languages.forEach((lang) => {
      tempDc[lang].fields.splice(index, 1);
    });

    this.setState({
      dc: tempDc,
      changesMade: true,
    });
  };

  changeStyle = (field, value) => {
    const { dc } = this.state;
    const tempDc = dc;
    if (tempDc.style === undefined) {
      tempDc.style = {};
    }
    tempDc.style[field] = value;
    console.log(field);
    console.log(value);
    this.setState({
      dc: tempDc,
      changesMade: true,
    });
  };

  render() {
    const { dc, lang } = this.state;
    const { selectedRobot } = this.props;
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Data collector</h1>
                <p className="category">Select the data you want to collect</p>
              </div>
              <div className="content">
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={dc[lang].speechAction}
                  selectStoryline={(value) =>
                    this.selectStoryline("speechAction", value)
                  }
                />
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={dc[lang].resultAction}
                  selectStoryline={(value) =>
                    this.selectStoryline("resultAction", value)
                  }
                  name="Result Action"
                  params={dc[lang].fields.map((field) =>
                    field.name.replace(" ", "_").toLowerCase()
                  )}
                />
                <h4>Name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Data collector name"
                  value={dc[lang].name}
                />
                <h4>Receiver</h4>
                <Dropdown
                  onChange={(value) => this.changeReceiver(value)}
                  options={[
                    { label: "cms", value: "cms" },
                    { label: "Waiting Room", value: "waitingroom" },
                  ]}
                  name="storage"
                  placeholder="Store data"
                  value={{ label: dc.receiver, value: dc.receiver }}
                />
                <h4>Store data</h4>
                <Dropdown
                  isDisabled={dc.receiver !== "cms"}
                  onChange={(value) => this.changeStorage(value)}
                  options={constants.storageTypes}
                  name="storage"
                  placeholder="Store data"
                  value={{ label: dc.storage, value: dc.storage }}
                />
                <br />
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/datacollectors">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveDc}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Fields</h1>
                <p className="category">Here you can add fields to the form</p>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Field name</th>
                      <th>Type</th>
                      <th>Required</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dc === null || dc === undefined
                      ? undefined
                      : dc[lang].fields.map((field, index) => (
                          <tr key={field._id}>
                            <td>
                              <Input
                                onChange={(event) =>
                                  this.changeFieldName(
                                    event.target.value,
                                    index
                                  )
                                }
                                type="text"
                                name="fieldName"
                                placeholder="Field name"
                                value={field.name}
                              />
                            </td>
                            <td>
                              <Dropdown
                                onChange={(value) =>
                                  this.changeFieldType(value, index)
                                }
                                options={constants.fieldTypes}
                                name="fieldName"
                                placeholder="Field type"
                                value={{ label: field.type, value: field.type }}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={() =>
                                  this.changeFieldMandatory(index)
                                }
                                type="checkbox"
                                name="mandatory"
                                defaultChecked={field.isMandatory}
                              />
                            </td>
                            <td>
                              <Button
                                onClick={() => this.deleteField(index)}
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <Button color="success" onClick={this.addField}>
                  New field <i className="pe-7s-plus"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Style</h1>
                <p className="category">
                  Here change the visuals of the data collector
                </p>
              </div>
              <div className="content">
                <h4>Text color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          dc !== null &&
                          dc !== undefined &&
                          dc.style !== undefined
                            ? dc.style.textColor
                            : undefined,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top left"
                >
                  <SketchPicker
                    color={
                      dc !== null && dc !== undefined && dc.style !== undefined
                        ? hexToRGBA(dc.style.textColor)
                        : undefined
                    }
                    onChange={(value) =>
                      this.changeStyle("textColor", value.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default DataCollector;
