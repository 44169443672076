export const fieldTypes = [
    { label: 'text', value: 'text' },
    { label: 'number', value: 'number' },
    { label: 'date', value: 'date' },
    { label: 'phone', value: 'phone' },
  ];

export const storageTypes = [
    { label: 'offline', value: 'offline' },
    { label: 'online', value: 'online' }
]