import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "../../../utils/AxiosService";

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class AddAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountMail: "",
      accountPassword: "",
      accountSecPassword: "",
    };
  }

  addAccount = () => {
    const { accountMail, accountPassword, accountSecPassword } = this.state;
    const { companyId } = this.props;
    if (
      accountPassword !== "" &&
      accountMail !== "" &&
      accountSecPassword !== "" &&
      accountPassword === accountSecPassword
    ) {
      const url = `/signup`;
      axios
        .post(url, { email: accountMail, password: accountPassword, companyId })
        .then((res) => {
          if (res.status === 200) {
            toast("Account created", { type: "success" });
          } else {
            toast("Server error", { type: "error" });
          }
        });
    } else if (accountPassword !== accountSecPassword) {
      toast("Passwords are not matching", { type: "error" });
      setTimeout(() => {
        this.setState({
          passwordEmpty: false,
          secPasswordEmpty: false,
          mailEmpty: false,
        });
      });
    } else {
      toast("Please fill up all required fields", { type: "error" });
      setTimeout(() => {
        this.setState({
          passwordEmpty: false,
          secPasswordEmpty: false,
          mailEmpty: false,
        });
      }, 3000);
      this.setState({
        passwordEmpty: accountPassword === "",
        secPasswordEmpty: accountSecPassword === "",
        mailEmpty: accountMail === "",
      });
    }
  };

  render() {
    const { passwordEmpty, secPasswordEmpty, mailEmpty } = this.state;
    return (
      <div className="admin-container">
        <div className="admin-container">
          <div className="form-container">
            <Form>
              <FormGroup>
                <Label for="accountMail">Email</Label>
                <Input
                  style={mailEmpty ? { border: "#d14e4e 1px solid" } : null}
                  onChange={(event) =>
                    this.setState(byPropKey("accountMail", event.target.value))
                  }
                  type="email"
                  name="accountMail"
                  id="accountMail"
                  placeholder="Email"
                />
                <Label for="accountPassword">Password</Label>
                <Input
                  style={passwordEmpty ? { border: "#d14e4e 1px solid" } : null}
                  onChange={(event) =>
                    this.setState(
                      byPropKey("accountPassword", event.target.value)
                    )
                  }
                  type="password"
                  name="accountPassword"
                  id="accountPassword"
                  placeholder="Password"
                />
                <Label for="accountSecPassword">Repeat Password</Label>
                <Input
                  style={
                    secPasswordEmpty ? { border: "#d14e4e 1px solid" } : null
                  }
                  onChange={(event) =>
                    this.setState(
                      byPropKey("accountSecPassword", event.target.value)
                    )
                  }
                  type="password"
                  name="accountSecPassword"
                  id="accountSecPassword"
                  placeholder="Repeat password"
                />
              </FormGroup>
              <Button className="btn-primary" onClick={this.addAccount}>
                Create
              </Button>
            </Form>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default AddAccount;
