import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import SpeechAction from "../../../components/SpeechAction";
import TextPreview from "../../../components/TextPreview";
import languages from "../../../constants/languages";
import axios from "../../../utils/AxiosService";

class MemberList extends React.Component {
  constructor(props) {
    super(props);
    this.changeText = this.changeText.bind(this);
    this.changeAnimation = this.changeAnimation.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    const { team } = this.props;
    this.state = {
      team: team,
      changesMade: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  addMember = () => {
    const { team } = this.state;
    const url = `/teams/${team._id}`;
    axios
      .post(url)
      .then((result) => {
        let tempTeam = team;
        const languages = ["en", "de", "fr", "it"];
        languages.forEach((lang) => {
          tempTeam[lang].teamMembers.push(result.data[lang].teamMembers.pop());
        });

        this.setState({
          team: tempTeam,
          changesMade: true,
        });
      })
      .catch((err) => {
        toast("Network error", { type: "error" });
      });
  };

  changeText = (text) => {
    const { lang } = this.props;
    const { team } = this.state;
    const tempTeam = team;
    tempTeam[lang].speechAction.text = text;

    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { lang } = this.props;
    const { team } = this.state;
    const tempTeam = team;
    tempTeam[lang].speechAction.animation = animation;
    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  changeName = (name, memberId) => {
    const { lang } = this.props;
    const { team } = this.state;
    const tempTeam = team;
    tempTeam[lang].teamMembers.find((member) => member._id === memberId).name =
      name;
    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  changeJobTitle = (jobTitle, memberId) => {
    const { lang } = this.props;
    const { team } = this.state;
    const tempTeam = team;
    tempTeam[lang].teamMembers.find(
      (member) => member._id === memberId
    ).jobTitle = jobTitle;
    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  changeDescription = (description, memberId) => {
    const { lang } = this.props;
    const { team } = this.state;
    const tempTeam = team;
    tempTeam[lang].teamMembers.find(
      (member) => member._id === memberId
    ).description = description;
    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  changeMemberImage = (memberInd, event) => {
    const { team } = this.state;
    const url = `/teams/${team._id}/members/${memberInd}/image`;
    const formData = new FormData();
    formData.append("myImage", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios.put(url, formData, config).then((response) => {
      let tempTeam = team;
      const languages = ["en", "de", "fr", "it"];
      languages.forEach((lang) => {
        tempTeam[lang].teamMembers[memberInd].picture =
          response.data[lang].teamMembers[memberInd].picture;
      });

      this.setState({
        team: tempTeam,
        changesMade: true,
      });
    });
  };

  deleteTeamMember = (memberInd) => {
    const { team } = this.state;
    let tempTeam = team;
    const languages = ["en", "de", "fr", "it"];
    languages.forEach((lang) => {
      tempTeam[lang].teamMembers.splice(memberInd, 1);
    });

    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  saveTeam = () => {
    const { selectedRobot } = this.props;
    const { team } = this.state;
    const url = `/teams/${team._id}/robot/${selectedRobot._id}`;
    axios.put(url, { team: team }).then((response) => {
      if (response.status === 200) {
        toast("Team saved", { type: "success" });
        this.setState({
          team: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  changeTeamName = (name) => {
    const { lang } = this.props;
    const { team } = this.state;
    const tempTeam = team;
    tempTeam[lang].name = name;
    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  selectStoryline = (storyline) => {
    const { team } = this.state;
    let tempTeam = team;
    languages.forEach((lang) => {
      tempTeam[lang].speechAction = storyline;
    });

    this.setState({
      team: tempTeam,
      changesMade: true,
    });
  };

  render() {
    const { lang, selectedRobot } = this.props;
    const { team } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Team</h1>
                <p className="category">
                  A description of the people of the team
                </p>
              </div>
              <div className="content">
                <div
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  <Link to="/teams">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={() => this.saveTeam()}>
                    Save changes
                  </Button>
                </div>
                <br />
                {team === null ? undefined : (
                  <React.Fragment>
                    <SpeechAction
                      selectedRobot={selectedRobot}
                      storyline={team[lang].speechAction}
                      selectStoryline={this.selectStoryline}
                    />
                    <h4 style={{ textAlign: "left" }}>Team name</h4>
                    <Input
                      onChange={(event) =>
                        this.changeTeamName(event.target.value)
                      }
                      type="text"
                      placeholder="Team name"
                      value={team[lang].name}
                    />
                  </React.Fragment>
                )}
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Picture</th>
                      <th>Name</th>
                      <th>Job title</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {team === null
                      ? undefined
                      : team[lang].teamMembers.map((member, index) => (
                          <tr key={member._id}>
                            <td>
                              <div style={{ display: "flex", flexFlow: "row" }}>
                                <div
                                  className="img-container"
                                  style={{ width: "80%" }}
                                >
                                  <img
                                    height="100px"
                                    src={`${member.picture}`}
                                    alt="Member"
                                  />
                                </div>
                                <div style={{ width: "20%" }}>
                                  <label
                                    htmlFor="slide-file"
                                    className="input-image"
                                    style={{ marginTop: "0px" }}
                                  >
                                    <i className="fa fa-image"></i>
                                    <input
                                      id="slide-file"
                                      type="file"
                                      accept="image/*"
                                      onChange={(event) =>
                                        this.changeMemberImage(index, event)
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                            </td>
                            <td className="td-name">
                              <div className="oral-input">
                                <Input
                                  onChange={(event) =>
                                    this.changeName(
                                      event.target.value,
                                      member._id
                                    )
                                  }
                                  type="text"
                                  name="name"
                                  id="name"
                                  placeholder="Member name"
                                  value={member.name}
                                />
                                <TextPreview
                                  text={member.name}
                                  robotId={selectedRobot._id}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="oral-input">
                                <Input
                                  onChange={(event) =>
                                    this.changeJobTitle(
                                      event.target.value,
                                      member._id
                                    )
                                  }
                                  type="text"
                                  name="jobtitle"
                                  id="jobtitle"
                                  placeholder="Member job title"
                                  value={member.jobTitle}
                                />
                                <TextPreview
                                  text={member.jobTitle}
                                  robotId={selectedRobot._id}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="oral-input">
                                <Input
                                  onChange={(event) =>
                                    this.changeDescription(
                                      event.target.value,
                                      member._id
                                    )
                                  }
                                  type="text"
                                  name="description"
                                  id="description"
                                  placeholder="Member description"
                                  value={member.description}
                                />
                                <TextPreview
                                  text={member.description}
                                  robotId={selectedRobot._id}
                                />
                              </div>
                            </td>
                            <td className="td-actions">
                              <button
                                type="button"
                                onClick={() => this.deleteTeamMember(index)}
                                rel="tooltip"
                                data-placement="left"
                                title=""
                                className="btn btn-danger btn-simple btn-icon "
                                data-original-title="Remove Member"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={this.addMember}
                >
                  Add member
                </Button>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </React.Fragment>
    );
  }
}

export default MemberList;
