import React, { Component } from "react";
import Dropdown from "react-select";
import { Button } from "reactstrap";
import LanguagePicker from "../../../components/LanguagePicker";
import axios from "../../../utils/AxiosService";
import * as moduleTypes from "../constants/moduleTypes";

class ModuleSelection extends Component {
  constructor(props) {
    super(props);

    this.setLang = this.setLang.bind(this);

    this.state = {
      lang: "en",
      selectedModule: null,
      modules: {},
      moduleType: "",
      modulesLoaded: false,
    };
  }

  componentDidMount() {
    const { selectedModule, lang } = this.props;
    if (selectedModule !== null) {
      const moduleType = moduleTypes.types.find(
        (type) => type.value === selectedModule.module.moduleType
      );
      this.setState(
        {
          moduleType: moduleType,
          selectedModule: {
            label: selectedModule.module.name[lang],
            value: selectedModule.module.moduleId,
          },
        },
        () => this.fetchModules(moduleType.value)
      );
    }
  }

  fetchModules = (type) => {
    const url = `/${moduleTypes.typesToEndpoint[type]}`;
    axios.get(url).then((response) => {
      this.setState({
        modules: response.data.map((config) =>
          config.configuration !== undefined ? config.configuration : config
        ),
        modulesLoaded: true,
      });
    });
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  selectModule = (selectedModule) => {
    this.setState({
      selectedModule: selectedModule,
    });
  };

  selectModuleType = (type) => {
    this.setState(
      {
        moduleType: type,
        modulesLoaded: false,
        selectedModule: null,
      },
      this.fetchModules(type.value)
    );
  };

  saveModule = () => {
    const { i, j, selectModule, closeFunc } = this.props;
    const { modules, selectedModule, moduleType } = this.state;
    const finalMod = modules.find((mod) => mod._id === selectedModule.value);
    selectModule(i, j, { type: moduleType, module: finalMod });
    closeFunc();
  };

  render() {
    const { lang, modules, moduleType, modulesLoaded, selectedModule } =
      this.state;

    return (
      <div>
        <LanguagePicker setLang={this.setLang} />
        <h6>Module type</h6>
        <Dropdown
          className="dropdown-speechaction"
          options={moduleTypes.types}
          onChange={this.selectModuleType}
          value={moduleType}
          placeholder="Select a module type"
        />
        <br />
        {!modulesLoaded ? undefined : (
          <React.Fragment>
            <h6>Module</h6>
            <Dropdown
              className="dropdown-speechaction"
              options={modules
                .filter((mod) => mod !== undefined)
                .map((mod) => ({
                  label: mod.hasOwnProperty(lang) ? mod[lang].name : mod.name,
                  value: mod._id,
                }))}
              onChange={this.selectModule}
              value={selectedModule}
              placeholder="Select a module"
            />
            <br />
          </React.Fragment>
        )}
        <Button disabled={selectedModule === null} onClick={this.saveModule}>
          Save
        </Button>
        <br />
      </div>
    );
  }
}

export default ModuleSelection;
