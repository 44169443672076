import Slider from "rc-slider";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import { animations } from "../../constants/animations";
import axios from "../../utils/AxiosService";
import AnimationList from "./components/AnimationList";
import StorylineList from "./components/StorylineList";
import Stream from "./components/Stream";

class YeoTheater extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.playAnimation = this.playAnimation.bind(this);
    const { selectedRobot } = this.props;
    this.state = {
      animationsList: [...animations[selectedRobot.type]],
      yeotheater: null,
      selectedRobot,
      changesMade: false,
      volume: 0,
      selectedStorylines: -1,
      selectedDialogs: -1,
      selectedAnimations: -1,
      newListName: "",
      animationsFilter: "",
      storylines: [],
      streamConnected: false,
      srcObject: null,
    };
  }

  componentDidMount() {
    this.getRobotVolume();
    this.fetchYeotheater();
    this.fetchStorylines();
  }

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchYeotheater = () => {
    const url = `/yeotheater`;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          yeotheater: response.data,
        });
      })
      .catch(() => {
        toast("Server error during fetching", { type: "error" });
      });
  };

  fetchStorylines = () => {
    const url = `/storylines`;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          storylines: response.data,
        });
      })
      .catch(() => {
        toast("Server error during fetching storylines", { type: "error" });
      });
  };

  getRobotVolume = () => {
    const { selectedRobot } = this.state;

    const url = `/debug/robotinfo/${selectedRobot._id}`;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          volume: response.data.volume,
        });
      })
      .catch(() => {
        toast("Robot not connected", { type: "error" });
      });
  };

  setRobotVolume = () => {
    const { selectedRobot, volume } = this.state;

    const url = `/debug/volume/${selectedRobot._id}`;
    axios
      .post(url, { volume })
      .then((response, status) => {
        if (status === 200) {
          toast("Volume set", { type: "success" });
        }
      })
      .catch(() => {
        toast("Robot not connected", { type: "error" });
      });
  };

  playAnimation = (animation) => {
    const { selectedRobot } = this.state;
    const url = `/debug/animationpreview/${selectedRobot._id}`;
    axios
      .post(url, { animation })
      .then((response) => {
        if (response.status === 200) {
          toast("Animation playing", { type: "success" });
        }
      })
      .catch(() => {
        toast("Robot not connected", { type: "error" });
      });
  };

  playStoryline = (storyline) => {
    const { selectedRobot } = this.state;
    const url = `/debug/storylinepreview/${selectedRobot._id}`;
    axios
      .post(url, { storyline })
      .then((response) => {
        if (response.status === 200) {
          toast("Storyline playing", { type: "success" });
        }
      })
      .catch(() => {
        toast("Robot not connected", { type: "error" });
      });
  };

  sendRobotVolume = () => {
    const { selectedRobot, volume } = this.state;
    const url = `/debug/volume/${selectedRobot._id}`;
    axios
      .post(url, { volume })
      .then((response) => {
        if (response.status === 200) {
          toast("Volume set", { type: "success" });
        }
      })
      .catch(() => {
        toast("Robot not connected", { type: "error" });
      });
  };

  addFavoriteList = (type) => {
    const { yeotheater, newListName, selectedRobot } = this.state;
    const tmpYeotheater = yeotheater;
    const newList = { name: newListName, items: [] };
    tmpYeotheater[type].push(newList);
    switch (type) {
      case "favoriteDialogs":
        this.setState({
          selectedDialogs: tmpYeotheater[type].length - 1,
          yeotheater: tmpYeotheater,
          newListName: "",
          changesMade: true,
        });
        break;
      case "favoriteAnimations":
        this.setState({
          selectedAnimations: tmpYeotheater[type].length - 1,
          animationsList: [...animations[selectedRobot.type]],
          yeotheater: tmpYeotheater,
          newListName: "",
          changesMade: true,
        });
        break;
      case "favoriteStorylines":
        this.setState({
          selectedStorylines: tmpYeotheater[type].length - 1,
          yeotheater: tmpYeotheater,
          newListName: "",
          changesMade: true,
        });
        break;
      default:
        break;
    }
  };

  addDialog = () => {
    const { yeotheater, selectedDialogs } = this.state;
    const tmpYeotheater = yeotheater;
    tmpYeotheater.favoriteDialogs[selectedDialogs].items.push("");
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  };

  deleteDialog = (index) => {
    const { yeotheater, selectedDialogs } = this.state;
    const tmpYeotheater = yeotheater;
    tmpYeotheater.favoriteDialogs[selectedDialogs].items.splice(index, 1);
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  };

  changeDialogField = (value, index) => {
    const { yeotheater, selectedDialogs } = this.state;
    const tmpYeotheater = yeotheater;
    tmpYeotheater.favoriteDialogs[selectedDialogs].items[index] = value;
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  };

  previewDialog = (index) => {
    const { yeotheater, selectedDialogs, selectedRobot } = this.state;
    const url = `/debug/textpreview/${selectedRobot._id}`;
    axios
      .post(url, {
        speech: yeotheater.favoriteDialogs[selectedDialogs].items[index],
      })
      .then((response) => {
        if (response.status === 200) {
          toast("Text sent", { type: "success" });
        } else {
          toast("Robot not connected", { type: "error" });
        }
      });
  };

  saveYeotheater = () => {
    const { selectedRobot, yeotheater } = this.state;
    const url = `/yeotheater/${yeotheater._id}/robots/${selectedRobot._id}`;
    axios
      .put(url, { yeotheater })
      .then((response) => {
        toast("Modifications saved", { type: "success" });
        this.setState({
          yeotheater: response.data,
          changesMade: false,
        });
      })
      .catch((error) => {
        toast("Modifications not saved: Server error", { type: "error" });
      });
  };

  pushAnimation(animation) {
    const { yeotheater, selectedAnimations } = this.state;
    const tmpYeotheater = yeotheater;
    tmpYeotheater.favoriteAnimations[selectedAnimations].items.push(animation);
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  }

  removeAnimation(index) {
    const { yeotheater, selectedAnimations } = this.state;
    const tmpYeotheater = yeotheater;
    tmpYeotheater.favoriteAnimations[selectedAnimations].items.splice(index, 1);
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  }

  moveAnimation(dragIndex, hoverIndex) {
    const { yeotheater, selectedAnimations } = this.state;
    const tmpYeotheater = yeotheater;
    const dragAnimation =
      tmpYeotheater.favoriteAnimations[selectedAnimations].items[dragIndex];
    tmpYeotheater.favoriteAnimations[selectedAnimations].items.splice(
      dragIndex,
      1
    );
    tmpYeotheater.favoriteAnimations[selectedAnimations].items.splice(
      hoverIndex,
      0,
      dragAnimation
    );
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  }

  pushStoryline(storyline) {
    const { yeotheater, selectedStorylines } = this.state;
    const tmpYeotheater = yeotheater;
    tmpYeotheater.favoriteStorylines[selectedStorylines].items.push(storyline);
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  }

  removeStoryline(index) {
    const { yeotheater, selectedStorylines } = this.state;
    const tmpYeotheater = yeotheater;
    tmpYeotheater.favoriteStorylines[selectedStorylines].items.splice(index, 1);
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  }

  moveStoryline(dragIndex, hoverIndex) {
    const { yeotheater, selectedStorylines } = this.state;
    const tmpYeotheater = yeotheater;
    const dragStoryline =
      tmpYeotheater.favoriteStorylines[selectedStorylines].items[dragIndex];
    tmpYeotheater.favoriteStorylines[selectedStorylines].items.splice(
      dragIndex,
      1
    );
    tmpYeotheater.favoriteStorylines[selectedStorylines].items.splice(
      hoverIndex,
      0,
      dragStoryline
    );
    this.setState({
      yeotheater: tmpYeotheater,
      changesMade: true,
    });
  }

  enterPressed(event, index) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.previewDialog(index);
    }
  }

  render() {
    const {
      selectedRobot,
      volume,
      yeotheater,
      selectedDialogs,
      selectedAnimations,
      selectedStorylines,
      newListName,
      animationsList,
      storylines,
    } = this.state;

    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">YeoTheater light</h1>
                <p className="category">Here you can control your robot</p>
              </div>
              <div className="content">
                <h2 className="title">Volume: {volume}</h2>
                <div style={{ display: "flex", flexFlow: "row" }}>
                  <Slider
                    defaultValue={volume}
                    min={0}
                    max={100}
                    value={volume}
                    onChange={(value) => this.setState({ volume: value })}
                  />
                  <Button
                    style={{ marginLeft: "30px" }}
                    color="primary"
                    onClick={this.sendRobotVolume}
                  >
                    Set
                  </Button>
                </div>
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button color="primary" onClick={this.saveYeotheater}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Quick text output</h2>
              </div>
              <div className="content">
                <div
                  style={{
                    position: "absolute",
                    top: "35px",
                    right: "46px",
                    width: "40%",
                    display: "flex",
                    flexFlow: "row",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <Dropdown
                      options={
                        yeotheater === null
                          ? []
                          : yeotheater.favoriteDialogs.map((dialog, index) => ({
                              label: dialog.name,
                              value: dialog,
                              index,
                            }))
                      }
                      onChange={(value) =>
                        this.setState({ selectedDialogs: value.index })
                      }
                      value={
                        selectedDialogs === -1
                          ? null
                          : {
                              label:
                                yeotheater.favoriteDialogs[selectedDialogs]
                                  .name,
                              value:
                                yeotheater.favoriteDialogs[selectedDialogs],
                            }
                      }
                      placeholder="Select a favorite dialog list..."
                    />
                  </div>
                  <div style={{ width: "15%" }}>
                    <Popup
                      trigger={
                        <span>
                          <Button
                            style={{ height: "38px", marginLeft: "5px" }}
                            color="success"
                          >
                            New list <i className="pe-7s-plus"></i>
                          </Button>
                        </span>
                      }
                      position="left center"
                    >
                      {(close) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px",
                            display: "flex",
                            flexFlow: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6>New list name</h6>
                          <Input
                            style={{ marginBottom: "10px" }}
                            type="text"
                            onChange={(event) =>
                              this.setState({ newListName: event.target.value })
                            }
                            value={newListName}
                            placeholder="New list name"
                          />
                          <Button
                            onClick={() => {
                              this.addFavoriteList("favoriteDialogs");
                              close();
                            }}
                            color="success"
                          >
                            Create <i className="pe-7s-plus"></i>
                          </Button>
                        </div>
                      )}
                    </Popup>
                  </div>
                </div>
                {selectedDialogs === -1 ||
                selectedDialogs === undefined ? undefined : (
                  <div>
                    <table className="table table-bigboy">
                      <thead>
                        <tr>
                          <th width="88%">Text</th>
                          <th width="6%">Delete</th>
                          <th width="6%">Preview</th>
                        </tr>
                      </thead>
                      <tbody>
                        {yeotheater.favoriteDialogs[selectedDialogs].items.map(
                          (field, index) => (
                            <tr>
                              <td>
                                <Input
                                  onChange={(event) =>
                                    this.changeDialogField(
                                      event.target.value,
                                      index
                                    )
                                  }
                                  type="text"
                                  name="fieldName"
                                  placeholder="Field name"
                                  value={field}
                                  onKeyPress={(event) =>
                                    this.enterPressed(event, index)
                                  }
                                />
                              </td>
                              <td>
                                <Button
                                  color="danger"
                                  onClick={() => this.deleteDialog(index)}
                                >
                                  <i className="pe-7s-trash"></i>
                                </Button>
                              </td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() => this.previewDialog(index)}
                                >
                                  <i className="pe-7s-volume"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <Button color="success" onClick={() => this.addDialog()}>
                      New dialog <i className="pe-7s-plus"></i>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Animations &#38; Dances</h2>
                <h6>Library:</h6>
              </div>
              <div
                style={{ display: "flex", flexFlow: "row" }}
                className="content"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "35px",
                    right: "46px",
                    width: "40%",
                    display: "flex",
                    flexFlow: "row",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <Dropdown
                      options={
                        yeotheater === null
                          ? []
                          : yeotheater.favoriteAnimations.map(
                              (animation, index) => ({
                                label: animation.name,
                                value: animation,
                                index,
                              })
                            )
                      }
                      onChange={(value) =>
                        this.setState({
                          selectedAnimations: value.index,
                          animationsList: [...animations[selectedRobot.type]],
                        })
                      }
                      value={
                        selectedAnimations === -1
                          ? null
                          : {
                              label:
                                yeotheater.favoriteAnimations[
                                  selectedAnimations
                                ].name,
                              value:
                                yeotheater.favoriteAnimations[
                                  selectedAnimations
                                ],
                            }
                      }
                      placeholder="Select a favorite animations list..."
                    />
                  </div>
                  <div style={{ width: "15%" }}>
                    <Popup
                      trigger={
                        <span>
                          <Button
                            style={{ height: "38px", marginLeft: "5px" }}
                            color="success"
                          >
                            New list <i className="pe-7s-plus"></i>
                          </Button>
                        </span>
                      }
                      position="left center"
                    >
                      {(close) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px",
                            display: "flex",
                            flexFlow: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6>New list name</h6>
                          <Input
                            style={{ marginBottom: "10px" }}
                            type="text"
                            onChange={(event) =>
                              this.setState({ newListName: event.target.value })
                            }
                            value={newListName}
                            placeholder="New list name"
                          />
                          <Button
                            onClick={() => {
                              this.addFavoriteList("favoriteAnimations");
                              close();
                            }}
                            color="success"
                          >
                            Create <i className="pe-7s-plus"></i>
                          </Button>
                        </div>
                      )}
                    </Popup>
                  </div>
                </div>
                <AnimationList
                  id={1}
                  list={animationsList}
                  playAnimation={this.playAnimation}
                />
                {selectedAnimations === -1 ||
                selectedAnimations === undefined ? undefined : (
                  <AnimationList
                    id={2}
                    list={
                      yeotheater.favoriteAnimations[selectedAnimations].items
                    }
                    pushAnimation={this.pushAnimation.bind(this)}
                    removeAnimation={this.removeAnimation.bind(this)}
                    moveAnimation={this.moveAnimation.bind(this)}
                    playAnimation={this.playAnimation}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Storylines</h2>
                <h6>Library:</h6>
              </div>
              <div
                style={{ display: "flex", flexFlow: "row" }}
                className="content"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "35px",
                    right: "46px",
                    width: "40%",
                    display: "flex",
                    flexFlow: "row",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <Dropdown
                      options={
                        yeotheater === null
                          ? []
                          : yeotheater.favoriteStorylines.map(
                              (storyline, index) => ({
                                label: storyline.name,
                                value: storyline,
                                index,
                              })
                            )
                      }
                      onChange={(value) =>
                        this.setState({
                          selectedStorylines: value.index,
                          storylinesList: [...storylines],
                        })
                      }
                      value={
                        selectedStorylines === -1
                          ? null
                          : {
                              label:
                                yeotheater.favoriteStorylines[
                                  selectedStorylines
                                ].name,
                              value:
                                yeotheater.favoriteStorylines[
                                  selectedStorylines
                                ],
                            }
                      }
                      placeholder="Select a favorite storyline list..."
                    />{" "}
                  </div>
                  <div style={{ width: "15%" }}>
                    <Popup
                      trigger={
                        <span>
                          <Button
                            style={{ height: "38px", marginLeft: "5px" }}
                            color="success"
                          >
                            New list <i className="pe-7s-plus"></i>
                          </Button>
                        </span>
                      }
                      position="left center"
                    >
                      {(close) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px",
                            display: "flex",
                            flexFlow: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6>New list name</h6>
                          <Input
                            style={{ marginBottom: "10px" }}
                            type="text"
                            onChange={(event) =>
                              this.setState({ newListName: event.target.value })
                            }
                            value={newListName}
                            placeholder="New list name"
                          />
                          <Button
                            onClick={() => {
                              this.addFavoriteList("favoriteStorylines");
                              close();
                            }}
                            color="success"
                          >
                            Create <i className="pe-7s-plus"></i>
                          </Button>
                        </div>
                      )}
                    </Popup>
                  </div>
                </div>
                <StorylineList
                  id={1}
                  list={storylines.map((storyline) => ({
                    label: storyline.name,
                    value: storyline,
                  }))}
                  playStoryline={this.playStoryline}
                />
                {selectedStorylines === -1 ||
                selectedStorylines === undefined ? undefined : (
                  <StorylineList
                    id={2}
                    list={
                      yeotheater.favoriteStorylines[selectedStorylines].items
                    }
                    pushStoryline={this.pushStoryline.bind(this)}
                    removeStoryline={this.removeStoryline.bind(this)}
                    moveStoryline={this.moveStoryline.bind(this)}
                    playStoryline={this.playStoryline}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Stream selectedRobot={selectedRobot} />
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default YeoTheater;
