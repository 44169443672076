import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";

class DataCollectors extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      dcs: [],
      selectedDc: null,
      newDc: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.fetchDcs();
  }

  fetchDcs = () => {
    const url = `/datacollectors`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          dcs: response.data.map((config) => config.configuration),
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  newDc = () => {
    const url = `/datacollectors`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newDc: response.data,
          redirect: true,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  deleteDc = (id) => {
    const { selectedRobot } = this.state;
    const url = `/datacollectors/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          dcs: response.data.map((config) => config.configuration),
          selectedDc: null,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  render() {
    const { dcs, redirect, newDc } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/datacollector",
            state: {
              dc: newDc,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Data collectors</h1>
                <p className="category">
                  Here you can select a data collector to edit or create a new
                  one
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <Button
                  onClick={this.newDc}
                  className="btn-success"
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  New data collector <i className="pe-7s-plus"></i>
                </Button>
                <h4 className="title">
                  Select a data collector to edit or create a new one
                </h4>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width="40px">Edit</th>
                      <th width="40px">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dcs === null
                      ? undefined
                      : dcs.map((dc) => (
                          <tr key={dc._id}>
                            <td className="td-name">
                              {dc.en.name !== ""
                                ? dc.en.name
                                : dc.de.name !== ""
                                ? dc.de.name
                                : dc.fr.name !== ""
                                ? dc.fr.name
                                : dc.it.name}
                            </td>
                            <td className="td-name">
                              <Button
                                tag={Link}
                                to={{
                                  pathname: "/datacollector",
                                  state: {
                                    dc: dc,
                                  },
                                }}
                                color="primary"
                              >
                                <i className="pe-7s-pen"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => this.deleteDc(dc._id)}
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default DataCollectors;
