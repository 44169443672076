export const animations = {
    pepper: [
        { label: 'None', value: 'none', file: '' },
        { label: 'Contextual', value: 'contextual', file: '' },
        { label: 'Winner', value: 'winner_animation', file: 'winner1.gif' },
        { label: 'Winner 2', value: 'winner2_animation', file: 'winner2.gif' },
        { label: 'Loser', value: 'loser_animation', file: 'loser.gif' },
        { label: 'Show tablet', value: 'show_tablet', file: 'show_tablet.gif' },
        { label: 'Air Guitar Loop', value: 'AirGuitar1_loop', file: '' },
        { label: 'Air Guitar Start', value: 'AirGuitar1_start', file: '' },
        { label: 'Back Rubs', value: 'BackRubs1', file: '' },
        { label: 'Bandmaster', value: 'Bandmaster1', file: '' },
        { label: 'Binoculars', value: 'Binoculars1', file: '' },
        { label: 'BreathLoop', value: 'BreathLoop1', file: '' },
        { label: 'BreathLoop 2', value: 'BreathLoop2', file: '' },
        { label: 'BreathLoop 3', value: 'BreathLoop3', file: '' },
        { label: 'Call Someone', value: 'CallSomeone1', file: '' },
        { label: 'Drink', value: 'Drink1', file: '' },
        { label: 'Drive Car', value: 'DriveCar1', file: '' },
        { label: 'Fitness', value: 'Fitness1', file: '' },
        { label: 'Fitness 2', value: 'Fitness2', file: '' },
        { label: 'Fitness 3', value: 'Fitness3', file: '' },
        { label: 'Funny Dancer', value: 'FunnyDancer1', file: '' },
        { label: 'Happy Birthday', value: 'HappyBirthday1', file: '' },
        { label: 'Helicopter', value: 'Helicopter1', file: '' },
        { label: 'Hide Eyes', value: 'HideEyes1', file: '' },
        { label: 'Hide Hands', value: 'HideHands1', file: '' },
        { label: 'Innocent', value: 'Innocent1', file: '' },
        { label: 'Knight', value: 'Knight1', file: '' },
        { label: 'Knock Eye', value: 'KnockEye1', file: '' },
        { label: 'Kung Fu', value: 'KungFu1', file: '' },
        { label: 'Look Hand', value: 'LookHand1', file: '' },
        { label: 'Look Hand', value: 'LookHand2', file: '' },
        { label: 'Love You', value: 'LoveYou1', file: '' },
        { label: 'Monster', value: 'Monster1', file: '' },
        { label: 'Mystical Power', value: 'MysticalPower1', file: '' },
        { label: 'Play Hands', value: 'PlayHands1', file: '' },
        { label: 'Play Hands 2', value: 'PlayHands2', file: '' },
        { label: 'Play Hands 3', value: 'PlayHands3', file: '' },
        { label: 'Relaxation', value: 'Relaxation1', file: '' },
        { label: 'Relaxation 2', value: 'Relaxation2', file: '' },
        { label: 'Relaxation 3', value: 'Relaxation3', file: '' },
        { label: 'Relaxation 4', value: 'Relaxation4', file: '' },
        { label: 'Rest', value: 'Rest1', file: '' },
        { label: 'Robot', value: 'Robot1', file: '' },
        { label: 'Scratch Back', value: 'ScratchBack1', file: '' },
        { label: 'Scratch Bottom', value: 'ScratchBottom1', file: '' },
        { label: 'Scratch Eye', value: 'ScratchEye1', file: '' },
        { label: 'Scratch Hand', value: 'ScratchHand1', file: '' },
        { label: 'Scratch Head', value: 'ScratchHead1', file: '' },
        { label: 'Scratch Leg', value: 'ScratchLeg1', file: '' },
        { label: 'Scratch Torso', value: 'ScratchTorso1', file: '' },
        { label: 'Show Muscles', value: 'ShowMuscles1', file: '' },
        { label: 'Show Muscles 2', value: 'ShowMuscles2', file: '' },
        { label: 'Show Muscles 3', value: 'ShowMuscles3', file: '' },
        { label: 'Show Muscles 4', value: 'ShowMuscles4', file: '' },
        { label: 'Show Muscles 5', value: 'ShowMuscles5', file: '' },
        { label: 'Show Sky', value: 'ShowSky1', file: '' },
        { label: 'Show Sky 2', value: 'ShowSky2', file: '' },
        { label: 'Space Shuttle', value: 'SpaceShuttle1', file: '' },
        { label: 'Stretch', value: 'Stretch1', file: '' },
        { label: 'Stretch 2', value: 'Stretch2', file: '' },
        { label: 'Take Picture', value: 'TakePicture1', file: '' },
        { label: 'Taxi', value: 'Taxi1', file: '' },
        { label: 'Think', value: 'Think1', file: '' },
        { label: 'Think 2', value: 'Think2', file: '' },
        { label: 'Think 3', value: 'Think3', file: '' },
        { label: 'Think 4', value: 'Think4', file: '' },
        { label: 'Waddle', value: 'Waddle1', file: '' },
        { label: 'Waddle 2', value: 'Waddle2', file: '' },
        { label: 'Wake Up', value: 'WakeUp1', file: '' },
        { label: 'Zombie', value: 'Zombie1', file: '' },
        { label: 'Ethernet Off', value: 'EthernetOff1', file: '' },
        { label: 'Ethernet On', value: 'EthernetOn1', file: '' },
        { label: 'Heat', value: 'Heat1', file: '' },
        { label: 'Heat 2', value: 'Heat2', file: '' },
        { label: 'Light Shine', value: 'LightShine1', file: '' },
        { label: 'Light Shine 2', value: 'LightShine2', file: '' },
        { label: 'Light Shine 3', value: 'LightShine3', file: '' },
        { label: 'Light Shine 4', value: 'LightShine4', file: '' },
        { label: 'See Color', value: 'SeeColor1', file: '' },
        { label: 'See Color 2', value: 'SeeColor2', file: '' },
        { label: 'See Color 3', value: 'SeeColor3', file: '' },
        { label: 'See Something', value: 'SeeSomething1', file: '' },
        { label: 'See Something 3', value: 'SeeSomething3', file: '' },
        { label: 'See Something 4', value: 'SeeSomething4', file: '' },
        { label: 'See Something 5', value: 'SeeSomething5', file: '' },
        { label: 'See Something 6', value: 'SeeSomething6', file: '' },
        { label: 'See Something 7', value: 'SeeSomething7', file: '' },
        { label: 'See Something 8', value: 'SeeSomething8', file: '' },
        { label: 'Shake Body', value: 'ShakeBody1', file: '' },
        { label: 'Shake Body 2', value: 'ShakeBody2', file: '' },
        { label: 'Shake Body 3', value: 'ShakeBody3', file: '' },
        { label: 'Touch Head', value: 'TouchHead1', file: '' },
        { label: 'Touch Head 2', value: 'TouchHead2', file: '' },
        { label: 'Touch Head 3', value: 'TouchHead3', file: '' },
        { label: 'Touch Head 4', value: 'TouchHead4', file: '' },
        { label: 'Angry', value: 'Angry1', file: '' },
        { label: 'Angry 2', value: 'Angry2', file: '' },
        { label: 'Angry 3', value: 'Angry3', file: '' },
        { label: 'Bow Short', value: 'BowShort1', file: '' },
        { label: 'Bow Short 2', value: 'BowShort2', file: '' },
        { label: 'Bow Short 3', value: 'BowShort3', file: '' },
        { label: 'But', value: 'But1', file: '' },
        { label: 'Calm Down', value: 'CalmDown1', file: '' },
        { label: 'Calm Down 2', value: 'CalmDown2', file: '' },
        { label: 'Calm Down 3', value: 'CalmDown3', file: '' },
        { label: 'Calm Down 4', value: 'CalmDown4', file: '' },
        { label: 'Calm Down 5', value: 'CalmDown5', file: '' },
        { label: 'Calm Down 6', value: 'CalmDown6', file: '' },
        { label: 'Choice', value: 'Choice1', file: '' },
        { label: 'Come On', value: 'ComeOn1', file: '' },
        { label: 'Confused', value: 'Confused1', file: '' },
        { label: 'Confused 2', value: 'Confused2', file: '' },
        { label: 'Count Five', value: 'CountFive1', file: '' },
        { label: 'Count Four', value: 'CountFour1', file: '' },
        { label: 'Count More', value: 'CountMore1', file: '' },
        { label: 'Count One', value: 'CountOne1', file: '' },
        { label: 'Count Three', value: 'CountThree1', file: '' },
        { label: 'Count Two', value: 'CountTwo1', file: '' },
        { label: 'Desperate', value: 'Desperate1', file: '' },
        { label: 'Desperate 2', value: 'Desperate2', file: '' },
        { label: 'Desperate 3', value: 'Desperate3', file: '' },
        { label: 'Desperate 4', value: 'Desperate4', file: '' },
        { label: 'Desperate 5', value: 'Desperate5', file: '' },
        { label: 'Dont Understand', value: 'DontUnderstand1', file: '' },
        { label: 'Enthusiastic 3', value: 'Enthusiastic3', file: '' },
        { label: 'Enthusiastic 4', value: 'Enthusiastic4', file: '' },
        { label: 'Enthusiastic 5', value: 'Enthusiastic5', file: '' },
        { label: 'Everything', value: 'Everything1', file: '' },
        { label: 'Everything 2', value: 'Everything2', file: '' },
        { label: 'Everything 3', value: 'Everything3', file: '' },
        { label: 'Everything 4', value: 'Everything4', file: '' },
        { label: 'Everything 6', value: 'Everything6', file: '' },
        { label: 'Excited', value: 'Excited1', file: '' },
        { label: 'Explain', value: 'Explain1', file: '' },
        { label: 'Explain 2', value: 'Explain2', file: '' },
        { label: 'Explain 3', value: 'Explain3', file: '' },
        { label: 'Explain 4', value: 'Explain4', file: '' },
        { label: 'Explain 5', value: 'Explain5', file: '' },
        { label: 'Explain 6', value: 'Explain6', file: '' },
        { label: 'Explain 7', value: 'Explain7', file: '' },
        { label: 'Explain 8', value: 'Explain8', file: '' },
        { label: 'Explain 10', value: 'Explain10', file: '' },
        { label: 'Explain 11', value: 'Explain11', file: '' },
        { label: 'Far', value: 'Far1', file: '' },
        { label: 'Far 2', value: 'Far2', file: '' },
        { label: 'Far 3', value: 'Far3', file: '' },
        { label: 'Follow', value: 'Follow1', file: '' },
        { label: 'Give', value: 'Give1', file: '' },
        { label: 'Give 2', value: 'Give2', file: '' },
        { label: 'Give 3', value: 'Give3', file: '' },
        { label: 'Give 4', value: 'Give4', file: '' },
        { label: 'Give 5', value: 'Give5', file: '' },
        { label: 'Give 6', value: 'Give6', file: '' },
        { label: 'Great', value: 'Great1', file: '' },
        { label: 'He Says', value: 'HeSays1', file: '' },
        { label: 'He Says 2', value: 'HeSays2', file: '' },
        { label: 'He Says 3', value: 'HeSays3', file: '' },
        { label: 'Hey', value: 'Hey1', file: '' },
        { label: 'Hey 2', value: 'Hey2', file: '' },
        { label: 'Hey 3', value: 'Hey3', file: '' },
        { label: 'Hey 4', value: 'Hey4', file: '' },
        { label: 'Hey 5', value: 'Hey5', file: '' },
        { label: 'Hey 6', value: 'Hey6', file: '' },
        { label: 'Hey 7', value: 'Hey7', file: '' },
        { label: 'Hey 8', value: 'Hey8', file: '' },
        { label: 'Hey 9', value: 'Hey9', file: '' },
        { label: 'Hey 10', value: 'Hey10', file: '' },
        { label: 'Hide', value: 'Hide1', file: '' },
        { label: 'Hot', value: 'Hot1', file: '' },
        { label: 'Hot 2', value: 'Hot2', file: '' },
        { label: 'I Dont Know', value: 'IDontKnow1', file: '' },
        { label: 'I Dont Know 2', value: 'IDontKnow2', file: '' },
        { label: 'I Dont Know 3', value: 'IDontKnow3', file: '' },
        { label: 'I Dont Know 4', value: 'IDontKnow4', file: '' },
        { label: 'I Dont Know 5', value: 'IDontKnow5', file: '' },
        { label: 'I Dont Know 6', value: 'IDontKnow6', file: '' },
        { label: 'Joy', value: 'Joy1', file: '' },
        { label: 'Kisses', value: 'Kisses1', file: '' },
        { label: 'Look', value: 'Look1', file: '' },
        { label: 'Look 2', value: 'Look2', file: '' },
        { label: 'Maybe', value: 'Maybe1', file: '' },
        { label: 'Me', value: 'Me1', file: '' },
        { label: 'Me 2', value: 'Me2', file: '' },
        { label: 'Me 4', value: 'Me4', file: '' },
        { label: 'Me 7', value: 'Me7', file: '' },
        { label: 'Me 8', value: 'Me8', file: '' },
        { label: 'Mime', value: 'Mime1', file: '' },
        { label: 'Mime 2', value: 'Mime2', file: '' },
        { label: 'Next', value: 'Next1', file: '' },
        { label: 'No', value: 'No1', file: '' },
        { label: 'No 2', value: 'No2', file: '' },
        { label: 'No 3', value: 'No3', file: '' },
        { label: 'No 4', value: 'No4', file: '' },
        { label: 'No 5', value: 'No5', file: '' },
        { label: 'No 6', value: 'No6', file: '' },
        { label: 'No 7', value: 'No7', file: '' },
        { label: 'No 8', value: 'No8', file: '' },
        { label: 'No 9', value: 'No9', file: '' },
        { label: 'Nothing', value: 'Nothing1', file: '' },
        { label: 'Nothing 2', value: 'Nothing2', file: '' },
        { label: 'OnTheEvening', value: 'OnTheEvening1', file: '' },
        { label: 'OnTheEvening 2', value: 'OnTheEvening2', file: '' },
        { label: 'OnTheEvening 3', value: 'OnTheEvening3', file: '' },
        { label: 'OnTheEvening 4', value: 'OnTheEvening4', file: '' },
        { label: 'OnTheEvening 5', value: 'OnTheEvening5', file: '' },
        { label: 'Please', value: 'Please1', file: '' },
        { label: 'Please 2', value: 'Please2', file: '' },
        { label: 'Please 3', value: 'Please3', file: '' },
        { label: 'Reject', value: 'Reject1', file: '' },
        { label: 'Reject 2', value: 'Reject2', file: '' },
        { label: 'Reject 3', value: 'Reject3', file: '' },
        { label: 'Reject 4', value: 'Reject4', file: '' },
        { label: 'Reject 5', value: 'Reject5', file: '' },
        { label: 'Reject 6', value: 'Reject6', file: '' },
        { label: 'Salute', value: 'Salute1', file: '' },
        { label: 'Salute 2', value: 'Salute2', file: '' },
        { label: 'Salute 3', value: 'Salute3', file: '' },
        { label: 'Show Floor', value: 'ShowFloor1', file: '' },
        { label: 'Show Floor 2', value: 'ShowFloor2', file: '' },
        { label: 'Show Floor 3', value: 'ShowFloor3', file: '' },
        { label: 'Show Floor 4', value: 'ShowFloor4', file: '' },
        { label: 'Show Sky', value: 'ShowSky1', file: '' },
        { label: 'Show Sky 2', value: 'ShowSky2', file: '' },
        { label: 'Show Sky 3', value: 'ShowSky3', file: '' },
        { label: 'Show Sky 4', value: 'ShowSky4', file: '' },
        { label: 'Show Sky 5', value: 'ShowSky5', file: '' },
        { label: 'Show Sky 6', value: 'ShowSky6', file: '' },
        { label: 'Show Sky 7', value: 'ShowSky7', file: '' },
        { label: 'Show Sky 8', value: 'ShowSky8', file: '' },
        { label: 'Show Sky 9', value: 'ShowSky9', file: '' },
        { label: 'Show Sky 10', value: 'ShowSky10', file: '' },
        { label: 'Show Sky 11', value: 'ShowSky11', file: '' },
        { label: 'Show Sky 12', value: 'ShowSky12', file: '' },
        { label: 'Show Tablet', value: 'ShowTablet1', file: '' },
        { label: 'Show Tablet 2', value: 'ShowTablet2', file: '' },
        { label: 'Show Tablet 3', value: 'ShowTablet3', file: '' },
        { label: 'Shy', value: 'Shy1', file: '' },
        { label: 'Stretch', value: 'Stretch1', file: '' },
        { label: 'Stretch 2', value: 'Stretch2', file: '' },
        { label: 'Surprised', value: 'Surprised1', file: '' },
        { label: 'Take', value: 'Take1', file: '' },
        { label: 'Take Place', value: 'TakePlace1', file: '' },
        { label: 'Take Place 2', value: 'TakePlace2', file: '' },
        { label: 'Thinking ', value: 'Thinking1', file: '' },
        { label: 'Thinking 2', value: 'Thinking2', file: '' },
        { label: 'Thinking 3', value: 'Thinking3', file: '' },
        { label: 'Thinking 4', value: 'Thinking4', file: '' },
        { label: 'Thinking 5', value: 'Thinking5', file: '' },
        { label: 'Thinking 6', value: 'Thinking6', file: '' },
        { label: 'Thinking 7', value: 'Thinking7', file: '' },
        { label: 'Thinking 8', value: 'Thinking8', file: '' },
        { label: 'This', value: 'This1', file: '' },
        { label: 'This 2', value: 'This2', file: '' },
        { label: 'This 3', value: 'This3', file: '' },
        { label: 'This 4', value: 'This4', file: '' },
        { label: 'This 5', value: 'This5', file: '' },
        { label: 'This 6', value: 'This6', file: '' },
        { label: 'This 7', value: 'This7', file: '' },
        { label: 'This 8', value: 'This8', file: '' },
        { label: 'This 9', value: 'This9', file: '' },
        { label: 'This 10', value: 'This10', file: '' },
        { label: 'This 11', value: 'This11', file: '' },
        { label: 'This 12', value: 'This12', file: '' },
        { label: 'This 13', value: 'This13', file: '' },
        { label: 'This 14', value: 'This14', file: '' },
        { label: 'This 15', value: 'This15', file: '' },
        { label: 'What is this', value: 'WhatSThis1', file: '' },
        { label: 'What is this 2', value: 'WhatSThis2', file: '' },
        { label: 'What is this 3', value: 'WhatSThis3', file: '' },
        { label: 'What is this 4', value: 'WhatSThis4', file: '' },
        { label: 'What is this 5', value: 'WhatSThis5', file: '' },
        { label: 'What is this 6', value: 'WhatSThis6', file: '' },
        { label: 'What is this 7', value: 'WhatSThis7', file: '' },
        { label: 'What is this 8', value: 'WhatSThis8', file: '' },
        { label: 'What is this 9', value: 'WhatSThis9', file: '' },
        { label: 'What is this 10', value: 'WhatSThis10', file: '' },
        { label: 'What is this 11', value: 'WhatSThis11', file: '' },
        { label: 'What is this 12', value: 'WhatSThis12', file: '' },
        { label: 'What is this 13', value: 'WhatSThis13', file: '' },
        { label: 'What is this 14', value: 'WhatSThis14', file: '' },
        { label: 'What is this 15', value: 'WhatSThis15', file: '' },
        { label: 'What is this 16', value: 'WhatSThis16', file: '' },
        { label: 'Whisper', value: 'Whisper1', file: '' },
        { label: 'Wings', value: 'Wings1', file: '' },
        { label: 'Wings 2', value: 'Wings2', file: '' },
        { label: 'Wings 3', value: 'Wings3', file: '' },
        { label: 'Wings 4', value: 'Wings4', file: '' },
        { label: 'Wings 5', value: 'Wings5', file: '' },
        { label: 'Yes', value: 'Yes1', file: '' },
        { label: 'Yes 2', value: 'Yes2', file: '' },
        { label: 'Yes 3', value: 'Yes3', file: '' },
        { label: 'You', value: 'You1', file: '' },
        { label: 'You 2', value: 'You2', file: '' },
        { label: 'You 3', value: 'You3', file: '' },
        { label: 'You 4', value: 'You4', file: '' },
        { label: 'You 5', value: 'You5', file: '' },
        { label: 'You Know What', value: 'YouKnowWhat1', file: '' },
        { label: 'You Know What 2', value: 'YouKnowWhat2', file: '' },
        { label: 'You Know What 3', value: 'YouKnowWhat3', file: '' },
        { label: 'You Know What 4', value: 'YouKnowWhat4', file: '' },
        { label: 'You Know What 5', value: 'YouKnowWhat5', file: '' },
        { label: 'You Know What 6', value: 'YouKnowWhat6', file: '' },
        { label: 'Yum', value: 'Yum1', file: '' },
        { label: 'Amused', value: 'Amused1', file: '' },
        { label: 'Confident', value: 'Confident1', file: '' },
        { label: 'Ecstatic', value: 'Ecstatic1', file: '' },
        { label: 'Enthusiastic', value: 'Enthusiastic1', file: '' },
        { label: 'Excited', value: 'Excited1', file: '' },
        { label: 'Excited 2', value: 'Excited2', file: '' },
        { label: 'Excited 3', value: 'Excited3', file: '' },
        { label: 'Happy', value: 'Happy1', file: '' },
        { label: 'Happy 2', value: 'Happy2', file: '' },
        { label: 'Happy 3', value: 'Happy3', file: '' },
        { label: 'Happy 4', value: 'Happy4', file: '' },
        { label: 'Hungry', value: 'Hungry1', file: '' },
        { label: 'Hysterical', value: 'Hysterical1', file: '' },
        { label: 'Interested', value: 'Interested1', file: '' },
        { label: 'Interested 2', value: 'Interested2', file: '' },
        { label: 'Laugh', value: 'Laugh1', file: '' },
        { label: 'Laugh 2', value: 'Laugh2', file: '' },
        { label: 'Laugh 3', value: 'Laugh3', file: '' },
        { label: 'Mocker', value: 'Mocker1', file: '' },
        { label: 'Optimistic', value: 'Optimistic1', file: '' },
        { label: 'Peaceful', value: 'Peaceful1', file: '' },
        { label: 'Proud', value: 'Proud1', file: '' },
        { label: 'Proud 2', value: 'Proud2', file: '' },
        { label: 'Proud 3', value: 'Proud3', file: '' },
        { label: 'Relieved', value: 'Relieved1', file: '' },
        { label: 'Shy', value: 'Shy1', file: '' },
        { label: 'Shy 2', value: 'Shy2', file: '' },
        { label: 'Sure', value: 'Sure1', file: '' },
        { label: 'Winner', value: 'Winner1', file: '' },
        { label: 'Winner 2', value: 'Winner2', file: '' },
        { label: 'Alienated', value: 'Alienated1', file: '' },
        { label: 'Ask For Attention', value: 'AskForAttention1', file: '' },
        { label: 'Ask For Attention 2', value: 'AskForAttention2', file: '' },
        { label: 'Ask For Attention 3', value: 'AskForAttention3', file: '' },
        { label: 'Cautious', value: 'Cautious1', file: '' },
        { label: 'Confused', value: 'Confused1', file: '' },
        { label: 'Determined', value: 'Determined1', file: '' },
        { label: 'Embarrassed', value: 'Embarrassed1', file: '' },
        { label: 'Hesitation', value: 'Hesitation1', file: '' },
        { label: 'Innocent', value: 'Innocent1', file: '' },
        { label: 'Lonely', value: 'Lonely1', file: '' },
        { label: 'Mischievous', value: 'Mischievous1', file: '' },
        { label: 'Puzzled', value: 'Puzzled1', file: '' },
        { label: 'Sneeze', value: 'Sneeze', file: '' },
        { label: 'Stubborn', value: 'Stubborn1', file: '' },
        { label: 'Suspicious', value: 'Suspicious1', file: '' },
        { label: 'Angry', value: 'Angry1', file: '' },
        { label: 'Angry 2', value: 'Angry2', file: '' },
        { label: 'Angry 3', value: 'Angry3', file: '' },
        { label: 'Angry 4', value: 'Angry4', file: '' },
        { label: 'Anxious', value: 'Anxious1', file: '' },
        { label: 'Bored', value: 'Bored1', file: '' },
        { label: 'Bored 2', value: 'Bored2', file: '' },
        { label: 'Disappointed', value: 'Disappointed1', file: '' },
        { label: 'Exhausted', value: 'Exhausted1', file: '' },
        { label: 'Exhausted 2', value: 'Exhausted2', file: '' },
        { label: 'Fear', value: 'Fear1', file: '' },
        { label: 'Fear 2', value: 'Fear2', file: '' },
        { label: 'Fearful', value: 'Fearful1', file: '' },
        { label: 'Frustrated', value: 'Frustrated1', file: '' },
        { label: 'Humiliated', value: 'Humiliated1', file: '' },
        { label: 'Hurt', value: 'Hurt1', file: '' },
        { label: 'Hurt 2', value: 'Hurt2', file: '' },
        { label: 'Late', value: 'Late1', file: '' },
        { label: 'Sad', value: 'Sad1', file: '' },
        { label: 'Sad 2', value: 'Sad2', file: '' },
        { label: 'Shocked', value: 'Shocked1', file: '' },
        { label: 'Sorry', value: 'Sorry1', file: '' },
        { label: 'Surprise', value: 'Surprise1', file: '' },
        { label: 'Surprise 2', value: 'Surprise2', file: '' },
        { label: 'Surprise 3', value: 'Surprise3', file: '' },
        { label: 'Remember', value: 'Remember1', file: '' },
        { label: 'Remember 2', value: 'Remember2', file: '' },
        { label: 'Remember 3', value: 'Remember3', file: '' },
        { label: 'Thinking Loop', value: 'ThinkingLoop1', file: '' },
        { label: 'Thinking Loop 2', value: 'ThinkingLoop2', file: '' },
        { label: 'Body Talk', value: 'BodyTalk1', file: '' },
        { label: 'Body Talk 2', value: 'BodyTalk2', file: '' },
        { label: 'Body Talk 3', value: 'BodyTalk3', file: '' },
        { label: 'Body Talk 4', value: 'BodyTalk4', file: '' },
        { label: 'Body Talk 5', value: 'BodyTalk5', file: '' },
        { label: 'Body Talk 6', value: 'BodyTalk6', file: '' },
        { label: 'Body Talk 7', value: 'BodyTalk7', file: '' },
        { label: 'Body Talk 8', value: 'BodyTalk8', file: '' },
        { label: 'Body Talk 9', value: 'BodyTalk9', file: '' },
        { label: 'Body Talk 10', value: 'BodyTalk10', file: '' },
        { label: 'Body Talk 11', value: 'BodyTalk11', file: '' },
        { label: 'Body Talk 12', value: 'BodyTalk12', file: '' },
        { label: 'Body Talk 13', value: 'BodyTalk13', file: '' },
        { label: 'Body Talk 14', value: 'BodyTalk14', file: '' },
        { label: 'Body Talk 15', value: 'BodyTalk15', file: '' },
        { label: 'Body Talk 16', value: 'BodyTalk16', file: '' },
        { label: 'Listening', value: 'Listening1', file: '' },
        { label: 'Listening 2', value: 'Listening2', file: '' },
        { label: 'Listening 3', value: 'Listening3', file: '' },
        { label: 'Listening 4', value: 'Listening4', file: '' },
        { label: 'Listening 5', value: 'Listening5', file: '' },
        { label: 'Listening 6', value: 'Listening6', file: '' },
        { label: 'Listening 7', value: 'Listening7', file: '' }
    ],
    cruzr: [
        { label: 'None', value: 'none', file: '' },
        { label: 'Hugs', value: 'hug', file: '' },
        { label: 'Shakehands', value: 'shankhand', file: '' },
        { label: 'Goodbye', value: 'goodbye', file: '' },
        { label: 'Nod head', value: 'nod', file: '' },
        { label: 'Clap hands', value: 'applause', file: '' },
        { label: 'Walk right', value: 'guideright', file: '' },
        { label: 'Walk left', value: 'guideleft', file: '' },
        { label: 'Grow', value: 'zhanggao', file: '' },
        { label: 'Swingarms', value: 'swingarm', file: '' },
        { label: 'Search', value: 'searching', file: '' },
        { label: 'Zone out', value: 'fadai', file: '' },
        { label: 'Survey', value: 'tiaowang', file: '' },
        { label: 'Wonder', value: 'surprise', file: '' },
        { label: 'Shy', value: 'shy', file: '' },
        { label: 'Talk 1', value: 'talk1', file: '' },
        { label: 'Talk 2', value: 'talk2', file: '' },
        { label: 'Talk 3', value: 'talk3', file: '' },
        { label: 'Talk 4', value: 'talk4', file: '' },
        { label: 'Talk 5', value: 'talk5', file: '' },
        { label: 'Talk 6', value: 'talk6', file: '' },
        { label: 'Talk 7', value: 'talk7', file: '' },
        { label: 'Talk 8', value: 'talk8', file: '' },
        { label: 'Talk 9', value: 'talk9', file: '' },
        { label: 'Talk 10', value: 'talk10', file: '' },
        { label: 'Talk 11', value: 'talk11', file: '' },
        { label: 'Talk 12', value: 'talk12', file: '' },
        { label: 'Talk 13', value: 'talk13', file: '' },
        { label: 'Talk 14', value: 'talk14', file: '' },
        { label: 'Talk 15', value: 'talk15', file: '' },
        { label: 'Talk 16', value: 'talk16', file: '' },
        { label: 'Talk 17', value: 'talk17', file: '' },
        { label: 'Talk 18', value: 'talk18', file: '' },
        { label: 'Talk 19', value: 'talk19', file: '' },
        { label: 'Talk 20', value: 'talk20', file: '' },
        { label: 'Talk 21', value: 'talk21', file: '' },
        { label: 'Talk 22', value: 'talk22', file: '' },
        { label: 'Talk 23', value: 'talk23', file: '' },
        { label: 'Talk 24', value: 'talk24', file: '' },
    ]
};