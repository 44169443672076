import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import SpeechAction from "../../components/SpeechAction";
import languages from "../../constants/languages";
import axios from "../../utils/AxiosService";

class Feed extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.changeText = this.changeText.bind(this);
    this.changeAnimation = this.changeAnimation.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    const { feed } = props.location.state;
    this.state = {
      lang: "en",
      feed,
      changesMade: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  changeText = (text) => {
    const { feed, lang } = this.state;
    const tempFeed = feed;
    tempFeed[lang].speechAction.text = text;

    this.setState({
      feed: tempFeed,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { feed, lang } = this.state;
    const tempFeed = feed;
    tempFeed[lang].speechAction.animation = animation;
    this.setState({
      feed: tempFeed,
      changesMade: true,
    });
  };

  changeName = (name) => {
    const { feed, lang } = this.state;
    const tmpFeed = feed;
    feed[lang].name = name;
    this.setState({
      feed: tmpFeed,
      changesMade: true,
    });
  };

  changeRss = (rssFeed) => {
    const { feed, lang } = this.state;
    const tmpFeed = feed;
    feed[lang].rssFeed = rssFeed;
    this.setState({
      feed: tmpFeed,
      changesMade: true,
    });
  };

  saveFeed = () => {
    const { selectedRobot } = this.props;
    const { feed } = this.state;
    const url = `/news/${feed._id}/robots/${selectedRobot._id}`;
    axios.put(url, { news: feed }).then((response) => {
      if (response.status === 200) {
        toast("Feed saved", { type: "success" });
        this.setState({
          feed: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  selectStoryline = (storyline) => {
    const { feed } = this.state;
    const tmpFeed = feed;
    languages.forEach((lang) => {
      feed[lang].speechAction = storyline;
    });

    this.setState({
      feed: tmpFeed,
      changesMade: true,
    });
  };

  render() {
    const { feed, lang } = this.state;
    const { selectedRobot } = this.props;

    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">News feed</h1>
                <p className="category">
                  Select the news feed name and past the RSS feed URL
                </p>
              </div>
              <div className="content">
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={feed[lang].speechAction}
                  selectStoryline={this.selectStoryline}
                />
                <h4>Name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Feed name"
                  value={feed[lang].name}
                />
                <h4>RSS feed URL</h4>
                <Input
                  onChange={(event) => this.changeRss(event.target.value)}
                  type="text"
                  name="rss"
                  id="rss"
                  placeholder="RSS feed URL"
                  value={feed[lang].rssFeed}
                />

                <br />
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button color="primary" onClick={this.saveFeed}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Feed;
