import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import axios from "../utils/AxiosService";

class RobotSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendTextPreview = () => {
    const { text, robotId } = this.props;
    const url = `/debug/textpreview/${robotId}`;
    axios.post(url, { speech: text }).then((response) => {
      if (response.status === 200) {
        toast("Text sent", { type: "success" });
      } else {
        toast("Robot not connected", { type: "error" });
      }
    });
  };

  render() {
    return (
      <div>
        <Button
          style={{ height: "34px" }}
          color="secondary"
          className="btn-soundpreview btn-primary pe-7s-volume"
          onClick={this.sendTextPreview}
        />
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default RobotSelection;
