import React, { Component } from "react";
import { Input, ListGroup, ListGroupItem } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import axios from "../../utils/AxiosService";
import QuestionResultView from "./components/QuestionResultView";

const options = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: "left",
    labels: {
      boxWidth: 10,
    },
  },
};

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.selectQuizz = this.selectQuizz.bind(this);
    this.selectSurvey = this.selectSurvey.bind(this);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      surveys: [],
      quizzes: [],
      filteredSurveys: [],
      filteredQuizzes: [],
      selectedQuizz: null,
      selectedSurvey: null,
      questions: [],
      data: null,
      searchText: "",
      lang: "en",
      loaded: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        selectedRobot: nextProps.selectedRobot,
      },
      this.fetchData
    );
  }

  fetchSurveys = () => {
    const url = `/surveys`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          surveys: response.data.map((config) => config.configuration),
          filteredSurveys: response.data.map((config) => config.configuration),
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  fetchQuizzes = () => {
    const url = `/quizzs`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          quizzes: response.data.map((config) => config.configuration),
          filteredQuizzes: response.data.map((config) => config.configuration),
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  fetchData = () => {
    this.fetchSurveys();
    this.fetchQuizzes();
  };

  selectSurvey = (survey) => {
    this.setState(
      {
        selectedSurvey: survey,
        selectedQuizz: null,
        loaded: false,
      },
      this.fetchResults
    );
  };

  selectQuizz = (quizz) => {
    this.setState(
      {
        selectedSurvey: null,
        selectedQuizz: quizz,
        loaded: false,
      },
      this.fetchResults
    );
  };

  changeSearchText(text) {
    const { surveys, quizzes, lang } = this.state;
    this.setState({
      searchText: text,
      filteredSurveys: surveys.filter((survey) =>
        survey[lang].name.toLowerCase().includes(text.toLowerCase())
      ),
      filteredQuizzes: quizzes.filter((quizz) =>
        quizz[lang].name.toLowerCase().includes(text.toLowerCase())
      ),
    });
  }

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  fetchResults = () => {
    const { selectedQuizz, selectedSurvey, lang } = this.state;

    if (selectedQuizz !== null) {
      let questions = [];
      selectedQuizz[lang].questions.forEach((question, index) => {
        questions.push({
          question: question.questionWritten,
          answers: [],
          correctAnswerIndex: question.correctAnswerId,
        });
        question.answersWritten.forEach((answer) => {
          questions[index].answers.push({
            answer,
            count: 0,
          });
        });
      });

      const url = `/statistics/quizz/${selectedQuizz._id}`;
      axios.get(url).then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((quizzResult) => {
            quizzResult.quizz.questions.forEach((question) => {
              questions[question.questionIndex].answers[question.answerIndex]
                .count++;
            });
          });
          this.setState({
            questions,
            loaded: true,
          });
        }
      });
    } else {
      let questions = [];
      selectedSurvey[lang].questions.forEach((question, index) => {
        questions.push({
          question: question.questionWritten,
          answers: [],
        });
        if (question.answerType === "smileys") {
          const smileys = ["very_happy", "happy", "neutral", "sad", "very_sad"];
          smileys.forEach((smiley) => {
            questions[index].answers.push({
              answer: smiley,
              count: 0,
            });
          });
        } else if (question.answerType === "single_choice") {
          question.answersWritten.forEach((answer) => {
            questions[index].answers.push({
              answer,
              count: 0,
            });
          });
        }
      });

      const url = `/statistics/survey/${selectedSurvey._id}`;
      axios.get(url).then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((surveyResult) => {
            surveyResult.survey.questions.forEach((question) => {
              if (
                question.answerText !== null &&
                question.answerText !== undefined
              ) {
                questions[question.questionIndex].answers.push(
                  question.answerText
                );
              } else {
                questions[question.questionIndex].answers[question.answerIndex]
                  .count++;
              }
            });
          });
          this.setState({
            questions,
            loaded: true,
          });
        }
      });
    }
  };

  questionToChart = (question) => {
    const backColors = [
      "#85ad00",
      "#006ba8",
      "#0c00ba",
      "#7b00b0",
      "#b0008a",
      "#cf0000",
      "#c95e00",
      "#c9a800",
      "#22ab00",
      "#00b078",
    ];
    const hoverColors = [
      "#c1ed2d",
      "#31aef5",
      "#3f33f2",
      "#c236ff",
      "#fc32d1",
      "#f01f1f",
      "#db751a",
      "#f2d127",
      "#4ae324",
      "#2bf0b1",
    ];

    let chartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };

    let answerCount = 0;
    question.answers.forEach((answer, index) => {
      chartData.labels.push(answer.answer);
      chartData.datasets[0].data.push(answer.count);
      chartData.datasets[0].backgroundColor.push(backColors[index]);
      chartData.datasets[0].hoverBackgroundColor.push(hoverColors[index]);
      answerCount++;
    });

    options.total = answerCount;

    return chartData;
  };

  dataToOverview = () => {
    const { questions } = this.state;

    let overview = {
      questionCount: 0,
      participantsCount: 0,
      completionRate: 0,
    };

    let minParticipants = 0;
    let maxParticipants = 0;
    if (questions.length > 0) {
      questions[0].answers.forEach((answer) => {
        minParticipants = maxParticipants += answer.count;
      });
    }

    questions.forEach((question) => {
      let answerCount = 0;
      question.answers.forEach((answer) => {
        answerCount += answer.count;
      });
      if (answerCount > maxParticipants) {
        maxParticipants = answerCount;
      } else if (answerCount < minParticipants) {
        minParticipants = answerCount;
      }
    });

    overview.questionCount = questions.length;
    overview.participantsCount = maxParticipants;
    overview.completionRate = (minParticipants / maxParticipants) * 100;

    return overview;
  };

  render() {
    const {
      filteredSurveys,
      filteredQuizzes,
      selectedSurvey,
      selectedQuizz,
      searchText,
      lang,
      questions,
      loaded,
    } = this.state;

    const selectedData =
      selectedQuizz === null
        ? selectedSurvey === null
          ? null
          : selectedSurvey
        : selectedQuizz;

    const selectedDataOverview = this.dataToOverview();

    return (
      <div style={{ margin: "20px" }}>
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Statistics</h1>
                <p className="category">
                  Here you can select a survey or quizz to see its statistics
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="header">
                <h4 className="title">
                  Type in the search bar a survey or quizz name
                </h4>
                <Input
                  style={{ marginBottom: "20px" }}
                  onChange={(event) =>
                    this.changeSearchText(event.target.value)
                  }
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Search accounts"
                  value={searchText}
                />
              </div>
              <div className="header">
                <h4>Surveys</h4>
              </div>
              <ListGroup style={{ marginLeft: "29px", marginRight: "29px" }}>
                {filteredSurveys.map((survey) => (
                  <ListGroupItem
                    active={
                      selectedSurvey !== null &&
                      survey._id === selectedSurvey._id
                    }
                    onClick={() => this.selectSurvey(survey)}
                    key={survey._id}
                    action
                  >
                    {survey[lang].name === ""
                      ? "Unnamed survey"
                      : survey[lang].name}
                  </ListGroupItem>
                ))}
              </ListGroup>
              <div className="header">
                <h4>Quizzes</h4>
              </div>
              <ListGroup style={{ marginLeft: "29px", marginRight: "29px" }}>
                {filteredQuizzes.map((quizz) => (
                  <ListGroupItem
                    active={
                      selectedQuizz !== null && quizz._id === selectedQuizz._id
                    }
                    onClick={() => this.selectQuizz(quizz)}
                    key={quizz._id}
                    action
                  >
                    {quizz[lang].name === ""
                      ? "Unnamed quizz"
                      : quizz[lang].name}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </div>
          {selectedData === null ? undefined : (
            <div className="col-md-8">
              <div className="card">
                <div className="header">
                  <h4>{selectedData[lang].name} overview</h4>
                </div>
                <div className="content">
                  <h6>
                    Number of questions :{" "}
                    <b>{selectedDataOverview.questionCount}</b>
                  </h6>
                  <h6>
                    Number of participants :{" "}
                    <b>{selectedDataOverview.participantsCount}</b>
                  </h6>
                  <h6>
                    Completion rate :{" "}
                    <b>{selectedDataOverview.completionRate}%</b>
                  </h6>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          {questions.length <= 0
            ? undefined
            : !loaded
            ? undefined
            : questions.map((question, index) => (
                <QuestionResultView
                  questionType={selectedData[lang].questions[index].answerType}
                  question={question}
                  options={options}
                  data={this.questionToChart(question)}
                  selectedData={selectedData}
                  lang={lang}
                  index={index}
                  key={index}
                />
              ))}
        </div>
      </div>
    );
  }
}

export default Statistics;
