import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';

class RobotNavigation extends React.Component {
  constructor(props) {
    super(props);
    const { storyline, index, lang } = this.props;
    this.state = {
      storyline,
      index,
      lang,
    };
  }

  componentDidUpdate(prevProps) {
    const { index, storyline, lang } = this.props;
    if (prevProps.index !== index || prevProps.storyline !== storyline || prevProps.lang !== lang) {
      this.setState({
        storyline: storyline,
        index: index,
        lang: lang
      });
    }
  }

  render() {
    const { toggleIsWaypoint, changeWaypoint, toggleIsRandom, changeX, changeY, changeTheta, changeOnFailure } = this.props;
    const { index, lang, storyline } = this.state;
    if (index > storyline.events.length-1) {
      return (<div></div>)
    }
    return (
      <div>
        <FormGroup style={{ marginTop: '20px' }}>
          <Label >Waypoint navigation?</Label>
          <Input 
            onChange={ () => toggleIsWaypoint() }
            type="checkbox" 
            name="Waypoint" 
            defaultChecked={storyline.events[index].robot_actions.navigation.isWaypoint} 
            style={{ marginLeft: '10px' }}
            />
        </FormGroup>
        { storyline.events[index].robot_actions.navigation.isWaypoint ?
        <div>
          <p>Waypoint:</p>
          <Input type="text" value={storyline.events[index].robot_actions.navigation.waypoint} onChange={(event) => changeWaypoint(event.target.value)} placeholder="WaypointName..." />
        </div>
          :
        <div>  
          <FormGroup style={{ marginTop: '20px' }}>
            <Label >Random navigation?</Label>
            <Input 
              onChange={ () => toggleIsRandom() }
              type="checkbox" 
              name="random navigation" 
              defaultChecked={storyline.events[index].robot_actions.navigation.isRandom} 
              style={{ marginLeft: '10px' }}
              />
          </FormGroup>

          <div style={{ display: 'flex', flexFlow: 'row', maxWidth: '300px' }}>
            <p style={{ marginRight: '5px', width: '80%' }}>x :</p>
            <Input type="number" value={storyline.events[index].robot_actions.navigation.x} onChange={(event) => changeX(event.target.value)} />
          </div>
          <div style={{ display: 'flex', flexFlow: 'row', maxWidth: '300px' }}>
            <p style={{ marginRight: '5px', width: '80%' }}>y :</p>
            <Input type="number" value={storyline.events[index].robot_actions.navigation.y} onChange={(event) => changeY(event.target.value)} />
          </div>
          <div style={{ display: 'flex', flexFlow: 'row', maxWidth: '300px' }}>
            <p style={{ marginRight: '5px', width: '80%' }}>theta :</p>
            <Input type="number" value={storyline.events[index].robot_actions.navigation.theta} onChange={(event) => changeTheta(event.target.value)} />
          </div>
        </div>
        }
        <div>
          <p>on failure TTS: </p>
          <Input type="text" value={storyline.events[index].robot_actions.navigation.onFailure[lang]} onChange={(event) => changeOnFailure(event.target.value)} placeholder="on failure TTS..." />
        </div>
      </div>
    )
  }
}


export default RobotNavigation;
