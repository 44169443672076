import React from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { BrowserRouter } from 'react-router-dom';
import Main from './pages/Main';

const App = () => (
  <BrowserRouter>
    <Main />
  </BrowserRouter>
);

export default DragDropContext(HTML5Backend)(App);