import React, { Component } from 'react';

class BreadCrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { currentPath, setPathLevel, lang } = this.props;

    return (
      <div>
        <h4>
          {
            currentPath.map((level, index) => (
              <React.Fragment key={index}>
                <span style={index !== currentPath.length-1 ? { color: 'blue', cursor: 'pointer' } : { pointerEvents: 'none' }} onClick={ () => setPathLevel(index)} >
                  {`${level.name[lang]}`}
                </span>
                {`${index !== currentPath.length-1 ? ' > ' : ''}`}
              </React.Fragment>
            ))
          }
        </h4>
      </div>
      
    );
  }
}

export default BreadCrumbs;
