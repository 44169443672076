import React from 'react';
import { Button, Input } from 'reactstrap';
import QRCode from 'qrcode.react'
import NotificationSystem from 'react-notification-system';

class BarcodeCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data: '',
        result: null
    };
  }

  render() {
    const { close } = this.props
    const { data, result } = this.state
    return (
      <div style={{ margin: '20px', height: '100%' }}>
        <div className="row">
            <div className="col-md-6" style={{ height: '100%' }}>
                <div className="card">
                    <div className="header">
                        <h2>Barcode creator</h2>
                    </div>
                    <div className="content">
                        <h4>QR code data</h4>
                        <Input value={data} onChange={event => this.setState({ data: event.target.value })} type="textarea" placeholder="Enter QR code data..." />
                        <br />
                        <Button onClick={() => this.setState({ result: data })} color='success'>Generate</Button>
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{ height: '100%' }}>
                <div className="card">
                    <div className="header">
                        <h2>Result</h2>
                    </div>
                    <div className="content">
                        {result === null ? undefined :
                            <QRCode value={result} />
                        }
                    </div>
                </div>
            </div>
        </div>
        <Button style={{ position: 'absolute', bottom: '15px', right: '15px' }} onClick={close} color="secondary">Close</Button>
        <NotificationSystem ref={ref => this.notificationSystem = ref} />
      </div>
    )
  }
}

export default BarcodeCreator;