import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import SpeechAction from "../../../components/SpeechAction";
import TextPreview from "../../../components/TextPreview";
import languages from "../../../constants/languages";
import axios from "../../../utils/AxiosService";

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.changeText = this.changeText.bind(this);
    this.changeAnimation = this.changeAnimation.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    this.state = {
      faq: null,
      changesMade: false,
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchQuestions = () => {
    const url = `/faqs`;
    axios.get(url).then((result) => {
      this.setState({
        faq: result.data[0].configuration,
      });
    });
  };

  addSubject = () => {
    const { lang } = this.props;
    const { faq } = this.state;
    let tmpFaq = faq;
    tmpFaq[lang].questions.push({
      writtenQuestion: "",
      oralQuestions: "",
      writtenAnswer: "",
      oralAnswer: "",
    });

    this.setState({
      faq: tmpFaq,
      changesMade: true,
    });
  };

  changeText = (text) => {
    const { lang } = this.props;
    const { faq } = this.state;
    const tempFaq = faq;
    tempFaq[lang].speechAction.text = text;
    this.setState({
      faq: tempFaq,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { lang } = this.props;
    const { faq } = this.state;
    const tempFaq = faq;
    tempFaq[lang].speechAction.animation = animation;
    this.setState({
      faq: tempFaq,
      changesMade: true,
    });
  };

  changeOralQuestion = (question, subjectId) => {
    const { lang } = this.props;
    const { faq } = this.state;
    const tempFaq = faq;
    tempFaq[lang].questions.find(
      (subject) => subject._id === subjectId
    ).oralQuestions = question;
    this.setState({
      faq: tempFaq,
      changesMade: true,
    });
  };

  changeWrittenQuestion = (question, subjectId) => {
    const { lang } = this.props;
    const { faq } = this.state;
    const tempFaq = faq;
    tempFaq[lang].questions.find(
      (subject) => subject._id === subjectId
    ).writtenQuestion = question;
    this.setState({
      faq: tempFaq,
      changesMade: true,
    });
  };

  changeWrittenAnswer = (answer, subjectId) => {
    const { lang } = this.props;
    const { faq } = this.state;
    const tempFaq = faq;
    tempFaq[lang].questions.find(
      (subject) => subject._id === subjectId
    ).writtenAnswer = answer;
    this.setState({
      faq: tempFaq,
      changesMade: true,
    });
  };

  changeOralAnswer = (answer, subjectId) => {
    const { lang } = this.props;
    const { faq } = this.state;
    const tempFaq = faq;
    tempFaq[lang].questions.find(
      (subject) => subject._id === subjectId
    ).oralAnswer = answer;
    this.setState({
      faq: tempFaq,
      changesMade: true,
    });
  };

  deleteSubject = (subjectInd) => {
    const { lang } = this.props;
    const { faq } = this.state;
    let tmpFaq = faq;
    tmpFaq[lang].questions.splice(subjectInd, 1);
    this.setState({
      faq: tmpFaq,
      changesMade: true,
    });
  };

  saveFaq = () => {
    const { selectedRobot } = this.props;
    const { faq } = this.state;
    const url = `/faqs/${faq._id}/robots/${selectedRobot._id}`;

    axios.put(url, { faq: faq }).then((response) => {
      if (response.status === 200) {
        toast("FAQ saved", { type: "success" });
        this.setState({
          faq: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  selectStoryline = (storyline) => {
    const { faq } = this.state;
    let tmpFaq = faq;
    languages.forEach((lang) => {
      tmpFaq[lang].speechAction = storyline;
    });

    this.setState({
      faq: tmpFaq,
      changesMade: true,
    });
  };

  render() {
    const { lang, selectedRobot } = this.props;
    const { faq } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">FAQ</h1>
                <p className="category">
                  Here you can create custom questions that Pepper will answer
                </p>
              </div>
              <div className="content">
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button color="primary" onClick={() => this.saveFaq()}>
                    Save changes
                  </Button>
                </div>
                <br />
                {faq === null ? undefined : (
                  <SpeechAction
                    selectedRobot={selectedRobot}
                    storyline={faq[lang].speechAction}
                    selectStoryline={this.selectStoryline}
                  />
                )}
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Written question</th>
                      <th>Oral questions</th>
                      <th>Written answer</th>
                      <th>Oral answer</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {faq === null
                      ? undefined
                      : faq[lang].questions.map((subject, index) => (
                          <tr key={subject._id}>
                            <td className="td-name">
                              <Input
                                onChange={(event) =>
                                  this.changeWrittenQuestion(
                                    event.target.value,
                                    subject._id
                                  )
                                }
                                type="text"
                                placeholder="Written question"
                                value={subject.writtenQuestion}
                              />
                            </td>
                            <td>
                              <div className="oral-input">
                                <Input
                                  onChange={(event) =>
                                    this.changeOralQuestion(
                                      event.target.value,
                                      subject._id
                                    )
                                  }
                                  type="text"
                                  placeholder="Oral question"
                                  value={subject.oralQuestions}
                                />
                                <TextPreview
                                  text={subject.oralQuestions}
                                  robotId={selectedRobot._id}
                                />
                              </div>
                            </td>
                            <td>
                              <Input
                                onChange={(event) =>
                                  this.changeWrittenAnswer(
                                    event.target.value,
                                    subject._id
                                  )
                                }
                                type="text"
                                placeholder="Written answer"
                                value={subject.writtenAnswer}
                              />
                            </td>
                            <td>
                              <div className="oral-input">
                                <Input
                                  onChange={(event) =>
                                    this.changeOralAnswer(
                                      event.target.value,
                                      subject._id
                                    )
                                  }
                                  type="text"
                                  placeholder="Oral answer"
                                  value={subject.oralAnswer}
                                />
                                <TextPreview
                                  text={subject.oralAnswer}
                                  robotId={selectedRobot._id}
                                />
                              </div>
                            </td>
                            <td className="td-actions">
                              <button
                                type="button"
                                onClick={() => this.deleteSubject(index)}
                                rel="tooltip"
                                data-placement="left"
                                title=""
                                className="btn btn-danger btn-simple btn-icon "
                                data-original-title="Remove question"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={this.addSubject}
                >
                  Add subject
                </Button>
              </div>
            </div>
          </div>
          <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        </div>
      </React.Fragment>
    );
  }
}

export default QuestionList;
