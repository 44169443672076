import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";

class FaceRecognitions extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      frs: [],
      selectedFr: null,
      newFr: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.fetchFrs();
  }

  fetchFrs = () => {
    const url = `/faceRecognitions`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          frs: response.data.map((config) => config.configuration),
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  newFr = () => {
    const url = `/faceRecognitions`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newFr: response.data,
          redirect: true,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  deleteFr = (id) => {
    const { selectedRobot } = this.state;
    const url = `/faceRecognitions/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          frs: response.data.map((config) => config.configuration),
          selectedFr: null,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  render() {
    const { frs, redirect, newFr } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/facerecognition",
            state: {
              fr: newFr,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Face Recognitions</h1>
                <p className="category">
                  Here you can select a face recognition to edit or create a new
                  one
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <Button
                  onClick={this.newFr}
                  className="btn-success"
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  New face recognition <i className="pe-7s-plus"></i>
                </Button>
                <h4 className="title">
                  Select a face recognition to edit or create a new one
                </h4>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width="40px">Edit</th>
                      <th width="40px">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {frs === null
                      ? undefined
                      : frs.map((fr) => (
                          <tr key={fr._id}>
                            <td className="td-name">{fr.name}</td>
                            <td className="td-name">
                              <Button
                                tag={Link}
                                to={{
                                  pathname: "/facerecognition",
                                  state: {
                                    fr: fr,
                                  },
                                }}
                                color="primary"
                              >
                                <i className="pe-7s-pen"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => this.deleteFr(fr._id)}
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default FaceRecognitions;
