export const dances = [
    { label: 'None', value: '' },
    { label: 'Naxi', value: 'Naxi' },
    { label: 'BBoomBBoom', value: 'BBoomBBoom' },
    { label: 'Flamenco', value: 'Flamenco' },
    { label: 'Modern Dance', value: 'Modern Dance' },
    { label: 'TocaToca', value: 'TocaToca' },
    { label: 'Crayon', value: 'Crayon' },
    { label: 'Seaweed', value: 'Seaweed' },
    { label: 'Curry', value: 'Curry' },
    { label: 'IevanPolkka', value: 'IevanPolkka' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Faded', value: 'Faded' },
    { label: 'Dura', value: 'Dura' },
    { label: 'Random Dance', value: 'random' },
];