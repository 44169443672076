import React from "react";
import NotificationSystem from "react-notification-system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert, Button, FormGroup, Input, Label } from "reactstrap";
import axios from "../../../utils/AxiosService";
import getVideoType from "../utils/utils";
import SpeechInput from "./SpeechInput";

class SlideView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImage: null,
      error: null,
      message: null,
      slideDuration: this.props.slide.duration,
      uploading: false,
    };

    this.changeText = this.changeText.bind(this);
  }

  changeText = (event) => {
    const { slide, change } = this.props;
    slide.text = event.target.value;
    change(true);
    this.setState({
      text: event.target.value,
    });
  };

  changeDuration = (event) => {
    const { change } = this.props;
    const { value } = event.target;
    change(true);
    if (value > 0) {
      const { slide } = this.props;
      slide.duration = value;
      this.setState({
        slideDuration: value,
      });
    }
  };

  changeImage = (event) => {
    if (event.target.files[0] !== undefined) {
      if (event.target.files[0].size > 200000000) {
        toast("File too large. Limit: 200MB", { type: "error" });
      } else {
        const {
          slide,
          presentationId,
          lang,
          changeSlideImage,
          updateSlide,
          index,
          change,
        } = this.props;
        change(true);
        this.setState({
          uploading: true,
        });
        const url = `/presentations/${presentationId}/${lang}/slides/${slide._id}/image`;
        const formData = new FormData();
        formData.append("myImage", event.target.files[0]);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        axios
          .post(url, formData, config)
          .then((response) => {
            if (response.status === 200) {
              changeSlideImage(
                slide,
                response.data.url,
                response.data.fileType,
                lang
              );
              this.setState({
                previewImage: response.data.url,
                fileType: response.data.fileType,
                uploading: false,
              });
              slide.url = response.data.url;
              slide.fileType = response.data.fileType;
              toast("File uploaded", { type: "success" });
              updateSlide(slide, index);
            }
          })
          .catch((error) => {
            toast("File too large. Limit: 200MB", { type: "error" });
          });
      }
    }
  };

  render() {
    const { slide, addSlide, removeSlide, index, lang, robotId } = this.props;
    const { previewImage, error, message, fileType, slideDuration, uploading } =
      this.state;
    const fileToShow =
      previewImage !== null ? { url: previewImage, fileType } : slide;
    return (
      <div className="slide-container">
        <div className="slide-forms">
          <div className="slide-name">
            <h4>Slide {index + 1}</h4>
          </div>
          <div className="slide-content">
            <div className="slide-image-content">
              {fileToShow.fileType === "video" ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video className="video-player" controls>
                  <source
                    src={`${fileToShow.url}`}
                    type={getVideoType(fileToShow.url)}
                  />
                </video>
              ) : (
                <img
                  className="slide-image"
                  src={`${fileToShow.url}`}
                  alt="slide"
                />
              )}

              <label htmlFor="slide-file" className="input-image">
                Select media
                <input
                  disabled={uploading}
                  id="slide-file"
                  type="file"
                  accept="image/*, video/*"
                  onChange={this.changeImage}
                />
              </label>
            </div>
            <div className="text-form">
              <div style={{ width: "100%" }}>
                <SpeechInput
                  onChange={this.changeText}
                  text={slide.text}
                  robotId={robotId}
                />
                <FormGroup>
                  <Label for="slideDuration">Pause after speech (s)</Label>
                  <Input
                    type="number"
                    name="number"
                    id="slideDuration"
                    placeholder="Slide duration"
                    value={slideDuration}
                    onChange={this.changeDuration}
                  />
                </FormGroup>
              </div>
              {message !== null ? (
                <Alert color="success">{message}</Alert>
              ) : undefined}
              {error !== null ? (
                <Alert color="danger">{error}</Alert>
              ) : undefined}
            </div>
          </div>
          <div className="slide-buttons">
            <Button color="danger" onClick={() => removeSlide(index, lang)}>
              X
            </Button>
          </div>
        </div>
        <Button color="secondary" onClick={() => addSlide(index + 1, lang)}>
          <b>+</b>
        </Button>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default SlideView;
