import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import Storyline from "../../../components/Storyline";
import TextPreview from "../../../components/TextPreview";
import * as animations from "../../../constants/animations";
import languages from "../../../constants/languages";
import axios from "../../../utils/AxiosService";

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.changeAnswerText = this.changeAnswerText.bind(this);
    this.changeAnswerAnimation = this.changeAnswerAnimation.bind(this);
    this.changeAnswerAction = this.changeAnswerAction.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    this.fetchStoryline = this.fetchStoryline.bind(this);
    this.state = {
      smalltalk: null,
      storylines: [],
      changesMade: false,
    };
  }

  componentDidMount() {
    this.fetchSmalltalk();
    this.fetchStoryline();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchSmalltalk = () => {
    const url = `/smalltalks`;
    axios
      .get(url)
      .then((result) => {
        console.log(result);
        this.setState({
          smalltalk: result.data[0].configuration,
        });
      })
      .catch(() => toast("Server Error", { type: "error" }));
  };

  fetchStoryline = () => {
    const url = `/storylines`;
    axios
      .get(url)
      .then((result) => {
        this.setState({
          storylines: result.data.map((data) => data.configuration),
        });
      })
      .catch(() => toast("Server Error", { type: "error" }));
  };

  addSubject = () => {
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    languages.forEach((lang) => {
      tempSmalltalk[lang].subjects.push({ questions: [], answers: [] });
    });
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  addQuestion = (subInd) => {
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    console.log(tempSmalltalk);
    languages.forEach((lang) => {
      tempSmalltalk[lang].subjects[subInd].questions.push("");
    });
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  addAnswer = (subInd) => {
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    languages.forEach((lang) => {
      tempSmalltalk[lang].subjects[subInd].answers.push(null);
    });
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  deleteSubject = (subInd) => {
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    languages.forEach((lang) => {
      tempSmalltalk[lang].subjects.splice(subInd, 1);
    });
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  deleteQuestion = (subInd, questionInd) => {
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    languages.forEach((lang) => {
      tempSmalltalk[lang].subjects[subInd].questions.splice(questionInd, 1);
    });
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  deleteAnswer = (subInd, answInd) => {
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    languages.forEach((lang) => {
      tempSmalltalk[lang].subjects[subInd].answers.splice(answInd, 1);
    });
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  changeQuestion = (subjectInd, questionInd, value) => {
    const { lang } = this.props;
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    tempSmalltalk[lang].subjects[subjectInd].questions[questionInd] = value;
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  changeAnswerText = (subjectInd, answerInd, value) => {
    const { lang } = this.props;
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    tempSmalltalk[lang].subjects[subjectInd].answers[answerInd].text = value;
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  changeAnswerAnimation = (subjectInd, answerInd, value) => {
    const { lang } = this.props;
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    tempSmalltalk[lang].subjects[subjectInd].answers[answerInd].animation =
      value.value;
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  changeAnswerAction = (subjectInd, answerInd, value) => {
    const { lang } = this.props;
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    tempSmalltalk[lang].subjects[subjectInd].answers[answerInd].action =
      value.value;
    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  selectStoryline = (subjectInd, answerInd, storyline) => {
    const { smalltalk } = this.state;
    const tempSmalltalk = smalltalk;
    languages.forEach((lang) => {
      tempSmalltalk[lang].subjects[subjectInd].answers[answerInd] = storyline;
    });

    this.setState({
      smalltalk: tempSmalltalk,
      changesMade: true,
    });
  };

  saveSmalltalk = () => {
    const { selectedRobot } = this.props;
    const { smalltalk } = this.state;
    const url = `/smalltalks/${smalltalk._id}/robots/${selectedRobot._id}`;
    axios
      .put(url, { smalltalk: smalltalk })
      .then((response) => {
        if (response.status === 200) {
          toast("Smalltalk saved", { type: "success" });
          this.setState({
            smalltalk: response.data,
            changesMade: false,
          });
        } else {
          toast("Server Error", { type: "error" });
        }
      })
      .catch(() => toast("Server Error", { type: "error" }));
  };

  getImageName = (answer) => {
    const { selectedRobot } = this.props;
    const animation = animations.animations[selectedRobot.type].find(
      (anim) => anim.value === answer.animation
    );
    return animation === undefined ? null : animation.file;
  };

  render() {
    const { lang, selectedRobot } = this.props;
    const { smalltalk, storylines } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Smalltalk</h1>
                <p className="category">
                  Here you can create custom dialogues that Pepper will be able
                  to perform from the main menu
                </p>
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button color="primary" onClick={this.saveSmalltalk}>
                    Save changes
                  </Button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        {smalltalk === null
          ? undefined
          : smalltalk[lang].subjects.map((subject, subInd) => (
              <div className="row" key={subInd}>
                <div className="col-md-12">
                  <div
                    className="card"
                    style={{ display: "flex", flexFlow: "row" }}
                  >
                    <div className="content" style={{ width: "47%" }}>
                      <h3>
                        Questions{" "}
                        <small>(Sentences that trigger the answer)</small>
                      </h3>
                      <div
                        style={{
                          position: "absolute",
                          top: "15px",
                          right: "30px",
                        }}
                      >
                        <Button
                          style={{ float: "right", marginBottom: "10px" }}
                          onClick={() => this.deleteSubject(subInd)}
                          className="btn btn-danger btn-simple btn-icon "
                        >
                          <i className="pe-7s-trash"></i>
                        </Button>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#a1a1a1",
                          marginRight: "10px",
                          height: "1px",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      />
                      {smalltalk === null
                        ? undefined
                        : subject.questions.map((question, questInd) => (
                            <div key={questInd}>
                              <div
                                className="oral-input"
                                style={{ marginBottom: "15px" }}
                              >
                                <Input
                                  onChange={(event) =>
                                    this.changeQuestion(
                                      subInd,
                                      questInd,
                                      event.target.value
                                    )
                                  }
                                  type="text"
                                  placeholder="Question"
                                  value={question}
                                />
                                <TextPreview
                                  text={question}
                                  robotId={selectedRobot._id}
                                />
                                &nbsp;
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.deleteQuestion(subInd, questInd)
                                  }
                                  rel="tooltip"
                                  data-placement="left"
                                  title=""
                                  className="btn btn-danger btn-simple btn-icon "
                                  data-original-title="Remove question"
                                >
                                  <i className="pe-7s-trash"></i>
                                </button>
                              </div>
                              {questInd ===
                              subject.questions.length - 1 ? undefined : (
                                <div
                                  style={{
                                    backgroundColor: "#a1a1a1",
                                    marginRight: "10px",
                                    height: "1px",
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                />
                              )}
                            </div>
                          ))}
                      <br />
                      <Button
                        color="success"
                        style={{ marginRight: "10px" }}
                        onClick={() => this.addQuestion(subInd)}
                      >
                        New question <i className="pe-7s-plus"></i>
                      </Button>
                    </div>
                    <i
                      className="pe-7s-shuffle"
                      style={{ fontSize: "40px", margin: "auto" }}
                    />
                    <div
                      className="content"
                      style={{ width: "47%", float: "right" }}
                    >
                      <h3>
                        Answers <small>(Picks one at random)</small>
                      </h3>
                      <div
                        style={{
                          position: "absolute",
                          top: "15px",
                          right: "30px",
                        }}
                      >
                        <Button
                          style={{ float: "right", marginBottom: "10px" }}
                          onClick={() => this.deleteSubject(subInd)}
                          className="btn btn-danger btn-simple btn-icon "
                        >
                          <i className="pe-7s-trash"></i>
                        </Button>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#a1a1a1",
                          marginRight: "10px",
                          height: "1px",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      />
                      {smalltalk === null
                        ? undefined
                        : subject.answers.map((answer, answInd) => (
                            <div key={answInd}>
                              <div
                                className="oral-input"
                                style={{ marginBottom: "15px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexFlow: "row",
                                    width: "100%",
                                  }}
                                >
                                  <div style={{ width: "90%" }}>
                                    <Dropdown
                                      options={storylines
                                        .filter(
                                          (storyline) => storyline !== undefined
                                        )
                                        .map((storyline) => ({
                                          label: storyline.name,
                                          value: storyline,
                                        }))}
                                      onChange={(value) =>
                                        this.selectStoryline(
                                          subInd,
                                          answInd,
                                          value.value
                                        )
                                      }
                                      value={
                                        answer === null || answer === undefined
                                          ? null
                                          : {
                                              label: answer.name,
                                              value: answer._id,
                                            }
                                      }
                                      placeholder="Select a storyline"
                                    />
                                  </div>
                                  <Popup
                                    trigger={
                                      <span>
                                        <Button
                                          style={{
                                            height: "38px",
                                            marginRight: "4px",
                                          }}
                                          className="btn btn-primary"
                                          disabled={answer === null}
                                        >
                                          <i className="pe-7s-pen"></i>
                                        </Button>
                                      </span>
                                    }
                                    position="left center"
                                    modal={true}
                                  >
                                    {(close) => (
                                      <Storyline
                                        selectedRobot={selectedRobot}
                                        storyline={answer}
                                        close={close}
                                        fetchStoryline={this.fetchStoryline}
                                        selectStoryline={(storyline) =>
                                          this.selectStoryline(
                                            subInd,
                                            answInd,
                                            storyline
                                          )
                                        }
                                      />
                                    )}
                                  </Popup>
                                  <Popup
                                    trigger={
                                      <span>
                                        <Button
                                          style={{ height: "38px" }}
                                          className="btn btn-success"
                                        >
                                          <i className="pe-7s-plus"></i>
                                        </Button>
                                      </span>
                                    }
                                    position="left center"
                                    modal={true}
                                  >
                                    {(close) => (
                                      <Storyline
                                        selectedRobot={selectedRobot}
                                        storyline={null}
                                        newStory
                                        selectStoryline={(storyline) =>
                                          this.selectStoryline(
                                            subInd,
                                            answInd,
                                            storyline
                                          )
                                        }
                                        close={close}
                                        fetchStoryline={this.fetchStoryline}
                                      />
                                    )}
                                  </Popup>
                                </div>
                                &nbsp;
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.deleteAnswer(subInd, answInd)
                                  }
                                  rel="tooltip"
                                  data-placement="left"
                                  title=""
                                  className="btn btn-danger btn-simple btn-icon "
                                  data-original-title="Remove answer"
                                >
                                  <i className="pe-7s-trash"></i>
                                </button>
                              </div>
                              {answInd ===
                              subject.answers.length - 1 ? undefined : (
                                <div
                                  style={{
                                    backgroundColor: "#a1a1a1",
                                    marginRight: "10px",
                                    height: "1px",
                                    width: "100%",
                                    marginBottom: "20px",
                                  }}
                                />
                              )}
                            </div>
                          ))}
                      <br />
                      <Button
                        color="success"
                        style={{ marginRight: "10px" }}
                        onClick={() => this.addAnswer(subInd)}
                      >
                        New answer <i className="pe-7s-plus"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        <Button
          color="success"
          style={{ marginRight: "10px" }}
          onClick={this.addSubject}
        >
          Add subject <i className="pe-7s-plus"></i>
        </Button>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </React.Fragment>
    );
  }
}

export default QuestionList;
