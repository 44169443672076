import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import axios from "../../utils/AxiosService";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
      matchingPwd: true,
    };
  }

  saveAccount = () => {
    const { selectedAccount } = this.state;
    const url = `/accounts`;
    axios.put(url, { account: selectedAccount }).then((response) => {
      toast("Account saved", { type: "success" });
      this.setState({
        selectedAccount: response.data,
      });
    });
  };

  changeOldPassword = (value) => {
    this.setState({
      oldPassword: value,
    });
  };

  changeNewPassword1 = (value) => {
    const { newPassword2 } = this.state;
    const match = newPassword2 === value;
    this.setState({
      newPassword1: value,
      matchingPwd: match,
    });
  };

  changeNewPassword2 = (value) => {
    const { newPassword1 } = this.state;
    const match = newPassword1 === value;
    this.setState({
      newPassword2: value,
      matchingPwd: match,
    });
  };

  changePassword = () => {
    const url = `/accounts/newpassword`;
    const { oldPassword, newPassword1, newPassword2, matchingPwd } = this.state;
    if (matchingPwd && newPassword1.length >= 8) {
      axios
        .put(url, { oldPassword, newPassword1, newPassword2 })
        .then((result) => {
          if (result.status === 200) {
            toast("Password changed", { type: "success" });
          }
        });
    } else {
      let message = !matchingPwd ? "- Provided passwords don't match" : "";
      message +=
        newPassword1.length < 8
          ? "\n- Please enter a password of at least 8 characters."
          : "";
      toast(message, { type: "error" });
    }
  };

  render() {
    const { oldPassword, newPassword1, newPassword2, matchingPwd } = this.state;

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Profile</h1>
                <p className="category">Here you can change your password</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1>Reset password</h1>
                <p>Old password</p>
                <Input
                  type="password"
                  invalid={!matchingPwd}
                  onChange={(event) =>
                    this.changeOldPassword(event.target.value)
                  }
                  placeholder="Old password"
                  value={oldPassword}
                />
                <br />
                <p>New password</p>
                <Input
                  type="password"
                  invalid={!matchingPwd}
                  onChange={(event) =>
                    this.changeNewPassword1(event.target.value)
                  }
                  placeholder="New password"
                  value={newPassword1}
                />
                <br />
                <p>New password (repeat)</p>
                <Input
                  type="password"
                  invalid={!matchingPwd}
                  onChange={(event) =>
                    this.changeNewPassword2(event.target.value)
                  }
                  placeholder="Repeat password"
                  value={newPassword2}
                />
                <br />
                <Button onClick={this.changePassword} color="danger">
                  Reset
                </Button>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Profile;
