import React from "react";
import { Route, withRouter } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import SideBar from "../../components/SideBar";
import axios from "../../utils/AxiosService";
import AdminConsole from "../AdminConsole";
import Barcode from "../Barcode";
import Barcodes from "../Barcodes";
import Calendar from "../Calendar";
import Chatbot from "../Chatbot";
import Chatbots from "../Chatbots";
import Contact from "../Contact";
import DataCollector from "../DataCollector";
import DataCollectors from "../DataCollectors";
import Debug from "../Debug";
import Entertainment from "../Entertainment";
import FaceRecognition from "../FaceRecognition";
import FaceRecognitions from "../FaceRecognitions";
import FAQ from "../FAQ";
import Feed from "../Feed";
import Galleries from "../Galleries";
import Gallery from "../Gallery";
/**
 * Pages
 */
import Home from "../Home";
import MenuStructure from "../MenuStructure";
import MenuStructures from "../MenuStructures";
import News from "../News";
import Persons from "../Persons";
import Presentation from "../Presentation";
import Presentations from "../Presentations";
import Profile from "../Profile";
import Quizz from "../Quizz";
import Quizzes from "../Quizzes";
import Receptionist from "../Receptionist";
import Receptionists from "../Receptionists";
import RobotBehavior from "../RobotBehavior";
import RobotBehaviors from "../RobotBehaviors";
import ServiceContact from "../ServiceContact";
import Signin from "../Signin";
import Smalltalk from "../Smalltalk";
import Statistics from "../Statistics";
import Storyline from "../Storyline";
import Storylines from "../Storylines";
import Style from "../Style";
import Styles from "../Styles";
import Survey from "../Survey";
import Surveys from "../Surveys";
import Team from "../Team";
import Teams from "../Teams";
import WaitingRoom from "../WaitingRoom";
import Wallpaper from "../Wallpaper";
import YeoTheater from "../YeoTheater";
import Footer from "./Footer";
import Header from "./Header";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.selectRobot = this.selectRobot.bind(this);
    this.updateRobotList = this.updateRobotList.bind(this);
    this.state = {
      selectedRobot: JSON.parse(localStorage.getItem("selectedRobot")) || null,
      robots: [],
    };
  }

  selectRobot = (robot) => {
    this.setState({
      selectedRobot: robot,
    });
  };

  updateRobotList = () => {
    const url = `/robots`;
    axios
      .get(url)
      .then((result) => {
        if (result.data !== undefined) {
          this.setState((prevState) => ({
            robots: result.data,
            selectedRobot:
              prevState.selectedRobot === null ||
              prevState.selectedRobot === undefined
                ? null
                : result.data.find(
                    (robot) => robot._id === prevState.selectedRobot._id
                  ),
          }));
        } else {
          this.props.history.push("/signin");
        }
      })
      .catch((error) => console.log("error"));
  };

  render() {
    const { selectedRobot, robots } = this.state;

    return (
      <div className="wrapper">
        <div className="close-layer"></div>
        <SideBar
          selectedRobot={selectedRobot}
          updateRobotList={this.updateRobotList}
          robots={robots}
          selectRobot={this.selectRobot}
        />

        <div className="main-panel">
          <Header />
          <Route path="/signin" component={Signin} />

          <ProtectedRoute
            exact
            path="/"
            component={Home}
            selectRobot={this.selectRobot}
            updateRobotList={this.updateRobotList}
            selectedRobot={selectedRobot}
            moduleName="robotSelection"
          />

          <ProtectedRoute
            path="/adminconsole"
            updateRobotList={this.updateRobotList}
            component={AdminConsole}
            moduleName="admin"
          />

          <ProtectedRoute
            path="/wallpaper"
            component={Wallpaper}
            updateRobotList={this.updateRobotList}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="wallpaper"
          />
          <ProtectedRoute
            path="/presentations"
            component={Presentations}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="presentation"
          />
          <ProtectedRoute
            path="/presentation"
            component={Presentation}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="presentation"
          />
          <ProtectedRoute
            path="/faq"
            component={FAQ}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="faq"
          />
          <ProtectedRoute
            path="/teams"
            component={Teams}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="team"
          />
          <ProtectedRoute
            path="/team"
            component={Team}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="team"
          />
          <ProtectedRoute
            path="/contacts"
            component={Contact}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="contact"
          />
          <ProtectedRoute
            path="/calendar"
            component={Calendar}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="calendar"
          />
          <ProtectedRoute
            path="/quizzes"
            component={Quizzes}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="quizz"
          />
          <ProtectedRoute
            path="/quizz"
            component={Quizz}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="quizz"
          />
          <ProtectedRoute
            path="/surveys"
            component={Surveys}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="survey"
          />
          <ProtectedRoute
            path="/survey"
            component={Survey}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="survey"
          />
          <ProtectedRoute
            path="/barcodes"
            component={Barcodes}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="barcode"
          />
          <ProtectedRoute
            path="/barcode"
            component={Barcode}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="barcode"
          />
          <ProtectedRoute
            path="/robotBehaviors"
            component={RobotBehaviors}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="robotBehavior"
          />
          <ProtectedRoute
            path="/robotBehavior"
            component={RobotBehavior}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="robotBehavior"
          />
          <ProtectedRoute
            path="/servicecontacts"
            component={ServiceContact}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="servicecontact"
          />
          <ProtectedRoute
            path="/receptionists"
            component={Receptionists}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="receptionist"
          />
          <ProtectedRoute
            path="/receptionist"
            component={Receptionist}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="receptionist"
          />
          <ProtectedRoute
            path="/menuStructure"
            component={MenuStructure}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="menuStructure"
          />
          <ProtectedRoute
            path="/menuStructures"
            component={MenuStructures}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="menuStructure"
          />
          <ProtectedRoute
            path="/statistics"
            component={Statistics}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="statistics"
          />
          <ProtectedRoute
            path="/entertainments"
            component={Entertainment}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="entertainment"
          />
          <ProtectedRoute
            path="/styles"
            component={Styles}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="style"
          />
          <ProtectedRoute
            path="/style"
            component={Style}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="style"
          />
          <ProtectedRoute
            path="/profile"
            component={Profile}
            moduleName="profile"
          />
          <ProtectedRoute
            path="/smalltalk"
            component={Smalltalk}
            selectedRobot={selectedRobot}
            moduleName="smalltalk"
          />
          <ProtectedRoute
            path="/chatbots"
            component={Chatbots}
            selectedRobot={selectedRobot}
            moduleName="chatbot"
          />
          <ProtectedRoute
            path="/chatbot"
            component={Chatbot}
            selectedRobot={selectedRobot}
            moduleName="chatbot"
          />
          <ProtectedRoute
            path="/news"
            component={News}
            selectedRobot={selectedRobot}
            moduleName="news"
          />
          <ProtectedRoute
            path="/feed"
            component={Feed}
            selectedRobot={selectedRobot}
            moduleName="news"
          />
          <ProtectedRoute
            path="/datacollectors"
            component={DataCollectors}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="datacollector"
          />
          <ProtectedRoute
            path="/datacollector"
            component={DataCollector}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="datacollector"
          />
          <ProtectedRoute
            path="/storylines"
            component={Storylines}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="storyline"
          />
          <ProtectedRoute
            path="/storyline"
            component={Storyline}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="storyline"
          />
          <ProtectedRoute
            path="/debug"
            component={Debug}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="debug"
          />
          <ProtectedRoute
            path="/yeotheater"
            component={YeoTheater}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="yeotheater"
          />
          <ProtectedRoute
            path="/waitingroom"
            component={WaitingRoom}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="waitingroom"
          />
          <ProtectedRoute
            path="/galleries"
            component={Galleries}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="gallery"
          />
          <ProtectedRoute
            path="/gallery"
            component={Gallery}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="gallery"
          />
          <ProtectedRoute
            path="/faceRecognitions"
            component={FaceRecognitions}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="faceRecognition"
          />
          <ProtectedRoute
            path="/facerecognition"
            component={FaceRecognition}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="faceRecognition"
          />
          <ProtectedRoute
            path="/persons"
            component={Persons}
            selectedRobot={selectedRobot}
            needRobot
            moduleName="faceRecognition"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Main);
