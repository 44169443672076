import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import LanguagePicker from '../../../components/LanguagePicker';

class SubmenuCreation extends Component {
  constructor(props) {
    super(props);

    this.setLang = this.setLang.bind(this);

    this.state = {
      lang: 'en',
      submenu: {
        en: '',
        de: '',
        fr: '',
        it: '',
      }
    };
  }

  setLang = (lang) => {
    this.setState({
      lang,
    })
  }

  changeSubmenuName = (name) => {
    const { submenu, lang } = this.state;
    let tmpMenu = submenu;
    tmpMenu[lang] = name;
    this.setState({
      submenu: tmpMenu,
    });
  }

  saveSubmenu = () => {
    const { i, j, createLevel } = this.props;
    const { submenu } = this.state;
    createLevel(i, j, submenu);
  }

  render() {
    const { submenu, lang } = this.state;
    return (
      <div>
        <LanguagePicker setLang={this.setLang} />
        <h6>Submenu name</h6>
        <Input type="text" onChange={event => this.changeSubmenuName(event.target.value)} value={submenu[lang]}></Input>
        <br />
        <Button onClick={this.saveSubmenu}>Save</Button>
      </div>
    );
  }
}

export default SubmenuCreation;
