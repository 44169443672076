import PropTypes from "prop-types";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "../../utils/AxiosService";

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class Signin extends Component {
  static propTypes = {
    history: PropTypes.PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      const { message } = location.state;
      if (message !== null) {
        toast(message, { type: "error" });
      }
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { history } = this.props;
    const { email, password } = this.state;
    const url = `/authentication/signin`;
    axios.post(url, { email, password }).then((res) => {
      if (res.data !== undefined && res.data.success) {
        const account = {
          jwt: res.data.token,
          id: res.data.account._id,
          email: res.data.account.email,
          components: res.data.account.components,
        };

        // Store the user in local storage
        localStorage.setItem("account", JSON.stringify(account));

        this.setState({ ...INITIAL_STATE });

        // Go to the next URL when logged
        history.push("/");
      } else {
        toast('Login or password incorrect"', { type: "error" });
      }
    });
  };

  render() {
    return (
      <div className="signin-container">
        <h2>Sign in</h2>

        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              onChange={(event) =>
                this.setState(byPropKey("email", event.target.value))
              }
              type="email"
              name="email"
              id="email"
              placeholder="Account email"
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              onChange={(event) =>
                this.setState(byPropKey("password", event.target.value))
              }
              type="password"
              name="password"
              id="password"
              placeholder="Account password"
            />
          </FormGroup>
          <Button color="primary" type="submit" className="action-btn">
            Sign in
          </Button>
        </Form>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Signin;
