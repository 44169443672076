import "dotenv/config";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert/dist/sweetalert.css";
import App from "./App";
import "./assets/styles/base.scss";
import configureStore from "./config/configureStore";
import registerServiceWorker from "./registerServiceWorker";

const store = configureStore();
const rootElement = document.getElementById("root");

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

registerServiceWorker();
