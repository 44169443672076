import React, { Component } from "react";
import update from "react-addons-update";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button, FormGroup, Input, Label } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import SpeechAction from "../../components/SpeechAction";
import axios from "../../utils/AxiosService";
import getVideoType from "./utils/utils";

class RobotBehavior extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.state = {
      robotBehavior: this.props.location.state.robotBehavior,
      error: null,
      message: null,
      lang: "en",
      changesMade: false,
      selectedCategory: -1,
      newCategoryName: "",
    };
  }
  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  saveRobotBehavior = () => {
    const { selectedRobot } = this.props;
    const { robotBehavior } = this.state;
    const url = `/robotBehaviors/${robotBehavior._id}/robots/${selectedRobot._id}`;
    axios.put(url, { robotBehavior: robotBehavior }).then((response) => {
      if (response.status === 200) {
        toast("Robot Behavior saved", { type: "success" });
        this.setState({
          robotBehavior: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  changeScreensaver = (event) => {
    const { selectedRobot } = this.props;
    const { robotBehavior } = this.state;
    const url = `/robotBehaviors/${robotBehavior._id}/robots/${selectedRobot._id}/screensaver`;
    const formData = new FormData();
    formData.append("myImage", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        const { robotBehavior } = this.state;
        const targetScreensaver = robotBehavior.screensaver;
        targetScreensaver.url = response.data.url;
        targetScreensaver.fileType = response.data.fileType;
        this.setState({
          previewImage: response.data.url,
          fileType: response.data.fileType,
        });
        robotBehavior.screensaver.url = response.data.url;
        robotBehavior.screensaver.fileType = response.data.fileType;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  changeThinkingImage = (event) => {
    const { robotBehavior, lang } = this.state;
    const url = `/robotBehaviors/${robotBehavior._id}/${lang}/thinkingImage`;
    const formData = new FormData();
    formData.append("myImage", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        const { robotBehavior } = this.state;
        const tmpRobotBehavior = robotBehavior;
        const targetImage = robotBehavior[lang].thinkingImage;
        targetImage.url = response.data.url;
        targetImage.fileType = response.data.fileType;

        tmpRobotBehavior[lang].thinkingImage.url = response.data.url;
        tmpRobotBehavior[lang].thinkingImage.fileType = response.data.fileType;
        console.log("salit");
        this.setState({
          robotBehavior: tmpRobotBehavior,
          previewImageThinking: response.data.url,
          fileTypeThinking: response.data.fileType,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  addCategory = () => {
    const { robotBehavior, newCategoryName } = this.state;
    if (newCategoryName === "") {
      toast("Name can not be empty", { type: "error" });
      return false;
    }
    const tmprobotBehavior = robotBehavior;
    let alreadyExists = false;
    tmprobotBehavior.solitaries.categories.map((cat) =>
      cat.name === newCategoryName ? (alreadyExists = true) : ""
    );
    if (alreadyExists) {
      toast("This name already exists", { type: "error" });
      return false;
    }

    const newList = { name: newCategoryName, priority: 3, storylines: [] };
    tmprobotBehavior.solitaries.categories.push(newList);
    this.setState({
      selectedCategory: tmprobotBehavior.solitaries.categories.length - 1,
      robotBehavior: tmprobotBehavior,
      newCategoryName: "",
      changesMade: true,
    });
  };

  changeTriggerSentence = (value, index) => {
    const { robotBehavior, lang } = this.state;
    const tmpRobotBehavior = robotBehavior;
    tmpRobotBehavior.screensaver.triggerSentences[lang][index] = value;
    this.setState({
      robotBehavior: tmpRobotBehavior,
      changesMade: true,
    });
  };

  addSentence = (index) => {
    const { robotBehavior, lang } = this.state;
    const tmpRobotBehavior = robotBehavior;
    tmpRobotBehavior.screensaver.triggerSentences[lang].push("");
    this.setState({
      robotBehavior: tmpRobotBehavior,
      changesMade: true,
    });
  };

  removeSentence = (index) => {
    const { robotBehavior, lang } = this.state;
    const tmpRobotBehavior = robotBehavior;
    tmpRobotBehavior.screensaver.triggerSentences[lang].splice(index, 1);
    this.setState({
      robotBehavior: tmpRobotBehavior,
      changesMade: true,
    });
  };

  render() {
    //const { robotBehavior } = location.state;
    const { robotBehavior, lang, selectedCategory, newCategoryName } =
      this.state;
    const { selectedRobot } = this.props;

    let hasSolitaries = false;

    robotBehavior.solitaries.categories.forEach((category) => {
      if (category.storylines.length > 0) {
        hasSolitaries = true;
      }
    });

    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Robot Behavior</h1>
                <p className="category">
                  Here you can create a new robot Behavior
                </p>
              </div>
              <div className="content">
                <h4>Name</h4>
                <Input
                  style={{ width: "200px" }}
                  onChange={(event) =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        [lang]: { name: { $set: event.target.value } },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                  type="text"
                  placeholder="Name"
                  value={robotBehavior[lang].name}
                />

                <FormGroup style={{ marginTop: "20px" }}>
                  <Label>Enabled:</Label>
                  <Input
                    onChange={() =>
                      this.setState({
                        robotBehavior: update(this.state.robotBehavior, {
                          enabled: { $set: !robotBehavior.enabled },
                        }),
                        changesMade: update(this.state.changesMade, {
                          $set: true,
                        }),
                      })
                    }
                    type="checkbox"
                    name="enabled"
                    defaultChecked={robotBehavior.enabled}
                    style={{ marginLeft: "10px" }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="startTimer">Start timer (s)</Label>
                  <Input
                    style={{ width: "200px" }}
                    type="number"
                    name="number"
                    id="startTimer"
                    placeholder="Start Timer"
                    value={robotBehavior.startTimer}
                    onChange={(event) =>
                      this.setState({
                        robotBehavior: update(this.state.robotBehavior, {
                          startTimer: { $set: event.target.value },
                        }),
                        changesMade: update(this.state.changesMade, {
                          $set: true,
                        }),
                      })
                    }
                  />
                </FormGroup>

                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/robotbehaviors">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveRobotBehavior}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Screensaver</h2>
              </div>
              <div className="content">
                <h4 style={hasSolitaries ? { color: "gray" } : undefined}>
                  Trigger sentences{" "}
                  {hasSolitaries ? "(Disabled with solitaries)" : undefined}
                </h4>
                {robotBehavior.screensaver.triggerSentences[lang].map(
                  (sentence, index) => (
                    <div style={{ display: "flex", flexFlow: "row" }}>
                      <Input
                        disabled={hasSolitaries}
                        type="text"
                        style={{ marginBottom: "10px" }}
                        onChange={(event) =>
                          this.changeTriggerSentence(event.target.value, index)
                        }
                        value={sentence}
                      />
                      <Button
                        disabled={hasSolitaries}
                        style={{ height: "34px", marginLeft: "10px" }}
                        color="danger"
                        onClick={() => this.removeSentence(index)}
                      >
                        <i className="pe-7s-close" />
                      </Button>
                    </div>
                  )
                )}

                <Button
                  disabled={hasSolitaries}
                  style={{ marginBottom: "20px" }}
                  color="success"
                  onClick={this.addSentence}
                >
                  Add sentence
                  <i className="pe-7s-plus" />
                </Button>
                <div className="slide-image-content">
                  {robotBehavior.screensaver.fileType === "video" ? (
                    <video className="video-player" controls>
                      <source
                        src={`${robotBehavior.screensaver.url}`}
                        type={getVideoType(robotBehavior.screensaver.url)}
                      />
                    </video>
                  ) : (
                    <img
                      className="slide-image"
                      src={robotBehavior.screensaver.url}
                      alt="screensaver"
                    />
                  )}
                </div>
                <div style={{ marginBottom: "40px" }}>
                  <label htmlFor="slide-file" className="input-image">
                    Choose a new Screensaver
                    <input
                      id="slide-file"
                      type="file"
                      accept="image/*, video/*"
                      onChange={this.changeScreensaver}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Welcome Storylines</h2>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.welcomeStorylines.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <SpeechAction
                            selectedRobot={selectedRobot}
                            storyline={robotBehavior.welcomeStorylines[index]}
                            selectStoryline={(value) =>
                              this.setState({
                                robotBehavior: update(
                                  this.state.robotBehavior,
                                  {
                                    welcomeStorylines: {
                                      [index]: { $set: value },
                                    },
                                  }
                                ),
                                changesMade: update(this.state.changesMade, {
                                  $set: true,
                                }),
                              })
                            }
                            name=""
                          />
                        </td>
                        <td className="td-actions">
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                robotBehavior: update(
                                  this.state.robotBehavior,
                                  {
                                    welcomeStorylines: {
                                      $splice: [[index, 1]],
                                    },
                                  }
                                ),
                                changesMade: update(this.state.changesMade, {
                                  $set: true,
                                }),
                              })
                            }
                            rel="tooltip"
                            data-placement="left"
                            title=""
                            className="btn btn-danger btn-simple btn-icon "
                            data-original-title="Remove Storyline"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        welcomeStorylines: { $push: [null] },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Sensors Storylines</h2>
              </div>
              <div className="content">
                <h4>Left bumper</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.sensorsStorylines.bumpers.left.map(
                      (data, index) => (
                        <tr key={index}>
                          <td>
                            <SpeechAction
                              selectedRobot={selectedRobot}
                              storyline={
                                robotBehavior.sensorsStorylines.bumpers.left[
                                  index
                                ]
                              }
                              selectStoryline={(value) =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        bumpers: {
                                          left: { [index]: { $set: value } },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              name=""
                            />
                          </td>
                          <td className="td-actions">
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        bumpers: {
                                          left: { $splice: [[index, 1]] },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              rel="tooltip"
                              data-placement="left"
                              title=""
                              className="btn btn-danger btn-simple btn-icon "
                              data-original-title="Remove Storyline"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        sensorsStorylines: {
                          bumpers: { left: { $push: [null] } },
                        },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />

                <h4>Right bumper</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.sensorsStorylines.bumpers.right.map(
                      (data, index) => (
                        <tr key={index}>
                          <td>
                            <SpeechAction
                              selectedRobot={selectedRobot}
                              storyline={
                                robotBehavior.sensorsStorylines.bumpers.right[
                                  index
                                ]
                              }
                              selectStoryline={(value) =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        bumpers: {
                                          right: { [index]: { $set: value } },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              name=""
                            />
                          </td>
                          <td className="td-actions">
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        bumpers: {
                                          right: { $splice: [[index, 1]] },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              rel="tooltip"
                              data-placement="left"
                              title=""
                              className="btn btn-danger btn-simple btn-icon "
                              data-original-title="Remove Storyline"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        sensorsStorylines: {
                          bumpers: { right: { $push: [null] } },
                        },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />

                <h4>Back bumper</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.sensorsStorylines.bumpers.back.map(
                      (data, index) => (
                        <tr key={index}>
                          <td>
                            <SpeechAction
                              selectedRobot={selectedRobot}
                              storyline={
                                robotBehavior.sensorsStorylines.bumpers.back[
                                  index
                                ]
                              }
                              selectStoryline={(value) =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        bumpers: {
                                          back: { [index]: { $set: value } },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              name=""
                            />
                          </td>
                          <td className="td-actions">
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        bumpers: {
                                          back: { $splice: [[index, 1]] },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              rel="tooltip"
                              data-placement="left"
                              title=""
                              className="btn btn-danger btn-simple btn-icon "
                              data-original-title="Remove Storyline"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        sensorsStorylines: {
                          bumpers: { back: { $push: [null] } },
                        },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />

                <h4>Left hand</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.sensorsStorylines.hands.left.map(
                      (data, index) => (
                        <tr key={index}>
                          <td>
                            <SpeechAction
                              selectedRobot={selectedRobot}
                              storyline={
                                robotBehavior.sensorsStorylines.hands.left[
                                  index
                                ]
                              }
                              selectStoryline={(value) =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        hands: {
                                          left: { [index]: { $set: value } },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              name=""
                            />
                          </td>
                          <td className="td-actions">
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        hands: {
                                          left: { $splice: [[index, 1]] },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              rel="tooltip"
                              data-placement="left"
                              title=""
                              className="btn btn-danger btn-simple btn-icon "
                              data-original-title="Remove Storyline"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        sensorsStorylines: {
                          hands: { left: { $push: [null] } },
                        },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />

                <h4>Right hand</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.sensorsStorylines.hands.right.map(
                      (data, index) => (
                        <tr key={index}>
                          <td>
                            <SpeechAction
                              selectedRobot={selectedRobot}
                              storyline={
                                robotBehavior.sensorsStorylines.hands.right[
                                  index
                                ]
                              }
                              selectStoryline={(value) =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        hands: {
                                          right: { [index]: { $set: value } },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              name=""
                            />
                          </td>
                          <td className="td-actions">
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({
                                  robotBehavior: update(
                                    this.state.robotBehavior,
                                    {
                                      sensorsStorylines: {
                                        hands: {
                                          right: { $splice: [[index, 1]] },
                                        },
                                      },
                                    }
                                  ),
                                  changesMade: update(this.state.changesMade, {
                                    $set: true,
                                  }),
                                })
                              }
                              rel="tooltip"
                              data-placement="left"
                              title=""
                              className="btn btn-danger btn-simple btn-icon "
                              data-original-title="Remove Storyline"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        sensorsStorylines: {
                          hands: { right: { $push: [null] } },
                        },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />

                <h4>Head</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.sensorsStorylines.head.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <SpeechAction
                            selectedRobot={selectedRobot}
                            storyline={
                              robotBehavior.sensorsStorylines.head[index]
                            }
                            selectStoryline={(value) =>
                              this.setState({
                                robotBehavior: update(
                                  this.state.robotBehavior,
                                  {
                                    sensorsStorylines: {
                                      head: { [index]: { $set: value } },
                                    },
                                  }
                                ),
                                changesMade: update(this.state.changesMade, {
                                  $set: true,
                                }),
                              })
                            }
                            name=""
                          />
                        </td>
                        <td className="td-actions">
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                robotBehavior: update(
                                  this.state.robotBehavior,
                                  {
                                    sensorsStorylines: {
                                      head: { $splice: [[index, 1]] },
                                    },
                                  }
                                ),
                                changesMade: update(this.state.changesMade, {
                                  $set: true,
                                }),
                              })
                            }
                            rel="tooltip"
                            data-placement="left"
                            title=""
                            className="btn btn-danger btn-simple btn-icon "
                            data-original-title="Remove Storyline"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        sensorsStorylines: { head: { $push: [null] } },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />

                <h4>On sight</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Storyline</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {robotBehavior.sensorsStorylines.head.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <SpeechAction
                            selectedRobot={selectedRobot}
                            storyline={
                              robotBehavior.sensorsStorylines.onSight[index]
                            }
                            selectStoryline={(value) =>
                              this.setState({
                                robotBehavior: update(
                                  this.state.robotBehavior,
                                  {
                                    sensorsStorylines: {
                                      onSight: { [index]: { $set: value } },
                                    },
                                  }
                                ),
                                changesMade: update(this.state.changesMade, {
                                  $set: true,
                                }),
                              })
                            }
                            name=""
                          />
                        </td>
                        <td className="td-actions">
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                robotBehavior: update(
                                  this.state.robotBehavior,
                                  {
                                    sensorsStorylines: {
                                      onSight: { $splice: [[index, 1]] },
                                    },
                                  }
                                ),
                                changesMade: update(this.state.changesMade, {
                                  $set: true,
                                }),
                              })
                            }
                            rel="tooltip"
                            data-placement="left"
                            title=""
                            className="btn btn-danger btn-simple btn-icon "
                            data-original-title="Remove Storyline"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() =>
                    this.setState({
                      robotBehavior: update(this.state.robotBehavior, {
                        sensorsStorylines: { onSight: { $push: [null] } },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                >
                  Add Storyline
                </Button>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Solitaries</h2>
              </div>
              <div className="content">
                <div style={{ width: "60%", display: "flex", flexFlow: "row" }}>
                  <div style={{ width: "85%" }}>
                    <Dropdown
                      options={robotBehavior.solitaries.categories.map(
                        (category, index) => ({
                          label: category.name,
                          value: category,
                          index,
                        })
                      )}
                      onChange={(value) =>
                        this.setState({ selectedCategory: value.index })
                      }
                      value={
                        selectedCategory === -1
                          ? null
                          : {
                              label:
                                robotBehavior.solitaries.categories[
                                  selectedCategory
                                ].name,
                              value:
                                robotBehavior.solitaries.categories[
                                  selectedCategory
                                ],
                            }
                      }
                      placeholder="Select a catergory..."
                    />
                  </div>
                  <div style={{ width: "15%" }}>
                    <Popup
                      trigger={
                        <span>
                          <Button
                            style={{ height: "38px", marginLeft: "5px" }}
                            color="success"
                          >
                            New category<i className="pe-7s-plus"></i>
                          </Button>
                        </span>
                      }
                      position="right center"
                    >
                      {(close) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px",
                            display: "flex",
                            flexFlow: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6>New category name</h6>
                          <Input
                            style={{ marginBottom: "10px" }}
                            type="text"
                            onChange={(event) =>
                              this.setState({
                                newCategoryName: event.target.value,
                              })
                            }
                            value={newCategoryName}
                            placeholder="Name"
                          />
                          <Button
                            onClick={() => {
                              this.addCategory();
                              close();
                            }}
                            color="success"
                          >
                            Create <i className="pe-7s-plus"></i>
                          </Button>
                        </div>
                      )}
                    </Popup>
                  </div>
                  {selectedCategory === -1 ||
                  selectedCategory === undefined ? undefined : (
                    <div style={{ marginLeft: "100px", width: "15%" }}>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({
                            robotBehavior: update(this.state.robotBehavior, {
                              solitaries: {
                                categories: {
                                  $splice: [[selectedCategory, 1]],
                                },
                              },
                            }),
                            selectedCategory: -1,
                            changesMade: update(this.state.changesMade, {
                              $set: true,
                            }),
                          })
                        }
                        rel="tooltip"
                        data-placement="left"
                        title=""
                        className="btn btn-danger btn-simple btn-icon "
                        data-original-title="Remove Category"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  )}
                </div>
                {selectedCategory === -1 ||
                selectedCategory === undefined ? undefined : (
                  <div>
                    <FormGroup style={{ marginTop: "15px" }}>
                      <Label for="Priority">
                        Priority (5 is highest priority)
                      </Label>
                      <Input
                        style={{ width: "80px" }}
                        type="number"
                        name="number"
                        id="priority"
                        placeholder="priority"
                        min="1"
                        max="5"
                        value={
                          robotBehavior.solitaries.categories[selectedCategory]
                            .priority
                        }
                        onChange={(event) =>
                          this.setState({
                            robotBehavior: update(this.state.robotBehavior, {
                              solitaries: {
                                categories: {
                                  [selectedCategory]: {
                                    priority: { $set: event.target.value },
                                  },
                                },
                              },
                            }),
                            changesMade: update(this.state.changesMade, {
                              $set: true,
                            }),
                          })
                        }
                      />
                    </FormGroup>
                    <table
                      className="table table-bigboy"
                      style={{ marginTop: "10px" }}
                    >
                      <thead>
                        <tr>
                          <th className="th-description">Storyline</th>
                          <th className="th-description">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {robotBehavior.solitaries.categories[
                          selectedCategory
                        ].storylines.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <SpeechAction
                                selectedRobot={selectedRobot}
                                storyline={
                                  robotBehavior.solitaries.categories[
                                    selectedCategory
                                  ].storylines[index]
                                }
                                selectStoryline={(value) =>
                                  this.setState({
                                    robotBehavior: update(
                                      this.state.robotBehavior,
                                      {
                                        solitaries: {
                                          categories: {
                                            [selectedCategory]: {
                                              storylines: {
                                                [index]: { $set: value },
                                              },
                                            },
                                          },
                                        },
                                      }
                                    ),
                                    changesMade: update(
                                      this.state.changesMade,
                                      { $set: true }
                                    ),
                                  })
                                }
                                name=""
                              />
                            </td>

                            <td className="td-actions">
                              <button
                                type="button"
                                onClick={() =>
                                  this.setState({
                                    robotBehavior: update(
                                      this.state.robotBehavior,
                                      {
                                        solitaries: {
                                          categories: {
                                            [selectedCategory]: {
                                              storylines: {
                                                $splice: [[index, 1]],
                                              },
                                            },
                                          },
                                        },
                                      }
                                    ),
                                    changesMade: update(
                                      this.state.changesMade,
                                      { $set: true }
                                    ),
                                  })
                                }
                                rel="tooltip"
                                data-placement="left"
                                title=""
                                className="btn btn-danger btn-simple btn-icon "
                                data-original-title="Remove Storyline"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Button
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        this.setState({
                          robotBehavior: update(this.state.robotBehavior, {
                            solitaries: {
                              categories: {
                                [selectedCategory]: {
                                  storylines: { $push: [null] },
                                },
                              },
                            },
                          }),
                          changesMade: update(this.state.changesMade, {
                            $set: true,
                          }),
                        })
                      }
                    >
                      Add Storyline
                    </Button>
                  </div>
                )}

                <FormGroup style={{ marginTop: "20px" }}>
                  <Label for="intervalTimer">Interval timer (s)</Label>
                  <Input
                    style={{ width: "200px" }}
                    type="number"
                    name="number"
                    id="intervalTimer"
                    placeholder="Interval Timer"
                    value={robotBehavior.solitaries.intervalTimer}
                    onChange={(event) =>
                      this.setState({
                        robotBehavior: update(this.state.robotBehavior, {
                          solitaries: {
                            intervalTimer: { $set: event.target.value },
                          },
                        }),
                        changesMade: update(this.state.changesMade, {
                          $set: true,
                        }),
                      })
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default RobotBehavior;
