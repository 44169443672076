export const modules = [
  { label: 'Administration console', value: 'admin' },
  { label: 'Wallpapers', value: 'wallpaper' },
  { label: 'Presentations', value: 'presentation' },
  { label: 'FAQs', value: 'faq' },
  { label: 'Teams', value: 'team' },
  { label: 'Calendar', value: 'calendar' },
  { label: 'Debug', value: 'debug' },
  { label: 'Entertainment', value: 'entertainment' },
  { label: 'Menu structure', value: 'menuStructure' },
  { label: 'Quizzes', value: 'quizz' },
  { label: 'Surveys', value: 'survey' },
  { label: 'Receptionist styles', value: 'receptionist' },
  { label: 'Service contact', value: 'servicecontact' },
  { label: 'Statistics', value: 'statistics' },
  { label: 'Contacts', value: 'contact' },
  { label: 'Barcodes', value: 'barcode' },
  { label: 'Robot behavior', value: 'robotBehavior' },
  { label: 'Chatbot', value: 'chatbot' },
  { label: 'Storylines', value: 'storyline' },
  { label: 'Yeotheater', value: 'yeotheater' },
  { label: 'News', value: 'news' },
  { label: 'Profile', value: 'profile' },
  { label: 'Robot selection', value: 'robotSelection' },
  { label: 'Application style', value: 'style' },
  { label: 'Virtual waiting room', value: 'waitingroom' },
  { label: 'Galleries', value: 'gallery' },
];