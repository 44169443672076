import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "../../../utils/AxiosService";

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class LinkRobots extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      robotId: "",
      message: null,
    };
  }

  linkRobot = (close) => {
    const { accountId, updateRobotList, fetchRobots } = this.props;
    const { robotId } = this.state;
    const url = `/accounts/${accountId}/robot/${robotId}`;
    axios.put(url).then((res) => {
      if (res.status === 200) {
        toast("Robot linked", { type: "success" });
        updateRobotList();
        fetchRobots();
        if (close !== null) {
          close();
        }
      } else {
        toast("Server error", { type: "error" });
        close();
      }
    });
  };

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { message } = this.state;
    const { changesMade } = this.props;
    return (
      <div className="admin-container">
        <div className="form-container">
          <Form>
            <FormGroup>
              <Label for="robotId">Robot Id</Label>
              <Input
                onChange={(event) =>
                  this.setState(byPropKey("robotId", event.target.value))
                }
                type="text"
                name="robotId"
                id="robotId"
                placeholder="Robot id"
              />
            </FormGroup>
            {changesMade ? (
              <Popup
                trigger={
                  <span>
                    <Button className="btn-primary">Update</Button>
                  </span>
                }
                position="right center"
                modal={true}
              >
                {(close) => (
                  <div style={{ position: "relative" }}>
                    <div className="header" style={{ marginBottom: "20px" }}>
                      <h2>Confirmation</h2>
                    </div>
                    <div className="content">
                      <p>
                        All unsaved modifications to robots will be lost. Do you
                        want to proceed?
                      </p>
                    </div>
                    <br />
                    <br />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "15px",
                        right: "15px",
                      }}
                    >
                      <Button onClick={close}>Cancel</Button>
                      <Button
                        style={{ marginLeft: "10px" }}
                        className="btn-primary"
                        onClick={() => this.linkRobot(close)}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                )}
              </Popup>
            ) : (
              <Button
                className="btn-primary"
                onClick={() => this.linkRobot(null)}
              >
                Update
              </Button>
            )}
          </Form>
          {message !== null ? (
            <Alert color={message.color}>{message.text}</Alert>
          ) : undefined}
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default LinkRobots;
