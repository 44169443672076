import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-dropdown/style.css";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Badge, Button, Input } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import SpeechAction from "../../components/SpeechAction";
import languages from "../../constants/languages";
import axios from "../../utils/AxiosService";
import * as triggers from "./constants/triggers";

class Receptionist extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    const { location } = this.props;
    const { receptionist } = location.state;
    this.selectStoryline = this.selectStoryline.bind(this);
    this.state = {
      lang: "en",
      receptionist,
      changesMade: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { location } = nextProps;
    const { receptionist } = location.state;
    this.setState({
      receptionist,
    });
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  changeText = (text) => {
    const { receptionist, lang } = this.state;
    const tempReceptionist = receptionist;
    tempReceptionist[lang].greeting.text = text;

    this.setState({
      receptionist: tempReceptionist,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { receptionist, lang } = this.state;
    const tempReceptionist = receptionist;
    tempReceptionist[lang].greeting.animation = animation;

    this.setState({
      receptionist: tempReceptionist,
      changesMade: true,
    });
  };

  changeName = (name) => {
    const { receptionist, lang } = this.state;
    const tempReceptionist = receptionist;
    tempReceptionist[lang].name = name;

    this.setState({
      receptionist: tempReceptionist,
      changesMade: true,
    });
  };

  addTrigger = (trigger) => {
    const { receptionist, lang } = this.state;
    const tempReceptionist = receptionist;
    tempReceptionist[lang].triggers.push(trigger.value);

    this.setState({
      receptionist: tempReceptionist,
      changesMade: true,
    });
  };

  removeTrigger = (index) => {
    const { receptionist, lang } = this.state;
    const tempReceptionist = receptionist;
    tempReceptionist[lang].triggers.splice(index, 1);

    this.setState({
      receptionist: tempReceptionist,
      changesMade: true,
    });
  };

  saveReceptionist = () => {
    const { selectedRobot } = this.props;
    const { receptionist } = this.state;
    const url = `/receptionists/${receptionist._id}/robots/${selectedRobot._id}`;
    axios.put(url, { receptionist: receptionist }).then((response) => {
      if (response.status === 200) {
        toast("Style saved", { type: "success" });
        this.setState({
          receptionist: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  selectStoryline = (storyline) => {
    const { receptionist } = this.state;
    const tempReceptionist = receptionist;
    languages.forEach((lang) => {
      tempReceptionist[lang].greeting = storyline;
    });

    this.setState({
      receptionist: tempReceptionist,
      changesMade: true,
    });
  };

  render() {
    const { selectedRobot } = this.props;
    const { lang, receptionist } = this.state;

    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Receptionist style</h1>
                <p className="category">
                  Here you can set the greeting message Pepper will say and
                  decide when with the triggers list.
                </p>
              </div>
              <div style={{ position: "absolute", top: "25px", right: "46px" }}>
                <Link to="/receptionists">
                  <Button
                    style={{ height: "34px", marginRight: "10px" }}
                    className=" btn-primary pe-7s-back"
                  ></Button>
                </Link>
                <Button color="primary" onClick={this.saveReceptionist}>
                  Save changes
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="content">
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={receptionist[lang].greeting}
                  selectStoryline={this.selectStoryline}
                />
                <h4>Receptionist style name</h4>
                <Input
                  type="text"
                  onChange={(event) => this.changeName(event.target.value)}
                  value={receptionist[lang].name}
                  placeholder="Style name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <h4>Greetings triggers</h4>
                <div className="card">
                  <div className="content">
                    {receptionist[lang].triggers.map((trigger, index) => (
                      <Badge
                        style={{
                          height: "25px",
                          fontSize: "14px",
                          paddingTop: "6px",
                          marginRight: "10px",
                        }}
                        color="primary"
                        key={trigger}
                      >
                        {trigger}{" "}
                        <i
                          style={{
                            cursor: "pointer",
                            backgroundColor: "rgba(255, 0, 0, 0.5)",
                          }}
                          onClick={() => this.removeTrigger(index)}
                          className="pe-7s-close"
                        ></i>
                      </Badge>
                    ))}
                  </div>
                </div>
                <Dropdown
                  className="dropdown-speechaction"
                  options={triggers.triggers.filter(
                    (trigger) =>
                      receptionist[lang].triggers.findIndex(
                        (trig) => trig === trigger.value
                      ) === -1
                  )}
                  onChange={this.addTrigger}
                  value={
                    receptionist[lang].triggers !== null
                      ? receptionist[lang].triggers[
                          receptionist[lang].triggers.length
                        ]
                      : null
                  }
                  placeholder="Select a trigger"
                />
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

Receptionist.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default Receptionist;
