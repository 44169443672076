import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input, Label } from "reactstrap";
import SpeechAction from "../../../components/SpeechAction";
import TextPreview from "../../../components/TextPreview";
import languages from "../../../constants/languages";
import axios from "../../../utils/AxiosService";

const answerTypes = [
  { label: "Single choice", value: "single_choice" },
  { label: "Free text", value: "free_text" },
  { label: "Smileys", value: "smileys" },
];

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.changeText = this.changeText.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeAnimation = this.changeAnimation.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    const { survey } = this.props;
    this.state = {
      survey,
      collapse: false,
      changesMade: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  addQuestion = () => {
    const { survey } = this.state;
    const tempSurvey = survey;
    languages.forEach((lang) =>
      tempSurvey[lang].questions.push({
        questionWritten: "",
        questionOral: "",
        answersWritten: [],
        answersOral: [],
      })
    );
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeText = (text) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].speechAction.text = text;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].speechAction.animation = animation;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  deleteSubject = (questionInd) => {
    const { survey } = this.state;
    const tempSurvey = survey;
    languages.forEach((lang) => {
      tempSurvey[lang].questions.splice(questionInd, 1);
    });
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  saveSurvey = () => {
    const { selectedRobot } = this.props;
    const { survey } = this.state;
    const url = `/surveys/${survey._id}/robots/${selectedRobot._id}`;
    axios.put(url, { survey: survey }).then((response) => {
      if (response.status === 200) {
        toast("Survey saved", { type: "success" });
        this.setState({
          survey: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  changeResult = (value) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].resultWritten = value;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeResultSpeech = (value) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].resultOral = value;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeName = (value) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].name = value;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  addAnswer = (index) => {
    const { survey } = this.state;
    const tempSurvey = survey;
    languages.forEach((lang) => {
      tempSurvey[lang].questions[index].answersWritten.push("");
      tempSurvey[lang].questions[index].answersOral.push("");
    });
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  deleteAnswer = (questIndex, answIndex) => {
    const { survey } = this.state;
    const tempSurvey = survey;
    languages.forEach((lang) => {
      tempSurvey[lang].questions[questIndex].answersWritten.splice(
        answIndex,
        1
      );
      tempSurvey[lang].questions[questIndex].answersOral.splice(answIndex, 1);
    });
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeAnswerWritten = (value, questIndex, answIndex) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].questions[questIndex].answersWritten[answIndex] = value;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeAnswerOral = (value, questIndex, answIndex) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].questions[questIndex].answersOral[answIndex] = value;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeWrittenQuestion = (question, subjectInd) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].questions[subjectInd].questionWritten = question;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  changeOralQuestion = (question, subjectInd) => {
    const { lang } = this.props;
    const { survey } = this.state;
    const tempSurvey = survey;
    tempSurvey[lang].questions[subjectInd].questionOral = question;
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  selectType = (subjectInd, type) => {
    const { survey } = this.state;
    const tempSurvey = survey;
    languages.forEach((lang) => {
      tempSurvey[lang].questions[subjectInd].answerType = type.value;
    });
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  selectStoryline = (storyline) => {
    const { survey } = this.state;
    const tempSurvey = survey;
    languages.forEach((lang) => {
      tempSurvey[lang].speechAction = storyline;
    });
    this.setState({
      survey: tempSurvey,
      changesMade: true,
    });
  };

  render() {
    const { lang, selectedRobot } = this.props;
    const { survey } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="header">
                <h1 className="title">Survey</h1>
                <p className="category">Here you can create a survey</p>
              </div>
              <div className="content">
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/surveys">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveSurvey}>
                    Save changes
                  </Button>
                </div>
                <br />
                {survey === null ? undefined : (
                  <SpeechAction
                    selectedRobot={selectedRobot}
                    storyline={survey[lang].speechAction}
                    selectStoryline={this.selectStoryline}
                  />
                )}
                <h4 style={{ textAlign: "left" }}>Survey name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Survey name"
                  value={survey[lang].name}
                />
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="header">
                <h1 className="title">Survey result speech</h1>
                {survey === null ? undefined : (
                  <div>
                    <h4 style={{ textAlign: "left" }}>Written result</h4>
                    <Input
                      onChange={(event) =>
                        this.changeResult(event.target.value)
                      }
                      type="text"
                      placeholder="Written result"
                      value={survey[lang].resultWritten}
                    />

                    <h4 style={{ textAlign: "left" }}>Oral result</h4>
                    <div className="oral-input">
                      <Input
                        onChange={(event) =>
                          this.changeResultSpeech(event.target.value)
                        }
                        type="text"
                        placeholder="Oral result"
                        value={survey[lang].resultOral}
                      />
                      <TextPreview
                        text={survey[lang].resultOral}
                        robotId={selectedRobot._id}
                      />
                    </div>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {survey === null ? undefined : survey[lang].questions.length ===
              0 ? (
              <div className="card">
                <div className="content">
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={this.addQuestion}
                    >
                      Add question
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              survey[lang].questions.map((subject, index) => (
                <React.Fragment key={subject._id}>
                  <div className="card">
                    <div
                      className="content table-responsive table-full-width"
                      style={{ marginBottom: "60px" }}
                    >
                      <div style={{ position: "relative" }} key={subject._id}>
                        <h4>Question {index + 1}</h4>
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                          }}
                        >
                          <Button
                            style={{ float: "right", marginBottom: "10px" }}
                            onClick={() => this.deleteSubject(index)}
                            className="btn btn-danger btn-simple btn-icon "
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </div>
                        <Label for="question">Written question</Label>
                        <Input
                          onChange={(event) =>
                            this.changeWrittenQuestion(
                              event.target.value,
                              index
                            )
                          }
                          type="text"
                          name="writtenq"
                          id="writtenq"
                          placeholder="Written question"
                          value={subject.questionWritten}
                        />
                        <br />
                        <Label for="question">Oral question</Label>
                        <div className="oral-input">
                          <Input
                            onChange={(event) =>
                              this.changeOralQuestion(event.target.value, index)
                            }
                            type="text"
                            name="oralq"
                            id="oralq"
                            placeholder="Oral question"
                            value={subject.questionOral}
                          />
                          <TextPreview
                            text={subject.questionOral}
                            robotId={selectedRobot._id}
                          />
                        </div>
                        <Label
                          style={{ marginBottom: "20px", marginTop: "10px" }}
                          for="answer"
                        >
                          Answers
                        </Label>
                        <br />
                        <h6>Answer type</h6>
                        <Dropdown
                          className="dropdown-speechaction"
                          options={answerTypes}
                          onChange={(event) => this.selectType(index, event)}
                          value={answerTypes.find(
                            (answerType) =>
                              answerType.value === subject.answerType
                          )}
                          placeholder="Select a type"
                        />
                        <br />
                        {subject.answerType !== "single_choice" ? undefined : (
                          <div>
                            <table className="table table-bigboy">
                              <thead>
                                <tr>
                                  <th>Written answer</th>
                                  <th>Oral answer</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {subject.answersWritten === null
                                  ? undefined
                                  : subject.answersWritten.map(
                                      (answer, answIndex) => (
                                        <tr key={answIndex}>
                                          <td className="td-name">
                                            <Input
                                              onChange={(event) =>
                                                this.changeAnswerWritten(
                                                  event.target.value,
                                                  index,
                                                  answIndex
                                                )
                                              }
                                              type="text"
                                              placeholder="Written answer"
                                              value={answer}
                                            />
                                          </td>
                                          <td>
                                            <div className="oral-input">
                                              <Input
                                                onChange={(event) =>
                                                  this.changeAnswerOral(
                                                    event.target.value,
                                                    index,
                                                    answIndex
                                                  )
                                                }
                                                type="text"
                                                placeholder="Oral answer"
                                                value={
                                                  subject.answersOral[answIndex]
                                                }
                                              />
                                              <TextPreview
                                                text={
                                                  subject.answersOral[answIndex]
                                                }
                                                robotId={selectedRobot._id}
                                              />
                                            </div>
                                          </td>
                                          <td className="td-actions">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                this.deleteAnswer(
                                                  index,
                                                  answIndex
                                                )
                                              }
                                              rel="tooltip"
                                              data-placement="left"
                                              title=""
                                              className="btn btn-danger btn-simple btn-icon "
                                              data-original-title="Remove answer"
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                              </tbody>
                            </table>
                            <Button
                              color="primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => this.addAnswer(index)}
                            >
                              Add answer
                            </Button>
                            <br />
                          </div>
                        )}
                      </div>
                      {index === survey[lang].questions.length - 1 ? (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "45px",
                            right: "35px",
                          }}
                        >
                          <Button
                            color="primary"
                            style={{ marginRight: "10px" }}
                            onClick={this.addQuestion}
                          >
                            Add question
                          </Button>
                        </div>
                      ) : undefined}
                    </div>
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
          <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        </div>
      </React.Fragment>
    );
  }
}

export default QuestionList;
