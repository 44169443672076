import React, { Component } from 'react';
import { hexToStyle } from '../utils/colorConverter';

class GlobalPreview extends Component {
  constructor(props) {
    super(props);
    const { style } = this.props;
    this.state = {
      style,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.style !== nextProps.style) {
      this.setState({ style: nextProps.style });
    }
  }

  render() {
    const { style } = this.state;
    const { mini } = this.props;

    const buttonStyle = {
      width: mini ? '35px' : '60px',
      height: mini ? '35px' : '60px',
      fontFamily: style['font-family'],
      backgroundColor: hexToStyle(style.menu['android_widget_Button'].mBackgroundTint),
      color: style.menu['android_widget_Button'].mTextColor,
      fontSize: style.menu['android_widget_Button'].mTextSize*(mini ? 0.5 : 1),
      display: 'inline-block',
    }

    return (
      <div>
        {mini ? undefined : <h3 className="title">Preview</h3>}
        <div style={{
          height: mini ? '120px' : '200px',
          width: mini ? '220px' : '300px',
          backgroundImage: `url("${style['background-image']}")`,
          backgroundSize: 'cover',
          textAlign: 'center',
        }}>
          <div style={{ marginLeft: 'auto', marginRight: 'auto', color: style['font-color'], display: 'inline-block', }}>{style.name}</div>
          <div style={{ display: 'flex', justifyContent: 'space-around', textAlign: 'center' }}>
            <div style={buttonStyle}>
              text
            </div>
            <div style={buttonStyle}>
              text
            </div>
            <div style={buttonStyle}>
              text
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px', textAlign: 'center' }}>
            <div style={buttonStyle}>
              text
            </div>
            <div style={buttonStyle}>
              text
            </div>
            <div style={buttonStyle}>
              text
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalPreview;


