import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import SpeechAction from "../../../components/SpeechAction";
import languages from "../../../constants/languages";
import axios from "../../../utils/AxiosService";

class ServiceList extends React.Component {
  constructor(props) {
    super(props);
    this.changeText = this.changeText.bind(this);
    this.changeAnimation = this.changeAnimation.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    this.state = {
      servicecontact: null,
      contacts: [],
      filteredContacts: [],
      changesMade: false,
    };
  }

  componentDidMount() {
    this.fetchServices();
    this.fetchContacts();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchServices = () => {
    const url = `/servicecontacts`;
    axios.get(url).then((response) => {
      this.setState({
        servicecontact: response.data[0].configuration,
      });
    });
  };

  fetchContacts = () => {
    const url = `/contacts`;
    axios.get(url).then((result) => {
      this.setState({
        contacts: result.data.contacts,
        filteredContacts: result.data.contacts,
        loaded: true,
      });
    });
  };

  saveServiceContact = () => {
    const { servicecontact } = this.state;
    const { selectedRobot } = this.props;
    const url = `/servicecontacts/${servicecontact._id}/robots/${selectedRobot._id}`;
    this.cleanServiceContact();
    axios.post(url, { servicecontact: servicecontact }).then((response) => {
      toast("Service contacts saved", { type: "success" });
      this.setState({
        servicecontact: response.data,
        changesMade: false,
      });
    });
  };

  cleanServiceContact = () => {
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    const languages = ["en", "de", "fr", "it"];
    const toRemove = [];
    languages.forEach((lang) => {
      tempServiceContact[lang].services.forEach((service, index) => {
        if (service.name === "" || service.contact === "") {
          toRemove.push(index);
        }
      });
      for (let i = toRemove.length - 1; i >= 0; i--) {
        tempServiceContact[lang].services.splice(toRemove[i], 1);
      }
    });

    this.setState({
      servicecontact: tempServiceContact,
    });
  };

  changeText = (text) => {
    const { lang } = this.props;
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    tempServiceContact[lang].speechAction.text = text;
    this.setState({
      servicecontact: tempServiceContact,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { lang } = this.props;
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    tempServiceContact[lang].speechAction.animation = animation;
    this.setState({
      servicecontact: tempServiceContact,
      changesMade: true,
    });
  };

  changeServiceName = (name, serviceInd) => {
    const { lang } = this.props;
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    tempServiceContact[lang].services[serviceInd].name = name;
    this.setState({
      servicecontact: tempServiceContact,
      changesMade: true,
    });
  };

  selectServiceContact = (contact, serviceInd) => {
    const { lang } = this.props;
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    tempServiceContact[lang].services[serviceInd].contact = contact.value;

    this.setState({
      servicecontact: tempServiceContact,
      changesMade: true,
    });
  };

  addService = () => {
    const { lang } = this.props;
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    tempServiceContact[lang].services.push({
      name: "",
      contact: null,
    });

    this.setState({
      servicecontact: tempServiceContact,
      changesMade: true,
    });
  };

  deleteService = (index) => {
    const { lang } = this.props;
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    tempServiceContact[lang].services.splice(index, 1);

    this.setState({
      servicecontact: tempServiceContact,
      changesMade: true,
    });
  };

  selectStoryline = (storyline) => {
    const { servicecontact } = this.state;
    const tempServiceContact = servicecontact;
    languages.forEach((lang) => {
      tempServiceContact[lang].speechAction = storyline;
    });
    this.setState({
      servicecontact: tempServiceContact,
      changesMade: true,
    });
  };

  render() {
    const { lang, selectedRobot } = this.props;
    const { servicecontact, contacts } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Service contact</h1>
                <p className="category">
                  Here you add services that can be contacted by clients
                </p>
              </div>
              <div className="content">
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.saveServiceContact()}
                  >
                    Save changes
                  </Button>
                </div>
                <br />
                {servicecontact === null ||
                servicecontact === undefined ? undefined : (
                  <SpeechAction
                    selectedRobot={selectedRobot}
                    storyline={servicecontact[lang].speechAction}
                    selectStoryline={this.selectStoryline}
                  />
                )}
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Name</th>
                      <th className="th-description">Contact</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {servicecontact === null || servicecontact === undefined
                      ? undefined
                      : servicecontact[lang].services.map((service, index) => (
                          <tr key={index}>
                            <td>
                              <Input
                                style={{ height: "38px" }}
                                onChange={(event) =>
                                  this.changeServiceName(
                                    event.target.value,
                                    index
                                  )
                                }
                                value={
                                  servicecontact[lang].services[index].name
                                }
                                placeholder="Service name"
                              />
                            </td>
                            <td>
                              <Dropdown
                                isSearchable
                                className="dropdown-speechaction"
                                options={contacts.map((cont) => ({
                                  value: cont,
                                  label: `${cont.name} ${cont.surname}`,
                                }))}
                                onChange={(contact) =>
                                  this.selectServiceContact(contact, index)
                                }
                                value={
                                  service.contact === null ||
                                  service.contact === undefined
                                    ? null
                                    : {
                                        value: service.contact,
                                        label: `${service.contact.name} ${service.contact.surname}`,
                                      }
                                }
                                placeholder="Select a contact"
                              />
                            </td>
                            <td className="td-actions">
                              <button
                                type="button"
                                onClick={() => this.deleteService(index)}
                                rel="tooltip"
                                data-placement="left"
                                title=""
                                className="btn btn-danger btn-simple btn-icon "
                                data-original-title="Remove service"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={this.addService}
                >
                  Add service
                </Button>
              </div>
            </div>
          </div>
          <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceList;
