import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import axios from "../../utils/AxiosService";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      loaded: false,
      changesMade: false,
    };
  }

  componentDidMount() {
    this.fetchContacts();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchContacts = () => {
    const url = `/contacts`;
    axios.get(url).then((result) => {
      this.setState({
        contacts: result.data,
        loaded: true,
      });
    });
  };

  addContact = () => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts.push({
      name: "",
      surname: "",
      mail: "",
      jobTitle: "",
      company: "",
      phone: "",
    });

    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  changeName = (name, contactInd) => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts[contactInd].name = name;
    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  changeSurname = (surname, contactInd) => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts[contactInd].surname = surname;
    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  changeMail = (mail, contactInd) => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts[contactInd].mail = mail;
    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  changeJobTitle = (jobTitle, contactInd) => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts[contactInd].jobTitle = jobTitle;
    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  changeCompany = (company, contactInd) => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts[contactInd].company = company;
    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  changePhone = (phone, contactInd) => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts[contactInd].phone = phone;
    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  deleteContact = (contactInd) => {
    const { contacts } = this.state;
    const tempContacts = contacts;
    tempContacts.contacts.splice(contactInd, 1);
    this.setState({
      contacts: tempContacts,
      changesMade: true,
    });
  };

  saveContacts = () => {
    const { selectedRobot } = this.props;
    const { contacts } = this.state;
    const url = `/contacts/${contacts._id}/robots/${selectedRobot._id}`;
    axios.put(url, { contacts: contacts }).then((response) => {
      if (response.status === 200) {
        toast("Contacts saved", { type: "success" });
        this.setState({
          contacts: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  render() {
    const { contacts, loaded } = this.state;
    if (!loaded) {
      return <div></div>;
    }
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Contacts</h1>
                <p className="category">
                  Here you can set up a list of contacts to be used in other
                  modules
                </p>
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button color="primary" onClick={() => this.saveContacts()}>
                    Save changes
                  </Button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Name</th>
                      <th className="th-description">Surname</th>
                      <th className="th-description">Mail</th>
                      <th className="th-description">Job title</th>
                      <th className="th-description">Company</th>
                      <th className="th-description">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts === null
                      ? undefined
                      : contacts.contacts.map((contact, index) => (
                          <tr key={index}>
                            <td className="td-name">
                              <Input
                                onChange={(event) =>
                                  this.changeName(event.target.value, index)
                                }
                                type="text"
                                name="name"
                                placeholder="Contact name"
                                value={contact.name}
                              />
                            </td>
                            <td className="td-name">
                              <Input
                                onChange={(event) =>
                                  this.changeSurname(event.target.value, index)
                                }
                                type="text"
                                name="surname"
                                placeholder="Contact surname"
                                value={contact.surname}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(event) =>
                                  this.changeMail(event.target.value, index)
                                }
                                type="text"
                                name="mail"
                                placeholder="Contact mail"
                                value={contact.mail}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(event) =>
                                  this.changeJobTitle(event.target.value, index)
                                }
                                type="text"
                                name="jobTitle"
                                placeholder="Contact job title"
                                value={contact.jobTitle}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(event) =>
                                  this.changeCompany(event.target.value, index)
                                }
                                type="text"
                                name="company"
                                placeholder="Contact company"
                                value={contact.company}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(event) =>
                                  this.changePhone(event.target.value, index)
                                }
                                type="text"
                                name="phone"
                                placeholder="Contact phone number"
                                value={contact.phone}
                              />
                            </td>
                            <td className="td-actions">
                              <button
                                type="button"
                                onClick={() => this.deleteContact(index)}
                                rel="tooltip"
                                data-placement="left"
                                title=""
                                className="btn btn-danger btn-simple btn-icon "
                                data-original-title="Remove contact"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={this.addContact}
                >
                  Add contact
                </Button>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Contact;
