import flow from "lodash/flow";
import React from "react";
import { DragSource, DropTarget } from "react-dnd";
import ReactDOM from "react-dom";
import getVideoType from "../utils/utils";

class SlideView extends React.Component {
  constructor(props) {
    super(props);
    const { url, fileType } = this.props;
    this.state = {
      previewImage: null,
      slide: { url, fileType },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { url, fileType } = nextProps;
    if (this.props.url !== url) {
      this.setState({
        slide: { url, fileType },
      });
    }
  }

  render() {
    const { isDragging, connectDragSource, connectDropTarget } = this.props;
    const { slide } = this.state;
    const opacity = isDragging ? 0 : 1;
    return connectDragSource(
      connectDropTarget(
        <div className="miniature-container" style={{ opacity }}>
          {slide.fileType === "video" ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video className="miniature-video-player">
              <source src={`${slide.url}`} type={getVideoType(slide.url)} />
            </video>
          ) : (
            <img className="miniature-image" src={`${slide.url}`} alt="slide" />
          )}
        </div>
      )
    );
  }
}

const slideSource = {
  beginDrag(props) {
    return {
      index: props.index,
      slide: props.slide,
    };
  },
};

const slideTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const { lang } = props;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect =
      ReactDOM.findDOMNode(component).getBoundingClientRect(); // eslint-disable-line react/no-find-dom-node

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveSlide(dragIndex, hoverIndex, lang);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex; // eslint-disable-line no-param-reassign
  },
};

export default flow(
  DropTarget("SLIDE", slideTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })),
  DragSource("SLIDE", slideSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))
)(SlideView);
