import React, { Component } from "react";
import update from "react-addons-update";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import uuidv4 from "uuid/v4";
import LanguagePicker from "../../components/LanguagePicker";
import axios from "../../utils/AxiosService";
import BreadCrumbs from "./components/BreadCrumbs";
import LevelView from "./components/LevelView";
import MenuLayout from "./components/MenuLayout";

class MenuStructure extends Component {
  constructor(props) {
    super(props);
    this.addLevelToPath = this.addLevelToPath.bind(this);
    this.setPathLevel = this.setPathLevel.bind(this);
    this.setLang = this.setLang.bind(this);
    this.createLevel = this.createLevel.bind(this);
    this.selectModule = this.selectModule.bind(this);
    this.updateStoryline = this.updateStoryline.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    this.updateModule = this.updateModule.bind(this);
    this.deleteCell = this.deleteCell.bind(this);
    this.saveStructure = this.saveStructure.bind(this);
    this.changeMenuStyle = this.changeMenuStyle.bind(this);
    this.state = {
      menuStructure: this.props.location.state.menuStructure,
      currentPath: [this.props.location.state.menuStructure],
      lang: "en",
      changesMade: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  addLevelToPath = (level) => {
    this.setState({
      currentPath: [...this.state.currentPath, level],
      changesMade: true,
    });
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  setPathLevel = (index) => {
    this.setState((prevState) => ({
      currentPath: prevState.currentPath.slice(0, index + 1),
    }));
  };

  createLevel = (i, j, submenu) => {
    const { menuStructure, currentPath } = this.state;
    currentPath[currentPath.length - 1].items.push({
      itemType: "submenu",
      submenu: {
        _id: uuidv4(),
        name: this.chooseSubmenuName(submenu),
        items: [],
      },
      positionX: j,
      positionY: i,
      rowWeight: 1,
      columnWeight: 1,
      backgroundColor: "#00FFFFFF",
      textBackgroundColor: "#00FFFFFF",
      textBackgroundWidth: 0,
      textBackgroundHeight: 0,
      borderColor: "#00FFFFFF",
      isWrapBefore: false,
    });
    this.setState({
      currentPath,
      menuStructure,
      changesMade: true,
    });
  };

  chooseSubmenuName = (submenu) => {
    const languages = ["it", "fr", "en", "de"];
    const missingNames = [];
    let name = {};
    let lastName = "";
    languages.forEach((lang) => {
      if (submenu[lang] !== "") {
        lastName = submenu[lang];
        name[lang] = lastName;
      } else {
        missingNames.push(lang);
      }
    });
    missingNames.forEach((lang) => {
      name[lang] = lastName;
    });
    return name;
  };

  chooseModuleName = (selectedModule) => {
    const languages = ["it", "fr", "en", "de"];
    const missingNames = [];
    let name = {};
    let lastName = "";
    languages.forEach((lang) => {
      if (
        selectedModule.module.hasOwnProperty(lang) &&
        selectedModule.module[lang].name !== ""
      ) {
        lastName = selectedModule.module[lang].name;
        name[lang] = lastName;
      } else if (
        selectedModule.module.hasOwnProperty("name") &&
        selectedModule.module.name !== ""
      ) {
        lastName = selectedModule.module.name;
        name[lang] = lastName;
      } else {
        missingNames.push(lang);
      }
    });
    missingNames.forEach((lang) => {
      name[lang] = lastName.length <= 0 ? selectedModule.type.label : lastName;
    });
    return name;
  };

  selectModule = (i, j, selectedModule) => {
    const { menuStructure, currentPath } = this.state;
    const tempPath = currentPath;
    tempPath[currentPath.length - 1].items.push({
      itemType: "module",
      module: {
        name: this.chooseModuleName(selectedModule),
        moduleType: selectedModule.type.value,
        moduleId: selectedModule.module._id,
      },
      positionX: j,
      positionY: i,
      rowWeight: 1,
      columnWeight: 1,
      backgroundColor: "#00FFFFFF",
      textBackgroundColor: "#00FFFFFF",
      textBackgroundWidth: 0,
      textBackgroundHeight: 0,
      borderColor: "#00FFFFFF",
      isWrapBefore: false,
    });
    this.setState({
      currentPath: tempPath,
      menuStructure,
      changesMade: true,
    });
  };

  selectStoryline = (i, j, selectedStoryline) => {
    const { menuStructure, currentPath } = this.state;
    const tempPath = currentPath;
    tempPath[currentPath.length - 1].items.push({
      itemType: "storyline",
      storyline: selectedStoryline,
      positionX: j,
      positionY: i,
      rowWeight: 1,
      columnWeight: 1,
      backgroundColor: "#00FFFFFF",
      textBackgroundColor: "#00FFFFFF",
      textBackgroundWidth: 0,
      textBackgroundHeight: 0,
      borderColor: "#00FFFFFF",
      isWrapBefore: false,
    });
    this.setState({
      currentPath: tempPath,
      menuStructure,
      changesMade: true,
    });
  };

  deleteCell = (i, j) => {
    const { menuStructure, currentPath } = this.state;
    const tempPath = currentPath;
    const itemIndex = tempPath[currentPath.length - 1].items.findIndex(
      (item) => item.positionX === j && item.positionY === i
    );
    tempPath[currentPath.length - 1].items.splice(itemIndex, 1);
    this.setState({
      currentPath: tempPath,
      menuStructure,
      changesMade: true,
    });
  };

  updateModule = (i, j, selectedModule) => {
    const { menuStructure, currentPath } = this.state;
    const tempPath = currentPath;
    tempPath[currentPath.length - 1].items = tempPath[
      currentPath.length - 1
    ].items.map((item) =>
      item.positionX === j && item.positionY === i
        ? {
            itemType: "module",
            module: {
              name: this.chooseModuleName(selectedModule),
              moduleType: selectedModule.type.value,
              moduleId: selectedModule.module._id,
            },
            positionX: j,
            positionY: i,
          }
        : item
    );
    this.setState({
      currentPath: tempPath,
      menuStructure,
      changesMade: true,
    });
  };

  updateStoryline = (i, j, selectedStoryline) => {
    const { menuStructure, currentPath } = this.state;
    const tempPath = currentPath;
    tempPath[currentPath.length - 1].items = tempPath[
      currentPath.length - 1
    ].items.map((item) =>
      item.positionX === j && item.positionY === i
        ? {
            itemType: "storyline",
            storyline: selectedStoryline,
            positionX: j,
            positionY: i,
          }
        : item
    );
    this.setState({
      currentPath: tempPath,
      menuStructure,
      changesMade: true,
    });
  };

  saveStructure = () => {
    const { menuStructure } = this.state;
    const { selectedRobot } = this.props;
    //FP added to sort the the items by position... to discuss with SM
    menuStructure.items.sort(
      (a, b) => a.positionX + a.positionY * 3 - (b.positionX + b.positionY * 3)
    );

    const url = `/menuStructures/${menuStructure._id}/robots/${selectedRobot._id}`;
    axios.post(url, { menuStructure: menuStructure }).then((response) => {
      if (response.status === 200) {
        toast("Structure saved", { type: "success" });
        this.setState({
          menuStructure: response.data,
          currentPath: [response.data],
          changesMade: false,
        });
      } else {
        toast("Server error", { type: "error" });
      }
    });
  };

  /*changeMenuStyle = (field, value) => {
    const { menuStructure } = this.state
    const tmpMenuStructure = menuStructure
    tmpMenuStructure[field] = value
    this.setState({
      menuStructure: tmpMenuStructure
    })
  }*/

  changeMenuStyle = (field, value) => {
    const { menuStructure, currentPath } = this.state;
    const tempPath = currentPath;
    console.log(currentPath);
    tempPath[currentPath.length - 1][field] = value;
    this.setState({
      currentPath: tempPath,
      menuStructure,
      changesMade: true,
    });
  };

  render() {
    const { menuStructure, currentPath, lang } = this.state;
    const { selectedRobot } = this.props;
    console.log(menuStructure);

    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Menu structure</h1>
                <p className="category">
                  Here you can configure the buttons displayed on the tablet
                </p>
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button color="primary" onClick={() => this.saveStructure()}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <div style={{ width: "100%" }}>
                  <h4>Menu Structure Name</h4>
                  <Input
                    className="menuStructureName"
                    type="text"
                    onChange={(event) =>
                      this.setState({
                        menuStructure: update(this.state.menuStructure, {
                          name: { [lang]: { $set: event.target.value } },
                        }),
                        changesMade: update(this.state.changesMade, {
                          $set: true,
                        }),
                      })
                    }
                    placeholder="Name"
                    value={menuStructure.name[lang]}
                  />
                </div>
                <h4>Submenu navigation</h4>
              </div>
              <div className="content">
                {menuStructure === null ? undefined : (
                  <div style={{ display: "flex", flexFlow: "row" }}>
                    <BreadCrumbs
                      lang={lang}
                      menuStructure={menuStructure}
                      currentPath={currentPath}
                      setPathLevel={this.setPathLevel}
                    />
                    <Popup
                      trigger={
                        <Button
                          style={{
                            height: "35px",
                            marginTop: "27px",
                            marginLeft: "10px",
                          }}
                          className="btn-success"
                        >
                          <i className="pe-7s-paint-bucket" />
                        </Button>
                      }
                      position="right center"
                      modal={true}
                    >
                      {(close) => (
                        <MenuLayout
                          close={close}
                          cell={menuStructure}
                          currentPath={currentPath}
                          changeMenuStyle={this.changeMenuStyle}
                          saveStructure={this.saveStructure}
                        />
                      )}
                    </Popup>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content" style={{ paddingTop: "20px" }}>
                {menuStructure === null ? undefined : (
                  <LevelView
                    lang={lang}
                    addLevelToPath={this.addLevelToPath}
                    selectModule={this.selectModule}
                    updateModule={this.updateModule}
                    createLevel={this.createLevel}
                    deleteCell={this.deleteCell}
                    menuStructure={menuStructure}
                    currentPath={currentPath}
                    selectedRobot={selectedRobot}
                    selectStoryline={this.selectStoryline}
                    updateStoryline={this.updateStoryline}
                    saveStructure={this.saveStructure}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default MenuStructure;
