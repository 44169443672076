import React, { Component } from 'react';
import MemberList from './components/MemberList';
import LanguagePicker from '../../components/LanguagePicker';

class Team extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.state = {
      team: this.props.location.state.team,
      error: null,
      message: null,
      lang: 'en',
    };
  }

  setLang = (lang) => {
    this.setState({
      lang,
    })
  }

  render() {
    const { selectedRobot } = this.props;
    const { lang, team } = this.state;

    return (
      <div style={{ margin: '20px' }}>
        <LanguagePicker setLang={this.setLang} />
        { team === null ? undefined : <MemberList lang={lang} team={team} selectedRobot={selectedRobot} /> }
      </div>
    );
  }
}

export default Team;
