import React, { Component } from "react";
import update from "react-addons-update";
import { ChromePicker } from "react-color";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input, Label } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import axios from "../../utils/AxiosService";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.state = {
      gallery: this.props.location.state.gallery,
      error: null,
      message: null,
      lang: "en",
      changesMade: false,
      colorVisible: false,
      fileList: [],
    };
  }
  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  saveGallery = () => {
    const { selectedRobot } = this.props;
    const { gallery } = this.state;
    const url = `/galleries/${gallery._id}/robots/${selectedRobot._id}`;
    axios.put(url, { gallery: gallery }).then((response) => {
      if (response.status === 200) {
        toast("Gallery saved", { type: "success" });
        this.setState({
          gallery: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  toggleColorVisible = () => {
    this.setState((prevState) => ({
      colorVisible: !prevState.ColorVisible,
    }));
  };

  loadImages = (files) => {
    this.setState({
      fileList: Array.from(files),
    });
  };

  render() {
    const { gallery, lang, fileList } = this.state;
    console.log(fileList);
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Gallery</h1>
                <p className="category">Here you can create a new gallery</p>
              </div>
              <div
                className="content"
                style={{ display: "flex", flexWrap: "nowrap" }}
              >
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/galleries">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveGallery}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Settings</h2>
              </div>
              <div className="content">
                <div style={{ width: "100%" }}>
                  <h4>Gallery Name</h4>
                  <Input
                    className="galleryNames"
                    type="text"
                    onChange={(event) =>
                      this.setState({
                        gallery: update(this.state.gallery, {
                          name: { [lang]: { $set: event.target.value } },
                        }),
                        changesMade: update(this.state.changesMade, {
                          $set: true,
                        }),
                      })
                    }
                    placeholder="Name"
                    value={gallery.name[lang]}
                  />
                </div>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <div style={{ width: "25%" }}>
                    <h4>Background Color</h4>
                    <ChromePicker
                      disableAlpha={true}
                      color={this.state.gallery.backgroundColor}
                      onChange={(color) =>
                        this.setState({
                          gallery: update(this.state.gallery, {
                            backgroundColor: { $set: color.hex },
                          }),
                          changesMade: update(this.state.changesMade, {
                            $set: true,
                          }),
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "25%" }}>
                    <h4>Grid</h4>
                    <Label>Rows</Label>
                    <Input
                      className="galleryRow"
                      type="number"
                      min="1"
                      style={{ width: "200px" }}
                      value={this.state.gallery.grid.rows}
                      onChange={(event) =>
                        this.setState({
                          gallery: update(this.state.gallery, {
                            grid: { rows: { $set: event.target.value } },
                          }),
                          changesMade: update(this.state.changesMade, {
                            $set: true,
                          }),
                        })
                      }
                    />
                    <Label>Columns</Label>
                    <Input
                      className="galleryColumn"
                      type="number"
                      min="1"
                      style={{ width: "200px" }}
                      value={this.state.gallery.grid.columns}
                      onChange={(event) =>
                        this.setState({
                          gallery: update(this.state.gallery, {
                            grid: { columns: { $set: event.target.value } },
                          }),
                          changesMade: update(this.state.changesMade, {
                            $set: true,
                          }),
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "25%" }}>
                    <h4>Scroll Direction</h4>
                    <Input
                      type="select"
                      className="scrollDirection"
                      style={{ width: "200px" }}
                    >
                      <option>Right</option>
                      <option>Down</option>
                    </Input>
                  </div>
                  <div style={{ width: "25%" }}>
                    <h4>
                      Auto Scroll ?&nbsp;
                      <Input
                        className="galleryAutoScroll"
                        type="checkbox"
                        defaultChecked={this.state.gallery.autoScroll.enabled}
                        onChange={() =>
                          this.setState({
                            gallery: update(this.state.gallery, {
                              autoScroll: {
                                enabled: {
                                  $set: !this.state.gallery.autoScroll.enabled,
                                },
                              },
                            }),
                            changesMade: update(this.state.changesMade, {
                              $set: true,
                            }),
                          })
                        }
                      />
                    </h4>
                    {this.state.gallery.autoScroll.enabled && (
                      <div>
                        <Label>Scroll Delay</Label>
                        <Input
                          label="Delay"
                          className="autoScrollDelay"
                          type="text"
                          style={{ width: "200px" }}
                          value={this.state.gallery.autoScroll.delay}
                          onChange={(event) =>
                            this.setState({
                              gallery: update(this.state.gallery, {
                                autoScroll: {
                                  delay: { $set: event.target.value },
                                },
                              }),
                              changesMade: update(this.state.changesMade, {
                                $set: true,
                              }),
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2 className="title">Pictures</h2>
              </div>
              <div className="content">
                <h4>Upload</h4>
                <Input
                  type="file"
                  multiple
                  onChange={(event) => this.loadImages(event.target.files)}
                />
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    flexWrap: "wrap",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    border: "#000000 1px solid",
                    padding: "20px",
                    width: "100%",
                    height: "530px",
                    marginTop: "20px",
                  }}
                >
                  {fileList.map((file) => (
                    <div style={{ padding: "20px" }}>
                      <img
                        alt=""
                        width="200px"
                        height="200px"
                        src={URL.createObjectURL(file)}
                      />
                      <h5>Image description:</h5>
                      <textarea
                        placeholder="Type your description here..."
                        style={{
                          type: "textarea",
                          width: "200px",
                          height: "200px",
                        }}
                      ></textarea>
                    </div>
                  ))}
                </div>
                <h4>
                  Text autoplay ?&nbsp;
                  <Input
                    className="galleryAutoPlay"
                    type="checkbox"
                    defaultChecked={this.state.gallery.autoPlay}
                    onChange={() =>
                      this.setState({
                        gallery: update(this.state.gallery, {
                          autoPlay: { $set: !this.state.gallery.autoPlay },
                        }),
                        changesMade: update(this.state.changesMade, {
                          $set: true,
                        }),
                      })
                    }
                  />
                </h4>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Gallery;
