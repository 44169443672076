import PropTypes from "prop-types";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, FormGroup, Input, Label } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import SpeechAction from "../../components/SpeechAction";
import languages from "../../constants/languages";
import axios from "../../utils/AxiosService";

class Entertainment extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.toggleEntertainment = this.toggleEntertainment.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    this.state = {
      entertainments: null,
      lang: "en",
      changesMade: false,
    };
  }

  componentDidMount() {
    this.fetchEntertainments();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchEntertainments = () => {
    const url = `/entertainments`;
    axios
      .get(url)
      .then((result) => {
        this.setState({
          entertainments: result.data[0].configuration,
        });
      })
      .catch((err) => {
        toast("Server error", { type: "error" });
      });
  };

  saveEntertainments = () => {
    const { entertainments } = this.state;
    const { selectedRobot } = this.props;
    const url = `/entertainments/${entertainments._id}/robots/${selectedRobot._id}`;
    axios.put(url, { entertainment: entertainments }).then((response) => {
      if (response.status === 200) {
        toast("Saved", { type: "success" });
        this.setState({
          entertainments: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  selectStoryline = (storyline) => {
    const { entertainments } = this.state;
    const tmpEntertainments = entertainments;
    languages.forEach((lang) => {
      tmpEntertainments[lang].speechAction = storyline;
    });
    this.setState({
      entertainments: tmpEntertainments,
      changesMade: true,
    });
  };

  toggleEntertainment = (index) => {
    const { entertainments, lang } = this.state;
    const tmpEntertainments = entertainments;
    tmpEntertainments[lang].applications[index].active =
      !tmpEntertainments[lang].applications[index].active;

    this.setState({
      entertainments: tmpEntertainments,
      changesMade: true,
    });
  };

  changeName = (name) => {
    const { entertainments, lang } = this.state;
    const tmpEntertainments = entertainments;
    tmpEntertainments[lang].name = name;

    this.setState({
      entertainments: tmpEntertainments,
      changesMade: true,
    });
  };

  render() {
    const { lang, entertainments } = this.state;
    const { selectedRobot } = this.props;
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Entertainment applications</h1>
                <p className="category">
                  Here you can select which application you want to appear in
                  the entertainments application list
                </p>
              </div>
              <div className="content">
                {entertainments === null ? undefined : (
                  <div>
                    <h4>Module name</h4>
                    <Input
                      type="text"
                      onChange={(event) => this.changeName(event.target.value)}
                      value={entertainments[lang].name}
                    />
                  </div>
                )}
                {entertainments === null ? undefined : (
                  <SpeechAction
                    selectedRobot={selectedRobot}
                    storyline={entertainments[lang].speechAction}
                    selectStoryline={this.selectStoryline}
                  />
                )}
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.saveEntertainments()}
                  >
                    Save changes
                  </Button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Icon</th>
                      <th>Title</th>
                      <th>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entertainments === null
                      ? undefined
                      : entertainments[lang].applications.map(
                          (application, index) => (
                            <tr key={application._id}>
                              <td>
                                <div
                                  className="img-container"
                                  style={{ width: "80%" }}
                                >
                                  <img
                                    height="100px"
                                    src={require(`./assets/images/${application.icon}`)}
                                    alt="Icon"
                                  />
                                </div>
                              </td>
                              <td>{application.title}</td>
                              <td>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      defaultChecked={application.active}
                                      onChange={() =>
                                        this.toggleEntertainment(index)
                                      }
                                    />{" "}
                                  </Label>
                                </FormGroup>
                              </td>
                            </tr>
                          )
                        )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

Entertainment.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default Entertainment;
