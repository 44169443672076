import axios from "axios";
import { Service } from "axios-middleware";

const service = new Service(axios);

class AxiosService {
  // eslint-disable-next-line class-methods-use-this
  _createConfig() {
    const account = JSON.parse(localStorage.getItem("account"));

    let jwt;

    if (account != null && account.jwt != null) {
      // eslint-disable-next-line prefer-destructuring
      jwt = account.jwt;
    } else {
      jwt = null;
    }

    let config = {
      baseURL: process.env.REACT_APP_BACKEND_URL,
    };

    if (jwt != null) {
      config = {
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          "x-access-token": jwt,
        },
      };
    }

    return config;
  }

  request() {
    const config = this._createConfig();
    return axios.request(config);
  }

  get(url) {
    const config = this._createConfig();
    return axios.get(url, config);
  }

  delete(url) {
    const config = this._createConfig();
    return axios.delete(url, config);
  }

  head(url) {
    const config = this._createConfig();
    return axios.head(url, config);
  }

  options(url) {
    const config = this._createConfig();
    return axios.options(url, config);
  }

  post(url, data = {}) {
    const config = this._createConfig();
    return axios.post(url, data, config);
  }

  put(url, data = {}) {
    const config = this._createConfig();
    return axios.put(url, data, config);
  }

  patch(url, data = {}) {
    const config = this._createConfig();
    return axios.patch(url, data, config);
  }
}

service.register({
  onResponseError(response) {
    if (response.status === 403) {
      localStorage.removeItem("account");
    }
    return response;
  },
});

export default new AxiosService();
