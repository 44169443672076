import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import SpeechAction from "../../components/SpeechAction";
import axios from "../../utils/AxiosService";

class FaceRecognition extends Component {
  constructor(props) {
    super(props);
    this.selectStoryline = this.selectStoryline.bind(this);
    const { fr } = props.location.state;
    this.state = {
      fr,
      changesMade: false,
      params: [],
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  componentDidMount() {
    this.getDataFormat();
  }

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  changeName = (name) => {
    const { fr } = this.state;
    const tmpFr = fr;
    tmpFr.name = name;
    this.setState({
      fr: tmpFr,
      changesMade: true,
    });
  };

  getDataFormat = () => {
    const url = `/persons/params`;
    axios.get(url).then((params) => {
      this.setState({
        params: params.data,
      });
    });
  };

  saveFr = () => {
    const { selectedRobot } = this.props;
    const { fr } = this.state;
    console.log(fr);
    const url = `/faceRecognitions/${fr._id}/robots/${selectedRobot._id}`;
    axios.put(url, { faceRecognition: fr }).then((response) => {
      if (response.status === 200) {
        toast("Face recognition saved", { type: "success" });
        this.setState({
          fr: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  selectStoryline = (action, storyline) => {
    const { fr } = this.state;
    const tmpFr = fr;
    tmpFr[action] = storyline;
    this.setState({
      fr: tmpFr,
      changesMade: true,
    });
  };

  changeShowGUI = () => {
    const { fr } = this.state;
    const tmpFr = fr;
    tmpFr.showGui = !tmpFr.showGui;
    this.setState({
      fr: tmpFr,
      changesMade: true,
    });
  };

  render() {
    const { fr, params } = this.state;
    const { selectedRobot } = this.props;
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Face Recognition</h1>
                <p className="category">
                  Here you can edit the settings of a face recognition usecase
                </p>
              </div>
              <div className="content">
                <h4>Name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Face recognition name"
                  value={fr.name}
                />
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={fr.speechAction}
                  selectStoryline={(value) =>
                    this.selectStoryline("speechAction", value)
                  }
                />
                <SpeechAction
                  params={params}
                  name="Recognition Action"
                  selectedRobot={selectedRobot}
                  storyline={fr.recognitionAction}
                  selectStoryline={(value) =>
                    this.selectStoryline("recognitionAction", value)
                  }
                />
                <SpeechAction
                  name="Register Action"
                  selectedRobot={selectedRobot}
                  storyline={fr.registerAction}
                  selectStoryline={(value) =>
                    this.selectStoryline("registerAction", value)
                  }
                />
                <SpeechAction
                  name="Failure Action"
                  selectedRobot={selectedRobot}
                  storyline={fr.failureAction}
                  selectStoryline={(value) =>
                    this.selectStoryline("failureAction", value)
                  }
                />
                <h4>Show GUI?</h4>
                <Input
                  type="checkbox"
                  defaultChecked={fr.showGui}
                  onChange={this.changeShowGUI}
                ></Input>
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/datacollectors">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveFr}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default FaceRecognition;
