import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionList from './components/QuestionList';
import LanguagePicker from '../../components/LanguagePicker';

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.state = {
      error: null,
      message: null,
      lang: 'en',
    };
  }

  setLang = (lang) => {
    this.setState({
      lang,
    })
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { selectedRobot } = this.props;
    const { lang } = this.state;

    return (
      <div style={{ margin: '20px' }}>
        <LanguagePicker setLang={this.setLang} />
        <QuestionList lang={lang} selectedRobot={selectedRobot} />
      </div>
    );
  }
}

FAQ.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default FAQ;
