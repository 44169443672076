import PropTypes from "prop-types";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import axios from "../../utils/AxiosService";
import MiniatureSlide from "./components/MiniatureSlide";
import SlideView from "./components/SlideView";

class Presentation extends Component {
  constructor(props) {
    super(props);
    this.addSlide = this.addSlide.bind(this);
    this.removeSlide = this.removeSlide.bind(this);
    this.moveSlide = this.moveSlide.bind(this);
    this.changeSlideImage = this.changeSlideImage.bind(this);
    this.setLang = this.setLang.bind(this);
    this.updateSlide = this.updateSlide.bind(this);
    this.change = this.change.bind(this);
    const { location } = this.props;
    const { presentation } = location.state;
    this.state = {
      changesMade: false,
      robotChanged: false,
      slidesToRemove: [],
      presentation,
      error: null,
      message: null,
      lang: "en",
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selectedRobot } = this.props;
    if (nextProps.selectedRobot !== selectedRobot) {
      this.setState({
        robotChanged: true,
      });
    }
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  change = (changesMade) => {
    this.setState({
      changesMade,
    });
  };

  changeSlideImage = (slide, url, fileType, lang) => {
    const { presentation } = this.state;
    let tmpPresentation = presentation;
    tmpPresentation.slides[lang].forEach((presSlide) => {
      if (presSlide._id === slide._id) {
        presSlide.url = url;
        presSlide.fileType = fileType;
      }
    });
    this.setState({
      presentation: tmpPresentation,
      changesMade: true,
    });
  };

  saveSlides = () => {
    const { selectedRobot } = this.props;
    const { presentation, slidesToRemove } = this.state;
    const url = `/presentations/save/${selectedRobot._id}`;
    axios.post(url, { presentation, slidesToRemove }).then((response) => {
      if (response.status === 200) {
        toast("Presentation saved", { type: "success" });
        this.setState({
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  addSlide = (index, lang) => {
    const { presentation } = this.state;
    const url = `/presentations/${presentation._id}/${lang}/slides`;
    axios
      .put(url)
      .then((response) => {
        presentation.slides[lang].splice(index, 0, response.data);
        this.setState({
          presentation,
          changesMade: true,
        });
      })
      .catch((error) => {
        this.setState({
          error: "Error while adding the slide",
        });
      });
  };

  removeSlide = (index, lang) => {
    const { presentation } = this.state;
    const slideToRemove = presentation.slides[lang][index];
    presentation.slides[lang].splice(index, 1);
    this.setState((prevState) => ({
      presentation,
      slidesToRemove: [...prevState.slidesToRemove, slideToRemove],
      changesMade: true,
    }));
  };

  moveSlide = (from, to, lang) => {
    const { presentation } = this.state;
    presentation.slides[lang].splice(
      to,
      0,
      presentation.slides[lang].splice(from, 1)[0]
    );
    this.setState({
      presentation,
      changesMade: true,
    });
  };

  changeName = (event) => {
    const { presentation } = this.state;
    presentation.name = event.target.value;
    this.setState({
      presentation,
      changesMade: true,
    });
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  updateSlide = (slide, index) => {
    const { presentation, lang } = this.state;
    const tempPresentation = presentation;
    tempPresentation.slides[lang][index] = slide;
    this.setState({
      presentation: tempPresentation,
      changesMade: true,
    });
  };

  render() {
    const { selectedRobot } = this.props;
    const { presentation, robotChanged, lang } = this.state;

    if (robotChanged) {
      return <Redirect to="/presentations" />;
    } else {
      return (
        <div style={{ margin: "20px" }}>
          <Prompt
            when={this.shouldBlockNavigation()}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          <LanguagePicker setLang={this.setLang} />
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h2 className="title">Presentation</h2>
                  <p className="category">
                    Here you can edit the selected presentation
                  </p>
                  <h4>Presentation name</h4>
                  <Input
                    onChange={(event) => this.changeName(event)}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Presentation name"
                    value={presentation.name}
                  />
                  <div
                    style={{ position: "absolute", top: "25px", right: "46px" }}
                  >
                    <Link to="/presentations">
                      <Button
                        style={{ height: "34px", marginRight: "10px" }}
                        className=" btn-primary pe-7s-back"
                      ></Button>
                    </Link>
                    <Button color="primary" onClick={this.saveSlides}>
                      Save changes
                    </Button>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="header">
                  <h4 className="title">Drag and drop to change slide order</h4>
                </div>
                <div className="content">
                  {presentation.slides[lang].map((slide, index) => (
                    <MiniatureSlide
                      key={slide._id}
                      index={index}
                      url={slide.url}
                      fileType={slide.fileType}
                      addSlide={this.addSlide}
                      removeSlide={this.removeSlide}
                      moveSlide={this.moveSlide}
                      robotId={selectedRobot._id}
                      presentationId={presentation._id}
                      lang={lang}
                      change={this.change}
                    />
                  ))}
                  <br />
                </div>
              </div>
            </div>
            {presentation.slides[lang].map((slide, index) => (
              <React.Fragment key={slide._id}>
                <div className="col-md-4" />
                <div className="col-md-8">
                  <div className="card">
                    <div className="header">
                      <h4 className="title">
                        Here you can edit the selected presentation
                      </h4>
                    </div>
                    <SlideView
                      key={slide._id}
                      index={index}
                      slide={slide}
                      addSlide={this.addSlide}
                      removeSlide={this.removeSlide}
                      moveSlide={this.moveSlide}
                      changeSlideImage={this.changeSlideImage}
                      robotId={selectedRobot._id}
                      presentationId={presentation._id}
                      lang={lang}
                      updateSlide={this.updateSlide}
                      change={this.change}
                    />
                    <br />
                    <br />
                  </div>
                </div>
              </React.Fragment>
            ))}
            {presentation.slides[lang].length === 0 ? (
              <div>
                <div className="col-md-4" />
                <div className="col-md-8">
                  <div className="card">
                    <div className="content">
                      <br />
                      <Button
                        style={{ width: "100%" }}
                        onClick={() =>
                          this.addSlide(
                            presentation.slides[lang].length - 1,
                            lang
                          )
                        }
                      >
                        Add slide
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : undefined}
          </div>
          <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        </div>
      );
    }
  }
}

Presentation.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default Presentation;
