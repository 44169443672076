import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";

class News extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      feeds: [],
      selectedFeed: null,
      newFeed: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.fetchFeeds();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        selectedRobot: nextProps.selectedRobot,
      },
      this.fetchFeeds
    );
  }

  fetchFeeds = () => {
    const url = `/news`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          feeds: response.data.map((config) => config.configuration),
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  newFeed = () => {
    const url = `/news`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newFeed: response.data,
          redirect: true,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  deleteFeed = (id) => {
    const { selectedRobot } = this.state;
    const url = `/news/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          feeds: response.data.map((config) => config.configuration),
          selectedFeed: null,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  render() {
    const { feeds, redirect, newFeed } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/feed",
            state: {
              feed: newFeed,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">News feed</h1>
                <p className="category">
                  Here you can select a feed to edit or create a new one
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <Button
                  onClick={this.newFeed}
                  className="btn-success"
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  New feed <i className="pe-7s-plus"></i>
                </Button>
                <h4 className="title">
                  Select a feed to edit or create a new one
                </h4>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width="40px">Edit</th>
                      <th width="40px">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feeds === null
                      ? undefined
                      : feeds.map((feed) => (
                          <tr key={feed._id}>
                            <td className="td-name">
                              {feed.en.name !== ""
                                ? feed.en.name
                                : feed.de.name !== ""
                                ? feed.de.name
                                : feed.fr.name !== ""
                                ? feed.fr.name
                                : feed.it.name}
                            </td>
                            <td className="td-name">
                              <Button
                                tag={Link}
                                to={{
                                  pathname: "/feed",
                                  state: {
                                    feed: feed,
                                  },
                                }}
                                color="primary"
                              >
                                <i className="pe-7s-pen"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => this.deleteFeed(feed._id)}
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default News;
