import React, { Component } from "react";
import { SketchPicker } from "react-color";
import Dropdown from "react-select";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import axios from "../../../utils/AxiosService";
import { fonts, styles } from "../constants/fonts";
import GravityPicker from "./GravityPicker";

class ButtonLayout extends Component {
  constructor(props) {
    super(props);
    const { cell } = this.props;
    this.state = {
      cell,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cell !== this.props.cell) {
      this.setState({
        cell: nextProps.cell,
      });
    }
  }

  uploadIcon = (icon) => {
    const { changeButtonStyle } = this.props;
    const url = `/menuStructures/icons`;
    const formData = new FormData();
    formData.append("icon", icon);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .put(url, formData, config)
      .then((response) => {
        changeButtonStyle("backgroundIcon", response.data);
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { close, changeButtonStyle, saveStructure, menuStructure } =
      this.props;
    const { cell } = this.state;

    return (
      <div style={{ margin: "20px" }}>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h2>Button layout</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Grid</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Row weight</h4>
                <Input
                  type="number"
                  value={cell.rowWeight}
                  onChange={(event) =>
                    changeButtonStyle("rowWeight", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Column weight</h4>
                <Input
                  type="number"
                  value={cell.columnWeight}
                  onChange={(event) =>
                    changeButtonStyle("columnWeight", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Wrap</h4>
                <Input
                  type="checkbox"
                  defaultChecked={cell.isWrapBefore}
                  onChange={() =>
                    changeButtonStyle("isWrapBefore", !cell.isWrapBefore)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Background</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor: cell.backgroundColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <div style={{ width: "0px" }}>
                    <SketchPicker
                      disableAlpha={false}
                      color={cell.backgroundColor}
                      onChange={(color) =>
                        changeButtonStyle("backgroundColor", color.hex)
                      }
                    />
                  </div>
                </Popup>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Icon</h4>
                <label
                  htmlFor="slide-file"
                  className="input-image"
                  style={{ marginTop: "0px", float: "left" }}
                >
                  <i className="fa fa-image"></i>
                  <img
                    height="100px"
                    src={`${cell.backgroundIcon}`}
                    alt="Upload an icon..."
                  />
                  <input
                    id="slide-file"
                    type="file"
                    accept="image/*"
                    onChange={(event) => this.uploadIcon(event.target.files[0])}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Icon width</h4>
                <Input
                  type="number"
                  value={cell.iconWidth}
                  onChange={(event) =>
                    changeButtonStyle("iconWidth", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Icon height</h4>
                <Input
                  type="number"
                  value={cell.iconHeight}
                  onChange={(event) =>
                    changeButtonStyle("iconHeight", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Icon gravity</h4>
                <GravityPicker
                  cell={cell}
                  menuStructure={menuStructure}
                  change={changeButtonStyle}
                  field="iconGravity"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Font</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Family</h4>
                <Dropdown
                  options={fonts}
                  value={{
                    label:
                      cell.fontFamily === undefined
                        ? menuStructure.fontFamily
                        : cell.fontFamily,
                    value:
                      cell.fontFamily === undefined
                        ? menuStructure.fontFamily
                        : cell.fontFamily,
                  }}
                  onChange={(event) =>
                    changeButtonStyle("fontFamily", event.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Style</h4>
                <Dropdown
                  options={styles}
                  value={{
                    label:
                      cell.fontStyle === undefined
                        ? menuStructure.fontStyle
                        : cell.fontStyle,
                    value:
                      cell.fontStyle === undefined
                        ? menuStructure.fontStyle
                        : cell.fontStyle,
                  }}
                  onChange={(event) =>
                    changeButtonStyle("fontStyle", event.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          cell.fontColor === undefined
                            ? menuStructure.fontColor
                            : cell.fontColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={
                      cell.fontColor === undefined
                        ? menuStructure.fontColor
                        : cell.fontColor
                    }
                    onChange={(color) =>
                      changeButtonStyle("fontColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Size</h4>
                <Input
                  type="number"
                  value={
                    cell.fontSize === undefined
                      ? menuStructure.fontSize
                      : cell.fontSize
                  }
                  onChange={(event) =>
                    changeButtonStyle("fontSize", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Text</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Background width</h4>
                <Input
                  type="number"
                  value={cell.textBackgroundWidth}
                  onChange={(event) =>
                    changeButtonStyle("textBackgroundWidth", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Background height</h4>
                <Input
                  type="number"
                  value={cell.textBackgroundHeight}
                  onChange={(event) =>
                    changeButtonStyle(
                      "textBackgroundHeight",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Background color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor: cell.textBackgroundColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={cell.textBackgroundColor}
                    onChange={(color) =>
                      changeButtonStyle("textBackgroundColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Gravity</h4>
                <GravityPicker
                  cell={cell}
                  menuStructure={menuStructure}
                  change={changeButtonStyle}
                  field="textGravity"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Border</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor: cell.borderColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={
                      cell.borderColor === undefined
                        ? menuStructure.borderColor
                        : cell.borderColor
                    }
                    onChange={(color) =>
                      changeButtonStyle("borderColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Size</h4>
                <Input
                  type="number"
                  value={
                    cell.borderSize === undefined
                      ? menuStructure.borderSize
                      : cell.borderSize
                  }
                  onChange={(event) =>
                    changeButtonStyle("borderSize", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Radius</h4>
                <Input
                  type="number"
                  value={
                    cell.borderRadius === undefined
                      ? menuStructure.borderRadius
                      : cell.borderRadius
                  }
                  onChange={(event) =>
                    changeButtonStyle("borderRadius", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="header">
              <Button
                onClick={() => {
                  saveStructure();
                  close();
                }}
                color="success"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ButtonLayout;
