import classnames from "classnames";
import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
//import CustomUI from './components/CustomUI';
import axios from "../../utils/AxiosService";
import LanguagePicker from "../LanguagePicker";
import RobotActions from "./components/RobotActions";
import RobotActivity from "./components/RobotActivity";
import RobotDialog from "./components/RobotDialog";
import RobotNavigation from "./components/RobotNavigation";
import RobotNotification from "./components/RobotNotification";
import { categories } from "./constants/categories";

const activeTabStyle = {
  backgroundColor: "white",
  border: "1px solid lightgray",
  borderBottom: "0px",
  bottom: "-1px",
  zIndex: "100",
  color: "black",
};

const inactiveTabStyle = {
  backgroundColor: "white",
  border: "1px solid lightgray",
  borderBottom: "0px",
  bottom: "1px",
  color: "black",
};

class Storyline extends React.Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.changeEventText = this.changeEventText.bind(this);
    this.changeEventAnimation = this.changeEventAnimation.bind(this);
    this.changeEventSound = this.changeEventSound.bind(this);
    this.changeEventSoundDelay = this.changeEventSoundDelay.bind(this);
    this.changeTarget = this.changeTarget.bind(this);
    this.resetTarget = this.resetTarget.bind(this);
    this.changeActivityType = this.changeActivityType.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.changeListen = this.changeListen.bind(this);
    this.changeDialogTarget = this.changeDialogTarget.bind(this);
    this.changeIntentTriggerType = this.changeIntentTriggerType.bind(this);
    this.changeIntentTriggerValue = this.changeIntentTriggerValue.bind(this);
    this.changeTimeout = this.changeTimeout.bind(this);
    this.toggleIsWaypoint = this.toggleIsWaypoint.bind(this);
    this.changeWaypoint = this.changeWaypoint.bind(this);
    this.toggleIsRandom = this.toggleIsRandom.bind(this);
    this.changeX = this.changeX.bind(this);
    this.changeY = this.changeY.bind(this);
    this.changeTheta = this.changeTheta.bind(this);
    this.changeNotifTarget = this.changeNotifTarget.bind(this);
    this.changeIsActivity = this.changeIsActivity.bind(this);
    const { selectedRobot, storyline, params } = this.props;

    this.state = {
      params,
      selectedRobot,
      lang: "en",
      storyline:
        storyline === null || storyline === undefined
          ? {
              name: "",
              category: "Robot behavior",
              events: [
                {
                  title: "Default event",
                  isActivity: false,
                  robot_actions: {
                    tts: {
                      en: "",
                      de: "",
                      fr: "",
                      it: "",
                    },
                    cruzr: {
                      animation: "",
                      expression: "",
                      expressionTimeout: 5,
                      dance: "",
                    },
                    pepper: {
                      animation: "",
                      dance: "",
                      sound: "",
                      soundDelay: 0,
                      led: "",
                    },
                    navigation: {
                      isWaypoint: true,
                      waypoint: "",
                      isRandom: false,
                      x: 0,
                      y: 0,
                      theta: 0,
                      onFailure: {
                        en: "Sorry I can not reach my destination",
                        de: "Entschuldigung, ich kann mein Ziel nicht erreichen",
                        fr: "Désolé je ne peux pas atteindre ma destination",
                        it: "Spiacente, non riesco a raggiungere la mia destinazione",
                      },
                    },
                    notification: {
                      target: "",
                    },
                  },
                  activity: {
                    type: "ANDROID",
                    target: "",
                  },
                  robot_dialog: {
                    listen: true,
                    target: "ROBOT",
                    intent_trigger: {
                      type: "NONE",
                      value: "",
                    },
                    timeout: 60,
                  },
                  //custom_ui: null,
                  delay: 1,
                },
              ],
            }
          : storyline,
      activeTab:
        storyline === null ? "1" : storyline.events[0].isActivity ? "2" : "1",
      changesMade: false,
      selectedEventIndex: 0,
      showParamPopup: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  openPopup = () => {
    this.setState({
      showParamPopup: true,
    });
  };

  closePopup = () => {
    this.setState({
      showParamPopup: false,
    });
  };

  changeName = (name) => {
    const { storyline } = this.state;
    const tempStoryline = storyline;
    tempStoryline.name = name;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeEventText = (event) => {
    const { storyline, lang, selectedEventIndex, params } = this.state;
    const { value } = event.target;
    if (params !== undefined && value.length > 0) {
      /*const lastChar = value.substring(selectionEnd-1, selectionEnd)
      if (lastChar === '$') {
        this.setState({
          showParamPopup: true
        });
      } else if (lastChar === ' ') {
        this.setState({
          showParamPopup: false
        });
      }*/
    }
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.tts[lang] = value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeEventAnimation = (robot, value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions[robot].animation =
      value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeEventSound = (value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.pepper.sound = value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeEventSoundDelay = (value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.pepper.soundDelay =
      value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  selectEvent = (index) => {
    this.setState({
      selectedEventIndex: index,
    });
  };

  addEvent = () => {
    const { storyline } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events.push({
      title: "Default event",
      isActivity: false,
      robot_actions: {
        tts: {
          en: "",
          de: "",
          fr: "",
          it: "",
        },
        cruzr: {
          animation: "",
          expression: "",
          expressionTimeout: 5,
          dance: "",
        },
        pepper: {
          animation: "",
          dance: "",
          sound: "",
          soundDelay: 0,
          led: "",
        },
        navigation: {
          isWaypoint: true,
          waypoint: "",
          isRandom: false,
          x: 0,
          y: 0,
          theta: 0,
          onFailure: {
            en: "Sorry I can not reach my destination",
            de: "Entschuldigung, ich kann mein Ziel nicht erreichen",
            fr: "Désolé je ne peux pas atteindre ma destination",
            it: "Spiacente, non riesco a raggiungere la mia destinazione",
          },
        },
        notification: {
          target: "",
        },
      },
      activity: {
        type: "ANDROID",
        target: "",
      },
      custom_ui: null,
      delay: 1,
    });
    this.setState({
      storyline: tempStoryline,
      selectedEventIndex: tempStoryline.events.length - 1,
      changeName: true,
    });
  };

  changeActivityType = (type) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].activity.type =
      type.value.toUpperCase();
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeTarget = (target, type, name) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].activity.name =
      type === "ANDROID" ? target.split(".").pop() : type !== "" ? name : "";
    tempStoryline.events[selectedEventIndex].activity.target = target;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  resetTarget = () => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].activity.name = "";
    tempStoryline.events[selectedEventIndex].activity.target = "";
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeTitle = (title) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].title = title;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeDuration = (delay) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].delay = delay;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  deleteEvent = (index) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events.splice(index, 1);
    this.setState({
      selectedEventIndex:
        selectedEventIndex > tempStoryline.events.length - 1
          ? selectedEventIndex - 1
          : selectedEventIndex,
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeListen = () => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_dialog.listen =
      !tempStoryline.events[selectedEventIndex].robot_dialog.listen;
    this.setState({
      storyline: tempStoryline,
    });
  };

  changeDialogTarget = (target) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_dialog.target = target.value;
    this.setState({
      storyline: tempStoryline,
    });
  };

  changeNotifTarget = (target) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.notification.target =
      target.value;
    this.setState({
      storyline: tempStoryline,
    });
  };

  changeIntentTriggerType = (type) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_dialog.intent_trigger.type =
      type.value;
    this.setState({
      storyline: tempStoryline,
    });
  };

  changeIntentTriggerValue = (value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_dialog.intent_trigger.value =
      value;
    this.setState({
      storyline: tempStoryline,
    });
  };

  changeTimeout = (timeout) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_dialog.timeout = timeout;
    this.setState({
      storyline: tempStoryline,
    });
  };
  toggleIsWaypoint = () => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[
      selectedEventIndex
    ].robot_actions.navigation.isWaypoint =
      !tempStoryline.events[selectedEventIndex].robot_actions.navigation
        .isWaypoint;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeWaypoint = (value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.navigation.waypoint =
      value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  toggleIsRandom = () => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.navigation.isRandom =
      !tempStoryline.events[selectedEventIndex].robot_actions.navigation
        .isRandom;
    console.log(tempStoryline);
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeX = (value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.navigation.x = value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeY = (value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.navigation.y = value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeTheta = (value) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.navigation.theta =
      value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeOnFailure = (value) => {
    const { storyline, selectedEventIndex, lang } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.navigation.onFailure[
      lang
    ] = value;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeExpression = (expression) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions.cruzr.expression =
      expression;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeExpressionTimeout = (timeout) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[
      selectedEventIndex
    ].robot_actions.cruzr.expressionTimeout = timeout;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeDance = (robot, dance) => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].robot_actions[robot].dance = dance;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  changeIsActivity = () => {
    const { storyline, selectedEventIndex } = this.state;
    const tempStoryline = storyline;
    tempStoryline.events[selectedEventIndex].isActivity =
      !tempStoryline.events[selectedEventIndex].isActivity;
    this.setState({
      storyline: tempStoryline,
      changesMade: true,
    });
  };

  saveChanges = () => {
    const { close, fetchStoryline } = this.props;
    const { storyline, selectedRobot } = this.state;
    const url = `/storylines/${storyline._id}/robots/${selectedRobot._id}`;
    axios
      .put(url, { storyline: storyline })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            storyline: response.data,
            changesMade: false,
          });
          toast("StoryLine saved", { type: "success" });
          if (close !== undefined) {
            fetchStoryline();
            close();
          }
        } else if (response.status === 400 || response.status === undefined) {
          toast("Duplicate name", { type: "error" });
        } else {
          toast("Server Error", { type: "error" });
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          toast("Duplicate name", { type: "error" });
        } else {
          toast("Server error", { type: "error" });
        }
      });
  };

  createStoryline = () => {
    const { selectStoryline, close, fetchStoryline } = this.props;
    const { storyline, selectedRobot } = this.state;
    const url = `/storylines/robots/${selectedRobot._id}`;
    axios
      .post(url, { storyline: storyline })
      .then((response) => {
        if (response.status === 200) {
          toast("StoryLine saved", { type: "success" });
          selectStoryline(response.data);
          this.setState({
            storyline: response.data,
            changesMade: false,
          });
          fetchStoryline();
          close();
        } else {
          toast("Server Error", { type: "error" });
        }
      })
      .catch(() => toast("Server Error", { type: "error" }));
  };

  render() {
    const { newStory, close } = this.props;
    const { storyline, lang, selectedEventIndex, selectedRobot, params } =
      this.state;
    return (
      <div>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <LanguagePicker setLang={this.setLang} />
                <h2>Create a new story line</h2>
              </div>
              <div className="content" style={{ marginTop: "10px" }}>
                <p>Name</p>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  placeholder="Name"
                  value={storyline.name}
                />
                <br />
                <p>Category</p>
                <Dropdown
                  options={categories.map((cat) => ({
                    label: cat,
                    value: cat,
                  }))}
                  onChange={(field) =>
                    this.setState((prevState) => ({
                      storyline: {
                        ...prevState.storyline,
                        category: field.value,
                      },
                    }))
                  }
                  value={{
                    label: storyline.category,
                    value: storyline.category,
                  }}
                  placeholder="Select a category"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <div
                  style={{
                    position: "absolute",
                    bottom: "45px",
                    right: "46px",
                  }}
                >
                  <Button color="primary" onClick={this.addEvent}>
                    Add event
                  </Button>
                </div>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Title</th>
                      <th>Duration</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storyline.events.map((ev, index) => (
                      <tr
                        key={index}
                        style={
                          selectedEventIndex === index
                            ? { backgroundColor: "#e3e3e3", cursor: "pointer" }
                            : { cursor: "pointer" }
                        }
                      >
                        <td width="10%">{index + 1}</td>
                        <td>{ev.title}</td>
                        <td>
                          {ev.delay < 0 ? "\u221e" : `${ev.delay} second(s)`}
                        </td>
                        <td>
                          <i
                            className="pe-7s-pen"
                            style={{ fontSize: "18px" }}
                            onClick={() => this.selectEvent(index)}
                          ></i>
                        </td>
                        <td>
                          {storyline.events.length > 1 ? (
                            <i
                              className="pe-7s-trash"
                              style={{ fontSize: "18px" }}
                              onClick={() => this.deleteEvent(index)}
                            ></i>
                          ) : undefined}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </div>
        </div>
        {selectedEventIndex < 0 ? undefined : (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div>
                  <div className="header">
                    <h2>{storyline.events[selectedEventIndex].title}</h2>
                  </div>
                  <div className="content">
                    <p>Title</p>
                    <Input
                      onChange={(event) => this.changeTitle(event.target.value)}
                      type="text"
                      placeholder="Title"
                      value={storyline.events[selectedEventIndex].title}
                    />
                    <br />
                    <p>Delay [s]</p>
                    <Input
                      onChange={(event) =>
                        this.changeDuration(event.target.value)
                      }
                      type="number"
                      placeholder="Duration"
                      value={storyline.events[selectedEventIndex].delay}
                    />
                    <br />
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                          style={
                            this.state.activeTab === "1"
                              ? activeTabStyle
                              : inactiveTabStyle
                          }
                        >
                          Robot actions
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                          style={
                            this.state.activeTab === "2"
                              ? activeTabStyle
                              : inactiveTabStyle
                          }
                        >
                          Activity
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3");
                          }}
                          style={
                            this.state.activeTab === "3"
                              ? activeTabStyle
                              : inactiveTabStyle
                          }
                        >
                          Dialog
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4");
                          }}
                          style={
                            this.state.activeTab === "4"
                              ? activeTabStyle
                              : inactiveTabStyle
                          }
                        >
                          Navigation
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5");
                          }}
                          style={
                            this.state.activeTab === "5"
                              ? activeTabStyle
                              : inactiveTabStyle
                          }
                        >
                          Notification
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>
                      <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle('3'); }}
                          style={this.state.activeTab === '3' ? activeTabStyle : inactiveTabStyle}
                        >
                          Custom interface
                      </NavLink>
                    </NavItem>*/}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane
                        tabId="1"
                        style={{ padding: "0px", marginTop: "16px" }}
                      >
                        <RobotActions
                          selectedRobot={selectedRobot}
                          changeExpression={this.changeExpression}
                          changeExpressionTimeout={this.changeExpressionTimeout}
                          changeDance={this.changeDance}
                          changeEventText={this.changeEventText}
                          changeEventAnimation={this.changeEventAnimation}
                          changeEventSound={this.changeEventSound}
                          changeEventSoundDelay={this.changeEventSoundDelay}
                          storyline={storyline}
                          index={selectedEventIndex}
                          lang={lang}
                          params={params}
                        />
                      </TabPane>
                      <TabPane
                        tabId="2"
                        style={{ padding: "0px", marginTop: "16px" }}
                      >
                        <RobotActivity
                          selectedRobot={selectedRobot}
                          changeIsActivity={this.changeIsActivity}
                          changeActivityType={this.changeActivityType}
                          changeTarget={this.changeTarget}
                          storyline={storyline}
                          index={selectedEventIndex}
                          lang={lang}
                        />
                      </TabPane>
                      <TabPane
                        tabId="3"
                        style={{ padding: "0px", marginTop: "16px" }}
                      >
                        <RobotDialog
                          selectedRobot={selectedRobot}
                          changeListen={this.changeListen}
                          changeDialogTarget={this.changeDialogTarget}
                          changeIntentTriggerType={this.changeIntentTriggerType}
                          changeIntentTriggerValue={
                            this.changeIntentTriggerValue
                          }
                          changeTimeout={this.changeTimeout}
                          storyline={storyline}
                          index={selectedEventIndex}
                          lang={lang}
                        />
                      </TabPane>
                      <TabPane
                        tabId="4"
                        style={{ padding: "0px", marginTop: "16px" }}
                      >
                        <RobotNavigation
                          selectedRobot={selectedRobot}
                          storyline={storyline}
                          index={selectedEventIndex}
                          lang={lang}
                          toggleIsWaypoint={this.toggleIsWaypoint}
                          changeWaypoint={this.changeWaypoint}
                          toggleIsRandom={this.toggleIsRandom}
                          changeX={this.changeX}
                          changeY={this.changeY}
                          changeTheta={this.changeTheta}
                          changeOnFailure={this.changeOnFailure}
                        />
                      </TabPane>
                      <TabPane
                        tabId="5"
                        style={{ padding: "0px", marginTop: "16px" }}
                      >
                        <RobotNotification
                          selectedRobot={selectedRobot}
                          storyline={storyline}
                          index={selectedEventIndex}
                          changeNotifTarget={this.changeNotifTarget}
                        />
                      </TabPane>
                      {/*<TabPane tabId="3" style={{ padding: '0px', marginTop: '16px' }}>
                      <CustomUI storyline={storyline} index={selectedEventIndex} />
                  </TabPane>*/}
                    </TabContent>
                    <br />
                    <Link to="/storylines">
                      {close === undefined ? (
                        <Button
                          style={{ height: "34px", marginRight: "10px" }}
                          className=" btn-primary pe-7s-back"
                        ></Button>
                      ) : undefined}
                    </Link>
                    <Button
                      color="primary"
                      onClick={
                        newStory ? this.createStoryline : this.saveChanges
                      }
                    >
                      Save changes
                    </Button>
                    {close !== undefined ? (
                      <Button
                        style={{ marginLeft: "5px" }}
                        color="secondary"
                        onClick={close}
                      >
                        Cancel
                      </Button>
                    ) : undefined}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Storyline;
