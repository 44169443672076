import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DateTimePicker from "react-datetime-picker";
import "react-dropdown/style.css";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Badge, Button, Input } from "reactstrap";
import SpeechAction from "../../components/SpeechAction";
import axios from "../../utils/AxiosService";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.selectStoryline = this.selectStoryline.bind(this);
    BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

    let allViews = Object.keys(BigCalendar.views).map(
      (k) => BigCalendar.views[k]
    );
    this.state = {
      calendar: null,
      allViews,
      title: "",
      start: null,
      end: null,
      organizer: null,
      participants: [],
      location: "",
      open: false,
      selectedEvent: null,
      loaded: false,
      contacts: [],
      changesMade: false,
    };
  }

  componentDidMount() {
    this.fetchCalendar();
    this.fetchContacts();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchCalendar = () => {
    const url = `/calendars`;
    axios.get(url).then((result) => {
      this.setState({
        calendar: result.data[0].configuration,
      });
    });
  };

  fetchContacts = () => {
    const url = `/contacts`;
    axios.get(url).then((result) => {
      this.setState({
        contacts: result.data.contacts,
        filteredContacts: result.data.contacts,
        loaded: true,
      });
    });
  };

  changeText = (text) => {
    const { calendar } = this.state;
    const tempCalendar = calendar;
    tempCalendar.speechAction.text = text;
    this.setState({
      calendar: tempCalendar,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { calendar } = this.state;
    const tempCalendar = calendar;
    tempCalendar.speechAction.animation = animation;
    this.setState({
      calendar: tempCalendar,
      changesMade: true,
    });
  };

  changeName = (name) => {
    const { calendar } = this.state;
    const tempCalendar = calendar;
    tempCalendar.name = name.target.value;
    this.setState({
      calendar: tempCalendar,
      changesMade: true,
    });
  };

  createEvent = (closeFunc) => {
    const { calendar, title, start, end, location, organizer, participants } =
      this.state;
    if (title !== "" && start !== null && end !== null) {
      const url = `/calendars/${calendar._id}/events`;
      axios
        .post(url, {
          event: { title, start, end, location, organizer, participants },
        })
        .then((response) => {
          if (response.status === 200) {
            closeFunc();
            toast("Event created", { type: "success" });
            this.setState({
              calendar: response.data,
              title: "",
              start: null,
              end: null,
              organizer: null,
              participants: [],
              location: "",
              changesMade: false,
            });
          } else {
            toast("Server Error", { type: "error" });
          }
        })
        .catch((error) => {
          toast("Server Error", { type: "error" });
        });
    } else {
      toast("Please fill up all required fields", { type: "error" });
      setTimeout(() => {
        this.setState({
          titleEmpty: false,
          startEmpty: false,
          endEmpty: false,
        });
      }, 3000);
      this.setState({
        titleEmpty: title === "",
        startEmpty: start === null,
        endEmpty: end === null,
      });
    }
  };

  updateEvent = (closeFunc) => {
    const { calendar } = this.state;
    const url = `/calendars/${calendar._id}`;
    axios
      .put(url, { calendar: calendar })
      .then((response) => {
        if (response.status === 200) {
          if (closeFunc !== null) {
            closeFunc();
          }
          this.setState(
            {
              selectedEvent: null,
              calendar: response.data,
              title: "",
              start: null,
              end: null,
              organizer: null,
              participants: [],
              location: "",
              changesMade: false,
            },
            () => toast("Calendar saved", { type: "success" })
          );
        } else {
          toast("Server Error", { type: "error" });
        }
      })
      .catch((error) => {
        toast("Server Error", { type: "error" });
      });
  };

  deleteEvent = (closeFunc) => {
    const { calendar, selectedEvent } = this.state;
    const url = `/calendars/${calendar._id}/events/${selectedEvent._id}`;
    axios
      .delete(url)
      .then((response) => {
        if (response.status === 200) {
          closeFunc();
          this.setState({
            calendar: response.data,
            selectedEvent: null,
          });
        } else {
          toast("Server Error", { type: "error" });
        }
      })
      .catch((error) => {
        toast("Server Error", { type: "error" });
      });
  };

  changeTitle = (value) => {
    this.setState({
      title: value,
      changesMade: true,
    });
  };

  changeLocation = (value) => {
    this.setState({
      location: value,
      changesMade: true,
    });
  };

  changeStart = (value) => {
    this.setState({
      start: value,
      changesMade: true,
    });
  };

  changeEnd = (value) => {
    this.setState({
      end: value,
      changesMade: true,
    });
  };

  addParticipant = (participant) => {
    const { contacts } = this.state;
    this.setState((prevState) => ({
      participants: [
        ...prevState.participants,
        contacts.find(
          (contact) => contact._id.toString() === participant.value
        ),
      ],
      changesMade: true,
    }));
  };

  removeParticipant = (participantInd) => {
    const { participants } = this.state;
    this.setState({
      participants: participants.filter((_, index) => index !== participantInd),
      changesMade: true,
    });
  };

  selectContact = (value) => {
    const { contacts } = this.state;
    this.setState({
      organizer: contacts.find(
        (contact) => contact._id.toString() === value.value
      ),
      changesMade: true,
    });
  };

  changeEventTitle = (value) => {
    const { selectedEvent } = this.state;
    let tmpEvent = selectedEvent;
    tmpEvent.title = value;

    this.setState((prevState) => ({
      calendar: {
        _id: prevState.calendar._id,
        speechAction: prevState.calendar.speechAction,
        events: prevState.calendar.events.map((event) =>
          event._id.toString() === selectedEvent._id ? tmpEvent : event
        ),
      },
      selectedEvent: tmpEvent,
      changesMade: true,
    }));
  };

  changeEventLocation = (value) => {
    const { selectedEvent, calendar } = this.state;
    let tmpEvent = selectedEvent;
    tmpEvent.location = value;

    this.setState((prevState) => ({
      calendar: {
        _id: prevState.calendar._id,
        events: calendar.events.map((event) =>
          event._id === selectedEvent._id ? tmpEvent : event
        ),
        speechAction: prevState.calendar.speechAction,
      },
      selectedEvent: tmpEvent,
      changesMade: true,
    }));
  };

  changeEventStart = (value) => {
    const { selectedEvent, calendar } = this.state;
    let tmpEvent = selectedEvent;
    tmpEvent.start = value;

    this.setState((prevState) => ({
      calendar: {
        _id: prevState.calendar._id,
        events: calendar.events.map((event) =>
          event._id === selectedEvent._id ? tmpEvent : event
        ),
        speechAction: prevState.calendar.speechAction,
      },
      selectedEvent: tmpEvent,
      changesMade: true,
    }));
  };

  changeEventEnd = (value) => {
    const { selectedEvent, calendar } = this.state;
    let tmpEvent = selectedEvent;
    tmpEvent.end = value;

    this.setState((prevState) => ({
      calendar: {
        _id: prevState.calendar._id,
        events: calendar.events.map((event) =>
          event._id === selectedEvent._id ? tmpEvent : event
        ),
        speechAction: prevState.calendar.speechAction,
      },
      selectedEvent: tmpEvent,
      changesMade: true,
    }));
  };

  addEventParticipant = (participant) => {
    const { selectedEvent, calendar, contacts } = this.state;
    let tmpEvent = selectedEvent;
    tmpEvent.participants.push(
      contacts.find((contact) => contact._id.toString() === participant)
    );

    this.setState((prevState) => ({
      calendar: {
        _id: prevState.calendar._id,
        events: calendar.events.map((event) =>
          event._id === selectedEvent._id ? tmpEvent : event
        ),
        speechAction: prevState.calendar.speechAction,
      },
      selectedEvent: tmpEvent,
      changesMade: true,
    }));
  };

  removeEventParticipant = (participantInd) => {
    const { selectedEvent, calendar } = this.state;
    let tmpEvent = selectedEvent;
    tmpEvent = tmpEvent.participants.filter(
      (_, index) => index !== participantInd
    );

    this.setState((prevState) => ({
      calendar: {
        _id: prevState.calendar._id,
        events: calendar.events.map((event) =>
          event._id === selectedEvent._id ? tmpEvent : event
        ),
        speechAction: prevState.calendar.speechAction,
      },
      selectedEvent: tmpEvent,
      changesMade: true,
    }));
  };

  selectEventContact = (value) => {
    const { selectedEvent, calendar, contacts } = this.state;
    let tmpEvent = selectedEvent;
    tmpEvent.organizer = contacts.find(
      (contact) => contact._id.toString() === value
    );

    this.setState((prevState) => ({
      calendar: {
        _id: prevState.calendar._id,
        events: calendar.events.map((event) =>
          event._id === selectedEvent._id ? tmpEvent : event
        ),
        speechAction: prevState.calendar.speechAction,
      },
      selectedEvent: tmpEvent,
      changesMade: true,
    }));
  };

  selectEvent = (event) => {
    this.setState({
      selectedEvent: event,
    });
  };

  selectStoryline = (storyline) => {
    const { calendar } = this.state;
    let tmpCalendar = calendar;
    tmpCalendar.speechAction = storyline;
    this.setState({
      calendar: tmpCalendar,
    });
  };

  render() {
    const { selectedRobot } = this.props;
    const {
      calendar,
      allViews,
      title,
      start,
      end,
      selectedEvent,
      loaded,
      contacts,
      organizer,
      participants,
      location,
      titleEmpty,
      startEmpty,
      endEmpty,
    } = this.state;
    if (!loaded) {
      return <div></div>;
    }
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Calendar</h1>
                <p className="category">
                  Here you can configure your calendar by creating events
                </p>
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button
                    color="primary"
                    onClick={() => this.updateEvent(null)}
                  >
                    Save changes
                  </Button>
                </div>
                <br />
              </div>
              <div className="content">
                {calendar === null ? undefined : (
                  <React.Fragment>
                    <SpeechAction
                      selectedRobot={selectedRobot}
                      storyline={calendar.speechAction}
                      selectStoryline={this.selectStoryline}
                    />
                    <h4>Calendar name</h4>
                    <Input
                      type="text"
                      onChange={this.changeName}
                      value={calendar.name}
                      placeholder="Calendar name"
                    ></Input>
                  </React.Fragment>
                )}
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content" style={{ paddingTop: "20px" }}>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "center",
                  }}
                >
                  <Popup
                    trigger={
                      <span>
                        <Button
                          style={{ marginRight: "5px", marginLeft: "5px" }}
                          className="btn btn-primary"
                        >
                          New event
                        </Button>
                      </span>
                    }
                    position="right center"
                    modal={true}
                  >
                    {(close) => (
                      <div>
                        <div
                          className="header"
                          style={{ marginBottom: "20px" }}
                        >
                          <h2>New event</h2>
                        </div>
                        <div className="content">
                          <h4>Title*</h4>
                          <Input
                            style={
                              titleEmpty
                                ? { border: "#d14e4e 1px solid" }
                                : null
                            }
                            type="text"
                            placeholder="Title"
                            onChange={(event) =>
                              this.changeTitle(event.target.value)
                            }
                            value={title}
                          ></Input>
                          <h4>Start date*</h4>
                          <div
                            style={
                              startEmpty
                                ? {
                                    diplay: "table",
                                    border: "#d14e4e 1px solid",
                                  }
                                : {
                                    diplay: "table",
                                    border: "transparent 1px solid",
                                  }
                            }
                          >
                            <DateTimePicker
                              onChange={(date) =>
                                this.setState({ start: date })
                              }
                              value={start}
                            />
                          </div>
                          <h4>End date*</h4>
                          <div
                            style={
                              endEmpty
                                ? {
                                    diplay: "table",
                                    border: "#d14e4e 1px solid",
                                  }
                                : {
                                    diplay: "table",
                                    border: "transparent 1px solid",
                                  }
                            }
                          >
                            <DateTimePicker
                              onChange={(date) => this.setState({ end: date })}
                              value={end}
                            />
                          </div>
                          <h4>Location</h4>
                          <Input
                            type="text"
                            placeholder="Location"
                            onChange={(event) =>
                              this.changeLocation(event.target.value)
                            }
                            value={location}
                          ></Input>

                          <h4>Organizer</h4>
                          <Dropdown
                            isSearchable
                            className="dropdown-speechaction"
                            options={contacts.map((cont) => ({
                              value: cont._id,
                              label: `${cont.name} ${cont.surname}`,
                            }))}
                            onChange={this.selectContact}
                            value={
                              organizer === null
                                ? null
                                : {
                                    value: organizer._id,
                                    label: `${organizer.surname} ${organizer.name}`,
                                  }
                            }
                            placeholder="Select a contact"
                          />
                          <h4>Participants</h4>
                          <div className="card">
                            <div className="content">
                              {participants.map((participant, index) => (
                                <Badge
                                  style={{
                                    height: "25px",
                                    fontSize: "14px",
                                    paddingTop: "6px",
                                    marginRight: "10px",
                                  }}
                                  color="primary"
                                  key={participant._id}
                                >
                                  {`${participant.surname} ${participant.name}`}{" "}
                                  <i
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "rgba(255, 0, 0, 0.5)",
                                    }}
                                    onClick={() =>
                                      this.removeParticipant(index)
                                    }
                                    className="pe-7s-close"
                                  ></i>
                                </Badge>
                              ))}
                            </div>
                          </div>
                          <Dropdown
                            isSearchable
                            className="dropdown-speechaction"
                            options={contacts
                              .filter((cont) => !participants.includes(cont))
                              .map((cont) => ({
                                value: cont._id,
                                label: `${cont.name} ${cont.surname}`,
                              }))}
                            onChange={this.addParticipant}
                            value={
                              organizer === null
                                ? null
                                : {
                                    value: organizer._id,
                                    label: `${organizer.surname} ${organizer.name}`,
                                  }
                            }
                            placeholder="Select a contact"
                          />
                          <br />
                          <br />
                          <br />
                          <div
                            style={{
                              position: "absolute",
                              bottom: "25px",
                              left: "35px",
                            }}
                          >
                            *Required fields
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              bottom: "25px",
                              right: "35px",
                            }}
                          >
                            <Button className="btn-secondary" onClick={close}>
                              Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              className="btn-primary"
                              onClick={() => this.createEvent(close)}
                            >
                              Create
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>

                  <Popup
                    trigger={
                      <span>
                        <Button
                          style={{ marginRight: "5px", marginLeft: "5px" }}
                          disabled={selectedEvent === null}
                          className="btn btn-primary"
                        >
                          Edit event
                        </Button>
                      </span>
                    }
                    position="right center"
                    modal={true}
                  >
                    {(close) => (
                      <div>
                        <div
                          className="header"
                          style={{ marginBottom: "20px" }}
                        >
                          <h2>Edit event</h2>
                        </div>
                        <div className="content">
                          <h4>Title*</h4>
                          <Input
                            style={
                              titleEmpty
                                ? { border: "#d14e4e 1px solid" }
                                : null
                            }
                            type="text"
                            placeholder="Title"
                            onChange={(event) =>
                              this.changeEventTitle(event.target.value)
                            }
                            value={selectedEvent.title}
                          ></Input>
                          <h4>Start date*</h4>
                          <div
                            style={
                              startEmpty
                                ? {
                                    diplay: "table",
                                    border: "#d14e4e 1px solid",
                                  }
                                : {
                                    diplay: "table",
                                    border: "transparent 1px solid",
                                  }
                            }
                          >
                            <DateTimePicker
                              onChange={(date) => this.changeEventStart(date)}
                              value={new Date(selectedEvent.start)}
                            />
                          </div>
                          <h4>End date*</h4>
                          <div
                            style={
                              endEmpty
                                ? {
                                    diplay: "table",
                                    border: "#d14e4e 1px solid",
                                  }
                                : {
                                    diplay: "table",
                                    border: "transparent 1px solid",
                                  }
                            }
                          >
                            <DateTimePicker
                              onChange={(date) => this.changeEventEnd(date)}
                              value={new Date(selectedEvent.end)}
                            />
                          </div>
                          <h4>Location</h4>
                          <Input
                            type="text"
                            placeholder="Location"
                            onChange={(event) =>
                              this.changeEventLocation(event.target.value)
                            }
                            value={selectedEvent.location}
                          ></Input>

                          <h4>Organizer</h4>
                          <Dropdown
                            isSearchable
                            className="dropdown-speechaction"
                            options={contacts.map((cont) => ({
                              value: cont._id,
                              label: `${cont.name} ${cont.surname}`,
                            }))}
                            onChange={this.selectEventContact}
                            value={
                              selectedEvent.organizer === null
                                ? null
                                : {
                                    value:
                                      selectedEvent.organizer === undefined
                                        ? null
                                        : selectedEvent.organizer._id,
                                    label:
                                      selectedEvent.organizer === undefined
                                        ? ""
                                        : `${selectedEvent.organizer.surname} ${selectedEvent.organizer.name}`,
                                  }
                            }
                            placeholder="Select a contact"
                          />
                          <h4>Participants</h4>
                          <div className="card">
                            <div className="content">
                              {selectedEvent.participants === undefined
                                ? undefined
                                : selectedEvent.participants.map(
                                    (participant, index) => (
                                      <Badge
                                        style={{
                                          height: "25px",
                                          fontSize: "14px",
                                          paddingTop: "6px",
                                          marginRight: "10px",
                                        }}
                                        color="primary"
                                        key={participant._id}
                                      >
                                        {`${participant.surname} ${participant.name}`}{" "}
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor:
                                              "rgba(255, 0, 0, 0.5)",
                                          }}
                                          onClick={() =>
                                            this.removeEventParticipant(index)
                                          }
                                          className="pe-7s-close"
                                        ></i>
                                      </Badge>
                                    )
                                  )}
                            </div>
                          </div>
                          <Dropdown
                            isSearchable
                            className="dropdown-speechaction"
                            options={contacts
                              .filter((cont) => !participants.includes(cont))
                              .map((cont) => ({
                                value: cont._id,
                                label: `${cont.name} ${cont.surname}`,
                              }))}
                            onChange={this.addParticipant}
                            value={
                              organizer === null
                                ? null
                                : {
                                    value: organizer._id,
                                    label: `${organizer.surname} ${organizer.name}`,
                                  }
                            }
                            placeholder="Select a contact"
                          />
                          <br />
                          <br />
                          <br />
                          <div
                            style={{
                              position: "absolute",
                              bottom: "25px",
                              left: "35px",
                            }}
                          >
                            *Required fields
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              bottom: "25px",
                              right: "35px",
                            }}
                          >
                            <Button className="btn-secondary" onClick={close}>
                              Cancel
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              className="btn-primary"
                              onClick={() => this.updateEvent(close)}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>

                  <Popup
                    modal={true}
                    trigger={
                      <span>
                        <Button
                          style={{ marginRight: "5px", marginLeft: "5px" }}
                          disabled={selectedEvent === null}
                          className="btn btn-danger"
                        >
                          Delete event
                        </Button>
                      </span>
                    }
                  >
                    {(close) => (
                      <div>
                        <div
                          className="header"
                          style={{ marginBottom: "20px" }}
                        >
                          <h2>Confirmation</h2>
                        </div>
                        <div className="content">
                          <h4>
                            Do you really want to delete the event{" "}
                            {selectedEvent.title}?
                          </h4>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "25px",
                            right: "35px",
                          }}
                        >
                          <Button className="btn-secondary" onClick={close}>
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            className="btn-danger"
                            onClick={() => this.deleteEvent(close)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
                <br />
                <br />
                {calendar === null ||
                calendar.events === undefined ? undefined : (
                  <BigCalendar
                    events={calendar.events}
                    views={allViews}
                    defaultDate={new Date(2019, 6, 22)}
                    onSelectEvent={(event) => this.selectEvent(event)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

Calendar.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default Calendar;
