import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import axios from "../../utils/AxiosService";

class Debug extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      lang: "en",
      config: "",
      changesMade: false,
    };
  }

  componentDidMount() {
    //this.fetchConfig();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedRobot: nextProps.selectedRobot,
    });
    //this.fetchConfig();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchConfig = () => {
    const url = `/debug`;
    axios.get(url).then((result) => {
      this.setState({
        config: result.data.config,
      });
    });
  };

  sendConfig = () => {
    const { config } = this.state;
    const { selectedRobot } = this.props;
    const url = `/debug/robot/${selectedRobot._id}`;
    axios.post(url, { config }).then((result) => {
      if (result.data.success) {
        toast("Configuration sent", { type: "success" });
      } else {
        toast("Robot not online", { type: "error" });
      }
    });
  };

  changeConfig = (newConfig) => {
    this.setState({
      config: newConfig,
      changesMade: true,
    });
  };

  render() {
    const { config } = this.state;

    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Debug</h1>
                <p className="category">
                  Here you can send any configuration file directly to the
                  robot. The robot must be online.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ padding: "10px" }}>
              <div className="header">
                <h4>JSON configuration</h4>
              </div>
              <div className="content">
                <Input
                  type="textarea"
                  onChange={(event) => this.changeConfig(event.target.value)}
                  value={config}
                />
                <br />
                <Button onClick={this.sendConfig}>Send config</Button>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Debug;
