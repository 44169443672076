import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import Popup from "reactjs-popup";
import ModuleSelection from './ModuleSelection';
import SubmenuCreation from './SubmenuCreation';
import LanguagePicker from '../../../components/LanguagePicker';
import StorylineSelection from './StorylineSelection';
import ButtonLayout from './ButtonLayout'

class LevelView extends Component {
  constructor(props) {
    super(props);

    this.changeButtonStyle = this.changeButtonStyle.bind(this)
    this.setLang = this.setLang.bind(this);
    const { currentPath } = this.props;
    const grid = this.menuStructureToGrid(currentPath);

    this.state = {
      grid,
      selectedModule: null,
      language: 'en',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      grid: this.menuStructureToGrid(nextProps.currentPath),
    });
  }

  menuStructureToGrid = (currentPath) => {
    const grid = [
      [null, null, null],
      [null, null, null],
      [null, null, null],
      [null, null, null],
      [null, null, null],
      [null, null, null]
    ];
    currentPath[currentPath.length-1].items.forEach(item => {
      grid[item.positionY][item.positionX] = item;
    });
    return grid;
  }

  changeSubmenuName = (name, i, j) => {
    const { grid, language } = this.state;
    const { currentPath } = this.props;
    const tmpGrid = grid;
    currentPath[currentPath.length-1].items.find(item => item.positionX === j && item.positionY === i).submenu.name[language] = name;
    tmpGrid[i][j].submenu.name[language] = name;

    this.setState({
      grid: tmpGrid,
    });
  }

  changeStorylineName = (name, i, j) => {
    const { grid, language } = this.state;
    const { currentPath } = this.props;
    const tmpGrid = grid;
    currentPath[currentPath.length-1].items.find(item => item.positionX === j && item.positionY === i).storyline.name[language] = name;
    tmpGrid[i][j].storyline.name[language] = name;

    this.setState({
      grid: tmpGrid,
    });
  }

  changeButtonStyle = (i, j, fieldName, value) => {
    console.log(value)
    const { grid } = this.state;
    const { currentPath } = this.props;
    const tmpGrid = grid;
    currentPath[currentPath.length-1].items.find(item => item.positionX === j && item.positionY === i)[fieldName] = value;
    tmpGrid[i][j][fieldName] = value;

    this.setState({
      grid: tmpGrid,
    });
  }

  setLang = (lang) => {
    this.setState({
      language: lang,
    })
  }

  render() {
    const { lang, addLevelToPath, createLevel, selectModule, selectStoryline, updateModule, updateStoryline, selectedRobot, deleteCell, saveStructure, menuStructure } = this.props;
    const { grid, language } = this.state;
    
    return (
      <div>
        <table style={{ width: '100%', height: '600px', textAlign: 'center' }}>
          <tbody>
            {grid.map((row, i) => (
              <tr key={i}>
                {
                  row.map((cell, j) => (
                    <td key={j} style={{ border: '8px solid white', borderRadius: '15px' }}>
                      {cell === null ?
                        <div style={{ height: '100%', width: '100%', borderRadius: '12px', backgroundColor: '#f2f5f8', marginTop: 'auto', marginBottom: 'auto', display: 'flex', justifyContent: 'center', flexFlow: 'column' }}>
                          <Popup 
                            trigger={<span><Button style={{ marginRight: '5px', marginLeft: '5px', alignSelf: 'center' }} className="btn btn-primary">Add button</Button></span>}
                            position="center center"
                          >
                            <div style={{ padding: '10px', height: '100%', width: '100%' }}>
                              <div>
                                <Popup 
                                  trigger={<span><Button>Create submenu</Button></span>}
                                  position="right center"
                                  modal={true}
                                >
                                  <SubmenuCreation i={i} j={j} createLevel={createLevel} />
                                </Popup>
                              </div>
                              <br />
                              <div style={{ height: '1px', width: '100%', backgroundColor: 'black' }} />
                              <br />
                              <div>
                              <Popup 
                                trigger={<span><Button style={{ marginRight: '5px', marginLeft: '5px' }} className="btn btn-primary">Select module</Button></span>}
                                position="right center"
                                modal={true}
                              >
                                {close => (
                                  <ModuleSelection i={i} j={j} selectedRobot={selectedRobot} selectModule={selectModule} selectedModule={null} closeFunc={close.bind(this)} />
                                )}
                              </Popup>
                                
                              </div>
                              <br />
                              <div style={{ height: '1px', width: '100%', backgroundColor: 'black' }} />
                              <br />
                              <Popup 
                                trigger={<span><Button style={{ marginRight: '5px', marginLeft: '5px' }} className="btn btn-primary">Select storyline</Button></span>}
                                position="right center"
                                modal={true}
                              >
                                {close => (
                                  <StorylineSelection i={i} j={j} selectedRobot={selectedRobot} selectStoryline={(storyline) => selectStoryline(i, j, storyline)} selectedStoryline={null} closeFunc={close.bind(this)} />
                                )}
                              </Popup>
                            </div>
                          </Popup>
                        </div> :
                        <div style={{ height: '100%' }}>
                          {cell.itemType === 'submenu' ?
                          <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', borderRadius: '12px', backgroundColor: '#f2f5f8' }}>
                            <Popup 
                              trigger={<span><Button style={{ position: 'absolute', top: '10px', left: '10px' }} className="btn-success"><i className="pe-7s-paint-bucket" /></Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <ButtonLayout close={close} cell={cell} changeButtonStyle={(fieldName, value) => this.changeButtonStyle(i, j, fieldName, value)} saveStructure={saveStructure} menuStructure={menuStructure} />
                              )}
                            </Popup>
                            <Popup 
                              trigger={<span><Button style={{ position: 'absolute', top: '10px', right: '10px' }} className="btn-danger">X</Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <div className="header">
                                  <h4>Confirmation</h4>
                                  <p>Delete this submenu?</p>
                                  <Button onClick={close}>Cancel</Button>&nbsp;&nbsp;
                                  <Button className="btn-danger" onClick={() => deleteCell(i, j)}>Delete</Button>
                                  <br />
                                  <br />
                                </div>
                              )}
                            </Popup>
                            <Popup 
                              trigger={<span><Button style={{ position: 'absolute', top: '48px', left: '10px' }} className="btn-primary"><i className="pe-7s-pen"></i></Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <div className="header">
                                  <LanguagePicker setLang={this.setLang} />
                                  <h6>Submenu name</h6>
                                  <Input type="text" onChange={event => this.changeSubmenuName(event.target.value, i, j)} value={cell.submenu.name[language]}></Input>
                                  <br />
                                  <Button className="btn-danger" onClick={close}>Confirm</Button>
                                  <br />
                                </div>
                              )}
                            </Popup>
                            <div style={{ margin: 'auto' }}>
                              {cell.submenu.name[lang]}
                              <br />
                              <Button style={{ height: '34px' }} onClick={() => addLevelToPath(cell.submenu)}>Open submenu</Button>
                            </ div>
                          </div> : cell.itemType === 'module' ?
                          <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', flexFlow: 'column', borderRadius: '12px', backgroundColor: '#f2f5f8' }}>
                            <Popup 
                              trigger={<span><Button style={{ position: 'absolute', top: '10px', left: '10px' }} className="btn-success"><i className="pe-7s-paint-bucket" /></Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <ButtonLayout close={close} cell={cell} changeButtonStyle={(fieldName, value) => this.changeButtonStyle(i, j, fieldName, value)} saveStructure={saveStructure} menuStructure={menuStructure} />
                              )}
                            </Popup>
                            <Popup 
                              trigger={<span><Button style={{ position: 'absolute', top: '10px', right: '10px' }} className="btn-danger">X</Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <div className="header">
                                  <h4>Confirmation</h4>
                                  <p>Remove this module?</p>
                                  <Button onClick={close}>Cancel</Button>&nbsp;&nbsp;
                                  <Button className="btn-danger" onClick={() => deleteCell(i, j)}>Delete</Button>
                                  <br />
                                  <br />
                                </div>
                              )}
                            </Popup>
                            {cell.module.name[lang]}
                            <br />
                            
                            <Popup 
                              trigger={<span><Button>Change</Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <ModuleSelection i={i} j={j} selectedRobot={selectedRobot} selectModule={updateModule} selectedModule={cell} closeFunc={close.bind(this)} lang={lang} />
                              )}
                            </Popup>
                          </div> : 
                          <div style={{ height: '100%' }}>
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexFlow: 'column', borderRadius: '12px', backgroundColor: '#f2f5f8', height: '100%' }}>
                              <Popup 
                                trigger={<span><Button style={{ position: 'absolute', top: '10px', left: '10px' }} className="btn-success"><i className="pe-7s-paint-bucket" /></Button></span>}
                                position="right center"
                                modal={true}
                              >
                              {close => (
                                <ButtonLayout close={close} cell={cell} changeButtonStyle={(fieldName, value) => this.changeButtonStyle(i, j, fieldName, value)} saveStructure={saveStructure} menuStructure={menuStructure} />
                              )}
                            </Popup>
                            <Popup 
                              trigger={<span><Button style={{ position: 'absolute', top: '10px', right: '10px' }} className="btn-danger">X</Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <div className="header">
                                  <h4>Confirmation</h4>
                                  <p>Remove this storyline?</p>
                                  <Button onClick={close}>Cancel</Button>&nbsp;&nbsp;
                                  <Button className="btn-danger" onClick={() => deleteCell(i, j)}>Delete</Button>
                                  <br />
                                  <br />
                                </div>
                              )}
                            </Popup>
                            {cell.storyline.name[lang]}
                            <br />
                            
                            <Popup 
                              trigger={<span><Button>Change</Button></span>}
                              position="right center"
                              modal={true}
                            >
                              {close => (
                                <StorylineSelection i={i} j={j} selectedRobot={selectedRobot} selectStoryline={(storyline) => updateStoryline(i, j, storyline)} selectedStoryline={cell.storyline} closeFunc={close.bind(this)} />
                              )}
                            </Popup>
                          </div>
                          </div>
                          }
                        </div>
                      }
                    </td>
                  ))
                }
              </tr>
            ))

            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default LevelView;
