import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import axios from "../../utils/AxiosService";

class Persons extends React.Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.state = {
      persons: [],
      loaded: false,
      changesMade: false,
      lang: "en",
      editForAllLang: true,
    };
  }

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  componentDidMount() {
    this.fetchPersons();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  fetchPersons = () => {
    const url = `/persons`;
    axios.get(url).then((result) => {
      const persons = result.data.configuration;
      if (persons.persons.persons.length > 0) {
        const firstPersonData = persons.persons.persons[0].data;
        persons.persons.persons.forEach((person) => {
          if (person.data.length <= 0) {
            firstPersonData.forEach((data) => {
              if (
                person.data.findIndex((field) => field.key === data.key) < 0
              ) {
                person.data.push({
                  key: data.key,
                  value: {
                    en: "",
                    de: "",
                    fr: "",
                    it: "",
                  },
                });
              }
            });
          }
        });
      }
      this.setState({
        persons: result.data.configuration,
        loaded: true,
      });
    });
  };

  changeField = (key, value, personInd) => {
    const { persons } = this.state;
    const tempPersons = persons;
    tempPersons.persons.persons[personInd][key] = value;
    this.setState({
      persons: tempPersons,
      changesMade: true,
    });
  };

  changeFieldKey = (value, index) => {
    const { persons } = this.state;
    const tempPersons = persons;
    tempPersons.persons.persons.forEach(
      (person) => (person.data[index].key = value)
    );
    this.setState({
      persons: tempPersons,
      changesMade: true,
    });
  };

  changeFieldValue = (value, indexPerson, indexData) => {
    const { persons, lang, editForAllLang } = this.state;
    const tempPersons = persons;
    if (editForAllLang) {
      const langs = ["en", "de", "fr", "it"];
      langs.forEach(
        (curLang) =>
          (tempPersons.persons.persons[indexPerson].data[indexData].value[
            curLang
          ] = value)
      );
    } else {
      tempPersons.persons.persons[indexPerson].data[indexData].value[lang] =
        value;
    }
    this.setState({
      persons: tempPersons,
      changesMade: true,
    });
  };

  deleteField = (index) => {
    const { persons } = this.state;
    const tempPersons = persons;
    tempPersons.persons.persons.forEach((person) =>
      person.data.splice(index, 1)
    );
    this.setState({
      persons: tempPersons,
      changesMade: true,
    });
  };

  newField = () => {
    const { persons } = this.state;
    const tempPersons = persons;
    tempPersons.persons.persons.forEach((person) =>
      person.data.push({ key: "", value: { en: "", de: "", fr: "", it: "" } })
    );
    console.log(tempPersons);
    this.setState({
      persons: tempPersons,
      changesMade: true,
    });
  };

  savePersons = () => {
    const { selectedRobot } = this.props;
    const { persons } = this.state;
    const url = `/persons/${persons._id}/robots/${selectedRobot._id}`;
    persons.persons.persons.forEach((person) => {
      delete person.picture;
    });
    console.log(persons);
    axios.put(url, persons).then((response) => {
      if (response.status === 200) {
        toast("Persons saved", { type: "success" });
        this.setState({
          persons: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  render() {
    const { persons, loaded, lang, editForAllLang } = this.state;
    if (!loaded) {
      return <div></div>;
    }
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Persons</h1>
                <p className="category">
                  Here are all the people recognized by the robot
                </p>
                <div style={{ display: "flex", flexFlow: "row" }}>
                  <h4 className="category">Edit fields for all languages?</h4>
                  <Input
                    type="checkbox"
                    onChange={() =>
                      this.setState((prevState) => ({
                        editForAllLang: !prevState.editForAllLang,
                      }))
                    }
                    defaultChecked={true}
                    style={{ marginLeft: "20px", marginTop: "22px" }}
                  />
                </div>
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Button color="primary" onClick={() => this.savePersons()}>
                    Save changes
                  </Button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <div style={{ width: "100%" }}>
                  <Button
                    color="primary"
                    style={{ float: "right" }}
                    onClick={this.fetchPersons}
                  >
                    <i className="pe-7s-refresh-2"></i>
                  </Button>
                </div>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Face ID</th>
                      <th className="th-description">Picture</th>
                      {persons !== null && persons.persons.persons.length > 0
                        ? persons.persons.persons[0].data.map((data, index) => (
                            <th className="th-description">
                              <div style={{ display: "flex", flexFlow: "row" }}>
                                <Input
                                  style={{ width: "80%" }}
                                  type="text"
                                  value={data.key}
                                  onChange={(event) =>
                                    this.changeFieldKey(
                                      event.target.value,
                                      index
                                    )
                                  }
                                />
                                <Button
                                  style={{ width: "33px", height: "33px" }}
                                  color="danger"
                                  onClick={() => this.deleteField(index)}
                                >
                                  X
                                </Button>
                              </div>
                            </th>
                          ))
                        : undefined}
                      <th className="th-description">
                        <Button color="success" onClick={this.newField}>
                          Add field
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {persons === null
                      ? undefined
                      : persons.persons.persons.map((person, index) => (
                          <tr key={index}>
                            <td className="td-name">
                              <Input
                                type="text"
                                name="name"
                                readOnly
                                value={person.faceId}
                              />
                            </td>
                            <td className="td-name">
                              <img
                                height="100px"
                                src={`data:image/jpeg;base64,${person.picture}`}
                              />
                            </td>
                            {person.data.map((data, indData) => (
                              <td className="td-name">
                                <Input
                                  type="text"
                                  value={data.value[lang]}
                                  onChange={(event) =>
                                    this.changeFieldValue(
                                      event.target.value,
                                      index,
                                      indData
                                    )
                                  }
                                />
                              </td>
                            ))}
                            {/*<td className="td-actions">
                          <button type="button" onClick={() => this.deleteContact(index)} rel="tooltip" data-placement="left" title="" className="btn btn-danger btn-simple btn-icon " data-original-title="Remove contact">
                            <i className="fa fa-times"></i>
                          </button>
                        </td>*/}
                            <td></td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Persons;
