import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Pie } from 'react-chartjs-2';

const smileys = ["Very positive", 'Positive', 'Neutral', 'Negative', 'Very negative'];

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginations: [],
      page: 0,
    };
  }

  componentDidMount() {
    this.answersToPagination();
  }

  answersToPagination = () => {
    let paginations = [[]];
    let i = 0;
    const { question, questionType } = this.props;
    if (questionType === 'free_text') {
      question.answers.forEach((answer, index) => {
        paginations[i].push(answer);
        if ((index+1) % 5 === 0) {
          i++;
          paginations[i] = [];
        }
      });
      this.setState({
        paginations,
        loaded: true,
      });
    }
  }

  setPage = (page) => {
    this.setState({
      page,
    });
  }

  render() {
    const { questionType, question, options, data, selectedData, lang, index } = this.props;
    const { paginations, page, loaded } = this.state;
    switch (questionType) {
      case undefined :
        return (
          <div className="col-md-6" key={question.questionWritten}>
            <div className="card">
              <div className="header">
                <h4>{question.question}</h4>
              </div>
              <div className="content">
                <Pie data={data} height={250} width={300} options={options}/>
              </div>
              <div className="content">
                <h4>Correct answer : {selectedData[lang].questions[index].answersWritten[selectedData[lang].questions[index].correctAnswerId]}</h4>
              </div>
            </div>
          </div>
        );
      case 'single_choice':
        return (
          <div className="col-md-6" key={question.questionWritten}>
            <div className="card">
              <div className="header">
                <h4>{question.question}</h4>
              </div>
              <div className="content">
                <Pie data={data} height={250} width={300} options={options}/>
              </div>
            </div>
          </div>
        );
      case 'smileys':
        data.labels = smileys;
        return (
          <div className="col-md-6" key={question.questionWritten}>
            <div className="card">
              <div className="header">
                <h4>{question.question}</h4>
              </div>
              <div className="content">
                <Pie data={data} height={250} width={300} options={options}/>
              </div>
            </div>
          </div>
        );
      case 'free_text':
        return (
          <div className="col-md-6" key={question.questionWritten}>
            <div className="card">
              <div className="header">
                <h4>{question.question}</h4>
              </div>
              <div className="content">
                {!loaded ? undefined : paginations[page].map((answer, index) => (
                  <div key={index} style={{ height: '45px' }}>
                    {`"${answer}"`}
                    {index === paginations[page].length-1 ? undefined : <div style={{ backgroundColor: '#9c9c9c', height: '1px', width: '100%' }} />}
                  </div>
                ))}
                <Pagination aria-label="Survey answers">
                  <PaginationItem disabled={page === 0}>
                    <PaginationLink className="pe-7s-angle-left" onClick={page === 0 ? null : () => this.setPage(page-1)} href="#" />
                  </PaginationItem>
                  {paginations.map((curPage, index) => (
                    <PaginationItem active={index === curPage} key={index}>
                      <PaginationLink onClick={() => this.setPage(index)} href="#">
                        {index+1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={page === paginations.length-1}>
                    <PaginationLink className="pe-7s-angle-right" onClick={page === paginations.length-1 ? null : () => this.setPage(page+1)} href="#" />
                  </PaginationItem>
                </Pagination>
              </div>
            </div>
          </div>
        );
      default:
        return <div></div>
    }
  }
}

export default Statistics;
