import React, { Component } from "react";
import Dropdown from "react-select";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import LanguagePicker from "../../../components/LanguagePicker";
import Storyline from "../../../components/Storyline";
import languages from "../../../constants/languages";
import axios from "../../../utils/AxiosService";

class StorylineSelection extends Component {
  constructor(props) {
    super(props);

    this.setLang = this.setLang.bind(this);
    const { selectedStoryline } = this.props;

    this.state = {
      lang: "en",
      selectedStoryline:
        selectedStoryline === null
          ? {
              name: {
                en: "",
                de: "",
                fr: "",
                it: "",
              },
              storylineId: "",
            }
          : selectedStoryline,
      storylines: [],
    };
  }

  componentDidMount() {
    this.fetchStorylines();
  }

  fetchStorylines = () => {
    const url = `/storylines`;
    axios.get(url).then((response) => {
      this.setState({
        storylines: response.data.map((config) => config),
      });
    });
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  changeStorylineName = (name) => {
    const { selectedStoryline, lang } = this.state;
    let tempStoryline = selectedStoryline;
    tempStoryline.name[lang] = name;
    this.setState({
      selectedStoryline: tempStoryline,
    });
  };

  selectStoryline = (storyline) => {
    const { selectedStoryline } = this.state;
    let tempStoryline = selectedStoryline;
    tempStoryline.storylineId = storyline;
    languages.forEach((lang) => {
      if (tempStoryline.name[lang] === "") {
        tempStoryline.name[lang] = storyline.name;
      }
    });

    this.setState({
      selectedStoryline: tempStoryline,
    });
  };

  saveStoryline = () => {
    const { selectStoryline, closeFunc } = this.props;
    const { selectedStoryline } = this.state;
    selectStoryline(selectedStoryline);
    closeFunc();
  };

  render() {
    const { lang, storylines, selectedStoryline } = this.state;
    const { i, j, selectedRobot } = this.props;

    return (
      <div>
        <LanguagePicker setLang={this.setLang} />
        <h6>Button name</h6>
        <Input
          type="text"
          onChange={(event) =>
            this.changeStorylineName(event.target.value, i, j)
          }
          value={selectedStoryline.name[lang]}
        ></Input>
        <h6>Storyline</h6>
        <div style={{ display: "flex", flexFlow: "row", width: "100%" }}>
          <div style={{ width: "90%" }}>
            <Dropdown
              options={storylines
                .filter((storyline) => storyline !== undefined)
                .map((storyline) => ({
                  label: storyline.name,
                  value: storyline,
                }))}
              onChange={(value) => this.selectStoryline(value.value)}
              value={
                selectedStoryline.storylineId === null ||
                selectedStoryline.storylineId === undefined
                  ? null
                  : {
                      label: selectedStoryline.storylineId.name,
                      value: selectedStoryline.storylineId._id,
                    }
              }
              placeholder="Select a storyline"
            />
          </div>
          <Popup
            trigger={
              <span>
                <Button
                  style={{ height: "38px", marginRight: "4px" }}
                  className="btn btn-primary"
                  disabled={selectedStoryline.storylineId.name === undefined}
                >
                  <i className="pe-7s-pen"></i>
                </Button>
              </span>
            }
            position="left center"
            modal={true}
          >
            {(close) => (
              <Storyline
                selectedRobot={selectedRobot}
                storyline={selectedStoryline.storylineId}
                selectStoryline={(storyline) => this.selectStoryline(storyline)}
                fetchStoryline={this.fetchStorylines}
                close={close}
              />
            )}
          </Popup>
          <Popup
            trigger={
              <span>
                <Button style={{ height: "38px" }} className="btn btn-success">
                  <i className="pe-7s-plus"></i>
                </Button>
              </span>
            }
            position="left center"
            modal={true}
          >
            {(close) => (
              <Storyline
                selectedRobot={selectedRobot}
                storyline={null}
                newStory
                selectStoryline={(storyline) => this.selectStoryline(storyline)}
                close={close}
                fetchStoryline={this.fetchStorylines}
              />
            )}
          </Popup>
        </div>
        <br />
        <Button
          color="success"
          disabled={
            selectedStoryline.name === "" ||
            selectedStoryline.storylineId.name === undefined
          }
          onClick={this.saveStoryline}
        >
          Save
        </Button>
        <br />
      </div>
    );
  }
}

export default StorylineSelection;
