import cx from "classnames";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Collapse, Input } from "reactstrap";
import { categories } from "../../components/Storyline/constants/categories";
import axios from "../../utils/AxiosService";

class Storylines extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      storylines: [],
      newStoryline: null,
      redirect: false,
      collapse: categories.map(() => false),
      storylineFilter: "",
    };
  }

  componentDidMount() {
    this.fetchStorylines();
  }

  fetchStorylines = () => {
    const url = `/storylines`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          storylines: response.data,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  newStoryline = () => {
    const url = `/storylines`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newStoryline: response.data,
          redirect: true,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  deleteStoryline = (id) => {
    const { selectedRobot } = this.state;
    const url = `/storylines/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          storylines: response.data.map((config) => config.configuration),
          selectedStoryline: null,
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  render() {
    const { storylines, redirect, newStoryline, storylineFilter, collapse } =
      this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/storyline",
            state: {
              storyline: newStoryline,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Storylines</h1>
                <p className="category">
                  Here you can select a storyline to edit or create a new one
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <Button
                  onClick={this.newStoryline}
                  className="btn-success"
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  New storyline <i className="pe-7s-plus"></i>
                </Button>
                <h4 className="title">
                  Select a storyline to edit or create a new one
                </h4>
              </div>
              <div className="content">
                <Input
                  type="text"
                  onChange={(event) => {
                    this.setState({
                      storylineFilter: event.target.value,
                      collapse: collapse.map(() => true),
                    });
                  }}
                  value={storylineFilter}
                  placeholder="Search storylines"
                />
                {categories.map((category, index) => (
                  <div key={category}>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState((state) => ({
                          collapse: state.collapse.map((col, ind) =>
                            index === ind ? !col : col
                          ),
                        }))
                      }
                    >
                      <h4>
                        {category}
                        <span
                          className={cx("pe-7s-angle-down collapse-arrow", {
                            active: collapse[index],
                          })}
                        ></span>
                      </h4>
                    </div>
                    <Collapse isOpen={this.state.collapse[index]}>
                      <table className="table table-bigboy">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th width="40px">Edit</th>
                            <th width="40px">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {storylines === null
                            ? undefined
                            : storylines
                                .filter((storyline) =>
                                  storyline.name
                                    .toLowerCase()
                                    .includes(storylineFilter.toLowerCase())
                                )
                                .filter(
                                  (storyline) => storyline.category === category
                                )
                                .map((storyline) => (
                                  <tr key={storyline._id}>
                                    <td className="td-name">
                                      {storyline.name}
                                    </td>
                                    <td className="td-name">
                                      <Button
                                        tag={Link}
                                        to={{
                                          pathname: "/storyline",
                                          state: {
                                            storyline: storyline,
                                          },
                                        }}
                                        color="primary"
                                      >
                                        <i className="pe-7s-pen"></i>
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        onClick={() =>
                                          this.deleteStoryline(storyline._id)
                                        }
                                        color="danger"
                                      >
                                        <i className="pe-7s-trash"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                        </tbody>
                      </table>
                    </Collapse>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Storylines;
