import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Button, Label, FormGroup, Form
} from 'reactstrap';
import TextPreview from '../../../components/TextPreview';
import * as keywords from '../constants/keywords';
import Popup from 'reactjs-popup';

class SpeechInput extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      cursorIndex: 0,
      pauseValue: 500,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.addPause = this.addPause.bind(this);
    this.changePauseText = this.changePauseText.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
  }

  addPause() {
    const { cursorIndex, pauseValue } = this.state;
    const { text, onChange } = this.props;
    const newText = `${text.slice(0, cursorIndex)}${keywords.pause(pauseValue)}${text.slice(cursorIndex)}`;
    onChange({ target: { value: newText } });
  }

  changePauseText(event) {
    event.preventDefault();
    let { value } = event.target;
    if (value >= 0) {
      value = value.replace(/[^0-9]/g, '');
      this.setState({
        pauseValue: value,
      });
    }
  }

  handleInputChange(event) {
    const { onChange } = this.props;
    this.setState({
      cursorIndex: event.target.selectionStart,
    });
    onChange(event);
  }

  changeSelection(event) {
    this.setState({
      cursorIndex: event.target.selectionStart,
    });
  }

  render() {
    const { text, robotId } = this.props;
    const { pauseValue } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <div className="action-buttons">
          <Form style={{ width: '100%' }}>
            <FormGroup>
              <Label for="textInput">Slide text</Label>
              <div style={{ display: 'flex', flexFlow: 'row' }}>
                <Popup
                  trigger={<div style={{ width: '100%' }}><Input
                    type="textarea"
                    name="slide-text"
                    id="slide-text"
                    value={text}
                    onChange={this.handleInputChange}
                    onClick={this.changeSelection}
                  /></div>}
                  position="top center"
                  on="hover"
                >
                  <div>
                    <Label for="pauseDuration">Insert pause in speech (ms)</Label>
                    <div style={{ display: 'flex', flexFlow: 'row', width: '90%' }}>
                      <Input
                        type="number"
                        name="pause"
                        id="pauseDuration"
                        placeholder="Pause duration"
                        value={pauseValue}
                        onChange={this.changePauseText}
                      />
                      <Button onClick={this.addPause}>Insert</Button>
                    </div>
                  </div>
                </Popup>
                <div style={{  }}>
                  <TextPreview text={text} robotId={robotId} />
                </div>
              </div>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default SpeechInput;
