import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import saveAs from "save-as";
import axios from "../../utils/AxiosService";
import AddAccount from "./components/AddAccount";
import AddRobots from "./components/AddRobots";
import LinkRobots from "./components/LinkRobots";
import { modules } from "./constants/modules";

class AdminConsole extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.fetchRobots = this.fetchRobots.bind(this);
    this.state = {
      companies: [],
      filteredCompanies: [],
      selectedCompany: null,
      selectedRobot: null,
      activeTab: "1",
      searchText: "",
      robots: [],
      changesMade: false,
      companyName: "",
    };
  }

  componentDidMount() {
    this.fetchCompanies();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  fetchCompanies = () => {
    const url = `/companies`;
    axios.get(url).then((result) => {
      this.setState({
        companies: result.data,
        filteredCompanies: result.data,
      });
    });
  };

  fetchRobots = () => {
    const { selectedCompany } = this.state;
    const url = `/robots/companies/${selectedCompany._id}`;
    axios.get(url).then((response) => {
      this.setState({
        robots: response.data,
      });
    });
  };

  addCompany = () => {
    const { companyName } = this.state;
    const url = `/companies`;
    axios.post(url, { name: companyName }).then((response) => {
      if (response.status === 200) {
        toast("Company created", { type: "success" });
        this.fetchCompanies();
      } else {
        toast("Server error", { type: "error" });
      }
    });
  };

  selectCompany = (company) => {
    this.setState(
      {
        selectedCompany: company.value,
      },
      this.fetchRobots
    );
  };

  changeSearchText(text) {
    const { companies } = this.state;
    this.setState({
      searchText: text,
      filteredCompanies: companies.filter((company) =>
        company.name.toLowerCase().includes(text.toLowerCase())
      ),
    });
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  changeRobotName = (name, index) => {
    const { robots } = this.state;
    const tmpRobots = robots;
    tmpRobots[index].name = name;

    this.setState({
      robots: tmpRobots,
      changesMade: true,
    });
  };

  deleteRobot = (robotId) => {
    const { updateRobotList } = this.props;
    const { selectedCompany } = this.state;
    const url = `/robots/${robotId}/companies/${selectedCompany._id}`;
    axios.delete(url).then((response) => {
      if (response.data.success) {
        toast("Robot deleted", { type: "success" });
        updateRobotList();
        this.fetchRobots();
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  saveRobots = () => {
    const { robots } = this.state;
    const url = `/robots`;
    axios.put(url, { robots }).then((response) => {
      toast("Robots saved", { type: "success" });
      this.setState({
        robots: response.data,
        changesMade: false,
      });
    });
  };

  setModule = (mod) => {
    const { selectedCompany } = this.state;
    const tmpCompany = selectedCompany;
    const modIndex = selectedCompany.components.findIndex(
      (comp) => comp.name === mod.value
    );
    if (modIndex >= 0) {
      tmpCompany.components.splice(modIndex, 1);
    } else {
      tmpCompany.components.push({ name: mod.value });
    }
    this.setState({
      selectedCompany: tmpCompany,
      changesMade: true,
    });
  };

  saveCompany = () => {
    const { selectedCompany } = this.state;
    const url = `/companies`;
    axios.put(url, { company: selectedCompany }).then((response) => {
      toast("Account saved", { type: "success" });
      this.setState({
        selectedCompany: response.data,
        changesMade: false,
      });
    });
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  uploadAnimation = (event) => {
    const url = `/debug/animations`;
    const formData = new FormData();
    formData.append("myAnimation", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .put(url, formData, config)
      .then((response) => {
        toast("Upload successful", { type: "success" });
      })
      .catch((err) => {
        toast("Upload failed", { type: "error" });
      });
  };

  downloadLogs = (robot) => {
    const url = `/debug/logs/${robot._id}`;
    axios
      .get(url)
      .then((response) => {
        response.data.forEach((file) => {
          const blob = new Blob([file.data], {
            type: "text/plain;charset=utf-8",
          });
          saveAs(blob, file.name);
        });
      })
      .catch((err) => {
        toast("Download failed", { type: "error" });
      });
  };

  uploadYeowelcome = (event) => {
    const url = `/debug/yeowelcome/upload`;
    const formData = new FormData();
    formData.append("yeowelcome", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .put(url, formData, config)
      .then((response) => {
        toast("Upload successful", { type: "success" });
      })
      .catch((err) => {
        toast("Upload failed", { type: "error" });
      });
  };

  render() {
    const { selectedCompany, companies, robots, changesMade, selectedRobot } =
      this.state;
    const { updateRobotList } = this.props;

    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Admin console</h1>
                <p className="category">
                  Here you can add new robots, link them to an account or edit
                  them.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ padding: "10px" }}>
              <div className="header">
                <Popup
                  trigger={
                    <span>
                      <Button
                        style={{
                          position: "absolute",
                          top: "25px",
                          right: "60px",
                        }}
                        className="btn btn-success"
                      >
                        <i className="pe-7s-plus"></i> New company
                      </Button>
                    </span>
                  }
                  position="left center"
                  modal={true}
                >
                  {(close) => (
                    <div
                      className="companyContainer"
                      style={{ margin: "30px", height: "100%" }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="header">
                              <h1 className="title">New company</h1>
                              <p className="category">
                                Create a new company here.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="header">
                              <h3 className="title">Company name</h3>
                              <Input
                                type="text"
                                onChange={(event) =>
                                  this.setState({
                                    companyName: event.target.value,
                                  })
                                }
                                name="companyName"
                                id="name"
                                placeholder="Add your company name"
                                style={{ marginBottom: "30px" }}
                              ></Input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Button
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          right: "105px",
                        }}
                        onClick={this.addCompany}
                        color="secondary"
                      >
                        Save
                      </Button>
                      <Button
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          right: "30px",
                        }}
                        onClick={close}
                        color="secondary"
                      >
                        Close
                      </Button>
                      <NotificationSystem
                        ref={(ref) => (this.notificationSystem = ref)}
                      />
                    </div>
                  )}
                </Popup>
                <h4 className="title">Company</h4>
              </div>
              <div className="content">
                <Dropdown
                  isSearchable
                  className="dropdown-speechaction"
                  options={companies.map((company) => ({
                    label: company.name,
                    value: company,
                  }))}
                  onChange={this.selectCompany}
                  value={
                    selectedCompany === null ? null : selectedCompany.label
                  }
                  placeholder="Select a company"
                />
              </div>
            </div>
          </div>
        </div>
        {selectedCompany !== null ? (
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="header">
                  <h4 className="title">Create an account</h4>
                </div>
                <AddAccount
                  changesMade={changesMade}
                  companyId={selectedCompany._id}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="header">
                  <h4 className="title">Create a robot</h4>
                </div>
                <AddRobots
                  updateRobotList={updateRobotList}
                  changesMade={changesMade}
                  fetchRobots={this.fetchRobots}
                  companyId={selectedCompany._id}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="header">
                  <h4 className="title">Link robot to company</h4>
                </div>
                <LinkRobots
                  updateRobotList={updateRobotList}
                  changesMade={changesMade}
                  fetchRobots={this.fetchRobots}
                  companyId={selectedCompany._id}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h4 className="title">
                    Please select an account in the list
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedCompany === null ? undefined : (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h4>{selectedCompany.name}'s robots</h4>
                </div>
                <div className="content">
                  <table className="table table-bigboy">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>ID</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {robots.length <= 0
                        ? undefined
                        : robots.map((robot, index) => (
                            <tr key={index}>
                              <td>
                                <Input
                                  onChange={(event) =>
                                    this.changeRobotName(
                                      event.target.value,
                                      index
                                    )
                                  }
                                  type="text"
                                  name="name"
                                  id="name"
                                  placeholder="Robot name"
                                  value={robot.name}
                                />
                              </td>
                              <td>{robot._id}</td>
                              <td>
                                <Button
                                  className="btn-danger"
                                  onClick={() =>
                                    this.deleteRobot(robot._id, index)
                                  }
                                >
                                  X
                                </Button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  <Button color="primary" onClick={this.saveRobots}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedCompany === null ? undefined : (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h4>{selectedCompany.name}'s modules</h4>
                </div>
                <div className="content">
                  <table className="table table-bigboy">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modules.map((mod, index) => (
                        <tr key={index}>
                          <td>{mod.label}</td>
                          <td>
                            <Input
                              type="checkbox"
                              onChange={() => this.setModule(mod)}
                              defaultChecked={
                                selectedCompany.components.findIndex(
                                  (comp) => comp.name === mod.value
                                ) >= 0
                              }
                            ></Input>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Button color="primary" onClick={this.saveCompany}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h4>Upload animations</h4>
              </div>
              <div className="content">
                <label htmlFor="slide-file" style={{ marginTop: "0px" }}>
                  <input
                    id="slide-file"
                    type="file"
                    onChange={this.uploadAnimation}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h4>Robot logs</h4>
              </div>
              <div
                className="content"
                style={{ display: "flex", flexFlow: "row" }}
              >
                <div style={{ width: "90%" }}>
                  <Dropdown
                    isSearchable
                    className="dropdown-speechaction"
                    options={robots.map((robot) => ({
                      label: `${robot.name} - ${robot._id}`,
                      value: robot,
                    }))}
                    onChange={(value) =>
                      this.setState({ selectedRobot: value.value })
                    }
                    value={selectedRobot === null ? null : selectedRobot.label}
                    placeholder="Select a robot"
                  />
                </div>
                <div>
                  <Button
                    disabled={selectedRobot === null}
                    onClick={() => this.downloadLogs(selectedRobot)}
                    color="primary"
                    style={{ marginLeft: "5px", height: "38px" }}
                  >
                    Download
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h4>Upload a new Yeowelcome version</h4>
              </div>
              <div className="content">
                <label htmlFor="slide-file" style={{ marginTop: "0px" }}>
                  <input
                    id="slide-file"
                    type="file"
                    onChange={this.uploadYeowelcome}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default AdminConsole;
