import React, { Component } from 'react';
import update from 'react-addons-update';
import AnimationItem from './AnimationItem';
import { DropTarget } from 'react-dnd';
import { Input } from 'reactstrap'

class AnimationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animations: props.list,
            animationsFilter: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.list !== this.props.list) {
            this.setState({
                animations: nextProps.list
            })
        }
    }

    pushAnimation(animation) {
        const { pushAnimation } = this.props
        if (pushAnimation === undefined) {
            this.setState(update(this.state, {
                animations: {
                    $push: [animation]
                }
            }));
        } else {
            pushAnimation(animation)
        }
    }

    removeAnimation(index) {
        const { removeAnimation } = this.props
        if (removeAnimation === undefined) {
            this.setState(update(this.state, {
                animations: {
                    $splice: [
                        [index, 1]
                    ]
                }
            }));
        } else {
            removeAnimation(index)
        }
    }

    moveAnimation(dragIndex, hoverIndex) {
        const { moveAnimation } = this.props
        if (moveAnimation === undefined) {
            const { animations } = this.state;
            const dragAnimation = animations[dragIndex];

            this.setState(update(this.state, {
                animations: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragAnimation]
                    ]
                }
            }));
        } else {
            moveAnimation(dragIndex, hoverIndex)
        }
    }

    render() {
        const { animations, animationsFilter } = this.state;
        const { connectDropTarget, playAnimation } = this.props;

        return connectDropTarget(
            <div style={{ width: '50%', border: '3px solid #e0ecff', borderRadius: '3px', padding: '5px', margin: '3px', height: '400px', overflow: 'scroll' }}>
                <table className="table table-bigboy">
                    <thead style={{ marginTop: '0px' }}>
                        <tr>
                            <th width="3%"></th>
                            <th width="85%">Name</th>
                            <th width="12%">Play</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '100%' }}>
                        <tr>
                            <td colSpan="2">
                                <Input onChange={(event) => this.setState({ animationsFilter: event.target.value })} value={animationsFilter} placeholder="Filter animations..." />
                            </td>
                        </tr>
                        {animations.filter(animation => animation.label.toLowerCase().includes(animationsFilter.toLowerCase())).map((animation, i) => {
                            return (
                                <AnimationItem
                                    key={i}
                                    index={i}
                                    listId={this.props.id}
                                    animation={animation}
                                    removeAnimation={this.removeAnimation.bind(this)}
                                    moveAnimation={this.moveAnimation.bind(this)}
                                    playAnimation={playAnimation}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

const animationTarget = {
	drop(props, monitor, component ) {
		const { id } = props;
		const sourceObj = monitor.getItem();		
		if ( id !== sourceObj.listId ) component.pushAnimation(sourceObj.animation);
		return {
			listId: id
		};
	}
}

export default DropTarget("ANIMATION", animationTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop()
}))(AnimationList);