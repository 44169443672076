import React, { Component } from 'react';
import update from 'react-addons-update';
import StorylineItem from './StorylineItem';
import { DropTarget } from 'react-dnd';
import { Input } from 'reactstrap'

class StorylineList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storylines: props.list,
            storylinesFilter: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.list !== this.props.list) {
            this.setState({
                storylines: nextProps.list
            })
        }
    }

    pushStoryline(storyline) {
        const { pushStoryline } = this.props
        if (pushStoryline === undefined) {
            this.setState(update(this.state, {
                storylines: {
                    $push: [storyline]
                }
            }));
        } else {
            pushStoryline(storyline)
        }
    }

    removeStoryline(index) {
        const { removeStoryline } = this.props
        if (removeStoryline === undefined) {
            this.setState(update(this.state, {
                storylines: {
                    $splice: [
                        [index, 1]
                    ]
                }
            }));
        } else {
            removeStoryline(index)
        }
    }

    moveStoryline(dragIndex, hoverIndex) {
        const { moveStoryline } = this.props
        if (moveStoryline === undefined) {
            const { storylines } = this.state;
            const dragStoryline = storylines[dragIndex];

            this.setState(update(this.state, {
                storylines: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragStoryline]
                    ]
                }
            }));
        } else {
            moveStoryline(dragIndex, hoverIndex)
        }
    }

    render() {
        const { storylines, storylinesFilter } = this.state;
        const { connectDropTarget, playStoryline } = this.props;

        return connectDropTarget(
            <div style={{ width: '50%', border: '3px solid #e0ecff', borderRadius: '3px', padding: '5px', margin: '3px', height: '400px', overflow: 'scroll' }}>
                <table className="table table-bigboy">
                    <thead style={{ marginTop: '0px' }}>
                        <tr>
                            <th width="3%"></th>
                            <th width="85%">Name</th>
                            <th width="12%">Play</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '100%' }}>
                        <tr>
                            <td colSpan="2">
                                <Input onChange={(event) => this.setState({ storylinesFilter: event.target.value })} value={storylinesFilter} placeholder="Filter storylines..." />
                            </td>
                        </tr>
                        {storylines.filter(storyline => storyline.label.toLowerCase().includes(storylinesFilter.toLowerCase())).map((storyline, i) => {
                            return (
                                <StorylineItem
                                    key={i}
                                    index={i}
                                    listId={this.props.id}
                                    storyline={storyline}
                                    removeStoryline={this.removeStoryline.bind(this)}
                                    moveStoryline={this.moveStoryline.bind(this)}
                                    playStoryline={playStoryline}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

const storylineTarget = {
	drop(props, monitor, component ) {
		const { id } = props;
		const sourceObj = monitor.getItem();		
		if ( id !== sourceObj.listId ) component.pushStoryline(sourceObj.storyline);
		return {
			listId: id
		};
	}
}

export default DropTarget("STORYLINE", storylineTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop()
}))(StorylineList);