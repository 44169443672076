import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Popup from "reactjs-popup";
import { Button } from "reactstrap";
import BarcodeCreator from "../../components/BarcodeCreator";
import axios from "../../utils/AxiosService";

class Barcodes extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      barcodes: [],
      newBarcode: null,
      error: null,
      message: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.fetchBarcodes();
  }

  fetchBarcodes = () => {
    const url = `/barcodes`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          barcodes: response.data,
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  newBarcode = () => {
    const url = `/barcodes`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newBarcode: response.data,
          redirect: true,
        });
      } else {
        this.setState({
          error: "Network error",
        });
      }
    });
  };

  deleteBarcode = (id) => {
    const { selectedRobot } = this.state;

    const url = `/barcodes/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          barcodes: response.data.map((config) => config.configuration),
          selectedBarcode: null,
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  render() {
    const { barcodes, redirect, newBarcode } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/barcode",
            state: {
              barcode: newBarcode,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Barcodes</h1>
                <p className="category">
                  Here you can select a barcode to edit or create a new one
                </p>
                <Popup
                  trigger={
                    <Button
                      color="primary"
                      style={{
                        position: "absolute",
                        top: "25px",
                        right: "40px",
                      }}
                    >
                      Generate barcode <i className="pe-7s-plus"></i>
                    </Button>
                  }
                  position="right center"
                  modal={true}
                >
                  {(close) => <BarcodeCreator close={close} />}
                </Popup>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <Button
                  onClick={this.newBarcode}
                  className="btn-success"
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  New barcode <i className="pe-7s-plus"></i>
                </Button>
                <h4 className="title">
                  Select a barcode to edit or create a new one
                </h4>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width="40px">Edit</th>
                      <th width="40px">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {barcodes === null
                      ? undefined
                      : barcodes.map((barcode) => (
                          <tr key={barcode._id}>
                            <td className="td-name">
                              {barcode.en.name !== ""
                                ? barcode.en.name
                                : barcode.de.name !== ""
                                ? barcode.de.name
                                : barcode.fr.name !== ""
                                ? barcode.fr.name
                                : barcode.it.name}
                            </td>
                            <td className="td-name">
                              <Button
                                tag={Link}
                                to={{
                                  pathname: "/barcode",
                                  state: {
                                    barcode: barcode,
                                  },
                                }}
                                color="primary"
                              >
                                <i className="pe-7s-pen"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => this.deleteBarcode(barcode._id)}
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Barcodes;
