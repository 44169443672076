export const triggerTypes = [
    { label: 'None', value: 'NONE' },
    { label: 'Text', value: 'TEXT' },
    { label: 'Event', value: 'EVENT' },
];

export const targetTypes = [
    { label: 'Robot', value: 'ROBOT' },
    { label: 'SAP', value: 'SAP' },
    { label: 'Dialogflow', value: 'DIALOGFLOW' },
];

export const notifTargetTypes = [
    { label: 'None', value: '' },
    { label: 'Waiting Room', value: 'WAITINGROOM' },
];