import React from 'react';
import { 
  Nav, NavItem, NavLink,
} from 'reactstrap';
import germany from '../assets/images/germany.png';
import france from '../assets/images/france.png';
import unitedKingdom from '../assets/images/united-kingdom.png';
import italy from '../assets/images/italy.png';

class LanguagePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'en',
    };
  }

  setLang = (lang) => {
    const { setLang } = this.props;
    this.setState({
      lang,
    })
    setLang(lang);
  }

  render() {
    const { lang } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <Nav tabs>
              <NavItem>
                <NavLink
                  onClick={() => { this.setLang('en'); }}
                  style={lang === 'en' ? { backgroundColor: '#e2e2e2' } : null}
                >
                  <img width="45px" src={unitedKingdom} alt="united kingdom" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => { this.setLang('de'); }}
                  style={lang === 'de' ? { backgroundColor: '#e2e2e2' } : null}
                >
                  <img width="45px" src={germany} alt="germany" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => { this.setLang('fr'); }}
                  style={lang === 'fr' ? { backgroundColor: '#e2e2e2' } : null}
                >
                  <img width="45px" src={france} alt="france" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => { this.setLang('it'); }}
                  style={lang === 'it' ? { backgroundColor: '#e2e2e2' } : null}
                >
                  <img width="45px" src={italy} alt="italy" />
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
    )
  }
}

export default LanguagePicker;
