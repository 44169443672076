import cx from "classnames";
import { struct } from "pb-util";
import React, { Component } from "react";
import update from "react-addons-update";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Badge, Button, Collapse, Input } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import SpeechAction from "../../components/SpeechAction";
import axios from "../../utils/AxiosService";
import { scopes, types } from "./constants/constants";

const inputStyle = {
  marginBottom: "5px",
  maxWidth: "50%",
};

class Chatbot extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.changePayloadResponse = this.changePayloadResponse.bind(this);
    const { chatbot } = this.props.location.state;
    this.state = {
      chatbot,
      changesMade: false,
      intents: [],
      lang: "en",
      collapse: [],
      changedIntents: [],
      newIntentName: "",
    };
  }

  componentDidMount() {
    const { chatbot } = this.state;
    if (
      chatbot.json.client_email !== "" &&
      chatbot.json.project_id !== "" &&
      chatbot.json.private_key !== ""
    ) {
      this.fetchIntents();
    }
  }

  fetchIntents = () => {
    const { chatbot, lang } = this.state;
    const url = `/chatbots/${chatbot._id}/intents/${lang}`;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          chatbot: response.data.chatbot,
          intents: response.data.intents,
          collapse: response.data.intents.map(() => false),
        });
      })
      .catch((error) => {
        toast("Server Error", { type: "error" });
      });
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  changeNewIntentName = (name) => {
    this.setState({
      newIntentName: name,
    });
  };

  changeName = (value) => {
    const { chatbot } = this.state;
    const tempChatbot = chatbot;
    tempChatbot.name = value;
    this.setState({
      chatbot: tempChatbot,
      changesMade: true,
    });
  };

  changeScope = (value) => {
    const { chatbot } = this.state;
    const tempChatbot = chatbot;
    tempChatbot.scope = value;
    this.setState({
      chatbot: tempChatbot,
      changesMade: true,
    });
  };

  changeUrl = (value) => {
    const { chatbot } = this.state;
    const tempChatbot = chatbot;
    tempChatbot.url = value;
    this.setState({
      chatbot: tempChatbot,
      changesMade: true,
    });
  };

  changeType = (value) => {
    const { chatbot } = this.state;
    const tempChatbot = chatbot;
    tempChatbot.type = value;
    this.setState({
      chatbot: tempChatbot,
      changesMade: true,
    });
  };

  changeTextResponse = (response, intentInd, messageInd, respInd) => {
    const { intents, changedIntents } = this.state;
    const tmpChangedIntents = changedIntents;
    if (changedIntents.findIndex((ind) => ind === intentInd) === -1) {
      tmpChangedIntents.push(intentInd);
    }
    const tmpIntents = intents;
    tmpIntents[intentInd].messages[messageInd].text.text[respInd] = response;
    this.setState({
      intents: tmpIntents,
      changedIntents: tmpChangedIntents,
      changesMade: true,
    });
  };

  changePayloadResponse = (response, intentInd, messageInd) => {
    const { intents, changedIntents } = this.state;
    const tmpChangedIntents = changedIntents;
    if (changedIntents.findIndex((ind) => ind === intentInd) === -1) {
      tmpChangedIntents.push(intentInd);
    }
    const tmpIntents = intents;
    const newPayload = struct.encode({ storylineId: response._id });
    tmpIntents[intentInd].messages[messageInd].payload = newPayload;
    this.setState({
      intents: tmpIntents,
      changedIntents: tmpChangedIntents,
      changesMade: true,
    });
  };

  uploadPrivateKey = (event) => {
    const { chatbot, lang } = this.state;
    const url = `/chatbots/${chatbot._id}/privateKey/${lang}`;
    const formData = new FormData();
    formData.append("privateKey", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .put(url, formData, config)
      .then((response) => {
        this.setState({
          chatbot: response.data.chatbot,
          intents: response.data.intents,
          changedIntents: [],
          collapse: response.data.intents.map(() => false),
        });
      })
      .catch((error) => {
        toast("Server Error", { type: "error" });
      });
  };

  newIntent = () => {
    const { intents, changedIntents, newIntentName } = this.state;
    const tmpChangedIntents = changedIntents;
    tmpChangedIntents.push(intents.length);
    const tmpIntents = intents;
    let newIntent = {
      displayName: newIntentName,
      messages: [],
    };
    tmpIntents.push(newIntent);
    this.setState({
      intents: tmpIntents,
      changedIntents: tmpChangedIntents,
      changesMade: true,
    });
  };

  newResponse = (index, type) => {
    const { intents, changedIntents } = this.state;
    const tmpChangedIntents = changedIntents;
    if (changedIntents.findIndex((ind) => ind === index) === -1) {
      tmpChangedIntents.push(index);
    }
    const tmpIntents = intents;
    let message;
    if (type === "payload") {
      message = {
        message: type,
        payload: struct.encode({ storylineId: "" }),
      };
    } else if (type === "text") {
      message = {
        message: type,
        text: {
          text: [""],
        },
      };
    }
    tmpIntents[index].messages.push(message);
    this.setState({
      intents: tmpIntents,
      changedIntents: tmpChangedIntents,
      changesMade: true,
    });
  };

  saveChatbot = () => {
    const { selectedRobot } = this.props;
    const { chatbot, intents, lang, changedIntents } = this.state;
    const url = `/chatbots/${chatbot._id}/robots/${selectedRobot._id}/${lang}`;
    const filteredIntents = intents.filter(
      (intent, index) =>
        changedIntents.findIndex((intentInd) => intentInd === index) >= 0
    );
    console.log(chatbot.name);
    axios
      .put(url, { chatbot: chatbot, intents: filteredIntents })
      .then((response) => {
        if (response.status === 200) {
          toast("Chatbot saved", { type: "success" });
          this.setState({
            changedIntents: [],
            changesMade: false,
          });
          this.fetchIntents();
        } else {
          toast("Server Error", { type: "error" });
        }
      });
  };

  toggle = (index) => {
    const { collapse } = this.state;
    const tempCollapse = collapse;
    tempCollapse[index] = !tempCollapse[index];
    this.setState({ collapse: tempCollapse });
  };

  addScope = (scope) => {
    const { chatbot } = this.state;
    const tempChatbot = chatbot;
    tempChatbot.scopes.push(scope.value);

    this.setState({
      chatbot: tempChatbot,
      changesMade: true,
    });
  };

  removeScope = (index) => {
    const { chatbot } = this.state;
    const tempChatbot = chatbot;
    tempChatbot.scopes.splice(index, 1);

    this.setState({
      chatbot: tempChatbot,
      changesMade: true,
    });
  };

  render() {
    const { chatbot, intents, collapse, newIntentName } = this.state;
    const { selectedRobot } = this.props;
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Chatbot</h1>
                <p className="category">Here you can create a chatbot</p>
              </div>
              <div className="content">
                <h4 style={{ textAlign: "left" }}>Chatbot name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Chatbot name"
                  value={chatbot.name}
                />
                <h4 style={{ textAlign: "left" }}>Chatbot scopes</h4>
                <div className="card">
                  <div className="content">
                    {chatbot.scopes.map((scope, index) => (
                      <Badge
                        style={{
                          height: "25px",
                          fontSize: "14px",
                          paddingTop: "6px",
                          marginRight: "10px",
                        }}
                        color="primary"
                        key={scope}
                      >
                        {scope}{" "}
                        <i
                          style={{
                            cursor: "pointer",
                            backgroundColor: "rgba(255, 0, 0, 0.5)",
                          }}
                          onClick={() => this.removeScope(index)}
                          className="pe-7s-close"
                        ></i>
                      </Badge>
                    ))}
                  </div>
                </div>
                <Dropdown
                  className="dropdown-speechaction"
                  options={scopes.filter(
                    (scope) =>
                      chatbot.scopes.findIndex(
                        (scopeIndex) => scopeIndex === scope.value
                      ) === -1
                  )}
                  onChange={this.addScope}
                  value={
                    chatbot.scopes !== null
                      ? chatbot.scopes[chatbot.scopes.length]
                      : null
                  }
                  placeholder="Select a scope"
                />
                <h4 style={{ textAlign: "left" }}>Chatbot type</h4>
                <Dropdown
                  style={{ width: "60%" }}
                  options={types}
                  onChange={(value) => this.changeType(value.value)}
                  value={{
                    label: types.find((type) => type.value === chatbot.type)
                      .label,
                    value: chatbot.type,
                  }}
                  placeholder="Type"
                />
                {chatbot.type === "dialogflow" ? (
                  <div>
                    <h4>Upload private key</h4>
                    <label htmlFor="slide-file" style={{ marginTop: "0px" }}>
                      <input
                        id="slide-file"
                        type="file"
                        onChange={this.uploadPrivateKey}
                      />
                    </label>
                  </div>
                ) : (
                  <div>
                    <h4>Token</h4>
                    <Input
                      style={{ width: "400px" }}
                      onChange={(event) =>
                        this.setState({
                          chatbot: update(this.state.chatbot, {
                            token: { $set: event.target.value },
                          }),
                          changesMade: update(this.state.changesMade, {
                            $set: true,
                          }),
                        })
                      }
                      type="text"
                      placeholder="Name"
                      value={chatbot.token}
                    />
                    <h4>Client URL</h4>
                    <Input
                      style={{ width: "400px" }}
                      onChange={(event) => this.changeUrl(event.target.value)}
                      type="text"
                      placeholder="Client URL..."
                      value={chatbot.url}
                    />
                  </div>
                )}
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/chatbots">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveChatbot}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Intents</h1>
              </div>
              <div className="content">
                {intents.map((intent, index) => (
                  <div
                    style={{
                      backgroundColor: "#f0f9ff",
                      border: "4px solid #cfeafc",
                      borderRadius: "4px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                    key={intent.name}
                  >
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => this.toggle(index)}
                    >
                      {intent.displayName}
                      <span
                        className={cx("pe-7s-angle-down collapse-arrow", {
                          active: collapse,
                        })}
                      ></span>
                    </h3>
                    <Collapse isOpen={collapse[index]}>
                      {/*<div>
                          <h5>Training phrases :</h5>
                          <div>
                            {
                              intent.trainingPhrases.map((phrase, indTP) => (
                                <Input style={inputStyle} key={phrase} type="text" onChange={(event) => this.changeTrainingPhrase(event.target.value, indTP)} value={phrase} />
                              ))
                            }
                          </div>
                        </div>*/}
                      <h4>Responses :</h4>
                      <div>
                        {intent.messages.map((message, messageInd) => (
                          <div key={`${intent.name}${messageInd}`}>
                            {message.message === "text"
                              ? message.text.text.map((response, indR) => (
                                  <div
                                    key={`${intent.name}${messageInd}${indR}`}
                                    style={{ display: "flex", flexFlow: "row" }}
                                  >
                                    <Input
                                      style={inputStyle}
                                      type="text"
                                      onChange={(event) =>
                                        this.changeTextResponse(
                                          event.target.value,
                                          index,
                                          messageInd,
                                          indR
                                        )
                                      }
                                      value={response}
                                    />
                                  </div>
                                ))
                              : undefined}
                            {message.message === "payload" ? (
                              <div style={{ display: "flex", flexFlow: "row" }}>
                                {struct.decode(message.payload).storylineId !==
                                undefined ? (
                                  <SpeechAction
                                    selectedRobot={selectedRobot}
                                    storylineId={
                                      struct.decode(message.payload).storylineId
                                    }
                                    selectStoryline={(value) =>
                                      this.changePayloadResponse(
                                        value,
                                        index,
                                        messageInd
                                      )
                                    }
                                    name=""
                                  />
                                ) : (
                                  <div>
                                    <Input
                                      style={inputStyle}
                                      type="textarea"
                                      defaultValue={JSON.stringify(
                                        struct.decode(message.payload),
                                        null,
                                        4
                                      )}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : undefined}
                          </div>
                        ))}
                        <br />
                        <Popup
                          trigger={
                            <span>
                              <Button color="success">
                                New response <i className="pe-7s-plus"></i>
                              </Button>
                            </span>
                          }
                          position="right center"
                        >
                          {(close) => (
                            <div
                              style={{
                                padding: "10px",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  style={{ margin: "auto" }}
                                  onClick={() => {
                                    this.newResponse(index, "payload");
                                    close();
                                  }}
                                  color="success"
                                >
                                  Storyline <i className="pe-7s-plus"></i>
                                </Button>
                              </div>
                              <br />
                              <div
                                style={{
                                  height: "1px",
                                  width: "100%",
                                  backgroundColor: "black",
                                }}
                              />
                              <br />
                              <div style={{ width: "100%" }}>
                                <Button
                                  style={{ margin: "auto" }}
                                  onClick={() => {
                                    this.newResponse(index, "text");
                                    close();
                                  }}
                                  color="success"
                                >
                                  Text <i className="pe-7s-plus"></i>
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                    </Collapse>
                  </div>
                ))}
                <br />
                <Popup
                  trigger={
                    <span>
                      <Button color="success">
                        New Intent <i className="pe-7s-plus"></i>
                      </Button>
                    </span>
                  }
                  position="right center"
                >
                  {(close) => (
                    <div
                      style={{
                        width: "100%",
                        padding: "10px",
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>New intent name</h6>
                      <Input
                        style={{ marginBottom: "10px" }}
                        type="text"
                        onChange={(event) =>
                          this.changeNewIntentName(event.target.value)
                        }
                        value={newIntentName}
                        placeholder="New intent name"
                      />
                      <Button
                        onClick={() => {
                          this.newIntent();
                          this.setState({ newIntentName: "" });
                          close();
                        }}
                        color="success"
                      >
                        Create <i className="pe-7s-plus"></i>
                      </Button>
                    </div>
                  )}
                </Popup>
              </div>
            </div>
          </div>
          <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        </div>
      </div>
    );
  }
}

export default Chatbot;
