import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";

class Receptionists extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      receptionists: [],
      newReceptionist: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.fetchReceptionists();
  }

  fetchReceptionists = () => {
    const url = `/receptionists`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          receptionists: response.data.map((config) => config.configuration),
        });
      } else {
        toast("Server error", { type: "error" });
      }
    });
  };

  newReceptionist = () => {
    const url = `/receptionists`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newReceptionist: response.data,
          redirect: true,
        });
      } else {
        toast("Server error", { type: "error" });
      }
    });
  };

  deleteReceptionist = (id) => {
    const { selectedRobot } = this.state;
    const url = `/receptionists/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          receptionists: response.data.map((config) => config.configuration),
          selectedReceptionist: null,
        });
      } else {
        toast("Server error", { type: "error" });
      }
    });
  };

  render() {
    const { receptionists, redirect, newReceptionist } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/receptionist",
            state: {
              receptionist: newReceptionist,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Receptionist styles</h1>
                <p className="category">
                  Here you can create receptionist styles for Pepper. Click on
                  the style you want and press the 'Set active' button to
                  activate a style for the selected robot.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <Button
                  onClick={this.newReceptionist}
                  className="btn-success"
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  New style <i className="pe-7s-plus"></i>
                </Button>
                <h4 className="title">
                  Select a receptionist style, edit or create a new one
                </h4>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width="40px">Edit</th>
                      <th width="40px">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {receptionists === null
                      ? undefined
                      : receptionists.map((receptionist) => (
                          <tr key={receptionist._id}>
                            <td className="td-name">
                              {receptionist.en.name !== ""
                                ? receptionist.en.name
                                : receptionist.de.name !== ""
                                ? receptionist.de.name
                                : receptionist.fr.name !== ""
                                ? receptionist.fr.name
                                : receptionist.it.name}
                            </td>
                            <td className="td-name">
                              <Button
                                tag={Link}
                                to={{
                                  pathname: "/receptionist",
                                  state: {
                                    receptionist: receptionist,
                                  },
                                }}
                                color="primary"
                              >
                                <i className="pe-7s-pen"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() =>
                                  this.deleteReceptionist(receptionist._id)
                                }
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Receptionists;
