import React, { Component } from 'react';
import { Button } from 'reactstrap'

class GravityPicker extends Component {
    constructor(props) {
        super(props);
        const { cell } = this.props;
        this.state = {
            cell
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cell !== this.props.cell) {
            this.setState({
                cell: nextProps.cell
            })
        }
    }

    render() {
        const { change, field, menuStructure } = this.props;
        let { cell } = this.state
        
        if (cell[field] === null) {
            cell = menuStructure
        }

        return (
            <div>
                <table style={{ width: '100px', height: '100px', border: '2px solid gray' }}>
                    <tbody>
                        <tr>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'topLeft')} color={cell[field] === 'topLeft' ? 'primary' : 'secondary'} disabled={cell[field] === 'topLeft'}></Button></td>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'topCenter')} color={cell[field] === 'topCenter' ? 'primary' : 'secondary'} disabled={cell[field] === 'topCenter'}></Button></td>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'topRight')} color={cell[field] === 'topRight' ? 'primary' : 'secondary'} disabled={cell[field] === 'topRight'}></Button></td>
                        </tr>
                        <tr>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'left')} color={cell[field] === 'left' ? 'primary' : 'secondary'} disabled={cell[field] === 'left'}></Button></td>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'center')} color={cell[field] === 'center' ? 'primary' : 'secondary'} disabled={cell[field] === 'center'}></Button></td>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'right')} color={cell[field] === 'right' ? 'primary' : 'secondary'} disabled={cell[field] === 'right'}></Button></td>
                        </tr>
                        <tr>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'bottomLeft')} color={cell[field] === 'bottomLeft' ? 'primary' : 'secondary'} disabled={cell[field] === 'bottomLeft'}></Button></td>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'bottomCenter')} color={cell[field] === 'bottomCenter' ? 'primary' : 'secondary'} disabled={cell[field] === 'bottomCenter'}></Button></td>
                            <td style={{ border: '2px solid gray' }}><Button style={{ height: '100%', width: '100%'}} onClick={() => change(field, 'bottomRight')} color={cell[field] === 'bottomRight' ? 'primary' : 'secondary'} disabled={cell[field] === 'bottomRight'}></Button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default GravityPicker;
