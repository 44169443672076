import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import axios from "../../utils/AxiosService";
import GlobalPreview from "./components/GlobalPreview";
import GlobalStyle from "./components/GlobalStyle";
/*import EntertainmentStyle from './components/EntertainmentStyle';
import EntertainmentPreview from './components/EntertainmentPreview';
import FaqStyle from './components/FaqStyle';
import FaqPreview from './components/FaqPreview';
import TeamStyle from './components/TeamStyle';
import TeamPreview from './components/TeamPreview';
import QuizStyle from './components/QuizStyle';
import QuizPreview from './components/QuizPreview';
import SurveyStyle from './components/SurveyStyle';
import SurveyPreview from './components/SurveyPreview';
import ServiceContactStyle from './components/ServiceContactStyle';
import ServiceContactPreview from './components/ServiceContactPreview';*/

const activeTabStyle = {
  backgroundColor: "white",
  border: "1px solid lightgray",
  borderBottom: "0px",
  bottom: "-1px",
  zIndex: "100",
  color: "black",
};

const inactiveTabStyle = {
  backgroundColor: "white",
  border: "1px solid lightgray",
  borderBottom: "0px",
  bottom: "-1px",
  color: "black",
};

class Style extends Component {
  constructor(props) {
    super(props);
    this.changeBackgroundImage = this.changeBackgroundImage.bind(this);
    this.changeFontFamily = this.changeFontFamily.bind(this);
    this.changeFontColor = this.changeFontColor.bind(this);
    this.changeButtonTextColor = this.changeButtonTextColor.bind(this);
    this.changeButtonColor = this.changeButtonColor.bind(this);
    this.deleteBackgroundImage = this.deleteBackgroundImage.bind(this);
    this.saveStyle = this.saveStyle.bind(this);
    this.state = {
      style: this.props.location.state.style,
      activeTab: "1",
      changesMade: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  changeName = (value) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle.name = value;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  changeBackgroundImage = (event) => {
    const { style } = this.state;
    const url = `/styles/${style._id}/image`;
    const formData = new FormData();
    formData.append("myImage", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios.put(url, formData, config).then((response) => {
      let tempStyle = style;
      tempStyle["background-image"] = response.data["background-image"];

      this.setState({
        style: tempStyle,
        changesMade: true,
      });
    });
  };

  deleteBackgroundImage = () => {
    const { style } = this.state;
    const url = `/styles/${style._id}/image`;
    axios.delete(url).then((response) => {
      if (response.data.success) {
        let tempStyle = style;
        tempStyle["background-image"] = "";
        toast("Image deleted", { type: "success" });
        this.setState({
          style: tempStyle,
          changesMade: true,
        });
      } else {
        toast("Error deleting image", { type: "error" });
      }
    });
  };

  changeFontFamily = (value) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle["font-family"] = value.value;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  changeFontColor = (color) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle["font-color"] = color.hex;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  changeButtonTextColor = (target, color) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle[target]["android_widget_Button"].mTextColor = color.hex;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  changeButtonTextSize = (target, value) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle[target]["android_widget_Button"].mTextSize = value;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  changeButtonColor = (target, color) => {
    const { style } = this.state;
    const tempStyle = style;
    let hexAlpha = Math.round(color.rgb.a * 255).toString(16);
    if (hexAlpha.length < 2) {
      hexAlpha = `0${hexAlpha}`;
    }
    tempStyle[target][
      "android_widget_Button"
    ].mBackgroundTint = `#${hexAlpha}${color.hex.slice(1)}`;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  changeAndroidRessource = (mod, widget, property, value) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle[mod].android.widget[widget][property] =
      value.hex === undefined ? value : value.hex;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  changeComRessource = (mod, widget, property, value) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle[mod].com.viewpagerindicator[widget][property] =
      value.hex === undefined ? value : value.hex;
    this.setState({
      style: tempStyle,
      changesMade: true,
    });
  };

  saveStyle = () => {
    const { style } = this.state;
    const { selectedRobot } = this.props;
    const url = `/styles/${style._id}/robots/${selectedRobot._id}`;
    axios.put(url, { style: style }).then((response) => {
      if (response.status === 200) {
        toast("Style saved", { type: "success" });
        this.setState({
          style: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  render() {
    const { selectedRobot } = this.props;
    const { style } = this.state;
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">{style.name} style</h1>
                <p className="category">Here you can edit the selected style</p>
                <div
                  style={{ position: "absolute", top: "45px", right: "35px" }}
                >
                  <Link to="/styles">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveStyle}>
                    Save changes
                  </Button>
                </div>
                <h4 style={{ textAlign: "left" }}>Style name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Style name"
                  value={style.name}
                />
                <br />
              </div>
            </div>
          </div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
              style={
                this.state.activeTab === "1" ? activeTabStyle : inactiveTabStyle
              }
            >
              Global
            </NavLink>
          </NavItem>
          {/*<NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
                style={this.state.activeTab === '2' ? activeTabStyle : inactiveTabStyle}
              >
                FAQ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
                style={this.state.activeTab === '3' ? activeTabStyle : inactiveTabStyle}
              >
                Team
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '4' })}
                onClick={() => { this.toggle('4'); }}
                style={this.state.activeTab === '4' ? activeTabStyle : inactiveTabStyle}
              >
                Quiz
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '5' })}
                onClick={() => { this.toggle('5'); }}
                style={this.state.activeTab === '5' ? activeTabStyle : inactiveTabStyle}
              >
                Survey
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '6' })}
                onClick={() => { this.toggle('6'); }}
                style={this.state.activeTab === '6' ? activeTabStyle : inactiveTabStyle}
              >
                Entertainments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
                className={classnames({ active: this.state.activeTab === '7' })}
                onClick={() => { this.toggle('7'); }}
                style={this.state.activeTab === '7' ? activeTabStyle : inactiveTabStyle}
              >
                Service contact
            </NavLink>
          </NavItem>*/}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" style={{ padding: "0px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header">
                    <div style={{ display: "flex", flexFlow: "row" }}>
                      <div style={{ width: "70%" }}>
                        <GlobalStyle
                          selectedRobot={selectedRobot}
                          style={style}
                          changeBackgroundImage={this.changeBackgroundImage}
                          changeFontFamily={this.changeFontFamily}
                          changeFontColor={this.changeFontColor}
                          changeButtonTextColor={this.changeButtonTextColor}
                          changeButtonColor={this.changeButtonColor}
                          changeButtonTextSize={this.changeButtonTextSize}
                          deleteBackgroundImage={this.deleteBackgroundImage}
                          saveStyle={this.saveStyle}
                        />
                      </div>
                      <div style={{ width: "30%" }}>
                        <GlobalPreview style={style} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
        {/*<TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="2" style={{ padding: '0px' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header">
                    <div style={{ display: 'flex', flexFlow: 'row' }}>
                      <div style={{ width: '70%' }}>
                        <FaqStyle 
                          selectedRobot={selectedRobot}
                          style={style}
                          changeAndroidRessource={this.changeAndroidRessource}
                          saveStyle={this.saveStyle}
                        />
                      </div>
                      <div style={{ width: '30%' }}>
                        <FaqPreview style={style} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="3" style={{ padding: '0px' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header">
                    <div style={{ display: 'flex', flexFlow: 'row' }}>
                      <div style={{ width: '70%' }}>
                        <TeamStyle 
                          selectedRobot={selectedRobot}
                          style={style}
                          changeAndroidRessource={this.changeAndroidRessource}
                          changeComRessource={this.changeComRessource}
                          saveStyle={this.saveStyle}
                        />
                      </div>
                      <div style={{ width: '30%' }}>
                        <TeamPreview style={style} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="4" style={{ padding: '0px' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header">
                    <div style={{ display: 'flex', flexFlow: 'row' }}>
                      <div style={{ width: '70%' }}>
                        <QuizStyle 
                          selectedRobot={selectedRobot}
                          style={style}
                          changeAndroidRessource={this.changeAndroidRessource}
                          saveStyle={this.saveStyle}
                        />
                      </div>
                      <div style={{ width: '30%' }}>
                        <QuizPreview style={style} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="5" style={{ padding: '0px' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header">
                    <div style={{ display: 'flex', flexFlow: 'row' }}>
                      <div style={{ width: '70%' }}>
                        <SurveyStyle 
                          selectedRobot={selectedRobot}
                          style={style}
                          changeAndroidRessource={this.changeAndroidRessource}
                          saveStyle={this.saveStyle}
                        />
                      </div>
                      <div style={{ width: '30%' }}>
                        <SurveyPreview style={style} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="6" style={{ padding: '0px' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header">
                    <div style={{ display: 'flex', flexFlow: 'row' }}>
                      <div style={{ width: '70%' }}>
                        <EntertainmentStyle 
                          selectedRobot={selectedRobot}
                          style={style}
                          changeAndroidRessource={this.changeAndroidRessource}
                          saveStyle={this.saveStyle}
                        />
                      </div>
                      <div style={{ width: '30%' }}>
                        <EntertainmentPreview style={style} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="7" style={{ padding: '0px' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="header">
                    <div style={{ display: 'flex', flexFlow: 'row' }}>
                      <div style={{ width: '70%' }}>
                        <ServiceContactStyle 
                          selectedRobot={selectedRobot}
                          style={style}
                          changeAndroidRessource={this.changeAndroidRessource}
                          saveStyle={this.saveStyle}
                        />
                      </div>
                      <div style={{ width: '30%' }}>
                        <ServiceContactPreview style={style} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>*/}
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

Style.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default Style;
