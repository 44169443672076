export const sounds = [
	{ "label": "Agree_01", "value": "agree_01", "file": "agree_01.ogg" },
	{ "label": "Aha", "value": "aha", "file": "aha.ogg" },
	{ "label": "Ah_curious_01", "value": "ah_curious_01", "file": "ah_curious_01.ogg" },
	{ "label": "Ah_interested_01", "value": "ah_interested_01", "file": "ah_interested_01.ogg" },
	{ "label": "Ah_scared", "value": "ah_scared", "file": "ah_scared.ogg" },
	{ "label": "Alright_01", "value": "alright_01", "file": "alright_01.ogg" },
	{ "label": "Alright_02", "value": "alright_02", "file": "alright_02.ogg" },
	{ "label": "Amazing_01", "value": "amazing_01", "file": "amazing_01.ogg" },
	{ "label": "Amazing_02", "value": "amazing_02", "file": "amazing_02.ogg" },
	{ "label": "Awesome_01", "value": "awesome_01", "file": "awesome_01.ogg" },
	{ "label": "Awesome_02", "value": "awesome_02", "file": "awesome_02.ogg" },
	{ "label": "Bonjour_01", "value": "bonjour_01", "file": "bonjour_01.ogg" },
	{ "label": "Boom", "value": "boom", "file": "boom.ogg" },
	{ "label": "Coffee", "value": "coffee", "file": "coffee.ogg" },
	{ "label": "Comprehension_01", "value": "comprehension_01", "file": "comprehension_01.ogg" },
	{ "label": "Comprehension_02", "value": "comprehension_02", "file": "comprehension_02.ogg" },
	{ "label": "Comprehension_03", "value": "comprehension_03", "file": "comprehension_03.ogg" },
	{ "label": "Comprehension_04", "value": "comprehension_04", "file": "comprehension_04.ogg" },
	{ "label": "Cool_01", "value": "cool_01", "file": "cool_01.ogg" },
	{ "label": "Cool_02", "value": "cool_02", "file": "cool_02.ogg" },
	{ "label": "Cough_01", "value": "cough_01", "file": "cough_01.ogg" },
	{ "label": "Cough_02", "value": "cough_02", "file": "cough_02.ogg" },
	{ "label": "Cough_03", "value": "cough_03", "file": "cough_03.ogg" },
	{ "label": "Crying", "value": "crying", "file": "crying.ogg" },
	{ "label": "Crying_02", "value": "crying_02", "file": "crying_02.ogg" },
	{ "label": "Cute_oh_01", "value": "cute_oh_01", "file": "cute_oh_01.ogg" },
	{ "label": "Cute_oh_02", "value": "cute_oh_02", "file": "cute_oh_02.ogg" },
	{ "label": "Desktop", "value": "desktop", "file": "desktop.ini" },
	{ "label": "Duh", "value": "duh", "file": "duh.ogg" },
	{ "label": "Et_voila_01", "value": "et_voila_01", "file": "et_voila_01.ogg" },
	{ "label": "Fantastic_01", "value": "fantastic_01", "file": "fantastic_01.ogg" },
	{ "label": "Fantastic_02", "value": "fantastic_02", "file": "fantastic_02.ogg" },
	{ "label": "Fart", "value": "fart", "file": "fart.ogg" },
	{ "label": "Fine_01", "value": "fine_01", "file": "fine_01.ogg" },
	{ "label": "Fine_02", "value": "fine_02", "file": "fine_02.ogg" },
	{ "label": "Happybirthday_01", "value": "happybirthday_01", "file": "happybirthday_01.ogg" },
	{ "label": "Happybirthday_02", "value": "happybirthday_02", "file": "happybirthday_02.ogg" },
	{ "label": "Happy_01", "value": "happy_01", "file": "happy_01.ogg" },
	{ "label": "Harrumph", "value": "harrumph", "file": "harrumph.ogg" },
	{ "label": "Harrumph_01", "value": "harrumph_01", "file": "harrumph_01.ogg" },
	{ "label": "Harrumph_02", "value": "harrumph_02", "file": "harrumph_02.ogg" },
	{ "label": "Heartbeat", "value": "heartbeat", "file": "heartbeat.ogg" },
	{ "label": "Here_we_go_01", "value": "here_we_go_01", "file": "here_we_go_01.ogg" },
	{ "label": "Hesitation", "value": "hesitation", "file": "hesitation.ogg" },
	{ "label": "Hesitation_long_01", "value": "hesitation_long_01", "file": "hesitation_long_01.ogg" },
	{ "label": "Hesitation_long_02", "value": "hesitation_long_02", "file": "hesitation_long_02.ogg" },
	{ "label": "Hesitation_long_03", "value": "hesitation_long_03", "file": "hesitation_long_03.ogg" },
	{ "label": "Hey_01", "value": "hey_01", "file": "hey_01.ogg" },
	{ "label": "Hey_02", "value": "hey_02", "file": "hey_02.ogg" },
	{ "label": "Hiccupp", "value": "hiccupp", "file": "hiccupp.ogg" },
	{ "label": "Hihihi", "value": "hihihi", "file": "hihihi.ogg" },
	{ "label": "Hihihi_02", "value": "hihihi_02", "file": "hihihi_02.ogg" },
	{ "label": "Hm", "value": "hm", "file": "hm.ogg" },
	{ "label": "Hmmmiam", "value": "hmmmiam", "file": "hmmmiam.ogg" },
	{ "label": "Hmm_miam", "value": "hmm_miam", "file": "hmm_miam.ogg" },
	{ "label": "Hmm_miam2", "value": "hmm_miam2", "file": "hmm_miam2.ogg" },
	{ "label": "Hm_curious", "value": "hm_curious", "file": "hm_curious.ogg" },
	{ "label": "Hohoho", "value": "hohoho", "file": "hohoho.ogg" },
	{ "label": "Hooray_01", "value": "hooray_01", "file": "hooray_01.ogg" },
	{ "label": "Hoorraay", "value": "hoorraay", "file": "hoorraay.ogg" },
	{ "label": "Hophophop", "value": "hophophop", "file": "hophophop.ogg" },
	{ "label": "Hum", "value": "hum", "file": "hum.ogg" },
	{ "label": "Humming_01", "value": "humming_01", "file": "humming_01.ogg" },
	{ "label": "Humming_02", "value": "humming_02", "file": "humming_02.ogg" },
	{ "label": "Humming_03", "value": "humming_03", "file": "humming_03.ogg" },
	{ "label": "Humming_04", "value": "humming_04", "file": "humming_04.ogg" },
	{ "label": "Humming_05", "value": "humming_05", "file": "humming_05.ogg" },
	{ "label": "Humming_06", "value": "humming_06", "file": "humming_06.ogg" },
	{ "label": "Hum_01", "value": "hum_01", "file": "hum_01.ogg" },
	{ "label": "Hum_02", "value": "hum_02", "file": "hum_02.ogg" },
	{ "label": "Its_so_funny", "value": "its_so_funny", "file": "its_so_funny.ogg" },
	{ "label": "Kiss", "value": "kiss", "file": "kiss.ogg" },
	{ "label": "Laughing_demonic", "value": "laughing_demonic", "file": "laughing_demonic.ogg" },
	{ "label": "Laughing_ironic", "value": "laughing_ironic", "file": "laughing_ironic.ogg" },
	{ "label": "Laugh_01", "value": "laugh_01", "file": "laugh_01.ogg" },
	{ "label": "Laugh_02", "value": "laugh_02", "file": "laugh_02.ogg" },
	{ "label": "Laugh_03", "value": "laugh_03", "file": "laugh_03.ogg" },
	{ "label": "Laugh_04", "value": "laugh_04", "file": "laugh_04.ogg" },
	{ "label": "Laugh_badjoke", "value": "laugh_badjoke", "file": "laugh_badjoke.ogg" },
	{ "label": "Laugh_embarassed_01", "value": "laugh_embarassed_01", "file": "laugh_embarassed_01.ogg" },
	{ "label": "Laugh_expressive", "value": "laugh_expressive", "file": "laugh_expressive.ogg" },
	{ "label": "Laugh_happy", "value": "laugh_happy", "file": "laugh_happy.ogg" },
	{ "label": "Laugh_mischievous", "value": "laugh_mischievous", "file": "laugh_mischievous.ogg" },
	{ "label": "Laugh_tease", "value": "laugh_tease", "file": "laugh_tease.ogg" },
	{ "label": "Lis", "value": "lis", "file": "list.py" },
	{ "label": "List", "value": "list", "file": "list.txt" },
	{ "label": "Littlesong", "value": "littlesong", "file": "littlesong.ogg" },
	{ "label": "Love_it_01", "value": "love_it_01", "file": "love_it_01.ogg" },
	{ "label": "Mmmm_yummie", "value": "mmmm_yummie", "file": "mmmm_yummie.ogg" },
	{ "label": "Music_i_believe_i_can_fly", "value": "music_i_believe_i_can_fly", "file": "music_i_believe_i_can_fly.ogg" },
	{ "label": "Music_lullaby_01", "value": "music_lullaby_01", "file": "music_lullaby_01.ogg" },
	{ "label": "Music_lullaby_02", "value": "music_lullaby_02", "file": "music_lullaby_02.ogg" },
	{ "label": "Music_lullaby_03", "value": "music_lullaby_03", "file": "music_lullaby_03.ogg" },
	{ "label": "Music_lullaby_04", "value": "music_lullaby_04", "file": "music_lullaby_04.ogg" },
	{ "label": "Music_lullaby_05", "value": "music_lullaby_05", "file": "music_lullaby_05.ogg" },
	{ "label": "Music_song_02", "value": "music_song_02", "file": "music_song_02.ogg" },
	{ "label": "Music_song_03", "value": "music_song_03", "file": "music_song_03.ogg" },
	{ "label": "Music_song_06", "value": "music_song_06", "file": "music_song_06.ogg" },
	{ "label": "Music_take_me_out", "value": "music_take_me_out", "file": "music_take_me_out.ogg" },
	{ "label": "Nooo", "value": "nooo", "file": "nooo.ogg" },
	{ "label": "Nooo_02", "value": "nooo_02", "file": "nooo_02.ogg" },
	{ "label": "Ohoh", "value": "ohoh", "file": "ohoh.ogg" },
	{ "label": "Ohoh_02", "value": "ohoh_02", "file": "ohoh_02.ogg" },
	{ "label": "Oh_no", "value": "oh_no", "file": "oh_no.ogg" },
	{ "label": "Oh_sad_01", "value": "oh_sad_01", "file": "oh_sad_01.ogg" },
	{ "label": "Okay_01", "value": "okay_01", "file": "okay_01.ogg" },
	{ "label": "Okie_dokie_01", "value": "okie_dokie_01", "file": "okie_dokie_01.ogg" },
	{ "label": "Olala_01", "value": "olala_01", "file": "olala_01.ogg" },
	{ "label": "Olala_02", "value": "olala_02", "file": "olala_02.ogg" },
	{ "label": "Ooops", "value": "ooops", "file": "ooops.ogg" },
	{ "label": "Ouch", "value": "ouch", "file": "ouch.ogg" },
	{ "label": "Oui", "value": "oui", "file": "oui.ogg" },
	{ "label": "Oups", "value": "oups", "file": "oups.ogg" },
	{ "label": "Peekaboo_laugh", "value": "peekaboo_laugh", "file": "peekaboo_laugh.ogg" },
	{ "label": "Phew", "value": "phew", "file": "phew.ogg" },
	{ "label": "Pss_01", "value": "pss_01", "file": "pss_01.ogg" },
	{ "label": "Really", "value": "really", "file": "really.ogg" },
	{ "label": "Relief_01", "value": "relief_01", "file": "relief_01.ogg" },
	{ "label": "Relief_02", "value": "relief_02", "file": "relief_02.ogg" },
	{ "label": "Sad", "value": "sad", "file": "sad.ogg" },
	{ "label": "Satisfied_01", "value": "satisfied_01", "file": "satisfied_01.ogg" },
	{ "label": "Satisfied_02", "value": "satisfied_02", "file": "satisfied_02.ogg" },
	{ "label": "Scream_01", "value": "scream_01", "file": "scream_01.ogg" },
	{ "label": "Shhh_01", "value": "shhh_01", "file": "shhh_01.ogg" },
	{ "label": "Shhh_02", "value": "shhh_02", "file": "shhh_02.ogg" },
	{ "label": "Sigh_bored_01", "value": "sigh_bored_01", "file": "sigh_bored_01.ogg" },
	{ "label": "Sigh_bored_02", "value": "sigh_bored_02", "file": "sigh_bored_02.ogg" },
	{ "label": "Sigh_bored_03", "value": "sigh_bored_03", "file": "sigh_bored_03.ogg" },
	{ "label": "Sigh_bored_04", "value": "sigh_bored_04", "file": "sigh_bored_04.ogg" },
	{ "label": "Sigh_bored_05", "value": "sigh_bored_05", "file": "sigh_bored_05.ogg" },
	{ "label": "Sigh_disappointed_01", "value": "sigh_disappointed_01", "file": "sigh_disappointed_01.ogg" },
	{ "label": "Sigh_disappointed_02", "value": "sigh_disappointed_02", "file": "sigh_disappointed_02.ogg" },
	{ "label": "Sigh_relief", "value": "sigh_relief", "file": "sigh_relief.ogg" },
	{ "label": "Sigh_relief_02", "value": "sigh_relief_02", "file": "sigh_relief_02.ogg" },
	{ "label": "Sigh_relief_03", "value": "sigh_relief_03", "file": "sigh_relief_03.ogg" },
	{ "label": "Sigh_resignation_01", "value": "sigh_resignation_01", "file": "sigh_resignation_01.ogg" },
	{ "label": "Sigh_sad_01", "value": "sigh_sad_01", "file": "sigh_sad_01.ogg" },
	{ "label": "Sigh_scared_01", "value": "sigh_scared_01", "file": "sigh_scared_01.ogg" },
	{ "label": "Sigh_surprised_01", "value": "sigh_surprised_01", "file": "sigh_surprised_01.ogg" },
	{ "label": "Sigh_tired_01", "value": "sigh_tired_01", "file": "sigh_tired_01.ogg" },
	{ "label": "Sigh_tired_02", "value": "sigh_tired_02", "file": "sigh_tired_02.ogg" },
	{ "label": "Singing", "value": "singing", "file": "singing.ogg" },
	{ "label": "Singing_bored", "value": "singing_bored", "file": "singing_bored.ogg" },
	{ "label": "Singing_bored_02", "value": "singing_bored_02", "file": "singing_bored_02.ogg" },
	{ "label": "Smell_01", "value": "smell_01", "file": "smell_01.ogg" },
	{ "label": "Smell_02", "value": "smell_02", "file": "smell_02.ogg" },
	{ "label": "Sneeze_01", "value": "sneeze_01", "file": "sneeze_01.ogg" },
	{ "label": "Sniffing", "value": "sniffing", "file": "sniffing.ogg" },
	{ "label": "Snoring", "value": "snoring", "file": "snoring.ogg" },
	{ "label": "Snoring_02", "value": "snoring_02", "file": "snoring_02.ogg" },
	{ "label": "Sorry_01", "value": "sorry_01", "file": "sorry_01.ogg" },
	{ "label": "Sorry_02", "value": "sorry_02", "file": "sorry_02.ogg" },
	{ "label": "Sorry_i_m_so", "value": "sorry_i_m_so", "file": "sorry_i_m_so.ogg" },
	{ "label": "Stretch", "value": "stretch", "file": "stretch.ogg" },
	{ "label": "Surprised", "value": "surprised", "file": "surprised.ogg" },
	{ "label": "Surprised_02", "value": "surprised_02", "file": "surprised_02.ogg" },
	{ "label": "Surprised_oh", "value": "surprised_oh", "file": "surprised_oh.ogg" },
	{ "label": "Surprised_oh_02", "value": "surprised_oh_02", "file": "surprised_oh_02.ogg" },
	{ "label": "Tada", "value": "tada", "file": "tada.ogg" },
	{ "label": "Thinking_01", "value": "thinking_01", "file": "thinking_01.ogg" },
	{ "label": "Thinking_02", "value": "thinking_02", "file": "thinking_02.ogg" },
	{ "label": "Thinking_03", "value": "thinking_03", "file": "thinking_03.ogg" },
	{ "label": "Tututut", "value": "tututut", "file": "tututut.ogg" },
	{ "label": "Uhm", "value": "uhm", "file": "uhm.ogg" },
	{ "label": "Uh_huh", "value": "uh_huh", "file": "uh_huh.ogg" },
	{ "label": "Very_funny_01", "value": "very_funny_01", "file": "very_funny_01.ogg" },
	{ "label": "Wah_wah_failed_01", "value": "wah_wah_failed_01", "file": "wah_wah_failed_01.ogg" },
	{ "label": "Wondering_01", "value": "wondering_01", "file": "wondering_01.ogg" },
	{ "label": "Wondering_02", "value": "wondering_02", "file": "wondering_02.ogg" },
	{ "label": "Woo_01", "value": "woo_01", "file": "woo_01.ogg" },
	{ "label": "Woo_02", "value": "woo_02", "file": "woo_02.ogg" },
	{ "label": "Wowwowwoooow", "value": "wowwowwoooow", "file": "wowwowwoooow.ogg" },
	{ "label": "Wow_01", "value": "wow_01", "file": "wow_01.ogg" },
	{ "label": "Wow_02", "value": "wow_02", "file": "wow_02.ogg" },
	{ "label": "Wow_03", "value": "wow_03", "file": "wow_03.ogg" },
	{ "label": "Wow_04", "value": "wow_04", "file": "wow_04.ogg" },
	{ "label": "Wow_05", "value": "wow_05", "file": "wow_05.ogg" },
	{ "label": "Wow_06", "value": "wow_06", "file": "wow_06.ogg" },
	{ "label": "Yawn_01", "value": "yawn_01", "file": "yawn_01.ogg" },
	{ "label": "Yawn_02", "value": "yawn_02", "file": "yawn_02.ogg" },
	{ "label": "Yawn_03", "value": "yawn_03", "file": "yawn_03.ogg" },
	{ "label": "Yay", "value": "yay", "file": "yay.ogg" },
	{ "label": "Yeaaah", "value": "yeaaah", "file": "yeaaah.ogg" },
	{ "label": "Yeah", "value": "yeah", "file": "yeah.ogg" }
];