import React, { Component } from "react";
import { SketchPicker } from "react-color";
import Dropdown from "react-select";
import Popup from "reactjs-popup";
import { Button, Input } from "reactstrap";
import axios from "../../../utils/AxiosService";
import { fonts, styles } from "../constants/fonts";
import GravityPicker from "./GravityPicker";

class MenuLayout extends Component {
  constructor(props) {
    super(props);
    const { cell } = this.props;
    this.state = {
      cell,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cell !== this.props.cell) {
      this.setState({
        cell: nextProps.cell,
      });
    }
  }

  uploadIcon = (icon) => {
    const { changeMenuStyle } = this.props;
    const url = `/menuStructures/icons`;
    const formData = new FormData();
    formData.append("icon", icon);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .put(url, formData, config)
      .then((response) => {
        changeMenuStyle("backButtonImage", response.data);
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { close, changeMenuStyle, saveStructure, currentPath } = this.props;

    return (
      <div style={{ margin: "20px" }}>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h2>Menu layout</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Grid</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Rows</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].rows}
                  onChange={(event) =>
                    changeMenuStyle("rows", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Columns</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].columns}
                  onChange={(event) =>
                    changeMenuStyle("columns", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Horizontal spacing</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].spacingHorizontal}
                  onChange={(event) =>
                    changeMenuStyle("spacingHorizontal", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Vertical spacing</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].spacingVertical}
                  onChange={(event) =>
                    changeMenuStyle("spacingVertical", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Font</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Family</h4>
                <Dropdown
                  options={fonts}
                  value={{
                    label: currentPath[currentPath.length - 1].fontFamily,
                    value: currentPath[currentPath.length - 1].fontFamily,
                  }}
                  onChange={(event) =>
                    changeMenuStyle("fontFamily", event.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Style</h4>
                <Dropdown
                  options={styles}
                  value={{
                    label: currentPath[currentPath.length - 1].fontStyle,
                    value: currentPath[currentPath.length - 1].fontStyle,
                  }}
                  onChange={(event) =>
                    changeMenuStyle("fontStyle", event.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>All capital</h4>
                <Input
                  type="checkbox"
                  defaultChecked={
                    currentPath[currentPath.length - 1].textAllCaps
                  }
                  onChange={() =>
                    changeMenuStyle(
                      "textAllCaps",
                      !currentPath[currentPath.length - 1].isWrapBefore
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          currentPath[currentPath.length - 1].fontColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={currentPath[currentPath.length - 1].fontColor}
                    onChange={(color) =>
                      changeMenuStyle("fontColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Size</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].fontSize}
                  onChange={(event) =>
                    changeMenuStyle("fontSize", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Gravity</h4>
                <GravityPicker
                  cell={currentPath[currentPath.length - 1]}
                  change={changeMenuStyle}
                  field="textGravity"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Margin/Padding</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Margin left</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].marginLeft}
                  onChange={(event) =>
                    changeMenuStyle("marginLeft", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Margin right</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].marginRight}
                  onChange={(event) =>
                    changeMenuStyle("marginRight", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Margin top</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].marginTop}
                  onChange={(event) =>
                    changeMenuStyle("marginTop", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Margin bottom</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].marginBottom}
                  onChange={(event) =>
                    changeMenuStyle("marginBottom", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row">
              <div className="col-md-3">
                <div className="content" style={{ textAlign: "left" }}>
                  <h4>Padding left</h4>
                  <Input
                    type="number"
                    value={currentPath[currentPath.length - 1].paddingLeft}
                    onChange={(event) =>
                      changeMenuStyle("paddingLeft", event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="content" style={{ textAlign: "left" }}>
                  <h4>Padding right</h4>
                  <Input
                    type="number"
                    value={currentPath[currentPath.length - 1].paddingRight}
                    onChange={(event) =>
                      changeMenuStyle("paddingRight", event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="content" style={{ textAlign: "left" }}>
                  <h4>Padding top</h4>
                  <Input
                    type="number"
                    value={currentPath[currentPath.length - 1].paddingTop}
                    onChange={(event) =>
                      changeMenuStyle("paddingTop", event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="content" style={{ textAlign: "left" }}>
                  <h4>Padding bottom</h4>
                  <Input
                    type="number"
                    value={currentPath[currentPath.length - 1].paddingBottom}
                    onChange={(event) =>
                      changeMenuStyle("paddingBottom", event.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Border</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          currentPath[currentPath.length - 1].borderColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={currentPath[currentPath.length - 1].borderColor}
                    onChange={(color) =>
                      changeMenuStyle("borderColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Size</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].borderSize}
                  onChange={(event) =>
                    changeMenuStyle("borderSize", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Radius</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].borderRadius}
                  onChange={(event) =>
                    changeMenuStyle("borderRadius", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Tool bar</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Content color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          currentPath[currentPath.length - 1]
                            .toolbarContentColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={
                      currentPath[currentPath.length - 1].toolbarContentColor
                    }
                    onChange={(color) =>
                      changeMenuStyle("toolbarContentColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Size</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].toolBarSize}
                  onChange={(event) =>
                    changeMenuStyle("toolBarSize", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Back button</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Image</h4>
                <label
                  htmlFor="slide-file"
                  className="input-image"
                  style={{ marginTop: "0px", float: "left" }}
                >
                  <i className="fa fa-image"></i>
                  <img
                    height="100px"
                    src={`${
                      currentPath[currentPath.length - 1].backButtonImage
                    }`}
                    alt="Upload a file..."
                  />
                  <input
                    id="slide-file"
                    type="file"
                    accept="image/*"
                    onChange={(event) => this.uploadIcon(event.target.files[0])}
                  />
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Width</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].backButtonWidth}
                  onChange={(event) =>
                    changeMenuStyle("backButtonWidth", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Height</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].backButtonHeight}
                  onChange={(event) =>
                    changeMenuStyle("backButtonHeight", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Voice button</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Background color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          currentPath[currentPath.length - 1]
                            .voiceButtonBackgroundColor,
                        height: "60px",
                        width: "60px",
                        border: "1px solid",
                        borderRadius: "200px",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={
                      currentPath[currentPath.length - 1]
                        .voiceButtonBackgroundColor
                    }
                    onChange={(color) =>
                      changeMenuStyle("voiceButtonBackgroundColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Border color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          currentPath[currentPath.length - 1]
                            .voiceButtonBorderColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={
                      currentPath[currentPath.length - 1].voiceButtonBorderColor
                    }
                    onChange={(color) =>
                      changeMenuStyle("voiceButtonBorderColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Border size</h4>
                <Input
                  type="number"
                  value={
                    currentPath[currentPath.length - 1].voiceButtonBorderSize
                  }
                  onChange={(event) =>
                    changeMenuStyle("voiceButtonBorderSize", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="header">
                <h4>Voice bar</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Content color</h4>
                <Popup
                  trigger={
                    <div
                      style={{
                        backgroundColor:
                          currentPath[currentPath.length - 1]
                            .voiceBarContentColor,
                        height: "60px",
                        width: "60px",
                        borderRadius: "200px",
                        border: "1px solid",
                        cursor: "pointer",
                      }}
                    />
                  }
                  position="top center"
                >
                  <SketchPicker
                    disableAlpha={false}
                    color={
                      currentPath[currentPath.length - 1].voiceBarContentColor
                    }
                    onChange={(color) =>
                      changeMenuStyle("voiceBarContentColor", color.hex)
                    }
                  />
                </Popup>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content" style={{ textAlign: "left" }}>
                <h4>Text size</h4>
                <Input
                  type="number"
                  value={currentPath[currentPath.length - 1].voiceBarTextSize}
                  onChange={(event) =>
                    changeMenuStyle("voiceBarTextSize", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="header">
              <Button
                onClick={() => {
                  saveStructure();
                  close();
                }}
                color="success"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuLayout;
