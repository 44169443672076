import classnames from "classnames";
import React from "react";
import Dropdown from "react-select";
import { Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { animations } from "../../../constants/animations";
import { dances } from "../../../constants/dances";
import { expressions } from "../../../constants/expressions";
import { sounds } from "../../../constants/sounds";

const activeTabStyle = {
  backgroundColor: "white",
  border: "1px solid lightgray",
  borderBottom: "0px",
  bottom: "-1px",
  zIndex: "100",
  color: "black",
};

const inactiveTabStyle = {
  backgroundColor: "white",
  border: "1px solid lightgray",
  borderBottom: "0px",
  bottom: "1px",
  color: "black",
};

class RobotActions extends React.Component {
  constructor(props) {
    super(props);
    const { storyline, index, lang } = this.props;
    this.state = {
      storyline,
      index,
      lang,
      activeTab: "1",
    };
  }

  componentDidUpdate(prevProps) {
    const { index, storyline, lang } = this.props;
    if (
      prevProps.index !== index ||
      prevProps.storyline !== storyline ||
      prevProps.lang !== lang
    ) {
      this.setState({
        storyline: storyline,
        index: index,
        lang: lang,
      });
    }
  }

  getImageName = (robot_actions) => {
    const { selectedRobot } = this.props;
    const animation = animations[selectedRobot.type].find(
      (anim) => anim.value === robot_actions.animation
    );
    return animation === undefined ? null : `${animation.file}`;
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      changeEventText,
      changeEventAnimation,
      changeEventSound,
      changeEventSoundDelay,
      params,
      disableParamsPopup,
      changeExpression,
      changeExpressionTimeout,
      changeDance,
    } = this.props;
    const { index, lang, storyline } = this.state;
    if (index > storyline.events.length - 1) {
      return <div></div>;
    }
    return (
      <div>
        <p>Text</p>
        <Input
          onBlur={disableParamsPopup}
          onChange={changeEventText}
          type="text"
          placeholder="Text"
          value={storyline.events[index].robot_actions.tts[lang]}
        />
        {params === undefined ? undefined : (
          <p>
            <small>
              Parameters:{" "}
              {params.map(
                (param, index) =>
                  `$${param}${index === params.length - 1 ? "" : ", "}`
              )}
            </small>
          </p>
        )}
        <br />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
              style={
                this.state.activeTab === "1" ? activeTabStyle : inactiveTabStyle
              }
            >
              Pepper
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
              style={
                this.state.activeTab === "2" ? activeTabStyle : inactiveTabStyle
              }
            >
              Cruzr
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" style={{ padding: "0px", marginTop: "16px" }}>
            <p>Animation</p>
            <div style={{ display: "flex", flexFlow: "row" }}>
              <div style={{ width: "94%" }}>
                <Dropdown
                  style={{ width: "60%" }}
                  options={animations.pepper}
                  onChange={(value) =>
                    changeEventAnimation("pepper", value.value)
                  }
                  value={{
                    label:
                      storyline.events[index].robot_actions.pepper.animation,
                    value:
                      storyline.events[index].robot_actions.pepper.animation,
                  }}
                  placeholder="Select an animation..."
                />
              </div>
            </div>
            <br />
            <p>Sound</p>
            <Dropdown
              style={{ width: "60%" }}
              options={sounds}
              onChange={(value) => changeEventSound(value.value)}
              value={{
                label: storyline.events[index].robot_actions.pepper.sound,
                value: storyline.events[index].robot_actions.pepper.sound,
              }}
              placeholder="Select a sound..."
            />
            <br />
            <div
              style={{ display: "flex", flexFlow: "row", maxWidth: "300px" }}
            >
              <p style={{ marginRight: "5px", width: "80%" }}>
                Sound delay [s] :
              </p>
              <Input
                type="number"
                value={storyline.events[index].robot_actions.pepper.soundDelay}
                onChange={(event) => changeEventSoundDelay(event.target.value)}
              />
            </div>
          </TabPane>
          <TabPane tabId="2" style={{ padding: "0px", marginTop: "16px" }}>
            <p>Animation</p>
            <div style={{ display: "flex", flexFlow: "row" }}>
              <div style={{ width: "94%" }}>
                <Dropdown
                  style={{ width: "60%" }}
                  options={animations.cruzr}
                  onChange={(value) =>
                    changeEventAnimation("cruzr", value.value)
                  }
                  value={{
                    label:
                      storyline.events[index].robot_actions.cruzr.animation,
                    value:
                      storyline.events[index].robot_actions.cruzr.animation,
                  }}
                  placeholder="Select an animation..."
                />
              </div>
            </div>
            <br />
            <p>Face expression</p>
            <Dropdown
              style={{ width: "60%" }}
              options={expressions}
              onChange={(value) => changeExpression(value.value)}
              value={{
                label: storyline.events[index].robot_actions.cruzr.expression,
                value: storyline.events[index].robot_actions.cruzr.expression,
              }}
              placeholder="Select a face expression..."
            />
            <br />
            <div
              style={{ display: "flex", flexFlow: "row", maxWidth: "300px" }}
            >
              <p style={{ marginRight: "5px", width: "80%" }}>
                Expression duration [s]
              </p>
              <Input
                type="number"
                value={
                  storyline.events[index].robot_actions.cruzr.expressionTimeout
                }
                onChange={(event) =>
                  changeExpressionTimeout(event.target.value)
                }
              />
            </div>
            <br />
            <p>Dance</p>
            <Dropdown
              style={{ width: "60%" }}
              options={dances}
              onChange={(value) => changeDance("cruzr", value.value)}
              value={{
                label: storyline.events[index].robot_actions.cruzr.dance,
                value: storyline.events[index].robot_actions.cruzr.dance,
              }}
              placeholder="Select a dance..."
            />
            <br />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default RobotActions;
