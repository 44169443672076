import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';

const disabled = { pointerEvents: 'none', color: 'gray', cursor: 'default' };
const active = {
  color: 'white',
  paddingLeft: '16px',
  paddingTop: '12px',
  paddingBottom: '4px',
  cursor: 'pointer',
};

const inactive = {
  paddingLeft: '16px',
  paddingTop: '12px',
  paddingBottom: '4px',
  cursor: 'pointer',
};

const activeItem = {
  color: 'black',
  paddingLeft: '16px',
  paddingTop: '12px',
  paddingBottom: '4px',
  cursor: 'pointer',
  backgroundColor: '#6a7da5',
};

const inactiveItem = {
  paddingLeft: '16px',
  paddingTop: '12px',
  paddingBottom: '4px',
  cursor: 'pointer',
};

class Nav extends Component {
  constructor(props) {
    super(props);
    this.toggleModules = this.toggleModules.bind(this);
    this.toggleAnalytics = this.toggleAnalytics.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.state = {
      collapseModules: false,
      collapseAnalytics: false,
      collapseSettings: false,
    };
  }

  toggleModules = () => {
    this.setState(state => ({
      collapseAnalytics: false,
      collapseModules: !state.collapseModules,
      collapseSettings: false,
    }));
  }

  toggleAnalytics = () => {
    this.setState(state => ({
      collapseAnalytics: !state.collapseAnalytics,
      collapseModules: false,
      collapseSettings: false,
    }));
  }

  toggleSettings = () => {
    this.setState(state => ({
      collapseSettings: !state.collapseSettings,
      collapseModules: false,
      collapseAnalytics: false,
    }));
  }

  render() {
    let { location, activeNav, selectedRobot } = this.props;
    const { collapseModules, collapseAnalytics, collapseSettings } = this.state;
    const account = JSON.parse(localStorage.getItem('account'));

    return (
      !activeNav ? <div/> :
      <ul className="nav">
        <li onClick={this.toggleModules} style={collapseModules ? active : inactive}>
          <div>
            <p> 
              <i className="pe-7s-plugin"></i>
              &nbsp;Modules&nbsp;&nbsp;
              <span
                className={collapseModules ? "pe-7s-angle-up" : "pe-7s-angle-down"}>
              </span>
            </p>
          </div>
        </li>
        <div className="line"></div>
        <Collapse style={{ backgroundColor: '#36445e' }} isOpen={collapseModules}>
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'presentation') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/presentation' || location.pathname === '/presentations' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/presentations">
            <p>
              &nbsp;&nbsp;<i className="pe-7s-display2"></i>
              &nbsp;Presentations
            </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'gallery') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/gallery' || location.pathname === '/galleries' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/galleries">
            <p>
              &nbsp;&nbsp;<i className="pe-7s-photo"></i>
              &nbsp;Galleries
            </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'faq') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/faq' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/faq">
              <p> 
                &nbsp;&nbsp;<i className="pe-7s-chat"></i>
                &nbsp;FAQs
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'team') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/team' || location.pathname === '/teams' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/teams">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-network"></i>
                &nbsp;Teams
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'calendar') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/calendar' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/calendar">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-date"></i>
                &nbsp;Calendar
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'quizz') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/quizzes' || location.pathname === '/quizz' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/quizzes">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-help1"></i>
                &nbsp;Quizzes
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'survey') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/surveys' || location.pathname === '/survey' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/surveys">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-note2"></i>
                &nbsp;Surveys
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'barcode') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/barcodes' || location.pathname === '/barcode' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/barcodes">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-camera"></i>
                &nbsp;Barcodes
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'faceRecognition') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/faceRecognitions' || location.pathname === '/facerecognition' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/faceRecognitions">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-users"></i>
                &nbsp;Face Recognitions
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'servicecontact') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/servicecontacts' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/servicecontacts">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-id"></i>
                &nbsp;Service contact
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'entertainment') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/entertainments' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/entertainments">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-joy"></i>
                &nbsp;Entertainment applications
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'news') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/news' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/news">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-news-paper"></i>
                &nbsp;News feed
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'datacollector') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/datacollectors' || location.pathname === '/datacollector' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/datacollectors">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-disk"></i>
                &nbsp;Data collector
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'receptionist') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/receptionists' || location.pathname === '/receptionist' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/receptionists">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-headphones"></i>
                &nbsp;Receptionist styles
              </p>
            </Link>
          </li>}
        </Collapse>
        {collapseModules ? <div className="line"></div> : undefined}
        <li onClick={this.toggleAnalytics} style={collapseAnalytics ? active : inactive}>
          <div>
            <p> 
              <i className="pe-7s-graph3"></i>
              &nbsp;Analytics&nbsp;&nbsp;
              <span
                className={collapseAnalytics ? "pe-7s-angle-up" : "pe-7s-angle-down"}>
              </span>
            </p>
          </div>
        </li>
        <div className="line"></div>
        <Collapse style={{ backgroundColor: '#36445e' }} isOpen={this.state.collapseAnalytics}>
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'statistics') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/statistics' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/statistics">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-graph"></i>
                &nbsp;Statistics
              </p>
            </Link>
          </li>}
        </Collapse>
        {collapseAnalytics ? <div className="line"></div> : undefined}
        <li onClick={this.toggleSettings} style={collapseSettings ? active : inactive}>
          <div>
            <p> 
              <i className="pe-7s-config"></i>
              &nbsp;Settings&nbsp;&nbsp;
              <span
                className={collapseSettings ? "pe-7s-angle-up" : "pe-7s-angle-down"}>
              </span>
            </p>
          </div>
        </li>
        <div className="line"></div>
        <Collapse style={{ backgroundColor: '#36445e' }} isOpen={this.state.collapseSettings}>
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'admin') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/adminconsole' ? activeItem : inactiveItem}>
            <Link to="/adminconsole">
              <p> 
                &nbsp;&nbsp;<i className="pe-7s-door-lock"></i>
                &nbsp;Administration console
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'smalltalk') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/smalltalk' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/smalltalk">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-smile"></i>
                &nbsp;Smalltalk
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'robotBehavior') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/robotBehaviors' || location.pathname === '/robotBehavior' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/robotBehaviors">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-stopwatch"></i>
                &nbsp;Robot Behavior
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'storyline') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/storylines' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/storylines">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-notebook"></i>
                &nbsp;Storylines
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'chatbot') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/chatbots' || location.pathname === '/chatbot' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/chatbots">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-chat"></i>
                &nbsp;Chatbots
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'wallpaper') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/wallpaper' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/wallpaper">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-photo"></i>
                &nbsp;Wallpaper
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'faceRecognition') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/persons' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/persons">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-users"></i>
                &nbsp;Persons
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'menuStructure') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/menuStructures' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/menuStructures">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-tools"></i>
                &nbsp;Menu structures
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'menuStructure') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/style' || location.pathname === '/styles' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/styles">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-paint-bucket"></i>
                &nbsp;Application style
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'yeotheater') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/yeotheater' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/yeotheater">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-video"></i>
                &nbsp;YeoTheater
              </p>
            </Link>
          </li>}
          {account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'debug') < 0 ? undefined :
          <li className="sidebar-item" style={location.pathname === '/debug' ? activeItem : inactiveItem}>
            <Link style={selectedRobot === null || selectedRobot === undefined ? disabled : undefined} to="/debug">
              <p>
                &nbsp;&nbsp;<i className="pe-7s-edit"></i>
                &nbsp;Debug
              </p>
            </Link>
          </li>}
        </Collapse>
      </ul>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Nav);