import React from 'react';
import Dropdown from 'react-select';
import { notifTargetTypes } from '../constants/triggerTypes';

class RobotNotification extends React.Component {
  constructor(props) {
    super(props);
    const { storyline, index } = this.props;
    this.state = {
        storyline,
        index,
    };
  }

  render() {
    const { changeNotifTarget } = this.props;
    const { storyline, index } = this.state;
    
    if (index > storyline.events.length-1) {
      return (<div></div>)
    }
    return (
      <div>
        <p>Select target</p>
        <Dropdown options={notifTargetTypes} onChange={changeNotifTarget} value={{ label: storyline.events[index].robot_actions.notification.target, value: storyline.events[index].robot_actions.notification.target }} placeholder="Target..." />
      </div>
    )
  }
}

export default RobotNotification;
