import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RobotSelection from './RobotSelection';
import Nav from './Nav';
import logo from './assets/images/logo.png';
import * as routes from './constants/routes';

class SideBar extends Component {

  constructor(props) {
    super(props);
    this.activateNav = this.activateNav.bind(this);
    const { selectedRobot, robots } = this.props;
    this.state = {
      activeNav: false,
      selectedRobot,
      robots,
      showSidebar: true
    };
  }

  activateNav = () => {
    this.setState({
      activeNav: true,
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ 
      selectedRobot: nextProps.selectedRobot,
      robots: nextProps.robots,
    });
  }

  toggleShow = () => {
    this.setState(state => ({
      showSidebar: !state.showSidebar,
    }));
  }

  render() {
    const { selectRobot, updateRobotList } = this.props;
    const { selectedRobot, robots } = this.state;
   // const showSidebar = true;
    return (
      <div className="sidebar">

        <div className="brand">
          <Link to={routes.HOME}>
            <span><img width="190px" src={logo} className="navbar-logo mt-10 mr-3" alt="logo" /></span>
          </Link>

        </div>
      
        {
          localStorage.getItem('account') === null ? undefined :
          <div>
            <div className="sidebar-wrapper">
              <RobotSelection selectedRobot={selectedRobot} updateRobotList={updateRobotList} robots={robots} selectRobot={selectRobot} activateNav={this.activateNav}/>
              <div className="line"></div>
              <Nav selectedRobot={selectedRobot} activeNav/>
            </div>
            <div className="sidebar-background"/>
          </div>
        }
      </div>
    )
  }
}

export default SideBar;
