import React from "react";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";
import Storyline from "../Storyline";

class SpeechAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storylines: [],
    };
  }

  componentDidMount() {
    this.fetchRobotActions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.storylineId !== this.props.storylineId) {
      this.fetchStoryline(nextProps);
    }
  }

  fetchStoryline = (nextProps) => {
    if (nextProps.storylineId !== undefined) {
      const url = `/storylines/${nextProps.storylineId}`;
      axios
        .get(url)
        .then((result) => {
          this.setState({
            storyline: result.data,
          });
        })
        .catch(() => toast("Server Error", { type: "error" }));
    }
  };

  fetchRobotActions = () => {
    const url = `/storylines`;
    axios
      .get(url)
      .then((result) => {
        this.setState({
          storylines: result.data,
        });
      })
      .catch(() => toast("Server Error", { type: "error" }));
  };

  render() {
    const { selectedRobot, selectStoryline, name, params } = this.props;
    const { storylines } = this.state;
    let storyline =
      this.props.storylineId === undefined
        ? this.props.storyline
        : this.state.storyline;
    let storylineName =
      name === null || name === undefined ? "Welcome story" : name;
    return (
      <div>
        {name === "" ? undefined : (
          <h4 style={{ textAlign: "left" }}> {storylineName}</h4>
        )}
        <div style={{ display: "flex", flexFlow: "row", width: "100%" }}>
          <div style={{ minWidth: "400px", marginRight: "4px" }}>
            <Dropdown
              options={storylines.map((storyline) => ({
                label: storyline.name,
                value: storyline,
              }))}
              onChange={(value) => selectStoryline(value.value)}
              value={
                storyline === null || storyline === undefined
                  ? null
                  : { label: storyline.name, value: storyline }
              }
              placeholder="Select a storyline"
            />
          </div>
          <Popup
            trigger={
              <span>
                <Button
                  style={{ height: "38px", marginRight: "4px" }}
                  className="btn btn-primary"
                  disabled={storyline === null || storyline === undefined}
                >
                  <i className="pe-7s-pen"></i>
                </Button>
              </span>
            }
            position="left center"
            modal={true}
          >
            {(close) => (
              <Storyline
                selectedRobot={selectedRobot}
                storyline={storyline}
                close={close}
                fetchStoryline={this.fetchRobotActions}
                selectStoryline={selectStoryline}
                params={params}
              />
            )}
          </Popup>
          <Popup
            trigger={
              <span>
                <Button style={{ height: "38px" }} className="btn btn-success">
                  <i className="pe-7s-plus"></i>
                </Button>
              </span>
            }
            position="left center"
            modal={true}
          >
            {(close) => (
              <Storyline
                selectedRobot={selectedRobot}
                storyline={null}
                newStory
                selectStoryline={selectStoryline}
                fetchStoryline={this.fetchRobotActions}
                close={close}
                params={params}
              />
            )}
          </Popup>
        </div>
      </div>
    );
  }
}

export default SpeechAction;
