import React, { Component } from 'react';
import Storyline from '../../components/Storyline'

class PageStoryline extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { selectedRobot, location } = this.props;
    const { storyline } = location.state;

    return (
      <div style={{ margin: '20px' }}>
        <Storyline selectedRobot={selectedRobot} storyline={storyline} />
      </div>
    );
  }
}

export default PageStoryline;
