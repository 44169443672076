import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { ChromePicker } from "react-color";
import NotificationSystem from "react-notification-system";
import Dropdown from "react-select";
import { Button, Input } from "reactstrap";
import * as fonts from "../constants/fonts";
import { hexToRGBA } from "../utils/colorConverter";

class GlobalStyle extends Component {
  constructor(props) {
    super(props);
    const { style } = this.props;
    this.state = {
      style: style,
      fontColorVisible: false,
      buttonTextColorVisible: false,
      buttonColorVisible: false,
    };
  }

  handleChangeComplete = (color) => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle.menu["android_widget_Button"].mTextColor = color.hex;
    this.setState({
      style: tempStyle,
    });
  };

  toggleTextInCaps = () => {
    const { style } = this.state;
    const tempStyle = style;
    tempStyle.menu["android_widget_Button"].mTextAllCaps =
      !tempStyle.menu["android_widget_Button"].mTextAllCaps;
    this.setState({
      style: tempStyle,
    });
  };

  toggleFontColorVisible = () => {
    this.setState((prevState) => ({
      fontColorVisible: !prevState.fontColorVisible,
      buttonTextColorVisible: false,
      buttonColorVisible: false,
    }));
  };

  toggleButtonTextColorVisible = () => {
    this.setState((prevState) => ({
      buttonTextColorVisible: !prevState.buttonTextColorVisible,
      fontColorVisible: false,
      buttonColorVisible: false,
    }));
  };

  toggleButtonColorVisible = () => {
    this.setState((prevState) => ({
      buttonColorVisible: !prevState.buttonColorVisible,
      fontColorVisible: false,
      buttonTextColorVisible: false,
    }));
  };

  render() {
    const {
      style,
      changeBackgroundImage,
      changeFontColor,
      changeFontFamily,
      changeButtonTextSize,
      changeButtonTextColor,
      changeButtonColor,
      deleteBackgroundImage,
      saveStyle,
    } = this.props;
    const { fontColorVisible, buttonTextColorVisible, buttonColorVisible } =
      this.state;
    return (
      <div>
        <h3 className="title">Properties</h3>
        <div style={{ display: "flex", flexFlow: "row" }}>
          <div style={{ width: "50%" }}>
            <h4 style={{ textAlign: "left" }}>Background image</h4>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ marginLeft: "20px", position: "relative" }}>
                {style["background-image"] === "" ? (
                  <div style={{ height: "150px", width: "300px" }} />
                ) : (
                  <img
                    height="200px"
                    className="slide-image"
                    src={`${style["background-image"]}`}
                    alt="Style background"
                  />
                )}
                {style["background-image"] === "" ? undefined : (
                  <div
                    style={{
                      marginTop: "0px",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <Button onClick={deleteBackgroundImage}>X</Button>
                  </div>
                )}
                <label
                  htmlFor="slide-file"
                  className="input-image"
                  style={{
                    marginTop: "0px",
                    position: "absolute",
                    top: "35%",
                    left: "25%",
                  }}
                >
                  <div>
                    <i
                      style={{ marginLeft: "55px" }}
                      className="fa fa-image"
                    ></i>
                    <p>Choose an image file</p>
                  </div>
                  <input
                    id="slide-file"
                    type="file"
                    accept="image/*"
                    onChange={(event) => changeBackgroundImage(event)}
                    style={{ cursor: "pointer" }}
                  />
                </label>
              </div>
            </div>

            <h4 style={{ textAlign: "left" }}>Font family</h4>
            <div style={{ maxWidth: "250px" }}>
              <Dropdown
                className="dropdown-speechaction"
                options={fonts.fonts}
                onChange={changeFontFamily}
                value={{
                  label: style["font-family"],
                  value: style["font-family"],
                }}
                placeholder="Select a font family"
              />
            </div>
            <h4 style={{ textAlign: "left" }}>Font color</h4>
            <Button onClick={this.toggleFontColorVisible}>Choose</Button>
            {!fontColorVisible ? undefined : (
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={this.toggleFontColorVisible}
                />
                <div style={{ position: "absolute" }}>
                  <ChromePicker
                    disableAlpha={true}
                    color={style["font-color"]}
                    onChange={changeFontColor}
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ width: "50%", marginRight: "40px" }}>
            <h4 style={{ textAlign: "left" }}>Button text size</h4>
            <Slider
              defaultValue={style.menu["android_widget_Button"].mTextSize}
              min={14}
              max={28}
              value={style.menu["android_widget_Button"].mTextSize}
              onChange={(value) => changeButtonTextSize("menu", value)}
            />
            <Input
              style={{ marginTop: "10px", width: "60px" }}
              type="text"
              disabled
              value={style.menu["android_widget_Button"].mTextSize}
            ></Input>

            <h4 style={{ textAlign: "left" }}>Button text color</h4>
            <Button onClick={this.toggleButtonTextColorVisible}>Choose</Button>
            {!buttonTextColorVisible ? undefined : (
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={this.toggleButtonTextColorVisible}
                />
                <div style={{ position: "absolute" }}>
                  <ChromePicker
                    disableAlpha={true}
                    color={style.menu["android_widget_Button"].mTextColor}
                    onChange={(value) => changeButtonTextColor("menu", value)}
                  />
                </div>
              </div>
            )}

            <h4 style={{ textAlign: "left" }}>Button color</h4>
            <Button onClick={this.toggleButtonColorVisible}>Choose</Button>
            {!buttonColorVisible ? undefined : (
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={this.toggleButtonColorVisible}
                />
                <div style={{ position: "absolute" }}>
                  <ChromePicker
                    color={hexToRGBA(
                      style.menu["android_widget_Button"].mBackgroundTint
                    )}
                    onChange={(value) => changeButtonColor("menu", value)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <br />
        <div style={{ position: "absolute", bottom: "45px", right: "35px" }}>
          <Button color="primary" onClick={saveStyle}>
            Save changes
          </Button>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        <br />
      </div>
    );
  }
}

export default GlobalStyle;
