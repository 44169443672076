import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'reactstrap';

class Header extends React.Component {
  static propTypes = {
    history: PropTypes.PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  logout = () => {
    const { history } = this.props;
    localStorage.removeItem('account');
    history.push('/signin');
  }

  toggleNavbar() {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  render() {
    const account = JSON.parse(localStorage.getItem('account'));
    const authNav = (
      <div>
        <Button style={{ float: 'right', marginTop: '18px', marginRight: '20px' }} className="btn-neutral" to="/signin" onClick={this.logout} color="danger">Sign out</Button>
        <Link style={{ float: 'right', marginTop: '18px', marginRight: '20px' }} className="btn-neutral" to="/profile" color="primary"><Button color="primary">My profile</Button></Link>
        {
          account === null || account === undefined ||  account.components === undefined || account.components.findIndex(mod => mod.name === 'waitingroom') < 0 ? undefined :
          <Link style={{ float: 'right', marginTop: '18px', marginRight: '20px' }} className="btn-neutral" to="/waitingroom" color="primary"><Button color="primary">Virtual Waiting Room</Button></Link>
        }
      </div>
    );

    const nonAuthNav = (
      <Button style={{ float: 'right', marginTop: '18px', marginRight: '20px' }} to="/signin" color="primary">Sign In</Button>
    );

    return (
      <div className="custom-nav1">
        <div style={{ float: 'left', marginLeft: '10px' }}><h3>YeoSuite</h3></div>
        {localStorage.getItem('account') === null ? nonAuthNav : authNav}
      </div>
    );
  }
}

export default withRouter(Header);
