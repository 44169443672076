export function hexToRGBA(hex) {
    const hAlpha = parseInt(hex.substring(1, 3), 16)/255;
    const hRed = parseInt(hex.substring(3, 5), 16);
    const hGreen = parseInt(hex.substring(5, 7), 16);
    const hBlue = parseInt(hex.substring(7, 9), 16);

    const rgb = {
      r: hRed,
      g: hGreen,
      b: hBlue,
      a: hAlpha,
    };

    return rgb;
  }

  export function hexToStyle(hex) {
    const hAlpha = parseInt(hex.substring(1, 3), 16)/255;
    const hRed = parseInt(hex.substring(3, 5), 16);
    const hGreen = parseInt(hex.substring(5, 7), 16);
    const hBlue = parseInt(hex.substring(7, 9), 16);

    return `rgba(${hRed},${hGreen},${hBlue},${hAlpha})`;
  }