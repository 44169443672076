import React, { Component } from "react";
import update from "react-addons-update";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, FormGroup, Input, Label } from "reactstrap";
import LanguagePicker from "../../components/LanguagePicker";
import SpeechAction from "../../components/SpeechAction";
import axios from "../../utils/AxiosService";
import { receiverOptions } from "./constants/constants";

class Barcode extends Component {
  constructor(props) {
    super(props);
    this.setLang = this.setLang.bind(this);
    this.selectSpeechAction = this.selectSpeechAction.bind(this);
    this.state = {
      barcode: this.props.location.state.barcode,
      error: null,
      message: null,
      lang: "en",
      changesMade: false,
    };
  }
  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  setLang = (lang) => {
    this.setState({
      lang,
    });
  };

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  selectSpeechAction = (action, storyline) => {
    const { barcode, lang } = this.state;
    const tmpbarcode = barcode;
    barcode[lang][action] = storyline;
    this.setState({
      barcode: tmpbarcode,
      changesMade: true,
    });
  };

  changeSeparator = (text) => {
    const { barcode } = this.state;
    const tmpbarcode = barcode;
    tmpbarcode.dataFormat.separator = text;
    this.setState({
      barcode: tmpbarcode,
      changesMade: true,
    });
  };

  changeName = (name) => {
    const { barcode, lang } = this.state;
    const tmpbarcode = barcode;
    tmpbarcode[lang].name = name;
    this.setState({
      barcode: tmpbarcode,
      changesMade: true,
    });
  };

  saveBarcode = () => {
    const { selectedRobot } = this.props;
    const { barcode } = this.state;
    const url = `/barcodes/${barcode._id}/robots/${selectedRobot._id}`;
    axios.put(url, { barcode: barcode }).then((response) => {
      if (response.status === 200) {
        toast("Barcode saved", { type: "success" });
        this.setState({
          barcode: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  changeDataName = (name, dataInd) => {
    const { barcode } = this.state;
    const tempBarcode = barcode;
    tempBarcode.dataFormat.data[dataInd] = name;
    this.setState({
      barcode: tempBarcode,
      changesMade: true,
    });
  };

  addData = (data) => {
    const { barcode } = this.state;
    const tempBarcode = barcode;
    tempBarcode.dataFormat.data.push(data.value);

    this.setState({
      barcode: tempBarcode,
      changesMade: true,
    });
  };

  removeData = (index) => {
    const { barcode } = this.state;
    const tempBarcode = barcode;
    tempBarcode.dataFormat.data.splice(index, 1);

    this.setState({
      barcode: tempBarcode,
      changesMade: true,
    });
  };

  render() {
    //const { barcode } = location.state;
    const { barcode, lang } = this.state;
    const { selectedRobot } = this.props;
    let preview = "";
    barcode.dataFormat.data.forEach(
      (data, index) =>
        (preview +=
          data +
          (index === barcode.dataFormat.data.length - 1
            ? ""
            : barcode.dataFormat.separator))
    );
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <LanguagePicker setLang={this.setLang} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Barcode</h1>
                <p className="category">Here you can create barcode reader</p>
              </div>
              <div className="content">
                <h4>Name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  placeholder="Name"
                  value={barcode[lang].name}
                />
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={barcode[lang].speechAction}
                  selectStoryline={(value) =>
                    this.selectSpeechAction("speechAction", value)
                  }
                  name="Introduction Action"
                />
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={barcode[lang].successAction}
                  selectStoryline={(value) =>
                    this.selectSpeechAction("successAction", value)
                  }
                  name="Success Action"
                  params={barcode.dataFormat.data}
                />
                <SpeechAction
                  selectedRobot={selectedRobot}
                  storyline={barcode[lang].failureAction}
                  selectStoryline={(value) =>
                    this.selectSpeechAction("failureAction", value)
                  }
                  name="Failure Action"
                />
                <h4>Receiver</h4>
                <Dropdown
                  options={receiverOptions}
                  value={{
                    label: receiverOptions.find(
                      (option) => option.value === barcode.receiver
                    ).label,
                    value: barcode.receiver,
                  }}
                  onChange={(value) =>
                    this.setState({
                      barcode: update(this.state.barcode, {
                        receiver: { $set: value.value },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                  placeholder="Receiver..."
                />
                <h4>Receiver text</h4>
                <Input
                  onChange={(event) =>
                    this.setState({
                      barcode: update(this.state.barcode, {
                        receiverText: { $set: event.target.value },
                      }),
                      changesMade: update(this.state.changesMade, {
                        $set: true,
                      }),
                    })
                  }
                  type="text"
                  placeholder="Receiver text..."
                  value={barcode.receiverText}
                  disabled={barcode.receiver === "cms"}
                />
                <FormGroup style={{ marginTop: "20px" }}>
                  <Label>Show Data after success:</Label>
                  <Input
                    onChange={() =>
                      this.setState({
                        barcode: update(this.state.barcode, {
                          showData: { $set: !barcode.showData },
                        }),
                        changesMade: update(this.state.changesMade, {
                          $set: true,
                        }),
                      })
                    }
                    type="checkbox"
                    name="showData"
                    defaultChecked={barcode.showData}
                    style={{ marginLeft: "10px" }}
                  />
                </FormGroup>

                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/barcodes">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveBarcode}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="content">
                <h4>Data</h4>
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th className="th-description">Data</th>
                      <th className="th-description">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {barcode.dataFormat.data.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <Input
                            style={{ height: "38px" }}
                            onChange={(event) =>
                              this.changeDataName(event.target.value, index)
                            }
                            value={barcode.dataFormat.data[index]}
                            placeholder="data"
                          />
                        </td>
                        <td className="td-actions">
                          <button
                            type="button"
                            onClick={() => this.removeData(index)}
                            rel="tooltip"
                            data-placement="left"
                            title=""
                            className="btn btn-danger btn-simple btn-icon "
                            data-original-title="Remove Data"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={this.addData}
                >
                  Add data
                </Button>
                <h4>Separator</h4>
                <Input
                  onChange={(event) => this.changeSeparator(event.target.value)}
                  type="text"
                  placeholder=";"
                  value={barcode.dataFormat.separator}
                />
                <h4>Preview</h4>
                <Input type="text" readOnly value={preview} />
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Barcode;
