export const expressions = [
    { label: 'None', value: '' },
    { label: 'Tech daze', value: 'techface_daze' },
    { label: 'Tech smile', value: 'techface_smile' },
    { label: 'Tech happy', value: 'techface_happy' },
    { label: 'Tech love', value: 'techface_love' },
    { label: 'Tech shy', value: 'techface_shy' },
    { label: 'Tech music', value: 'techface_music' },
    { label: 'Tech speak', value: 'techface_speak' },
    { label: 'Tech upset', value: 'techface_upset' },
    { label: 'Tech amazing', value: 'techface_amazing' },
    { label: 'Tech naughty', value: 'techface_naughty' },
    { label: 'Tech grin', value: 'techface_grin' },
    { label: 'Tech wronged', value: 'techface_wronged' },
    { label: 'Tech proud', value: 'techface_proud' },
    { label: 'Tech default', value: 'techface_default' },
    { label: 'Tech data', value: 'techface_data' },
    { label: 'Tech monitor', value: 'techface_monitor' },
    { label: 'Tech standby', value: 'techface_standby' },
    { label: 'Tech doubt', value: 'techface_doubt' },
    { label: 'Daze', value: 'face_daze' },
    { label: 'Happy', value: 'face_happy' },
    { label: 'Love', value: 'face_love' },
    { label: 'Shy', value: 'face_shy' },
    { label: 'Music', value: 'face_music' },
    { label: 'Speak', value: 'face_speak' },
    { label: 'Upset', value: 'face_upset' },
    { label: 'Amazing', value: 'face_amazing' },
    { label: 'Naughty', value: 'face_naughty' },
    { label: 'Grin', value: 'face_grin' },
    { label: 'Wronged', value: 'face_wronged' },
    { label: 'Proud', value: 'face_proud' },
    { label: 'Smile', value: 'face_smile' },
    { label: 'Default', value: 'face_default' },
];