import React, { Component } from "react";
import update from "react-addons-update";
import { Prompt } from "react-router";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button, Input, Spinner } from "reactstrap";
import socketIOClient from "socket.io-client";
import UIfx from "uifx";
import SpeechAction from "../../components/SpeechAction";
import languages from "../../constants/languages";
import axios from "../../utils/AxiosService";
import bellSound from "./assets/sounds/bell.mp3";
import cancelSound from "./assets/sounds/cancel.mp3";
import notifSound from "./assets/sounds/patientwaiting.wav";
import alertSound from "./assets/sounds/ringtone.mp3";
import "./styles/styles.css";

let socket;
const params = ["firstname", "lastname"];

class WaitingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changesMade: false,
      waitingroom: null,
      patientsList: [],
      ivorisPatients: [],
      patientWaiting: false,
      robotAvailable: true,
      connected: false,
      selectedPatient: null,
      customMessage: "",
    };
  }

  componentDidMount() {
    const { selectedRobot } = this.props;
    this.fetchWaitingRoom();
    this.connectToBackend();
    this.fetchPatients();
    socket.emit("waitingroominitlist", { robotId: selectedRobot._id });
  }

  fetchWaitingRoom = () => {
    const url = `/waitingrooms`;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          waitingroom: response.data,
        });
      })
      .catch(() => {
        toast("Server error fetching waiting room", { type: "error" });
      });
  };

  fetchPatients = () => {
    const url = `http://localhost:8888/patients`;
    axios
      .get(url)
      .then((response) => {
        console.log(response);
        this.setState({
          ivorisPatients: response.data.recordset.map((patient) => ({
            id: patient.id,
            firstName: patient.VORNAME,
            lastName: patient.NAME,
            mobile: patient.INFO2,
          })),
        });
      })
      .catch(() => {
        toast("Server error fetching waiting room", { type: "error" });
      });
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  playStoryline = (storylineInd, firstname, lastname) => {
    const { selectedRobot } = this.props;
    const { waitingroom } = this.state;
    console.log(waitingroom.actions[storylineInd]);
    /*const finalStory = this.resolveParams(waitingroom.actions[storylineInd], firstName, lastName)
    console.log(finalStory)*/
    const url = `/debug/storylinepreview/${selectedRobot._id}`;
    axios
      .post(url, {
        storyline: waitingroom.actions[storylineInd],
        params: { firstname, lastname },
      })
      .then((response) => {
        if (response.status === 200) {
          toast("Storyline playing", { type: "success" });
        } else {
          toast("Robot not connected", { type: "error" });
        }
      })
      .catch(() => {
        toast("Robot not connected", { type: "error" });
      });
  };

  resolveParams = (storyline, firstName, lastName) => {
    let finalStory = storyline;
    finalStory.events.forEach((event) => {
      languages.forEach((lang) => {
        event.robot_actions.tts[lang] = event.robot_actions.tts[lang].replace(
          "$firstname",
          firstName
        );
        event.robot_actions.tts[lang] = event.robot_actions.tts[lang].replace(
          "$lastname",
          lastName
        );
      });
    });
    return finalStory;
  };

  changeWaitingRoomName = (name) => {
    const { waitingroom } = this.state;
    let tmpWaitingRoom = waitingroom;
    tmpWaitingRoom.name = name;
    this.setState({
      waitingroom: tmpWaitingRoom,
      changesMade: true,
    });
  };

  addAction = () => {
    const { waitingroom } = this.state;
    let tmpWaitingRoom = waitingroom;
    tmpWaitingRoom.actions.push("");
    this.setState({
      waitingroom: tmpWaitingRoom,
      changesMade: true,
    });
  };

  removePatient = (index, send) => {
    console.log(index);
    const { patientsList } = this.state;
    const { selectedRobot } = this.props;
    let tmpPatientsList = patientsList;
    tmpPatientsList.splice(index, 1);
    if (send) {
      socket.emit("waitingroomdeletepatient", {
        robotId: selectedRobot._id,
        index,
      });
    }
    const beep = new UIfx(cancelSound);
    beep.play();
    this.setState({
      patientsList: tmpPatientsList,
      changesMade: true,
    });
  };

  saveWaitingRoom = () => {
    const { selectedRobot } = this.props;
    const { waitingroom } = this.state;
    const url = `/waitingRooms/${waitingroom._id}/robots/${selectedRobot._id}`;
    axios.put(url, { waitingRoom: waitingroom }).then((response) => {
      if (response.status === 200) {
        toast("Waiting room saved", { type: "success" });
        this.setState({
          waitingroom: response.data,
          changesMade: false,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  connectToBackend = () => {
    socket = socketIOClient(process.env.REACT_APP_SOCKETIO_URL, {
      rejectUnauthorized: false,
    });
    console.log(process.env.REACT_APP_SOCKETIO_URL);
    const { selectedRobot } = this.props;
    socket.on("connect", (data) => {
      console.log("Connected");
      socket.emit("robotauthwaitingroom", { robotId: selectedRobot._id });
    });

    socket.on("initList", (data) => {
      const beep = new UIfx(alertSound);
      beep.play();
      this.setState({
        patientsList: data.patients,
        patientWaiting: data.patientWaiting,
        connected: true,
      });
    });

    socket.on("newPatient", (data) => {
      const { patientsList } = this.state;
      const tmpPatientsList = patientsList;
      tmpPatientsList.push(data);
      const beep = new UIfx(alertSound);
      beep.play();
      this.setState({
        patientsList: tmpPatientsList,
        connected: true,
      });
    });

    socket.on("newPatientData", (data) => {
      const { patientsList } = this.state;
      const tmpPatientsList = patientsList;
      tmpPatientsList.push(data);
      const beep = new UIfx(alertSound);
      beep.play();
      this.setState({
        patientsList: tmpPatientsList,
        connected: true,
      });
    });

    socket.on("robotAvailable", () => {
      const beep = new UIfx(bellSound);
      beep.play();
      this.setState({
        robotAvailable: true,
      });
    });

    socket.on("robotOccupied", () => {
      this.setState({
        robotAvailable: false,
      });
    });

    socket.on("patientWaiting", () => {
      const beep = new UIfx(notifSound);
      beep.play();
      this.setState({
        patientWaiting: true,
      });
    });

    socket.on("waitingroomdeletepatient", (data) => {
      this.removePatient(data, false);
    });

    socket.on("waitingroomdeletenotif", (data) => {
      this.removePatientWaiting(false);
    });
  };

  removePatientWaiting = (send) => {
    const { selectedRobot } = this.props;
    const beep = new UIfx(cancelSound);
    beep.play();
    if (send) {
      socket.emit("waitingroomdeletenotif", { robotId: selectedRobot._id });
    }
    this.setState({
      patientWaiting: false,
    });
  };

  sendMMSToPatient = () => {
    const { selectedPatient, phoneNumber, customMessage } = this.state;
    if (selectedPatient === null) {
      toast("Please choose a patient in the list", { type: "error" });
    } else if (phoneNumber === null) {
      toast("Please enter a phone number", { type: "error" });
    } else {
      const url = `/debug/sendMMS`;
      axios
        .put(url, { content: selectedPatient, customMessage, to: phoneNumber })
        .then((response) => {
          if (response.status === 200) {
            toast("MMS sent", { type: "success" });
          } else {
            toast("Server Error", { type: "error" });
          }
        });
    }
  };

  selectPatient = (patient) => {
    console.log(patient);
    this.setState({
      selectedPatient: patient.value,
      phoneNumber: patient.value.mobile,
    });
  };

  render() {
    const {
      waitingroom,
      patientsList,
      patientWaiting,
      robotAvailable,
      selectedPatient,
      phoneNumber,
      ivorisPatients,
      customMessage,
    } = this.state;
    const { selectedRobot } = this.props;
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Virtual Waiting Room</h1>
                <p className="category">Here is your patients list</p>
              </div>
              <div className="content">
                {patientWaiting ? (
                  <div
                    onClick={() => this.removePatientWaiting(true)}
                    className="patientWaiting"
                  >
                    <b>A patient wants to see you</b>
                  </div>
                ) : undefined}
                <Popup
                  trigger={
                    <Button
                      style={{
                        position: "absolute",
                        top: "25px",
                        right: "106px",
                      }}
                      color="primary"
                      disabled={waitingroom === null}
                    >
                      <i className="pe-7s-mail" />
                    </Button>
                  }
                  position="right center"
                  modal={true}
                >
                  {(close) => (
                    <div style={{ padding: "20px" }}>
                      <h2>Send MMS to patients</h2>
                      <h4>To</h4>
                      <Dropdown
                        isSearchable
                        className="dropdown-speechaction"
                        options={ivorisPatients.map((patient) => ({
                          label: `${patient.firstName} ${patient.lastName}`,
                          value: patient,
                        }))}
                        //options={[ { label: 'Samuel Mayor', value: { firstName: 'Samuel', lastName: 'Mayor', mobile: '079 959 39 35'} }, { label: 'Jonas Pyroth', value: { firstName: 'Jonas', lastName: 'Pyroth', mobile: '079 511 74 54'} }]}
                        onChange={(value) => this.selectPatient(value)}
                        value={
                          selectedPatient === null
                            ? null
                            : {
                                label: `${selectedPatient.firstName} ${selectedPatient.lastName}`,
                                value: selectedPatient,
                              }
                        }
                        placeholder="Select an patient"
                      />
                      <h4>Phone number</h4>
                      <Input
                        type="text"
                        onChange={(event) =>
                          this.setState({ phoneNumber: event.target.value })
                        }
                        placeholder="Ex: +41791234567"
                        value={phoneNumber}
                      />
                      <h4>Custom message (leave blank for default message)</h4>
                      <Input
                        type="text"
                        onChange={(event) =>
                          this.setState({ customMessage: event.target.value })
                        }
                        placeholder="Custom message..."
                        value={customMessage}
                      />
                      <br />
                      <Button
                        style={{ float: "right" }}
                        color="danger"
                        onClick={close}
                      >
                        Close
                        <i className="pe-7s-close" />
                      </Button>

                      <Button
                        style={{ float: "right", marginRight: "10px" }}
                        color="primary"
                        onClick={this.sendMMSToPatient}
                      >
                        Send
                        <i className="pe-7s-mail" />
                      </Button>
                      <br />
                    </div>
                  )}
                </Popup>
                <Popup
                  trigger={
                    <Button
                      style={{
                        position: "absolute",
                        top: "25px",
                        right: "46px",
                      }}
                      color="primary"
                      disabled={waitingroom === null}
                    >
                      <i className="pe-7s-settings" />
                    </Button>
                  }
                  position="right center"
                  modal={true}
                >
                  {(close) => (
                    <div style={{ padding: "20px" }}>
                      <h2>Edit Virtual Waiting Room settings</h2>
                      <h4>Name</h4>
                      <Input
                        type="text"
                        onChange={(event) =>
                          this.changeWaitingRoomName(event.target.value)
                        }
                        placeholder="Waiting room name..."
                        value={waitingroom.name}
                      />
                      <h4>Patient actions</h4>
                      <table className="table table-bigboy">
                        <thead>
                          <tr>
                            <th className="th-description">Storyline</th>
                            <th className="th-description">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {waitingroom.actions.map((action, index) => (
                            <tr key={index}>
                              <td>
                                <SpeechAction
                                  selectedRobot={selectedRobot}
                                  storyline={action}
                                  selectStoryline={(value) =>
                                    this.setState({
                                      waitingroom: update(
                                        this.state.waitingroom,
                                        {
                                          actions: { [index]: { $set: value } },
                                        }
                                      ),
                                      changesMade: update(
                                        this.state.changesMade,
                                        { $set: true }
                                      ),
                                    })
                                  }
                                  name=""
                                  params={params}
                                />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.setState({
                                      waitingroom: update(
                                        this.state.waitingroom,
                                        { actions: { $splice: [[index, 1]] } }
                                      ),
                                      changesMade: update(
                                        this.state.changesMade,
                                        { $set: true }
                                      ),
                                    })
                                  }
                                  rel="tooltip"
                                  data-placement="left"
                                  title=""
                                  className="btn btn-danger btn-simple btn-icon "
                                  data-original-title="Remove Storyline"
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <br />
                      <Button color="success" onClick={this.addAction}>
                        Add action <i className="pe-7s-plus" />
                      </Button>
                      <Button
                        style={{ float: "right" }}
                        color="success"
                        onClick={() => {
                          this.saveWaitingRoom();
                          close();
                        }}
                      >
                        Save <i className="pe-7s-diskette" />
                      </Button>
                    </div>
                  )}
                </Popup>
                {waitingroom === null ? (
                  <div>
                    <Spinner color="danger" />
                  </div>
                ) : (
                  <table className="table table-bigboy">
                    <thead>
                      <tr>
                        <th>Name</th>
                        {waitingroom.actions.map((action, index) => (
                          <th key={index}>{action.name}</th>
                        ))}
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patientsList.map((patient, patIndex) => (
                        <tr key={patIndex}>
                          <td>{`${patient.firstName} ${patient.lastName}`}</td>
                          {waitingroom.actions.map((action, index) => (
                            <td key={index}>
                              <Button
                                color="primary"
                                disabled={!robotAvailable}
                                onClick={() =>
                                  this.playStoryline(
                                    index,
                                    patient.firstName,
                                    patient.lastName
                                  )
                                }
                              >
                                <i className="pe-7s-play" />
                              </Button>
                            </td>
                          ))}
                          <td>
                            <Button
                              color="danger"
                              disabled={!robotAvailable}
                              onClick={() => this.removePatient(patIndex, true)}
                            >
                              <i className="pe-7s-trash" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WaitingRoom;
