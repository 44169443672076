import Slider from "rc-slider";
import React, { Component } from "react";
import update from "react-addons-update";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import Dropdown from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import { Button } from "reactstrap";
import offlineDot from "../../assets/images/offline.png";
import onlineDot from "../../assets/images/online.png";
import waitingDot from "../../assets/images/waiting.png";
import { robotImage } from "../../constants/robotImages";
import axios from "../../utils/AxiosService";

const selectedStyle = {
  width: "20%",
  margin: "30px",
  cursor: "pointer",
  backgroundColor: "#e6f3ff",
  borderColor: "#cce6ff",
};
const unselectedStyle = { width: "20%", margin: "30px", cursor: "pointer" };

class Home extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      robots: [],
      address: [],
      changesMade: false,
      chatbots: [],
      styles: [],
      robotBehaviors: [],
      menuStructures: [],
      selectedRobot,
      companyName: "",
      robotStatus: [],
      statusLoaded: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state !== undefined) {
      const { message } = location.state;
      if (message !== null) {
        toast(message, { type: "error" });
      }
    }
    this.fetchRobots();
    this.fetchStatus();
    this.fetchChatbots();
    this.fetchStyles();
    this.fetchBehaviors();
    this.fetchMenuStructures();
    this.getCompanyName();
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  getCompanyName = () => {
    const url = `/companies/name`;
    axios.get(url).then((result) => {
      console.log(result);
      this.setState({
        companyName: result.data,
      });
    });
  };

  fetchRobots = () => {
    const url = `/robots`;
    axios.get(url).then((result) => {
      console.log(result.data);
      this.setState({
        robots: result.data,
      });
    });
  };

  fetchStatus = () => {
    const url = `/robots/status`;
    axios.get(url).then((response) => {
      this.setState({
        robotStatus: response.data,
        statusLoaded: true,
      });
    });
  };

  computeStatus = (robot) => {
    const { robotStatus, statusLoaded } = this.state;
    if (statusLoaded && robotStatus !== undefined && robotStatus.length > 0) {
      const tarRobot = robotStatus.find(
        (robotStat) => robot !== undefined && robotStat.robotId === robot._id
      );
      if (tarRobot !== undefined) {
        if (tarRobot.online) {
          return onlineDot;
        } else {
          return offlineDot;
        }
      }
    }
    return waitingDot;
  };

  isOnline = (robot) => {
    const { robotStatus, statusLoaded } = this.state;
    if (statusLoaded && robotStatus !== undefined && robotStatus.length > 0) {
      const tarRobot = robotStatus.find(
        (robotStat) => robot !== undefined && robotStat.robotId === robot._id
      );
      if (tarRobot !== undefined) {
        return tarRobot.online;
      }
    }
    return false;
  };

  fetchChatbots = () => {
    const url = `/chatbots`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          chatbots: response.data.map((config) => config.configuration),
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  fetchStyles = () => {
    const url = `/styles`;
    axios.get(url).then((response) => {
      if (response.status === undefined) {
        this.setState({ unauth: true });
      } else if (response.status === 200) {
        this.setState({
          styles: response.data.map((config) => config.configuration),
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  fetchBehaviors = () => {
    const url = `/robotBehaviors`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          robotBehaviors: response.data.map(
            (robotBehaviors) => robotBehaviors.configuration
          ),
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  fetchMenuStructures = () => {
    const url = `/menuStructures`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          menuStructures: response.data,
        });
      } else {
        toast("Server Error", { type: "error" });
      }
    });
  };

  selectRobot(robot) {
    const { selectRobot } = this.props;
    localStorage.setItem("selectedRobot", JSON.stringify(robot));
    this.setState({
      selectedRobot: robot,
    });
    selectRobot(robot);
  }

  saveRobot = (selectedRobot) => {
    const url = `/robots/${selectedRobot._id}`;
    axios.put(url, { robot: selectedRobot }).then((response) => {
      this.setState({ changesMade: false });
      toast("Robots saved", { type: "success" });
    });
    this.fetchRobots();
  };

  changeChatbot = (chatbot) => {
    const { selectedRobot } = this.state;
    const tempRobot = selectedRobot;
    tempRobot.chatbot = chatbot;
    this.setState({
      selectedRobot: tempRobot,
      changesMade: true,
    });
  };

  changeStyle = (style) => {
    const { selectedRobot } = this.state;
    const tempRobot = selectedRobot;
    tempRobot.style = style;
    this.setState({
      selectedRobot: tempRobot,
      changesMade: true,
    });
  };

  changeMenuStructure = (menuStructure) => {
    const { selectedRobot } = this.state;
    const tempRobot = selectedRobot;
    tempRobot.menuStructure = menuStructure;
    this.setState({
      selectedRobot: tempRobot,
      changesMade: true,
    });
  };

  changeBehavior = (robotBehavior) => {
    const { selectedRobot } = this.state;
    const tempRobot = selectedRobot;
    tempRobot.robotBehavior = robotBehavior;
    this.setState({
      selectedRobot: tempRobot,
      changesMade: true,
    });
  };

  syncRobot = (robot) => {
    const url = `/debug/updateConfigs/${robot._id}`;
    axios.post(url, { robot }).then((response) => {
      if (response.data.success) {
        toast("Configurations sent", { type: "success" });
      } else {
        toast("Robot not online", { type: "error" });
      }
    });
  };

  getRobotLanguage = () => {
    const { selectedRobot } = this.state;
    switch (selectedRobot.defaultConfiguration.language) {
      case "en":
        return { label: "English", value: "en" };
      case "de":
        return { label: "German", value: "de" };
      case "fr":
        return { label: "French", value: "fr" };
      case "it":
        return { label: "Italian", value: "it" };
      default:
        return { label: "English", value: "en" };
    }
  };

  render() {
    const {
      robots,
      chatbots,
      styles,
      robotBehaviors,
      menuStructures,
      selectedRobot,
      companyName,
      changesMade,
    } = this.state;
    let currentChatbot;
    let currentStyle;
    let currentBehavior;
    let currentMenuStructure;

    if (selectedRobot !== undefined && selectedRobot !== null) {
      currentChatbot = chatbots.find(
        (chatbot) => chatbot._id === selectedRobot.chatbot
      );
      currentStyle = styles.find((style) => style._id === selectedRobot.style);
      currentBehavior = robotBehaviors.find(
        (robotBehavior) => robotBehavior._id === selectedRobot.robotBehavior
      );
      currentMenuStructure = menuStructures.find(
        (menuStructure) => menuStructure._id === selectedRobot.menuStructure
      );
    }
    return (
      <div style={{ margin: "20px" }}>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Content management</h1>
                <p className="category">
                  Here you can link the content you created to one of you robots
                </p>
              </div>
            </div>
          </div>
        </div>
        <h3
          className="header"
          style={{ marginBottom: "30px", marginLeft: "30px" }}
        >
          {companyName}'s robots
        </h3>
        <div
          className="robotsContainer"
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {robots.length <= 0
            ? undefined
            : robots.map((robot, index) => (
                <div
                  className="card"
                  style={
                    selectedRobot !== undefined &&
                    selectedRobot !== null &&
                    robot._id === selectedRobot._id
                      ? selectedStyle
                      : unselectedStyle
                  }
                  onClick={() => this.selectRobot(robot)}
                >
                  <img
                    style={{ float: "right", margin: "10px" }}
                    height="16px"
                    alt="status"
                    src={this.computeStatus(robot)}
                  />
                  <Popup
                    trigger={
                      <div className="contentmanagement">
                        <div className="user-wrapper">
                          <div className="user">
                            <div className="photo-container">
                              <img
                                src={robotImage[robot.type]}
                                alt="Robot avatar"
                                className="photo"
                              />
                            </div>
                            <div className="userinfo">
                              <div className="username">{robot.name}</div>
                              <div className="title">{robot._id}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    position="right center"
                    modal={true}
                  >
                    {(close) =>
                      selectedRobot !== undefined && selectedRobot !== null ? (
                        <div style={{ margin: "20px", height: "100%" }}>
                          <div className="row">
                            <div
                              className="col-md-6"
                              style={{ height: "100%" }}
                            >
                              <div className="card">
                                <div className="header">
                                  <h2>Robot's Details</h2>
                                </div>
                                <div className="content">
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Robot Name
                                  </div>
                                  <div className="username">{robot.name}</div>
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Robot Id
                                  </div>
                                  <div className="roboId">{robot._id}</div>
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Robot Type
                                  </div>
                                  <div className="type">{robot.type}</div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-md-6"
                              style={{ height: "100%" }}
                            >
                              <div className="card">
                                <div className="header">
                                  <h2>Configurations</h2>
                                </div>
                                <div className="content">
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Robot Behaviors
                                  </div>
                                  <Dropdown
                                    isSearchable
                                    options={robotBehaviors.map(
                                      (robotBehavior) => ({
                                        label:
                                          robotBehavior.en.name !== ""
                                            ? robotBehavior.en.name
                                            : robotBehavior.de.name !== ""
                                            ? robotBehavior.de.name
                                            : robotBehavior.fr.name !== ""
                                            ? robotBehavior.fr.name
                                            : robotBehavior.it.name,
                                        value: robotBehavior,
                                      })
                                    )}
                                    className="dropdown-speechaction"
                                    value={
                                      currentBehavior === undefined
                                        ? undefined
                                        : {
                                            label:
                                              currentBehavior.en.name !== ""
                                                ? currentBehavior.en.name
                                                : currentBehavior.de.name !== ""
                                                ? currentBehavior.de.name
                                                : currentBehavior.fr.name !== ""
                                                ? currentBehavior.fr.name
                                                : currentBehavior.it.name,
                                            value: currentBehavior,
                                          }
                                    }
                                    onChange={(selection) =>
                                      this.changeBehavior(selection.value)
                                    }
                                  />
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Chatbots
                                  </div>
                                  <Dropdown
                                    isSearchable
                                    options={chatbots.map((chatbot) => ({
                                      label: chatbot.name,
                                      value: chatbot,
                                    }))}
                                    className="dropdown-speechaction"
                                    value={
                                      currentChatbot === undefined
                                        ? undefined
                                        : {
                                            label: currentChatbot.name,
                                            value: currentChatbot,
                                          }
                                    }
                                    onChange={(selection) =>
                                      this.changeChatbot(selection.value)
                                    }
                                  />
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Menu Structures
                                  </div>
                                  <Dropdown
                                    isSearchable
                                    options={menuStructures.map(
                                      (menuStructure) => ({
                                        label:
                                          menuStructure.name.en !== ""
                                            ? menuStructure.name.en
                                            : menuStructure.name.de !== ""
                                            ? menuStructure.name.de
                                            : menuStructure.name.fr !== ""
                                            ? menuStructure.name.fr
                                            : menuStructure.name.it,
                                        value: menuStructure,
                                      })
                                    )}
                                    className="dropdown-speechaction"
                                    value={
                                      currentMenuStructure === undefined
                                        ? undefined
                                        : {
                                            label:
                                              currentMenuStructure.name.en !==
                                              ""
                                                ? currentMenuStructure.name.en
                                                : currentMenuStructure.name
                                                    .de !== ""
                                                ? currentMenuStructure.name.de
                                                : currentMenuStructure.name
                                                    .fr !== ""
                                                ? currentMenuStructure.name.fr
                                                : currentMenuStructure.name.it,
                                            value: currentMenuStructure,
                                          }
                                    }
                                    onChange={(selection) =>
                                      this.changeMenuStructure(selection.value)
                                    }
                                  />
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Application Style
                                  </div>
                                  <Dropdown
                                    isSearchable
                                    options={styles.map((style) => ({
                                      label: style.name,
                                      value: style,
                                    }))}
                                    className="dropdown-speechaction"
                                    value={
                                      currentStyle === undefined
                                        ? undefined
                                        : {
                                            label: currentStyle.name,
                                            value: currentStyle,
                                          }
                                    }
                                    onChange={(selection) =>
                                      this.changeStyle(selection.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-6"
                              style={{ height: "100%" }}
                            >
                              <div className="card">
                                <div className="header">
                                  <h2>Default configurations</h2>
                                </div>
                                <div className="content">
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Language
                                  </div>
                                  <Dropdown
                                    options={[
                                      { label: "English", value: "en" },
                                      { label: "German", value: "de" },
                                      { label: "French", value: "fr" },
                                      { label: "Italian", value: "it" },
                                    ]}
                                    className="dropdown-speechaction"
                                    value={this.getRobotLanguage()}
                                    onChange={(selection) =>
                                      this.setState({
                                        changesMade: true,
                                        selectedRobot: update(
                                          this.state.selectedRobot,
                                          {
                                            defaultConfiguration: {
                                              language: {
                                                $set: selection.value,
                                              },
                                            },
                                          }
                                        ),
                                      })
                                    }
                                  />
                                  <div
                                    className="title"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Volume:{" "}
                                    {selectedRobot.defaultConfiguration.volume}
                                  </div>
                                  <Slider
                                    defaultValue={
                                      selectedRobot.defaultConfiguration.volume
                                    }
                                    min={0}
                                    max={100}
                                    value={
                                      selectedRobot.defaultConfiguration.volume
                                    }
                                    onChange={(value) =>
                                      this.setState({
                                        changesMade: true,
                                        selectedRobot: update(
                                          this.state.selectedRobot,
                                          {
                                            defaultConfiguration: {
                                              volume: { $set: value },
                                            },
                                          }
                                        ),
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {changesMade ? (
                            <Button
                              style={{
                                position: "absolute",
                                width: "150px",
                                bottom: "15px",
                                right: "100px",
                              }}
                              onClick={() => this.saveRobot(selectedRobot)}
                              color="primary"
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              style={{
                                position: "absolute",
                                width: "150px",
                                bottom: "15px",
                                right: "100px",
                              }}
                              disabled={!this.isOnline(selectedRobot)}
                              onClick={() => this.syncRobot(selectedRobot)}
                              color="success"
                            >
                              Synchronize
                            </Button>
                          )}
                          <Button
                            style={{
                              position: "absolute",
                              bottom: "15px",
                              right: "25px",
                            }}
                            onClick={close}
                            color="secondary"
                          >
                            Close
                          </Button>
                          <NotificationSystem
                            ref={(ref) => (this.notificationSystem = ref)}
                          />
                        </div>
                      ) : undefined
                    }
                  </Popup>
                </div>
              ))}
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Home;
