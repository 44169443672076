import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, selectRobot, selectedRobot, updateRobotList, needRobot, moduleName,  ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('account') === null ? 
    <Redirect to={{
      pathname: '/signin',
      state: {
        message: 'Disconnected. Please log in again.',
      },
    }}
    />
    : JSON.parse(localStorage.getItem('account')).components.findIndex((comp) =>  comp.name === moduleName) < 0 ?
    <Redirect to={{
      pathname: '/',
      state: {
        message: 'Unauthorized',
      },
    }}
    />
    : needRobot && (localStorage.getItem('selectedRobot') === null || selectedRobot === null || selectedRobot === undefined) ?
    <Redirect to={{
      pathname: '/',
      state: {
        message: 'Please select a robot first in the top left corner.',
      },
    }}
    /> :
    <Component {...props} updateRobotList={updateRobotList} selectRobot={selectRobot} selectedRobot={selectedRobot} />
  )} />
)

export default ProtectedRoute;