import React from "react";
import { Button } from "reactstrap";
import io from "socket.io-client";
import Peer from "./Peer";
var mediaConfig = {
  audio: true,
  video: {
    mandatory: {},
    optional: [],
  },
};
let remoteStream = "";
let localStream = "";
let localId = "";
let peerDatabase = {};

const config = {
  peerConnectionConfig: {
    iceServers: [{ url: "stun:stun.l.google.com:19302" }],
  },
  peerConnectionConstraints: {
    optional: [{ DtlsSrtpKeyAgreement: true }],
  },
};

window.createIceServer = function (url, username, password) {
  var iceServer = null;
  var urlParts = url.split(":");
  if (urlParts[0].indexOf("stun") === 0) {
    // Create iceServer with stun url.
    iceServer = {
      url: url,
    };
  } else if (urlParts[0].indexOf("turn") === 0) {
    // Chrome M28 & above uses below TURN format.
    iceServer = {
      url: url,
      credential: password,
      username: username,
    };
  }
  return iceServer;
};

// Creates an ICEServer object from multiple URLs.
window.createIceServers = function (urls, username, password) {
  return {
    urls: urls,
    credential: password,
    username: username,
  };
};

// Get UserMedia (only difference is the prefix).
// Code from Adam Barth.
const getUserMedia = navigator.webkitGetUserMedia.bind(navigator);
navigator.getUserMedia = getUserMedia;

const socket = io(process.env.REACT_APP_SOCKETIO_URL, {
  rejectUnauthorized: false,
});

const offer = (remoteId) => {
  var pc = peerDatabase[remoteId].pc;
  pc.createOffer(function (sessionDescription) {
    pc.setLocalDescription(sessionDescription);
    send("offer", remoteId, sessionDescription);
  });
};

const answer = (remoteId) => {
  var pc = peerDatabase[remoteId].pc;
  console.log(remoteId);
  pc.createAnswer(
    function (sessionDescription) {
      pc.setLocalDescription(sessionDescription);
      send("answer", remoteId, sessionDescription);
    },
    function (failure) {
      console.log(failure);
    }
  );
};

const requestUserMedia = (constraints) => {
  return new Promise(function (resolve, reject) {
    var onSuccess = function (stream) {
      resolve(stream);
    };
    var onError = function (error) {
      reject(error);
    };

    try {
      getUserMedia(constraints, onSuccess, onError);
    } catch (e) {
      reject(e);
    }
  });
};

const send = (type, to, payload) => {
  console.log("sending " + type + " to " + to);

  socket.emit("message", {
    to: to,
    type: type,
    payload: payload,
  });
};

console.log(socket);

class Stream extends React.Component {
  constructor(props) {
    super(props);
    this.addPeer = this.addPeer.bind(this);
    this.startCamera = this.startCamera.bind(this);
    this.state = {
      remoteStream: React.createRef(),
      localStream: React.createRef(),
      remoteId: "",
    };
  }

  initStream = () => {
    const { selectedRobot } = this.props;
    /*const url = `/debug/stream/${selectedRobot._id}`;
    axios.post(url)
    .then((response) => {
      toast('Stream offer sent', {type: 'success'});
    })
    .catch((error) => {
      toast('Server error', {type: 'error'});
    })*/

    console.log("Emit init");
    console.log(socket);
    //socket.emit('message', { type: 'init', to: selectedRobot._id })
    socket.emit("message", { type: "init", to: "natel" });
    this.setState({
      streamPlaying: true,
    });
  };

  componentDidMount() {
    socket.on("message", this.handleMessage);
    socket.on("id", function (id) {
      console.log("Got local id: " + id);
      localId = id;
    });
    socket.emit("readyToStream", { name: "molo" });
    this.startCamera();
  }

  addPeer = (remoteId) => {
    var peer = new Peer(
      config.peerConnectionConfig,
      config.peerConnectionConstraints
    );
    peer.pc.onicecandidate = function (event) {
      if (event.candidate) {
        console.log(event.candidate);
        send("candidate", remoteId, {
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate,
        });
      }
    };
    peer.pc.onaddstream = function (event) {
      console.log("add stream");
      console.log("Remote Stream:");
      console.log(event.stream);
      let remotstr = this.state.remoteStream;
      remotstr.current.srcObject = event.stream;
      this.setState({
        remoteStream: remotstr,
      });
    }.bind(this);
    peer.pc.onremovestream = function (event) {
      this.setState({
        remoteStream: "",
      });
    };
    peer.pc.oniceconnectionstatechange = function (event) {
      console.log("Connection State Changed");
      console.log(event);
      switch (
        (
          event.srcElement || // Chrome
          event.target
        ).iceConnectionState // Firefox
      ) {
        case "disconnected":
          break;
        default:
          break;
      }
    };
    peerDatabase[remoteId] = peer;

    return peer;
  };

  handleMessage = (message) => {
    let { type, from } = message;
    let pc = (peerDatabase[from] || this.addPeer(from)).pc;

    console.log("received " + type + " from " + from);

    switch (type) {
      case "offer":
        this.setState({ remoteId: from });
        pc.setRemoteDescription(
          new RTCSessionDescription(message.payload),
          function () {},
          (error) => console.log(error)
        );
        answer(from);
        break;
      case "answer":
        pc.setRemoteDescription(
          new RTCSessionDescription(message.payload),
          function () {},
          (error) => console.log(error)
        );
        break;
      case "candidate":
        if (pc.remoteDescription) {
          console.log(message.payload);
          if (message.payload.candidate)
            pc.addIceCandidate(
              new RTCIceCandidate({
                sdpMLineIndex: message.payload.label,
                sdpMid: message.payload.id,
                candidate: message.payload.candidate,
              }),
              function () {},
              (error) => console.log(error)
            );
        }
        break;
      default:
        break;
    }
  };

  toggleLocalStream = (pc) => {
    if (this.state.localStream) {
      !!pc.getLocalStreams().length
        ? pc.removeStream(localStream)
        : pc.addStream(localStream);
    }
  };

  setLocalStream = (stream) => {
    // if local cam has been stopped, remove it from all outgoing streams.
    let localstr = this.state.localStream;
    localstr.current.srcObject = stream;
    this.setState({
      localStream: localstr,
      localStreamPlaying: true,
    });
  };

  startCamera = () => {
    return requestUserMedia(mediaConfig)
      .then(
        function (stream) {
          this.setLocalStream(stream);
          console.log(" Add local stream for " + this.state.remoteId);
          this.addPeer(this.state.remoteId).pc.addStream(stream);
        }.bind(this)
      )
      .catch(Error("Failed to get access to local media."));
  };

  render() {
    const { streamPlaying, remoteStream, localStream } = this.state;
    console.log(remoteStream);
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header">
              <h2 className="title">Stream camera</h2>
            </div>
            <Button
              color="primary"
              onClick={() => {
                this.initStream();
              }}
            >
              {streamPlaying ? "Stop" : "Start"}
            </Button>
            <div
              style={{ display: "flex", flexFlow: "column" }}
              className="content"
            >
              {streamPlaying && remoteStream !== "" ? (
                <div>
                  Remote
                  <video
                    ref={this.state.remoteStream}
                    controls
                    autoPlay
                  ></video>
                </div>
              ) : undefined}
            </div>
            <div
              style={{ display: "flex", flexFlow: "column" }}
              className="content"
            >
              {localStream !== "" ? (
                <div>
                  Local
                  <video
                    ref={this.state.localStream}
                    muted
                    controls
                    autoPlay
                  ></video>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stream;
