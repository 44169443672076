import cx from "classnames";
import React from "react";
import NotificationSystem from "react-notification-system";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Collapse, FormGroup, Input, Label } from "reactstrap";
import SpeechAction from "../../../components/SpeechAction";
import TextPreview from "../../../components/TextPreview";
import languages from "../../../constants/languages";
import axios from "../../../utils/AxiosService";

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.changeText = this.changeText.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeAnimation = this.changeAnimation.bind(this);
    this.selectStoryline = this.selectStoryline.bind(this);
    const { quizz } = this.props;
    this.state = {
      quizz,
      collapse: false,
      changesMade: false,
    };
  }

  componentDidUpdate = () => {
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  shouldBlockNavigation = () => {
    const { changesMade } = this.state;
    return changesMade;
  };

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  addQuestion = () => {
    const { quizz } = this.state;
    const tempQuizz = quizz;
    languages.forEach((lang) =>
      tempQuizz[lang].questions.push({
        questionWritten: "",
        questionOral: "",
        answersWritten: [],
        answersOral: [],
        correctAnswerId: 1,
        rightAnswerOutput: "",
        wrongAnswerOutput: "",
        mandatory: false,
      })
    );
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeText = (text) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].speechAction.text = text;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeAnimation = (animation) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].speechAction.animation = animation;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  deleteSubject = (questionInd) => {
    const { quizz } = this.state;
    const tempQuizz = quizz;
    languages.forEach((lang) => {
      tempQuizz[lang].questions.splice(questionInd, 1);
    });
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  saveQuizz = () => {
    const { selectedRobot } = this.props;
    const { quizz } = this.state;
    const url = `/quizzs/${quizz._id}/robots/${selectedRobot._id}`;
    axios.put(url, { quizz: quizz }).then((response) => {
      if (response.status === 200) {
        toast("Quizz saved", { type: "success" });
        this.setState({
          quizz: response.data,
          changesMade: false,
        });
      } else {
        toast("Network error", { type: "error" });
      }
    });
  };

  changePositiveResult = (value) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].resultWrittenPositive = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changePositiveResultSpeech = (value) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].resultOralPositive = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeNeutralResult = (value) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].resultWrittenNeutral = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeNeutralResultSpeech = (value) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].resultOralNeutral = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeNegativeResult = (value) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].resultWrittenNegative = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeNegativeResultSpeech = (value) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].resultOralNegative = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeName = (value) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].name = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeQuestionsToRead = (value) => {
    if (
      value === "" ||
      (value[value.length - 1] >= "0" && value[value.length - 1] <= "9")
    ) {
      const { lang } = this.props;
      const { quizz } = this.state;
      const tempQuizz = quizz;
      tempQuizz[lang].questionsToRead = value;
      this.setState({
        quizz: tempQuizz,
        changesMade: true,
      });
    } else {
      toast("Please enter a number", { type: "error" });
    }
  };

  setFeedback = () => {
    const { quizz } = this.state;
    const tempQuizz = quizz;

    languages.forEach((lang) => {
      tempQuizz[lang].sendFeedback = !tempQuizz[lang].sendFeedback;
    });
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  setRandom = () => {
    const { quizz } = this.state;
    const tempQuizz = quizz;

    languages.forEach((lang) => {
      tempQuizz[lang].randomOrder = !tempQuizz[lang].randomOrder;
    });
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  setMandatory = (questIndex) => {
    const { quizz } = this.state;
    const { lang } = this.props;
    const tempQuizz = quizz;

    tempQuizz[lang].questions[questIndex].mandatory =
      !tempQuizz[lang].questions[questIndex].mandatory;

    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  addAnswer = (index) => {
    const { quizz } = this.state;
    const tempQuizz = quizz;
    languages.forEach((lang) => {
      tempQuizz[lang].questions[index].answersWritten.push("");
      tempQuizz[lang].questions[index].answersOral.push("");
    });
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  deleteAnswer = (questIndex, answIndex) => {
    const { quizz } = this.state;
    const tempQuizz = quizz;
    languages.forEach((lang) => {
      tempQuizz[lang].questions[questIndex].answersWritten.splice(answIndex, 1);
      tempQuizz[lang].questions[questIndex].answersOral.splice(answIndex, 1);
    });
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeAnswerWritten = (value, questIndex, answIndex) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].questions[questIndex].answersWritten[answIndex] = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeAnswerOral = (value, questIndex, answIndex) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].questions[questIndex].answersOral[answIndex] = value;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeWrittenQuestion = (question, subjectInd) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].questions[subjectInd].questionWritten = question;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  changeOralQuestion = (question, subjectInd) => {
    const { lang } = this.props;
    const { quizz } = this.state;
    const tempQuizz = quizz;
    tempQuizz[lang].questions[subjectInd].questionOral = question;
    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  setCorrectAnswer = (questIndex, answerInd) => {
    const { quizz } = this.state;
    const tempQuizz = quizz;
    languages.forEach((lang) => {
      tempQuizz[lang].questions[questIndex].correctAnswerId = answerInd;
    });

    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  selectStoryline = (storyline) => {
    const { quizz } = this.state;
    const tempQuizz = quizz;
    languages.forEach((lang) => {
      tempQuizz[lang].speechAction = storyline;
    });

    this.setState({
      quizz: tempQuizz,
      changesMade: true,
    });
  };

  render() {
    const { lang, selectedRobot } = this.props;
    const { quizz, collapse } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={this.shouldBlockNavigation()}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="header">
                <h1 className="title">Quizz</h1>
                <p className="category">Here you can create a quizz</p>
              </div>
              <div className="content">
                <div
                  style={{ position: "absolute", top: "25px", right: "46px" }}
                >
                  <Link to="/quizzes">
                    <Button
                      style={{ height: "34px", marginRight: "10px" }}
                      className=" btn-primary pe-7s-back"
                    ></Button>
                  </Link>
                  <Button color="primary" onClick={this.saveQuizz}>
                    Save changes
                  </Button>
                </div>
                <br />
                {quizz === null ? undefined : (
                  <SpeechAction
                    selectedRobot={selectedRobot}
                    storyline={quizz[lang].speechAction}
                    selectStoryline={this.selectStoryline}
                  />
                )}
                <h4 style={{ textAlign: "left" }}>Quizz name</h4>
                <Input
                  onChange={(event) => this.changeName(event.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Quizz name"
                  value={quizz[lang].name}
                />
                <h4 style={{ textAlign: "left" }}>Questions to ask</h4>
                <Input
                  onChange={(event) =>
                    this.changeQuestionsToRead(event.target.value)
                  }
                  type="text"
                  name="questions"
                  id="questions"
                  placeholder="Questions to ask"
                  value={quizz[lang].questionsToRead}
                />
                <h4>
                  Send feedback to the server?&nbsp;
                  <Input
                    defaultChecked={quizz[lang].sendFeedback}
                    onChange={this.setFeedback}
                    type="checkbox"
                  />{" "}
                </h4>
                <h4>
                  Ask questions in a random order?&nbsp;
                  <Input
                    defaultChecked={quizz[lang].randomOrder}
                    onChange={this.setRandom}
                    type="checkbox"
                  />{" "}
                </h4>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="header">
                <h1 className="title">Quizz result speech</h1>
                <p className="category">Click the button to expand</p>
                {quizz === null ? undefined : (
                  <div>
                    <Collapse isOpen={this.state.collapse}>
                      <h4 style={{ textAlign: "left" }}>Positive result</h4>
                      <Input
                        onChange={(event) =>
                          this.changePositiveResult(event.target.value)
                        }
                        type="text"
                        placeholder="Positive result"
                        value={quizz[lang].resultWrittenPositive}
                      />

                      <h4 style={{ textAlign: "left" }}>
                        Positive result speech
                      </h4>
                      <div className="oral-input">
                        <Input
                          onChange={(event) =>
                            this.changePositiveResultSpeech(event.target.value)
                          }
                          type="text"
                          placeholder="Positive result speech"
                          value={quizz[lang].resultOralPositive}
                        />
                        <TextPreview
                          text={quizz[lang].resultOralPositive}
                          robotId={selectedRobot._id}
                        />
                      </div>

                      <h4 style={{ textAlign: "left" }}>Neutral result</h4>
                      <Input
                        onChange={(event) =>
                          this.changeNeutralResult(event.target.value)
                        }
                        type="text"
                        placeholder="Neutral result"
                        value={quizz[lang].resultWrittenNeutral}
                      />

                      <h4 style={{ textAlign: "left" }}>
                        Neutral result speech
                      </h4>
                      <div className="oral-input">
                        <Input
                          onChange={(event) =>
                            this.changeNeutralResultSpeech(event.target.value)
                          }
                          type="text"
                          placeholder="Neutral result speech"
                          value={quizz[lang].resultOralNeutral}
                        />
                        <TextPreview
                          text={quizz[lang].resultOralNeutral}
                          robotId={selectedRobot._id}
                        />
                      </div>

                      <h4 style={{ textAlign: "left" }}>Negative result</h4>
                      <Input
                        onChange={(event) =>
                          this.changeNegativeResult(event.target.value)
                        }
                        type="text"
                        placeholder="Negative result"
                        value={quizz[lang].resultWrittenNegative}
                      />

                      <h4 style={{ textAlign: "left" }}>
                        Negative result speech
                      </h4>
                      <div className="oral-input">
                        <Input
                          onChange={(event) =>
                            this.changeNegativeResultSpeech(event.target.value)
                          }
                          type="text"
                          placeholder="Negative result speech"
                          value={quizz[lang].resultOralNegative}
                        />
                        <TextPreview
                          text={quizz[lang].resultOralNegative}
                          robotId={selectedRobot._id}
                        />
                      </div>
                      <br />
                    </Collapse>
                    <Button
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        backgroundColor: "transparent",
                        border: "1px solid lightgray",
                      }}
                      onClick={this.toggle}
                    >
                      <span
                        className={cx("pe-7s-angle-down collapse-arrow", {
                          active: collapse,
                        })}
                      ></span>
                    </Button>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {quizz === null ? undefined : quizz[lang].questions.length === 0 ? (
              <div className="card">
                <div className="content">
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={this.addQuestion}
                    >
                      Add question
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              quizz[lang].questions.map((subject, index) => (
                <React.Fragment key={subject._id}>
                  <div className="card">
                    <div
                      className="content table-responsive table-full-width"
                      style={{ marginBottom: "60px" }}
                    >
                      <div style={{ position: "relative" }} key={subject._id}>
                        <h4>Question {index + 1}</h4>
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                          }}
                        >
                          <Button
                            style={{ float: "right", marginBottom: "10px" }}
                            onClick={() => this.deleteSubject(index)}
                            className="btn btn-danger btn-simple btn-icon "
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </div>
                        <Label for="question">Written question</Label>
                        <Input
                          onChange={(event) =>
                            this.changeWrittenQuestion(
                              event.target.value,
                              index
                            )
                          }
                          type="text"
                          name="writtenq"
                          id="writtenq"
                          placeholder="Written question"
                          value={subject.questionWritten}
                        />
                        <br />
                        <Label for="question">Oral question</Label>
                        <div className="oral-input">
                          <Input
                            onChange={(event) =>
                              this.changeOralQuestion(event.target.value, index)
                            }
                            type="text"
                            name="oralq"
                            id="oralq"
                            placeholder="Oral question"
                            value={subject.questionOral}
                          />
                          <TextPreview
                            text={subject.questionOral}
                            robotId={selectedRobot._id}
                          />
                        </div>
                        <Label for="question">
                          Mandatory question?&nbsp;
                          <Input
                            defaultChecked={subject.mandatory}
                            onChange={() => this.setMandatory(index)}
                            type="checkbox"
                          />{" "}
                        </Label>
                        <br />
                        <Label
                          style={{ marginBottom: "20px", marginTop: "10px" }}
                          for="answer"
                        >
                          Answers
                        </Label>
                        <FormGroup tag="fieldset">
                          <table className="table table-bigboy">
                            <thead>
                              <tr>
                                <th>Written answer</th>
                                <th>Oral answer</th>
                                <th>Correct answer</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {subject.answersWritten === null
                                ? undefined
                                : subject.answersWritten.map(
                                    (answer, answIndex) => (
                                      <tr key={answIndex}>
                                        <td className="td-name">
                                          <Input
                                            onChange={(event) =>
                                              this.changeAnswerWritten(
                                                event.target.value,
                                                index,
                                                answIndex
                                              )
                                            }
                                            type="text"
                                            placeholder="Written answer"
                                            value={answer}
                                          />
                                        </td>
                                        <td>
                                          <div className="oral-input">
                                            <Input
                                              onChange={(event) =>
                                                this.changeAnswerOral(
                                                  event.target.value,
                                                  index,
                                                  answIndex
                                                )
                                              }
                                              type="text"
                                              placeholder="Oral answer"
                                              value={
                                                subject.answersOral[answIndex]
                                              }
                                            />
                                            <TextPreview
                                              text={
                                                subject.answersOral[answIndex]
                                              }
                                              robotId={selectedRobot._id}
                                            />
                                          </div>
                                        </td>
                                        <td className="td-actions">
                                          <Input
                                            type="radio"
                                            name={`correctAnswer${subject._id}`}
                                            onChange={() =>
                                              this.setCorrectAnswer(
                                                index,
                                                answIndex
                                              )
                                            }
                                            defaultChecked={
                                              subject.correctAnswerId ===
                                              answIndex
                                            }
                                          />{" "}
                                        </td>
                                        <td className="td-actions">
                                          <button
                                            type="button"
                                            onClick={() =>
                                              this.deleteAnswer(
                                                index,
                                                answIndex
                                              )
                                            }
                                            rel="tooltip"
                                            data-placement="left"
                                            title=""
                                            className="btn btn-danger btn-simple btn-icon "
                                            data-original-title="Remove answer"
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                            </tbody>
                          </table>
                        </FormGroup>
                        <Button
                          color="primary"
                          style={{ marginRight: "10px" }}
                          onClick={() => this.addAnswer(index)}
                        >
                          Add answer
                        </Button>
                        <br />
                      </div>
                      {index === quizz[lang].questions.length - 1 ? (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "45px",
                            right: "35px",
                          }}
                        >
                          <Button
                            color="primary"
                            style={{ marginRight: "10px" }}
                            onClick={this.addQuestion}
                          >
                            Add question
                          </Button>
                        </div>
                      ) : undefined}
                    </div>
                  </div>
                </React.Fragment>
              ))
            )}
          </div>
          <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        </div>
      </React.Fragment>
    );
  }
}

export default QuestionList;
