export const HOME = '/';
export const ROBOTS = '/robots';
export const ROBOT = '/robot';
export const SIGNUP = '/signup';
export const SIGNIN = '/signin';
export const ADMINCONSOLE = '/adminconsole';
export const WALLPAPER = '/wallpaper';
export const MODULES = '/modules';
export const PRESENTATIONS = '/presentations';
export const PRESENTATION = '/presentation';
