import React from 'react';
import { Input } from 'reactstrap';
import Dropdown from 'react-select';
import { triggerTypes, targetTypes } from '../constants/triggerTypes';

class RobotDialog extends React.Component {
  constructor(props) {
    super(props);
    const { storyline, index } = this.props;
    if (storyline.events[index].robot_dialog === undefined) {
      storyline.events[index].robot_dialog = {
        listen: true,
        target: 'ROBOT',
        intent_trigger: {
          type: 'TEXT',
          value: ''
        },
        timeout: -1
      }
    }
    this.state = {
        storyline,
        index,
    };
  }

  componentDidUpdate(prevProps) {
    const { index, storyline, lang } = this.props;
    if (prevProps.index !== index || prevProps.storyline !== storyline || prevProps.lang !== lang) {
      if (storyline.events[index].robot_dialog === undefined) {
        storyline.events[index].robot_dialog = {
          listen: true,
          target: 'ROBOT',
          intent_trigger: {
            type: 'TEXT',
            value: ''
          },
          timeout: -1
        }
      }
      this.setState({
        storyline: storyline,
        index: index
      });
    }
  }

  render() {
    const { changeDialogTarget, changeListen, changeIntentTriggerType, changeIntentTriggerValue, changeTimeout } = this.props;
    const { storyline, index } = this.state;
    
    if (index > storyline.events.length-1) {
      return (<div></div>)
    }
    return (
      <div>
        <p>Select target</p>
        <Dropdown options={targetTypes} onChange={changeDialogTarget} value={{ label: storyline.events[index].robot_dialog.target, value: storyline.events[index].robot_dialog.target }} placeholder="Target..." />
        <p>Robot listening?</p>
        <Input type="checkbox" checked={storyline.events[index].robot_dialog.listen} onClick={() => changeListen()} />
        <p>Intent trigger type</p>
        <Dropdown options={triggerTypes} onChange={changeIntentTriggerType} value={{ label: storyline.events[index].robot_dialog.intent_trigger.type, value: storyline.events[index].robot_dialog.intent_trigger.type }} placeholder="Intent trigger type..." />
        <p>Intent trigger</p>
        <Input type="text" value={storyline.events[index].robot_dialog.intent_trigger.value} onChange={(event) => changeIntentTriggerValue(event.target.value)} placeholder="Intent trigger..." />
        <p>Timeout [s]</p>
        <Input type="number" value={storyline.events[index].robot_dialog.timeout} onChange={(event) => changeTimeout(event.target.value)} placeholder="Timeout..." />
      </div>
    )
  }
}

export default RobotDialog;
