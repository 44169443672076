import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";

class Wallpaper extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      file: null,
      selectedRobot,
      wallpapers: [],
      selectedWallpaper: null,
      previewWallpaper: null,
      error: null,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.fetchImages();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        selectedRobot: nextProps.selectedRobot,
      },
      this.fetchImages
    );
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { updateRobotList } = this.props;
    const { wallpapers } = this.state;
    if (wallpapers.length < 6) {
      const { selectedRobot } = this.props;
      const { file } = this.state;
      if (file !== undefined && file !== null) {
        const formData = new FormData();
        formData.append("myImage", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const url = `/wallpapers/robot/${selectedRobot._id}`;
        axios
          .post(url, formData, config)
          .then((response) => {
            if (response.status === 200) {
              this.setState(
                {
                  wallpapers: response.data,
                  error: null,
                },
                updateRobotList
              );
            }
          })
          .catch(() => {
            toast("Upload failed", { type: "error" });
          });
      } else {
        toast("No file selected", { type: "error" });
      }
    } else {
      toast("Maximum 6 wallpapers per robot.", { type: "error" });
    }
  }

  onChange(e) {
    if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
      this.setState({
        file: e.target.files[0],
        previewWallpaper: URL.createObjectURL(e.target.files[0]),
      });
    }
  }

  fetchImages = () => {
    const url = `/wallpapers`;
    axios.get(url).then((result) => {
      this.setState({
        wallpapers: result.data,
      });
    });
  };

  selectImage = (image) => {
    this.setState({
      selectedWallpaper: image,
    });
  };

  onSelectSubmit = () => {
    const { selectedWallpaper } = this.state;
    const { selectedRobot, updateRobotList } = this.props;
    const url = `/wallpapers/select/${selectedRobot._id}`;
    axios.put(url, selectedWallpaper).then((result) => {
      this.setState(
        {
          wallpapers: result.data,
        },
        updateRobotList
      );
    });
  };

  onRemoveSubmit = () => {
    const { selectedWallpaper } = this.state;
    const { selectedRobot } = this.props;
    const url = `/wallpapers/robot/${selectedRobot._id}`;
    axios.put(url, { selectedWallpaper }).then((result) => {
      this.setState({
        wallpapers: result.data,
      });
    });
  };

  render() {
    const { selectedRobot } = this.state;
    const { wallpapers, previewWallpaper, selectedWallpaper } = this.state;
    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Wallpapers</h1>
                <p className="category">
                  Here you can choose the image that will be displayed on the
                  tablet background
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card" style={{ padding: "10px" }}>
              <div className="header">
                <h4 className="title">Upload a new wallpaper</h4>
                {selectedRobot !== null && previewWallpaper !== null ? (
                  <img
                    style={{
                      marginTop: "30px",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    src={`${previewWallpaper}`}
                    alt="Wallpaper"
                  />
                ) : undefined}
                <form
                  style={{
                    marginLeft: "0px",
                    maxWidth: "140px",
                    fontSize: "16px",
                  }}
                  className="form-container flex-wallpaper"
                  onSubmit={this.onFormSubmit}
                >
                  <label
                    htmlFor="slide-file"
                    className="input-image"
                    style={{ marginTop: "0px", width: "100%" }}
                  >
                    <i className="fa fa-image"></i>&nbsp;&nbsp;Select a file
                    <input
                      name="myImage"
                      type="file"
                      accept="image/*"
                      onChange={this.onChange}
                    />
                  </label>
                </form>
                <br />
                <div className=" ">
                  <Button
                    style={{ width: "80px" }}
                    className="btn btn-primary"
                    onClick={this.onFormSubmit}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="header">
                <h4 className="title">Select a wallpaper</h4>
                <div className="images-container">
                  {wallpapers.map((wallpaper) => (
                    <div key={wallpaper.url} className="image-container">
                      <div
                        style={
                          wallpaper.selected
                            ? {
                                backgroundColor: "#dd7171",
                                borderRadius: "6px",
                              }
                            : undefined
                        }
                      >
                        <button
                          type="button"
                          onClick={() => this.selectImage(wallpaper)}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                        >
                          <img
                            className="image-content"
                            src={`${wallpaper.url}`}
                            alt="Wallpaper"
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="wallpaper-buttons">
                <Button
                  color="primary"
                  style={{ float: "right" }}
                  onClick={this.onSelectSubmit}
                  disabled={selectedWallpaper === null}
                >
                  Set active
                </Button>
                <Button
                  color="danger"
                  style={{ float: "right" }}
                  onClick={this.onRemoveSubmit}
                  disabled={selectedWallpaper === null}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default Wallpaper;
