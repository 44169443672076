import cx from "classnames";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import offlineDot from "../../assets/images/offline.png";
import onlineDot from "../../assets/images/online.png";
import waitingDot from "../../assets/images/waiting.png";
import { robotImage } from "../../constants/robotImages";
import axios from "../../utils/AxiosService.js";
import questionMark from "./assets/images/question.png";
import * as routes from "./constants/routes";

class RobotSelection extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    const { selectedRobot, robots } = this.props;
    this.state = {
      selectedRobot:
        JSON.parse(localStorage.getItem("selectedRobot")) ||
        selectedRobot ||
        null,
      wallLoaded: false,
      statusLoaded: false,
      robotStatus: [],
      wallpaper: null,
      collapse: false,
      robots: robots,
    };
  }

  componentDidMount() {
    const { updateRobotList } = this.props;
    updateRobotList();
    this.fetchWallpaper();
    this.fetchStatus();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        selectedRobot: nextProps.selectedRobot,
        robots: nextProps.robots,
        wallLoaded: false,
        statusLoaded: false,
      },
      () => this.fetchWallpaper(),
      this.fetchStatus()
    );
  }

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  selectRobot(robot) {
    const { activateNav, selectRobot } = this.props;
    localStorage.setItem("selectedRobot", JSON.stringify(robot));
    this.setState(
      {
        selectedRobot: robot,
      },
      this.fetchWallpaper
    );
    this.toggle();
    selectRobot(robot);
    activateNav();
  }

  fetchWallpaper = () => {
    const { selectedRobot } = this.state;
    if (selectedRobot !== null && selectedRobot !== undefined) {
      const url = `/wallpapers/selected`;
      axios.get(url).then((result) => {
        this.setState({
          wallpaper: result.data,
          wallLoaded: true,
        });
      });
    }
  };

  fetchStatus = () => {
    const url = `/robots/status`;
    axios.get(url).then((response) => {
      this.setState({
        robotStatus: response.data,
        statusLoaded: true,
      });
    });
  };

  refreshRobotList = () => {
    toast("Robot list updated", { type: "success" });
    this.fetchStatus();
  };

  editRobot = () => {
    const { robot, selectRobot } = this.props;
    selectRobot(robot);
  };

  selectAction = (action) => {
    this.setState(
      {
        selectedAction: action,
      },
      action.value()
    );
  };

  compouteStatus = (robot) => {
    const { robotStatus, statusLoaded } = this.state;
    if (statusLoaded && robotStatus !== undefined && robotStatus.length > 0) {
      const tarRobot = robotStatus.find(
        (robotStat) => robot !== undefined && robotStat.robotId === robot._id
      );
      if (tarRobot !== undefined) {
        if (tarRobot.online) {
          return onlineDot;
        } else {
          return offlineDot;
        }
      }
    }
    return waitingDot;
  };

  isConnected = (robot) => {
    const { robotStatus, statusLoaded } = this.state;
    if (
      statusLoaded &&
      robotStatus !== undefined &&
      robotStatus.length > 0 &&
      robot !== null
    ) {
      const tarRobot = robotStatus.find(
        (robotStat) => robot !== undefined && robotStat.robotId === robot._id
      );
      if (tarRobot !== undefined) {
        if (tarRobot.online) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  render() {
    let { collapse, selectedRobot, robots } = this.state;

    let image = questionMark;
    if (selectedRobot !== undefined && selectedRobot !== null) {
      image = robotImage[selectedRobot.type];
    }

    return (
      <div style={{ display: "flex", flexFlow: "column" }}>
        <div style={{ display: "flex", flexFlow: "column", margin: "10px" }}>
          <Link to={routes.HOME}>
            <Button
              style={{
                width: "100%",
                backgroundColor: "#3c4d6b",
                marginTop: "10px",
              }}
            >
              <i className="pe-7s"></i>Configuration Overview
            </Button>
          </Link>
          <Button
            style={{
              width: "100%",
              backgroundColor: "#3c4d6b",
              marginTop: "10px",
            }}
            onClick={() => this.refreshRobotList()}
          >
            <i className="pe-7s-refresh"></i>Refresh robot list
          </Button>
        </div>
        <div className="user-wrapper">
          <div className="user">
            <div className="photo-container">
              <img src={image} alt="Robot avatar" className="photo" />
            </div>

            <div className="userinfo">
              <div className="username">
                {selectedRobot === null || selectedRobot === undefined
                  ? "Select a robot"
                  : selectedRobot.name}
              </div>
              <div className="title">
                {selectedRobot === null || selectedRobot === undefined
                  ? undefined
                  : selectedRobot._id}
              </div>
            </div>
            <span
              onClick={this.toggle}
              className={cx("pe-7s-angle-down collapse-arrow", {
                active: collapse,
              })}
            ></span>
          </div>
          <Collapse isOpen={this.state.collapse}>
            <Nav>
              {robots.map((robot) => (
                <NavItem key={robot._id}>
                  <NavLink
                    className="btn-robot-selection"
                    color="primary"
                    key={robot._id}
                    onClick={() => this.selectRobot(robot)}
                  >
                    <img
                      style={{ marginTop: "-4px" }}
                      height="11px"
                      alt="status"
                      src={this.compouteStatus(robot)}
                    />
                    &nbsp;&nbsp;{`${robot.name} (${robot._id})`}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </div>
        <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
      </div>
    );
  }
}

export default RobotSelection;
