import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "../../utils/AxiosService";

class Quizzes extends Component {
  constructor(props) {
    super(props);
    const { selectedRobot } = this.props;
    this.state = {
      selectedRobot,
      quizzes: [],
      newQuizz: null,
      error: null,
      message: null,
      redirect: false,
    };
  }

  componentDidMount() {
    this.fetchQuizzes();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        selectedRobot: nextProps.selectedRobot,
      },
      this.fetchQuizzes
    );
  }

  fetchQuizzes = () => {
    const url = `/quizzs`;
    axios.get(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          quizzes: response.data.map((config) => config.configuration),
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  newQuizz = () => {
    const url = `/quizzs`;
    axios.post(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          newQuizz: response.data,
          redirect: true,
        });
      } else {
        this.setState({
          error: "Network error",
        });
      }
    });
  };

  deleteQuizz = (id) => {
    const { selectedRobot } = this.state;
    const url = `/quizzs/${id}/robot/${selectedRobot._id}`;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          quizzes: response.data.map((config) => config.configuration),
          selectedQuizz: null,
        });
      } else {
        this.setState({
          error: "Server error",
        });
      }
    });
  };

  render() {
    const { quizzes, redirect, newQuizz } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/quizz",
            state: {
              quizz: newQuizz,
            },
          }}
        />
      );
    }

    return (
      <div style={{ margin: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h1 className="title">Quizz</h1>
                <p className="category">
                  Here you can select a quizz to edit or create a new one by
                  clicking on the +
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <Button
                  onClick={this.newQuizz}
                  className="btn-success"
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                >
                  New quizz <i className="pe-7s-plus"></i>
                </Button>
                <h4 className="title">
                  Select a quizz to edit or create a new one
                </h4>
              </div>
              <div className="content">
                <table className="table table-bigboy">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width="40px">Edit</th>
                      <th width="40px">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizzes === null
                      ? undefined
                      : quizzes.map((quiz) => (
                          <tr key={quiz._id}>
                            <td className="td-name">
                              {quiz.en.name !== ""
                                ? quiz.en.name
                                : quiz.de.name !== ""
                                ? quiz.de.name
                                : quiz.fr.name !== ""
                                ? quiz.fr.name
                                : quiz.it.name}
                            </td>
                            <td className="td-name">
                              <Button
                                tag={Link}
                                to={{
                                  pathname: "/quizz",
                                  state: {
                                    quizz: quiz,
                                  },
                                }}
                                color="primary"
                              >
                                <i className="pe-7s-pen"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => this.deleteQuizz(quiz._id)}
                                color="danger"
                              >
                                <i className="pe-7s-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Quizzes;
