export const fonts = [
    { label: 'casual', value: 'casual' },
    { label: 'cursive', value: 'cursive' },
    { label: 'monospace', value: 'monospace' },
    { label: 'sans-serif', value: 'sans-serif' },
    { label: 'sans-serif-black', value: 'sans-serif-black' },
    { label: 'sans-serif-condensed', value: 'sans-serif-condensed' },
    { label: 'sans-serif-condensed-light', value: 'sans-serif-condensed-light' },
    { label: 'sans-serif-condensed-medium', value: 'sans-serif-condensed-medium' },
    { label: 'sans-serif-light', value: 'sans-serif-light' },
    { label: 'sans-serif-medium', value: 'sans-serif-medium' },
    { label: 'sans-serif-smallcaps', value: 'sans-serif-smallcaps' },
    { label: 'sans-serif-thin', value: 'sans-serif-thin' },
    { label: 'serif', value: 'serif' },
    { label: 'open-sans', value: 'open-sans' },
    { label: 'questrial', value: 'questrial' }
  ];

  export const styles = [
    { label: 'normal', value: 'normal' },
    { label: 'bold', value: 'bold' },
    { label: 'italic', value: 'italic' },
    { label: 'boldItalic', value: 'boldItalic' }
  ];