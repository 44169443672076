import React from "react";
import Dropdown from "react-select";
import { Input } from "reactstrap";
import { types, typesToEndpoint } from "../../../constants/moduleTypes";
import axios from "../../../utils/AxiosService";

class RobotActivity extends React.Component {
  constructor(props) {
    super(props);
    const { storyline, index } = this.props;
    this.state = {
      storyline,
      index,
      moduleType: { label: "Select a module type...", value: "" },
      modules: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { index, storyline, lang } = this.props;
    if (
      prevProps.index !== index ||
      prevProps.storyline !== storyline ||
      prevProps.lang !== lang
    ) {
      this.setState({
        storyline: storyline,
        index: index,
      });
    }
  }

  getDropDownType = (type) => {
    const tmpType = types.find((ty) => ty.value === type);
    if (tmpType !== undefined) {
      return { label: tmpType.label, value: type };
    } else {
      return { label: "Select a type...", value: "" };
    }
  };

  changeModuleType = (type) => {
    const { changeActivityType } = this.props;
    changeActivityType(type);
    if (type.value !== "ANDROID") {
      this.setState(
        {
          moduleType: type,
        },
        () => this.fetchModules(type)
      );
    }
  };

  fetchModules = (type) => {
    const { lang } = this.props;
    const url = `/${typesToEndpoint[type.value]}`;
    axios.get(url).then((response) => {
      console.log(response.data);
      this.setState({
        modules: response.data.map((mod) =>
          mod.configuration !== undefined
            ? {
                label: mod.configuration.hasOwnProperty("name")
                  ? mod.configuration.name
                  : mod.configuration[lang].name,
                value: mod.configuration._id,
              }
            : {
                label: mod.hasOwnProperty("name") ? mod.name : mod[lang].name,
                value: mod._id,
              }
        ),
      });
    });
  };

  render() {
    const { changeTarget, changeIsActivity } = this.props;
    const { storyline, index, moduleType, modules } = this.state;

    if (index > storyline.events.length - 1) {
      return <div></div>;
    }
    return (
      <div>
        <p>Enable?</p>
        <Input
          type="checkbox"
          defaultChecked={storyline.events[index].isActivity}
          onChange={changeIsActivity}
        ></Input>

        <Dropdown
          options={types}
          onChange={this.changeModuleType}
          value={{
            label: storyline.events[index].activity.type,
            value: storyline.events[index].activity.type,
          }}
          placeholder="Type"
          isDisabled={!storyline.events[index].isActivity}
        />
        {storyline.events[index].activity.type === "ANDROID" ? (
          <div>
            <br />
            <p>Package name (e.g. com.example.app)</p>
            <Input
              disabled={!storyline.events[index].isActivity}
              onChange={(event) =>
                changeTarget(
                  event.target.value,
                  storyline.events[index].activity.type,
                  ""
                )
              }
            />
          </div>
        ) : storyline.events[index].activity.type !== "" ? (
          <div>
            <p>Select module</p>
            <Dropdown
              options={modules}
              isDisabled={!storyline.events[index].isActivity}
              onChange={(field) =>
                changeTarget(
                  field.value,
                  storyline.events[index].activity.type,
                  field.label
                )
              }
              value={{
                label: storyline.events[index].activity.name,
                value: storyline.events[index].activity.target,
              }}
              placeholder="Type"
              disabled={moduleType.value === ""}
            />
          </div>
        ) : undefined}
      </div>
    );
  }
}

export default RobotActivity;
